/**
 * Created by happy on 11.02.17.
 */

export const MANAGER = 'MANAGER_';
export const CORE = 'CORE_';
export const SIMPLE = 'SIMPLE_';
// export const ENDPOINT = 'http://localhost:8001/';
export const ENDPOINT = 'https://r-stat.org/api/v2/';
// export const WS_ENDPOINT = 'ws://127.0.0.1:1234/ws';
export const WS_ENDPOINT = 'wss://new.r-stat.org/ws';
export const CONSTRUCTOR_API_URL = 'https://r-stat.org/sport-site-api/';
// export const CONSTRUCTOR_API_URL = 'http://localhost:8160/';

export const SHOW_SNACKBAR = 'SHOW_SNACKBAR';
export const HIDE_SNACKBAR = 'HIDE_SNACKBAR';