import {MANAGER} from '../../constants/Common';
import {
    DOC_SET_ID,
    DOC_SHOW_FORM
} from "../../constants/Site";
import {makeSiteDispatch, siteApi} from "../../helpers/api";
import {CREATE, DELETE, DOC, LIST, UPDATE, VIEW} from "../../constants/Global";

export function showDocForm(open, type) {
    return (dispatch) => {
        dispatch({
            type: type + DOC_SHOW_FORM,
            payload: open
        })
    }
}

export function setDocID(id, type) {
    return (dispatch) => {
        dispatch({
            type: type + DOC_SET_ID,
            payload: id
        })
    }
}

export function viewDoc(id, type) {
    return (dispatch) => {
        siteApi.get('documents/' + id).then(function (response) {
            dispatch(makeSiteDispatch(MANAGER, VIEW, DOC, response));
        }).catch(function (error) {
            console.log(error)
            dispatch(makeSiteDispatch(MANAGER, VIEW, DOC, error.response));
        });
    }
}

export function listDocs(id, type) {
    return (dispatch) => {
        siteApi.get('documents/list/' + id).then(function (response) {
            dispatch(makeSiteDispatch(type, LIST, DOC, response));
        }).catch(function (error) {
            dispatch(makeSiteDispatch(type, LIST, DOC, error))
        });
    }
}

export function deleteDoc(id, type) {
    return (dispatch) => {
        siteApi.delete('documents/' + id).then(function (response) {
            dispatch(makeSiteDispatch(MANAGER, DELETE, DOC, response,parseInt(id, 10)));
        }).catch(function (error) {
            dispatch(makeSiteDispatch(MANAGER, DELETE, DOC, error.response));
        });
    }
}

export function createDoc(body, type) {
    return (dispatch) => {
        siteApi.post('documents', body).then(function (response) {
            dispatch(makeSiteDispatch(MANAGER, CREATE, DOC, response));
        }).catch(function (error) {
            dispatch(makeSiteDispatch(MANAGER, CREATE, DOC, error.response));
        });
    }
}

export function updateDoc(id, body, type) {
    return (dispatch) => {
        siteApi.put('documents/' + id, body).then(function (response) {
            dispatch(makeSiteDispatch(MANAGER, UPDATE, DOC, response));
        }).catch(function (error) {
            dispatch(makeSiteDispatch(MANAGER, UPDATE, DOC, error.response));
        });
    }
}
