/**
 * Created by happy on 20.02.17.
 */
import React from 'react';
import {FOOTBALL_PROTOCOL_ROW} from "../../constants/Protocol";
import ProtocolDropDown from "./ProtocolDropDown";
import {FormattedMessage} from "react-intl";


class ProtocolPlayerOld extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            matchPlayers: []
        };

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        let name = target.attributes.getNamedItem('data').value;

        this.setState({
            [name]: value
        });
    }

    componentWillReceiveProps(nextProps) {
        let number;
        let player;
        let ampluaID;
        let played;
        let best;
        let dataNumber;
        let dataPlayer;
        let dataAmplua;
        let dataPlayed;
        let dataBest;

        for (let i = 0; i < FOOTBALL_PROTOCOL_ROW; i++) {

            dataNumber = 'number_' + i
            dataPlayer = 'player_' + i
            dataAmplua = 'amplua_' + i
            dataPlayed = 'played_' + i
            dataBest = 'best_' + i

            if (typeof nextProps.matchPlayers[i] === 'undefined') {
                number = undefined
                player = 0
                ampluaID = 0
                played = 0
                best = 0
            } else {
                number = nextProps.matchPlayers[i].number
                player = nextProps.matchPlayers[i].divisionPlayer.id
                ampluaID = nextProps.matchPlayers[i].amplua
                played = nextProps.matchPlayers[i].played
                best = nextProps.matchPlayers[i].best
            }

            this.setState({
                matchPlayers: nextProps.matchPlayers,
                [dataNumber]: number,
                [dataPlayer]: player,
                [dataAmplua]: ampluaID,
                [dataPlayed]: played,
                [dataBest]: best,
            });
        }

        let players = [];

        nextProps.players.map((model, i) =>
            players.push({
                id: model.id,
                name: model.people.surname + " " + model.people.name
            })
        )

        this.setState({
            players: players
        })
    }

    componentWillMount() {

        let number;
        let player;
        let amplua;
        let played;
        let best;
        let time_in;
        let time_out;
        let dataNumber;
        let dataPlayer;
        let dataAmplua;
        let dataPlayed;
        let dataBest;
        let dataIn;
        let dataOut;

        for (let i = 0; i < FOOTBALL_PROTOCOL_ROW; i++) {

            dataNumber = 'number_' + i;
            dataPlayer = 'player_' + i;
            dataAmplua = 'amplua_' + i;
            dataPlayed = 'played_' + i;
            dataBest = 'best_' + i;
            dataIn = 'in_' + i;
            dataOut = 'out_' + i;

            if (typeof this.props.matchPlayers[i] === 'undefined') {
                number = '';
                player = 0;
                amplua = 'NOT_SET';
                played = 0;
                best = 0;
                time_in = 0;
                time_out = 60;
            } else {
                number = this.props.matchPlayers[i].number;
                player = this.props.matchPlayers[i].divisionPlayer.id;
                amplua = this.props.matchPlayers[i].amplua;
                played = this.props.matchPlayers[i].played;
                best = this.props.matchPlayers[i].best;
                time_in = this.props.matchPlayers[i].time_in;
                time_out = this.props.matchPlayers[i].time_out;
            }

            let players = [];

            this.props.players.map((model, i) =>
                players.push({
                    id: model.id,
                    name: model.people.surname + " " + model.people.name
                })
            )

            this.setState({
                matchPlayers: this.props.matchPlayers,
                players: players,
                [dataNumber]: number,
                [dataPlayer]: player,
                [dataAmplua]: amplua,
                [dataPlayed]: played,
                [dataBest]: best,
            });
        }
    }

    render() {

        let ampluaList = [
            {id: "GOALKEEPER", name: 'вратарь'},
            {id: 'DEFENDER', name: 'защитник'},
            {id: 'MIDFIELDER', name: 'полузащитник'},
            {id: 'FORWARD', name: 'нападающий'}
        ];

        let rows = [];
        let id;

        for (let i = 0; i < FOOTBALL_PROTOCOL_ROW; i++) {
            let dataNumber = 'number_' + i;
            let dataPlayer = 'player_' + i;
            let dataAmplua = 'amplua_' + i;
            let dataPlayed = 'played_' + i;
            let dataBest = 'best_' + i;
            let dataIn = 'in_' + i;
            let dataOut = 'out_' + i;

            if (typeof this.state.matchPlayers[i] === 'undefined') {
                id = undefined
            } else {
                id = this.state.matchPlayers[i].id
            }

            rows.push(
                <tr key={i} id={this.props.prefix + "_player_" + i}>
                    <td>
                        <input type='hidden' name={'matchPlayers[' + this.props.prefix + '][' + i + '][id]'} value={id}/>
                        <input className='protocol-number'
                               id={"number_" + this.props.prefix + "_" + i}
                               onChange={this.handleInputChange}
                               data={dataNumber}
                               value={this.state[dataNumber]}
                               name={'matchPlayers[' + this.props.prefix + '][' + i + '][number]'}/>
                    </td>
                    <td>
                        <ProtocolDropDown name={'matchPlayers[' + this.props.prefix + '][' + i + '][divisionPlayerID]'}
                                          id={"player_" + this.props.prefix + "_" + i}
                                          data={dataPlayer}
                                          handleChange={this.handleInputChange}
                                          value={this.state[dataPlayer]}
                                          items={this.state.players}/>
                    </td>
                    <td>
                        <ProtocolDropDown name={'matchPlayers[' + this.props.prefix + '][' + i + '][amplua]'}
                                          id={"amplua_" + this.props.prefix + "_" + i}
                                          handleChange={this.handleInputChange}
                                          data={dataAmplua}
                                          value={this.state[dataAmplua]}
                                          items={ampluaList}/>
                    </td>
                    <td>
                        <input type='hidden' name={'matchPlayers[' + this.props.prefix + '][' + i + '][played]'}
                               value='0'/>
                        <input
                            type='checkbox'
                            data={dataPlayed}
                            onChange={this.handleInputChange}
                            checked={this.state[dataPlayed]}
                            value='1'
                            name={'matchPlayers[' + this.props.prefix + '][' + i + '][played]'}
                        />
                    </td>
                    <td>
                        <input className='protocol-number'
                               onChange={this.handleInputChange}
                               data={dataIn}
                               value={this.state[dataIn]}
                               name={'matchPlayers[' + this.props.prefix + '][' + i + '][in]'}/>
                    </td>
                    <td>
                        <input className='protocol-number'
                               onChange={this.handleInputChange}
                               data={dataOut}
                               value={this.state[dataOut]}
                               name={'matchPlayers[' + this.props.prefix + '][' + i + '][out]'}/>
                    </td>
                    <td>
                        <input type='hidden' name={'matchPlayers[' + this.props.prefix + '][' + i + '][best]'}
                               value='0'/>
                        <input
                            type='checkbox'
                            data={dataBest}
                            onChange={this.handleInputChange}
                            checked={this.state[dataBest]}
                            value='1'
                            name={'matchPlayers[' + this.props.prefix + '][' + i + '][best]'}
                        />
                    </td>
                    <td>
                        <spa className='protocol-remove'>⊘</spa>
                    </td>
                </tr>
            );
        }

        return (
            <table className='protocol-table'>
                <thead>
                <tr>
                    <th>#</th>
                    <th><FormattedMessage id="protocol.player" defaultMessage="player"/></th>
                    <th><FormattedMessage id="protocol.amplua" defaultMessage="amplua"/></th>
                    <th>✓</th>
                    <th>⬇</th>
                    <th>⬆</th>
                    <th>★</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {rows}
                </tbody>
            </table>
        );
    }
}

export default ProtocolPlayerOld;
