import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import IconButton from "@material-ui/core/IconButton";
import {MenuItem} from "@mui/material";
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import Menu from "@material-ui/core/Menu";
import {FormattedMessage} from "react-intl";

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),

        },
    },
}));

export default function Logout() {
    const classes = useStyles();

    const [auth, setAuth] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleChange = (event) => {
        setAuth(event.target.checked);
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleLogout = () => {
        setAnchorEl(null);
        localStorage.removeItem('token')
        window.location.href = '/'
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div style={{float:'right'}}>
            <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
            >
                <AccountBoxIcon/>
            </IconButton>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
            >
                <MenuItem><FormattedMessage id="profile.logout" defaultMessage="Logout"/></MenuItem>
                <MenuItem onClick={handleLogout}><FormattedMessage id="profile.exit" defaultMessage="Exit"/></MenuItem>
            </Menu>
        </div>
    );
}