import * as React from 'react';
import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import ProtocolPlayerOld from "../ProtocolPlayerOld";
import ProtocolResultOld from "../ProtocolResultOld";
import ProtocolPenaltyOld from "../ProtocolPenaltyOld";

const Item = styled(Paper)(({theme}) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function FootballProtocol(props) {
    console.log(props.sportType)
    return (
        <Box sx={{flexGrow: 1, height: "100%"}}>
            <Grid container spacing={1}>
                <Grid item xs={4}>
                    <Item elevation={3}>
                        <ProtocolPlayerOld
                            players={props.players}
                            matchPlayers={props.matchPlayers}
                            prefix={props.prefix}
                        />
                    </Item>
                </Grid>
                <Grid item xs={3}>
                    <Item elevation={3}>
                        <ProtocolResultOld
                            matchPlayers={props.matchPlayers}
                            matchResults={props.matchResults}
                            prefix={props.prefix}
                            sportType={props.sportType}
                        />
                    </Item>
                </Grid>
                <Grid item xs={3}>
                    <Item elevation={3}>
                        <ProtocolPenaltyOld
                            matchPlayers={props.matchPlayers}
                            matchPenalties={props.matchPenalties}
                            reasonItems={props.reasons}
                            prefix={props.prefix}
                            sportType={props.sportType}
                        />
                    </Item>
                </Grid>
            </Grid>
        </Box>
    );
}