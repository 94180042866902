import {
    MANAGER_SEASON_CREATE_SUCCESS,
    MANAGER_SEASON_DELETE_SUCCESS,
    MANAGER_SEASON_LIST_FAILURE,
    MANAGER_SEASON_LIST_REQUEST,
    MANAGER_SEASON_LIST_SUCCESS, MANAGER_SEASON_SHOW_FORM,
    MANAGER_SEASON_UPDATE_SUCCESS,
    MANAGER_SEASON_VIEW_SUCCESS
} from '../constants/Season';
import {
    MANAGER_COMPETITION_LIST_REQUEST,
    MANAGER_COMPETITION_LIST_FAILURE,
    MANAGER_COMPETITION_LIST_SUCCESS,
    MANAGER_COMPETITION_SHOW_FORM,
    MANAGER_CONFERENCE_SELECT,
    MANAGER_COMPETITION_CREATE_SUCCESS,
    MANAGER_COMPETITION_VIEW_SUCCESS,
    MANAGER_COMPETITION_UPDATE_SUCCESS,
    MANAGER_COMPETITION_DELETE_SUCCESS, MANAGER_CITY_SELECT
} from "../constants/Competition";

import {
    MANAGER_DIVISION_CREATE_SUCCESS, MANAGER_DIVISION_DELETE_SUCCESS,
    MANAGER_DIVISION_LIST_FAILURE,
    MANAGER_DIVISION_LIST_REQUEST,
    MANAGER_DIVISION_LIST_SUCCESS,
    MANAGER_DIVISION_SHOW_FORM,
    MANAGER_DIVISION_UPDATE_SUCCESS,
    MANAGER_DIVISION_VIEW_SUCCESS
} from "../constants/Division";
import {
    MANAGER_DIVISION_TEAM_CITY_SELECT,
    MANAGER_DIVISION_TEAM_CREATE_SUCCESS, MANAGER_DIVISION_TEAM_DELETE_SUCCESS,
    MANAGER_DIVISION_TEAM_LIST_FAILURE,
    MANAGER_DIVISION_TEAM_LIST_REQUEST,
    MANAGER_DIVISION_TEAM_LIST_SUCCESS,
    MANAGER_DIVISION_TEAM_SHOW_FORM,
    MANAGER_DIVISION_TEAM_UPDATE_SUCCESS,
    MANAGER_DIVISION_TEAM_VIEW_SUCCESS
} from "../constants/DivisionTeam";
import {
    MANAGER_STAGE_CREATE_SUCCESS,
    MANAGER_STAGE_DELETE_SUCCESS,
    MANAGER_STAGE_LIST_FAILURE,
    MANAGER_STAGE_LIST_REQUEST,
    MANAGER_STAGE_LIST_SUCCESS,
    MANAGER_STAGE_SHOW_FORM,
    MANAGER_STAGE_SHOW_STATISTIC_MODAL,
    MANAGER_STAGE_UPDATE_SUCCESS,
    MANAGER_STAGE_VIEW_SUCCESS
} from "../constants/Stage";
import {
    MANAGER_GROUP_CREATE_SUCCESS,
    MANAGER_GROUP_DELETE_SUCCESS,
    MANAGER_GROUP_LIST_FAILURE,
    MANAGER_GROUP_LIST_REQUEST,
    MANAGER_GROUP_LIST_SUCCESS,
    MANAGER_GROUP_SHOW_FORM,
    MANAGER_GROUP_UPDATE_SUCCESS,
    MANAGER_GROUP_VIEW_SUCCESS
} from "../constants/Group";
import {
    MANAGER_STAGE_TEAM_COPY_REQUEST,
    MANAGER_STAGE_TEAM_COPY_SUCCESS,
    MANAGER_STAGE_TEAM_CREATE_SUCCESS, MANAGER_STAGE_TEAM_DELETE_SUCCESS,
    MANAGER_STAGE_TEAM_LIST_FAILURE, MANAGER_STAGE_TEAM_LIST_REQUEST,
    MANAGER_STAGE_TEAM_LIST_SUCCESS,
    MANAGER_STAGE_TEAM_SHOW_FORM, MANAGER_STAGE_TEAM_UPDATE_SUCCESS, MANAGER_STAGE_TEAM_VIEW_SUCCESS
} from "../constants/StageTeam";
import {
    MANAGER_TOUR_CREATE_SUCCESS, MANAGER_TOUR_DELETE_SUCCESS,
    MANAGER_TOUR_LIST_FAILURE,
    MANAGER_TOUR_LIST_REQUEST,
    MANAGER_TOUR_LIST_SUCCESS,
    MANAGER_TOUR_SHOW_FORM, MANAGER_TOUR_UPDATE_SUCCESS, MANAGER_TOUR_VIEW_SUCCESS
} from "../constants/Tour";
import {
    MANAGER_MATCH_CREATE_SUCCESS, MANAGER_MATCH_DELETE_SUCCESS,
    MANAGER_MATCH_LIST_FAILURE,
    MANAGER_MATCH_LIST_REQUEST,
    MANAGER_MATCH_LIST_SUCCESS, MANAGER_MATCH_SET_MODEL,
    MANAGER_MATCH_SHOW_FORM, MANAGER_MATCH_SHOW_RESULT, MANAGER_MATCH_UPDATE_SUCCESS, MANAGER_MATCH_VIEW_SUCCESS
} from "../constants/Match";
import {
    MANAGER_DIVISION_PLAYER_CREATE_SUCCESS,
    MANAGER_DIVISION_PLAYER_DELETE_SUCCESS,
    MANAGER_DIVISION_PLAYER_LIST_FAILURE,
    MANAGER_DIVISION_PLAYER_LIST_REQUEST,
    MANAGER_DIVISION_PLAYER_LIST_SUCCESS, MANAGER_DIVISION_PLAYER_SHOW_FORM,
    MANAGER_DIVISION_PLAYER_UPDATE_SUCCESS,
    MANAGER_DIVISION_PLAYER_VIEW_SUCCESS
} from "../constants/DivisionPlayer";
import {
    MANAGER_DIVISION_PERSONAL_CREATE_SUCCESS, MANAGER_DIVISION_PERSONAL_DELETE_SUCCESS,
    MANAGER_DIVISION_PERSONAL_LIST_FAILURE,
    MANAGER_DIVISION_PERSONAL_LIST_SUCCESS,
    MANAGER_DIVISION_PERSONAL_SHOW_FORM,
    MANAGER_DIVISION_PERSONAL_UPDATE_SUCCESS,
    MANAGER_DIVISION_PERSONAL_VIEW_SUCCESS
} from "../constants/DivisionPersonal";
import {
    MANAGER_ALBUM_CREATE_SUCCESS,
    MANAGER_ALBUM_DELETE_SUCCESS,
    MANAGER_ALBUM_LIST_FAILURE,
    MANAGER_ALBUM_LIST_REQUEST,
    MANAGER_ALBUM_LIST_SUCCESS,
    MANAGER_ALBUM_SHOW_FORM,
    MANAGER_ALBUM_UPDATE_SUCCESS,
    MANAGER_ALBUM_VIEW_SUCCESS,
    MANAGER_CONTACT_CREATE_SUCCESS,
    MANAGER_CONTACT_DELETE_SUCCESS,
    MANAGER_CONTACT_LIST_FAILURE,
    MANAGER_CONTACT_LIST_REQUEST,
    MANAGER_CONTACT_LIST_SUCCESS,
    MANAGER_CONTACT_SHOW_FORM,
    MANAGER_CONTACT_UPDATE_SUCCESS,
    MANAGER_CONTACT_VIEW_SUCCESS,
    MANAGER_DOC_CREATE_SUCCESS,
    MANAGER_DOC_DELETE_SUCCESS,
    MANAGER_DOC_LIST_FAILURE,
    MANAGER_DOC_LIST_REQUEST,
    MANAGER_DOC_LIST_SUCCESS,
    MANAGER_DOC_SHOW_FORM,
    MANAGER_DOC_UPDATE_SUCCESS,
    MANAGER_DOC_VIEW_SUCCESS,
    MANAGER_NEWS_CREATE_SUCCESS,
    MANAGER_NEWS_DELETE_SUCCESS,
    MANAGER_NEWS_LIST_FAILURE,
    MANAGER_NEWS_LIST_REQUEST,
    MANAGER_NEWS_LIST_SUCCESS,
    MANAGER_NEWS_SHOW_FORM,
    MANAGER_NEWS_UPDATE_SUCCESS,
    MANAGER_NEWS_VIEW_SUCCESS, MANAGER_PHOTO_LIST_SUCCESS,
    MANAGER_VIDEO_CREATE_SUCCESS, MANAGER_VIDEO_DELETE_SUCCESS,
    MANAGER_VIDEO_LIST_FAILURE,
    MANAGER_VIDEO_LIST_REQUEST,
    MANAGER_VIDEO_LIST_SUCCESS, MANAGER_VIDEO_SHOW_FORM, MANAGER_VIDEO_UPDATE_SUCCESS, MANAGER_VIDEO_VIEW_SUCCESS
} from "../constants/Site";
import {MANAGER_SET_SPORT_TYPE} from "../constants/Manager";

const {Map, OrderedMap} = require('immutable');

const initialState = Map({
    error: '',
    contact: Map({
        id: 0,
        model: {name: '', sort: 0},
        models: OrderedMap(),
        loading: false,
        error: '',
        showForm: false
    }),
    news: Map({
        id: 0,
        model: {name: '', sort: 0},
        models: OrderedMap(),
        loading: false,
        error: '',
        showForm: false
    }),
    video: Map({
        id: 0,
        model: {name: '', sort: 0},
        models: OrderedMap(),
        loading: false,
        error: '',
        showForm: false
    }),
    album: Map({
        id: 0,
        model: {name: '', sort: 0},
        models: OrderedMap(),
        loading: false,
        error: '',
        showForm: false
    }),
    photo: Map({
        id: 0,
        model: {id: 0},
        models: OrderedMap(),
        loading: false,
        error: '',
        showForm: false
    }),
    doc: Map({
        id: 0,
        model: {name: '', sort: 0},
        models: OrderedMap(),
        loading: false,
        error: '',
        showForm: false
    }),
    competition: Map({
        id: 0,
        model: {country: {}},
        models: OrderedMap(),
        loading: false,
        error: '',
        showForm: false,
        sportType: 'FOOTBALL',
        formID: 12
    }),
    conference: Map({
        id: 0,
    }),
    city: Map({
        id: 0,
    }),
    division: Map({
        id: 0,
        model: {name: '', sort: 0},
        models: OrderedMap(),
        loading: false,
        error: '',
        showForm: false
    }),
    divisionPlayer: Map({
        id: 0,
        divisionTeamID: 0,
        people: {id: 0},
        model: {people: {id: 0}, amplua: {id: 0}, sort: 0},
        models: OrderedMap(),
        loading: false,
        error: '',
        showForm: false
    }),
    divisionPersonal: Map({
        id: 0,
        city: 0,
        model: {people: {}, personalType: {}, sort: 0},
        models: OrderedMap(),
        loading: false,
        error: '',
        showForm: false
    }),
    divisionTeam: Map({
        id: 0,
        city: 0,
        model: {name: '', team: {id: 0, city: {id: 0, name: ''}}, sort: 0},
        models: OrderedMap(),
        loading: false,
        error: '',
        showForm: false
    }),
    group: Map({
        id: 0,
        model: {name: '', sort: 0},
        models: OrderedMap(),
        loading: false,
        error: '',
        showForm: false
    }),
    match: Map({
        id: 0,
        model: {name: '', stadium: {city: {}}, tour: {}, group: {}, sort: 0},
        models: OrderedMap(),
        loading: false,
        error: '',
        showForm: false,
        showResultForm: false
    }),
    season: Map({
        id: 0,
        model: {name: '', sort: 0},
        models: OrderedMap(),
        loading: false,
        error: '',
        showForm: false
    }),
    stage: Map({
        id: 0,
        model: {name: '', sort: 0},
        models: OrderedMap(),
        loading: false,
        error: '',
        showForm: false,
        showStatistic: 0,
    }),
    stageTeam: Map({
        id: 0,
        model: {name: '', divisionTeam: {id:0, team: {city: {name: ''}}}, sort: 0},
        models: OrderedMap(),
        loading: false,
        error: '',
        showForm: false
    }),
    tour: Map({
        id: 0,
        model: {name: '', sort: 0},
        models: OrderedMap(),
        loading: false,
        error: '',
        showForm: false
    }),
});

export default function manager(state = initialState, action) {

    if (action.type === MANAGER_SET_SPORT_TYPE){
        return state.setIn(['competition', 'sportType'],action.payload)
    }

    // NEWS
    switch (action.type) {
        case MANAGER_NEWS_LIST_REQUEST:
            return state
                .setIn(['news', 'loading'], true)
        case MANAGER_NEWS_LIST_SUCCESS:
            return state
                .setIn(['news', 'showModel'], false)
                .setIn(['news', 'loading'], false)
                .setIn(['news', 'models'], OrderedMap().withMutations(map => {
                    action.payload.forEach(news => map.set(news.id, news));
                }))
        case MANAGER_NEWS_LIST_FAILURE:
            return state
                .setIn(['news', 'showForm'], false)
                .setIn(['news', 'loading'], false)
                .setIn(['news', 'error'], action.payload)
        case MANAGER_NEWS_SHOW_FORM:
            return state
                .setIn(['news', 'model'], initialState.get('news').get('model'))
                .setIn(['news', 'showForm'], action.payload)
        case MANAGER_NEWS_CREATE_SUCCESS:
            return state
                .setIn(['news', 'models', action.payload[0].id], action.payload[0])
                .setIn(['news', 'showForm'], false)
        case MANAGER_NEWS_VIEW_SUCCESS:
            return state
                .setIn(['news', 'model'], action.payload[0])
                .setIn(['news', 'showForm'], true)
        case MANAGER_NEWS_UPDATE_SUCCESS:
            return state
                .setIn(['news', 'models', action.payload[0].id], action.payload[0])
                .setIn(['news', 'showForm'], false)
        case MANAGER_NEWS_DELETE_SUCCESS:
            return state
                .deleteIn(['news', 'models', action.payload])
    }


    // DOC
    switch (action.type) {
        case MANAGER_DOC_LIST_REQUEST:
            return state
                .setIn(['doc', 'loading'], true)
        case MANAGER_DOC_LIST_SUCCESS:
            return state
                .setIn(['doc', 'showForm'], false)
                .setIn(['doc', 'loading'], false)
                .setIn(['doc', 'models'], OrderedMap().withMutations(map => {
                    action.payload.forEach(news => map.set(news.id, news));
                }))
        case MANAGER_DOC_LIST_FAILURE:
            return state
                .setIn(['doc', 'showForm'], false)
                .setIn(['doc', 'loading'], false)
                .setIn(['doc', 'error'], action.payload)
        case MANAGER_DOC_SHOW_FORM:
            return state
                .setIn(['doc', 'model'], initialState.get('news').get('model'))
                .setIn(['doc', 'showForm'], action.payload)
        case MANAGER_DOC_CREATE_SUCCESS:
            return state
                .setIn(['doc', 'models', action.payload[0].id], action.payload[0])
                .setIn(['doc', 'showForm'], false)
        case MANAGER_DOC_VIEW_SUCCESS:
            return state
                .setIn(['doc', 'model'], action.payload[0])
                .setIn(['doc', 'showForm'], true)
        case MANAGER_DOC_UPDATE_SUCCESS:
            return state
                .setIn(['doc', 'models', action.payload[0].id], action.payload[0])
                .setIn(['doc', 'showForm'], false)
        case MANAGER_DOC_DELETE_SUCCESS:
            return state
                .deleteIn(['doc', 'models', action.payload])
    }


    // Contacts
    switch (action.type) {
        case MANAGER_CONTACT_LIST_REQUEST:
            return state
                .setIn(['contact', 'loading'], true)
        case MANAGER_CONTACT_LIST_SUCCESS:
            return state
                .setIn(['contact', 'showForm'], false)
                .setIn(['contact', 'loading'], false)
                .setIn(['contact', 'models'], OrderedMap().withMutations(map => {
                    action.payload.forEach(news => map.set(news.id, news));
                }))
        case MANAGER_CONTACT_LIST_FAILURE:
            return state
                .setIn(['contact', 'showForm'], false)
                .setIn(['contact', 'loading'], false)
                .setIn(['contact', 'error'], action.payload)
        case MANAGER_CONTACT_SHOW_FORM:
            return state
                .setIn(['contact', 'model'], initialState.get('news').get('model'))
                .setIn(['contact', 'showForm'], action.payload)
        case MANAGER_CONTACT_CREATE_SUCCESS:
            return state
                .setIn(['contact', 'models', action.payload[0].id], action.payload[0])
                .setIn(['contact', 'showForm'], false)
        case MANAGER_CONTACT_VIEW_SUCCESS:
            return state
                .setIn(['contact', 'model'], action.payload[0])
                .setIn(['contact', 'showForm'], true)
        case MANAGER_CONTACT_UPDATE_SUCCESS:
            return state
                .setIn(['contact', 'models', action.payload[0].id], action.payload[0])
                .setIn(['contact', 'showForm'], false)
        case MANAGER_CONTACT_DELETE_SUCCESS:
            return state
                .deleteIn(['contact', 'models', action.payload])
    }
    
    // Albums
    switch (action.type) {
        case MANAGER_ALBUM_LIST_REQUEST:
            return state
                .setIn(['album', 'loading'], true)
        case MANAGER_ALBUM_LIST_SUCCESS:
            return state
                .setIn(['album', 'showForm'], false)
                .setIn(['album', 'loading'], false)
                .setIn(['album', 'models'], OrderedMap().withMutations(map => {
                    action.payload.forEach(news => map.set(news.id, news));
                }))
        case MANAGER_ALBUM_LIST_FAILURE:
            return state
                .setIn(['album', 'showForm'], false)
                .setIn(['album', 'loading'], false)
                .setIn(['album', 'error'], action.payload)
        case MANAGER_ALBUM_SHOW_FORM:
            return state
                .setIn(['album', 'model'], initialState.get('news').get('model'))
                .setIn(['album', 'showForm'], action.payload)
        case MANAGER_ALBUM_CREATE_SUCCESS:
            return state
                .setIn(['album', 'models', action.payload[0].id], action.payload[0])
                .setIn(['album', 'showForm'], false)
        case MANAGER_ALBUM_VIEW_SUCCESS:
            return state
                .setIn(['album', 'model'], action.payload[0])
                .setIn(['album', 'showForm'], true)
        case MANAGER_ALBUM_UPDATE_SUCCESS:
            return state
                .setIn(['album', 'models', action.payload[0].id], action.payload[0])
                .setIn(['album', 'showForm'], false)
        case MANAGER_ALBUM_DELETE_SUCCESS:
            return state
                .deleteIn(['album', 'models', action.payload])
    }
    // Photo
    switch (action.type) {
        case MANAGER_PHOTO_LIST_SUCCESS:
            return state
                .setIn(['photo', 'loading'], false)
                .setIn(['photo', 'models'], OrderedMap().withMutations(map => {
                    action.payload.forEach(photo => map.set(photo.id, photo));
                }))
    }
    
    // Videos
    switch (action.type) {
        case MANAGER_VIDEO_LIST_REQUEST:
            return state
                .setIn(['video', 'loading'], true)
        case MANAGER_VIDEO_LIST_SUCCESS:
            return state
                .setIn(['video', 'showForm'], false)
                .setIn(['video', 'loading'], false)
                .setIn(['video', 'models'], OrderedMap().withMutations(map => {
                    action.payload.forEach(news => map.set(news.id, news));
                }))
        case MANAGER_VIDEO_LIST_FAILURE:
            return state
                .setIn(['video', 'showForm'], false)
                .setIn(['video', 'loading'], false)
                .setIn(['video', 'error'], action.payload)
        case MANAGER_VIDEO_SHOW_FORM:
            return state
                .setIn(['video', 'model'], initialState.get('news').get('model'))
                .setIn(['video', 'showForm'], action.payload)
        case MANAGER_VIDEO_CREATE_SUCCESS:
            return state
                .setIn(['video', 'models', action.payload[0].id], action.payload[0])
                .setIn(['video', 'showForm'], false)
        case MANAGER_VIDEO_VIEW_SUCCESS:
            return state
                .setIn(['video', 'model'], action.payload[0])
                .setIn(['video', 'showForm'], true)
        case MANAGER_VIDEO_UPDATE_SUCCESS:
            return state
                .setIn(['video', 'models', action.payload[0].id], action.payload[0])
                .setIn(['video', 'showForm'], false)
        case MANAGER_VIDEO_DELETE_SUCCESS:
            return state
                .deleteIn(['video', 'models', action.payload])
    }

    if (action.type === MANAGER_SEASON_LIST_REQUEST) {
        return state
            .setIn(['competition', 'id'], 0)
            .setIn(['division', 'id'], 0)
            .setIn(['divisionTeam', 'id'], 0)
            .setIn(['stage', 'id'], 0)
            .setIn(['stageTeam', 'id'], 0)
            .setIn(['group', 'id'], 0)
            .setIn(['tour', 'id'], 0)
            .setIn(['season', 'loading'], true)
    } else if (action.type === MANAGER_SEASON_LIST_SUCCESS) {
        return state
            .setIn(['season', 'showForm'], false)
            .setIn(['season', 'loading'], false)
            .setIn(['season', 'models'], OrderedMap().withMutations(map => {
                action.payload.forEach(season => map.set(season.id, season));
            }))
    } else if (action.type === MANAGER_SEASON_LIST_FAILURE) {
        return state
            .setIn(['season', 'showForm'], false)
            .setIn(['season', 'loading'], false)
            .setIn(['season', 'error'], action.payload)
    } else if (action.type === MANAGER_SEASON_SHOW_FORM) {
        return state
            .setIn(['season', 'model'], initialState.get('season').get('model'))
            .setIn(['season', 'showForm'], action.payload)
    } else if (action.type === MANAGER_SEASON_CREATE_SUCCESS) {
        return state
            .setIn(['season', 'models', action.payload.id], action.payload)
            .setIn(['season', 'showForm'], false)
    } else if (action.type === MANAGER_SEASON_VIEW_SUCCESS) {
        return state
            .setIn(['season', 'model'], action.payload)
            .setIn(['season', 'showForm'], true)
    } else if (action.type === MANAGER_SEASON_UPDATE_SUCCESS) {
        return state
            .setIn(['season', 'models', action.payload.id], action.payload)
            .setIn(['season', 'showForm'], false)
    } else if (action.type === MANAGER_SEASON_DELETE_SUCCESS) {
        return state
            .deleteIn(['season', 'models', action.payload])
    } else if (action.type === MANAGER_STAGE_LIST_REQUEST) {
        return state
            .setIn(['division', 'id'], action.payload)
            .setIn(['stageTeam', 'id'], 0)
            .setIn(['group', 'id'], 0)
            .setIn(['tour', 'id'], 0)
            .setIn(['stage', 'loading'], true)
    } else if (action.type === MANAGER_STAGE_LIST_SUCCESS) {
        return state
            .setIn(['stage', 'showForm'], false)
            .setIn(['stage', 'loading'], false)
            .setIn(['stage', 'models'], OrderedMap().withMutations(map => {
                action.payload.forEach(model => map.set(model.id, model));
            }))
    } else if (action.type === MANAGER_STAGE_LIST_FAILURE) {
        return state
            .setIn(['stage', 'showForm'], false)
            .setIn(['stage', 'loading'], false)
            .setIn(['stage', 'error'], action.payload)
    } else if (action.type === MANAGER_STAGE_SHOW_FORM) {
        return state
            .setIn(['stage', 'model'], initialState.get('stage').get('model'))
            .setIn(['stage', 'showForm'], action.payload)
    } else if (action.type === MANAGER_STAGE_SHOW_STATISTIC_MODAL) {
        return state
            .setIn(['stage', 'model'], initialState.get('stage').get('model'))
            .setIn(['stage', 'showStatistic'], action.payload)
    } else if (action.type === MANAGER_STAGE_CREATE_SUCCESS) {
        return state
            .setIn(['stage', 'models', action.payload.id], action.payload)
            .setIn(['stage', 'showForm'], false)
    } else if (action.type === MANAGER_STAGE_VIEW_SUCCESS) {
        return state
            .setIn(['stage', 'model'], action.payload)
            .setIn(['stage', 'showForm'], true)
    } else if (action.type === MANAGER_STAGE_UPDATE_SUCCESS) {
        return state
            .setIn(['stage', 'models', action.payload.id], action.payload)
            .setIn(['stage', 'showForm'], false)
    } else if (action.type === MANAGER_STAGE_DELETE_SUCCESS) {
        return state
            .deleteIn(['stage', 'models', action.payload])
    } else if (action.type === MANAGER_MATCH_LIST_REQUEST) {
        return state
            .setIn(['match', 'loading'], true)
    } else if (action.type === MANAGER_MATCH_SET_MODEL) {
        return state
            .setIn(['match', 'model'], action.payload)
    } else if (action.type === MANAGER_MATCH_LIST_SUCCESS) {
        return state
            .setIn(['match', 'showForm'], false)
            .setIn(['match', 'loading'], false)
            .setIn(['match', 'models'], OrderedMap().withMutations(map => {
                action.payload.forEach(model => map.set(model.id, model));
            }))
    } else if (action.type === MANAGER_MATCH_LIST_FAILURE) {
        return state
            .setIn(['match', 'showForm'], false)
            .setIn(['match', 'loading'], false)
            .setIn(['match', 'error'], action.payload)
    } else if (action.type === MANAGER_MATCH_SHOW_FORM) {
        return state
            .setIn(['match', 'model'], initialState.get('match').get('model'))
            .setIn(['match', 'showForm'], action.payload)
    } else if (action.type === MANAGER_MATCH_CREATE_SUCCESS) {
        return state
            .setIn(['match', 'models', action.payload.id], action.payload)
            .setIn(['match', 'showForm'], false)
    } else if (action.type === MANAGER_MATCH_VIEW_SUCCESS) {
        return state
            .setIn(['match', 'model'], action.payload)
            .setIn(['match', 'showForm'], true)
    } else if (action.type === MANAGER_MATCH_UPDATE_SUCCESS) {
        return state
            .setIn(['match', 'models', action.payload.id], action.payload)
            .setIn(['match', 'showForm'], false)
            .setIn(['match', 'showResultForm'], false)
    } else if (action.type === MANAGER_MATCH_DELETE_SUCCESS) {
        return state
            .deleteIn(['match', 'models', action.payload])
    } else if (action.type === MANAGER_MATCH_SHOW_RESULT) {
        return state
            .setIn(['match', 'showResultForm'], action.payload)
    } else if (action.type === MANAGER_STAGE_TEAM_LIST_REQUEST) {
        return state
            .setIn(['stageTeam', 'loading'], true)
    } else if (action.type === MANAGER_STAGE_TEAM_LIST_SUCCESS) {
        return state
            .setIn(['stageTeam', 'showForm'], false)
            .setIn(['stageTeam', 'loading'], false)
            .setIn(['stageTeam', 'models'], OrderedMap().withMutations(map => {
                action.payload.forEach(model => map.set(model.id, model));
            }))
    } else if (action.type === MANAGER_STAGE_TEAM_COPY_SUCCESS) {
        return state
            .setIn(['stageTeam', 'loading'], false)
            .setIn(['stageTeam', 'models'], OrderedMap().withMutations(map => {
                action.payload.data.forEach(model => map.set(model.id, model));
            }))
    } else if (action.type === MANAGER_STAGE_TEAM_COPY_REQUEST) {
        return state
            .setIn(['stageTeam', 'loading'], true)
    } else if (action.type === MANAGER_STAGE_TEAM_LIST_FAILURE) {
        return state
            .setIn(['stageTeam', 'showForm'], false)
            .setIn(['stageTeam', 'loading'], false)
            .setIn(['stageTeam', 'error'], action.payload)
    } else if (action.type === MANAGER_STAGE_TEAM_SHOW_FORM) {
        return state
            .setIn(['stageTeam', 'model'], initialState.get('stageTeam').get('model'))
            .setIn(['stageTeam', 'showForm'], action.payload)
    } else if (action.type === MANAGER_STAGE_TEAM_CREATE_SUCCESS) {
        return state
            .setIn(['stageTeam', 'models', action.payload.id], action.payload)
            .setIn(['stageTeam', 'showForm'], false)
    } else if (action.type === MANAGER_STAGE_TEAM_VIEW_SUCCESS) {
        return state
            .setIn(['stageTeam', 'model'], action.payload)
            .setIn(['stageTeam', 'showForm'], true)
    } else if (action.type === MANAGER_STAGE_TEAM_UPDATE_SUCCESS) {
        return state
            .setIn(['stageTeam', 'models', action.payload.id], action.payload)
            .setIn(['stageTeam', 'showForm'], false)
    } else if (action.type === MANAGER_STAGE_TEAM_DELETE_SUCCESS) {
        return state
            .deleteIn(['stageTeam', 'models', action.payload])
    } else if (action.type === MANAGER_DIVISION_LIST_REQUEST) {
        return state
            .setIn(['competition', 'id'], action.payload)
            .setIn(['divisionTeam', 'id'], 0)
            .setIn(['divisionTeam', 'models'], OrderedMap())
            .setIn(['divisionPlayer', 'models'], OrderedMap())
            .setIn(['stage', 'models'], OrderedMap())
            .setIn(['stage', 'id'], 0)
            .setIn(['stageTeam', 'id'], 0)
            .setIn(['group', 'id'], 0)
            .setIn(['tour', 'id'], 0)
            .setIn(['division', 'loading'], true)
    } else if (action.type === MANAGER_DIVISION_LIST_SUCCESS) {
        return state
            .setIn(['division', 'showForm'], false)
            .setIn(['division', 'loading'], false)
            .setIn(['division', 'models'], OrderedMap().withMutations(map => {
                action.payload.forEach(model => map.set(model.id, model));
            }))
    } else if (action.type === MANAGER_DIVISION_LIST_FAILURE) {
        return state
            .setIn(['division', 'showForm'], false)
            .setIn(['division', 'loading'], false)
            .setIn(['division', 'error'], action.payload)
    } else if (action.type === MANAGER_DIVISION_SHOW_FORM) {
        return state
            .setIn(['division', 'model'], initialState.get('season').get('model'))
            .setIn(['division', 'showForm'], action.payload)
    } else if (action.type === MANAGER_DIVISION_CREATE_SUCCESS) {
        return state
            .setIn(['division', 'models', action.payload.id], action.payload)
            .setIn(['division', 'showForm'], false)
    } else if (action.type === MANAGER_DIVISION_VIEW_SUCCESS) {
        return state
            .setIn(['division', 'model'], action.payload)
            .setIn(['division', 'showForm'], true)
    } else if (action.type === MANAGER_DIVISION_UPDATE_SUCCESS) {
        return state
            .setIn(['division', 'models', action.payload.id], action.payload)
            .setIn(['division', 'showForm'], false)
    } else if (action.type === MANAGER_DIVISION_DELETE_SUCCESS) {
        return state
            .deleteIn(['division', 'models', action.payload])
    } else if (action.type === MANAGER_TOUR_LIST_REQUEST) {
        return state
            .setIn(['tour', 'id'], 0)
            .setIn(['tour', 'loading'], true)
    } else if (action.type === MANAGER_TOUR_LIST_SUCCESS) {
        return state
            .setIn(['tour', 'showForm'], false)
            .setIn(['tour', 'loading'], false)
            .setIn(['tour', 'models'], OrderedMap().withMutations(map => {
                action.payload.forEach(model => map.set(model.id, model));
            }))
    } else if (action.type === MANAGER_TOUR_LIST_FAILURE) {
        return state
            .setIn(['tour', 'showForm'], false)
            .setIn(['tour', 'loading'], false)
            .setIn(['tour', 'error'], action.payload)
    } else if (action.type === MANAGER_TOUR_SHOW_FORM) {
        return state
            .setIn(['tour', 'model'], initialState.get('tour').get('model'))
            .setIn(['tour', 'showForm'], action.payload)
    } else if (action.type === MANAGER_TOUR_CREATE_SUCCESS) {
        return state
            .setIn(['tour', 'models', action.payload.id], action.payload)
            .setIn(['tour', 'showForm'], false)
    } else if (action.type === MANAGER_TOUR_VIEW_SUCCESS) {
        return state
            .setIn(['tour', 'model'], action.payload)
            .setIn(['tour', 'showForm'], true)
    } else if (action.type === MANAGER_TOUR_UPDATE_SUCCESS) {
        return state
            .setIn(['tour', 'models', action.payload.id], action.payload)
            .setIn(['tour', 'showForm'], false)
    } else if (action.type === MANAGER_TOUR_DELETE_SUCCESS) {
        return state
            .deleteIn(['tour', 'models', action.payload])
    } else if (action.type === MANAGER_GROUP_LIST_REQUEST) {
        return state
            .setIn(['stage', 'id'], action.payload)
            .setIn(['stageTeam', 'id'], 0)
            .setIn(['group', 'id'], 0)
            .setIn(['tour', 'id'], 0)
            .setIn(['group', 'loading'], true)
    } else if (action.type === MANAGER_GROUP_LIST_SUCCESS) {
        return state
            .setIn(['group', 'loading'], false)
            .setIn(['group', 'models'], OrderedMap().withMutations(map => {
                action.payload.forEach(model => map.set(model.id, model));
            }))
    } else if (action.type === MANAGER_GROUP_LIST_FAILURE) {
        return state
            .setIn(['group', 'showForm'], false)
            .setIn(['group', 'loading'], false)
            .setIn(['group', 'error'], action.payload)
    } else if (action.type === MANAGER_GROUP_SHOW_FORM) {
        return state
            .setIn(['group', 'model'], initialState.get('group').get('model'))
            .setIn(['group', 'showForm'], action.payload)
    } else if (action.type === MANAGER_GROUP_CREATE_SUCCESS) {
        return state
            .setIn(['group', 'models', action.payload.id], action.payload)
            .setIn(['group', 'showForm'], false)
    } else if (action.type === MANAGER_GROUP_VIEW_SUCCESS) {
        return state
            .setIn(['group', 'model'], action.payload)
            .setIn(['group', 'showForm'], true)
    } else if (action.type === MANAGER_GROUP_UPDATE_SUCCESS) {
        return state
            .setIn(['group', 'models', action.payload.id], action.payload)
            .setIn(['group', 'showForm'], false)
    } else if (action.type === MANAGER_GROUP_DELETE_SUCCESS) {
        return state
            .deleteIn(['group', 'models', action.payload])
    } else if (action.type === MANAGER_DIVISION_TEAM_LIST_REQUEST) {
        return state
            .setIn(['division', 'id'], action.payload)
            .setIn(['stage', 'id'], 0)
            .setIn(['stageTeam', 'id'], 0)
            .setIn(['group', 'id'], 0)
            .setIn(['tour', 'id'], 0)
            .setIn(['divisionTeam', 'loading'], true)
    } else if (action.type === MANAGER_DIVISION_TEAM_LIST_SUCCESS) {
        return state
            .setIn(['divisionTeam', 'showForm'], false)
            .setIn(['divisionTeam', 'loading'], false)
            .setIn(['divisionTeam', 'models'], OrderedMap().withMutations(map => {
                action.payload.forEach(model => map.set(model.id, model));
            }))
    } else if (action.type === MANAGER_DIVISION_TEAM_LIST_FAILURE) {
        return state
            .setIn(['divisionTeam', 'showForm'], false)
            .setIn(['divisionTeam', 'loading'], false)
            .setIn(['divisionTeam', 'error'], action.payload)
    } else if (action.type === MANAGER_DIVISION_TEAM_SHOW_FORM) {
        return state
            .setIn(['divisionTeam', 'model'], initialState.get('divisionTeam').get('model'))
            .setIn(['divisionTeam', 'showForm'], action.payload)
    } else if (action.type === MANAGER_DIVISION_TEAM_CREATE_SUCCESS) {
        return state
            .setIn(['divisionTeam', 'models', action.payload.id], action.payload)
            .setIn(['divisionTeam', 'showForm'], false)
    } else if (action.type === MANAGER_DIVISION_TEAM_VIEW_SUCCESS) {
        return state
            .setIn(['divisionTeam', 'model'], action.payload)
            .setIn(['divisionTeam', 'showForm'], true)
    } else if (action.type === MANAGER_DIVISION_TEAM_UPDATE_SUCCESS) {
        return state
            .setIn(['divisionTeam', 'models', action.payload.id], action.payload)
            .setIn(['divisionTeam', 'showForm'], false)
    } else if (action.type === MANAGER_DIVISION_TEAM_DELETE_SUCCESS) {
        return state
            .deleteIn(['divisionTeam', 'models', action.payload])
    } else if (action.type === MANAGER_DIVISION_TEAM_CITY_SELECT) {
        return state
            .setIn(['divisionTeam', 'city'], action.payload)
    } else if (action.type === MANAGER_DIVISION_PLAYER_LIST_REQUEST) {
        return state
            .setIn(['divisionTeam', 'id'], action.payload)
            .setIn(['divisionPlayer', 'loading'], true)
    } else if (action.type === MANAGER_DIVISION_PLAYER_LIST_SUCCESS) {
        return state
            .setIn(['divisionPlayer', 'showForm'], false)
            .setIn(['divisionPlayer', 'loading'], false)
            .setIn(['divisionPlayer', 'models'], OrderedMap().withMutations(map => {
                action.payload.forEach(model => map.set(model.id, model));
            }))
    } else if (action.type === MANAGER_DIVISION_PLAYER_LIST_FAILURE) {
        return state
            .setIn(['divisionPlayer', 'showForm'], false)
            .setIn(['divisionPlayer', 'loading'], false)
            .setIn(['divisionPlayer', 'error'], action.payload)
    } else if (action.type === MANAGER_DIVISION_PLAYER_SHOW_FORM) {
        return state
            .setIn(['divisionPlayer', 'model'], initialState.get('divisionPlayer').get('model'))
            .setIn(['divisionPlayer', 'showForm'], action.payload)
    } else if (action.type === MANAGER_DIVISION_PLAYER_CREATE_SUCCESS) {
        return state
            .setIn(['divisionPlayer', 'models', action.payload.id], action.payload)
            .setIn(['divisionPlayer', 'showForm'], false)
    } else if (action.type === MANAGER_DIVISION_PLAYER_VIEW_SUCCESS) {
        return state
            .setIn(['divisionPlayer', 'model'], action.payload)
            .setIn(['divisionPlayer', 'showForm'], true)
    } else if (action.type === MANAGER_DIVISION_PLAYER_UPDATE_SUCCESS) {
        return state
            .setIn(['divisionPlayer', 'models', action.payload.id], action.payload)
            .setIn(['divisionPlayer', 'showForm'], false)
    } else if (action.type === MANAGER_DIVISION_PERSONAL_LIST_SUCCESS) {
        return state
            .setIn(['divisionPersonal', 'showForm'], false)
            .setIn(['divisionPersonal', 'loading'], false)
            .setIn(['divisionPersonal', 'models'], OrderedMap().withMutations(map => {
                action.payload.forEach(model => map.set(model.id, model));
            }))
    } else if (action.type === MANAGER_DIVISION_PERSONAL_LIST_FAILURE) {
        return state
            .setIn(['divisionPersonal', 'showForm'], false)
            .setIn(['divisionPersonal', 'loading'], false)
            .setIn(['divisionPersonal', 'error'], action.payload)
    } else if (action.type === MANAGER_DIVISION_PERSONAL_DELETE_SUCCESS) {
        return state
            .deleteIn(['divisionPersonal', 'models', action.payload])
    } else if (action.type === MANAGER_DIVISION_PERSONAL_SHOW_FORM) {
        return state
            .setIn(['divisionPersonal', 'model'], initialState.get('divisionPersonal').get('model'))
            .setIn(['divisionPersonal', 'showForm'], action.payload)
    } else if (action.type === MANAGER_DIVISION_PERSONAL_CREATE_SUCCESS) {
        return state
            .setIn(['divisionPersonal', 'models', action.payload.id], action.payload)
            .setIn(['divisionPersonal', 'showForm'], false)
    } else if (action.type === MANAGER_DIVISION_PERSONAL_VIEW_SUCCESS) {
        return state
            .setIn(['divisionPersonal', 'model'], action.payload)
            .setIn(['divisionPersonal', 'showForm'], true)
    } else if (action.type === MANAGER_DIVISION_PERSONAL_UPDATE_SUCCESS) {
        return state
            .setIn(['divisionPersonal', 'models', action.payload.id], action.payload)
            .setIn(['divisionPersonal', 'showForm'], false)
    } else if (action.type === MANAGER_DIVISION_PLAYER_DELETE_SUCCESS) {
        return state
            .deleteIn(['divisionPlayer', 'models', action.payload])
    } else if (action.type === MANAGER_COMPETITION_SHOW_FORM) {
        return state
            .setIn(['competition', 'model'], initialState.get('competition').get('model'))
            .setIn(['competition', 'showForm'], action.payload)
    } else if (action.type === MANAGER_COMPETITION_LIST_REQUEST) {
        return state
            .setIn(['division', 'id'], 0)
            .setIn(['division', 'models'], OrderedMap())
            .setIn(['divisionTeam', 'id'], 0)
            .setIn(['divisionTeam', 'models'], OrderedMap())
            .setIn(['stage', 'id'], 0)
            .setIn(['stageTeam', 'id'], 0)
            .setIn(['group', 'id'], 0)
            .setIn(['tour', 'id'], 0)
            .setIn(['season', 'id'], action.payload)
            .setIn(['competition', 'loading'], true)
    } else if (action.type === MANAGER_COMPETITION_LIST_SUCCESS) {
        return state
            .setIn(['competition', 'showForm'], false)
            .setIn(['competition', 'loading'], false)
            .setIn(['competition', 'models'], OrderedMap().withMutations(map => {
                action.payload.forEach(competition => map.set(competition.id, competition));
            }))
    } else if (action.type === MANAGER_COMPETITION_LIST_FAILURE) {
        return state
            .setIn(['competition', 'showForm'], false)
            .setIn(['competition', 'loading'], false)
            .setIn(['competition', 'error'], action.payload)
    } else if (action.type === MANAGER_COMPETITION_CREATE_SUCCESS) {
        return state
            .setIn(['competition', 'models', action.payload.id], action.payload)
            .setIn(['competition', 'showForm'], false)
    } else if (action.type === MANAGER_COMPETITION_VIEW_SUCCESS) {
        return state
            .setIn(['competition', 'model'], action.payload)
            .setIn(['competition', 'showForm'], true)
            .setIn(['conference', 'id'], action.payload.conferenceID)
    } else if (action.type === MANAGER_COMPETITION_UPDATE_SUCCESS) {
        return state
            .setIn(['competition', 'models', action.payload.id], action.payload)
            .setIn(['competition', 'showForm'], false)
    } else if (action.type === MANAGER_COMPETITION_DELETE_SUCCESS) {
        return state
            .deleteIn(['competition', 'models', action.payload])
    } else if (action.type === MANAGER_CITY_SELECT) {
        return state
            .setIn(['city', 'id'], action.payload)
    } else if (action.type === MANAGER_CONFERENCE_SELECT) {
        return state
            .setIn(['conference', 'id'], action.payload)
    } else {
        return state;
    }
}
