import React from 'react'

const FormikSelectAdapter = ({
                                 field: {value, onChange, setFieldValue, name},
                                 form: {errors},
                                 helperText,
                                 select,
                                 inputComponent: InputComponent,
                                 ...props
                             }) => (
    <InputComponent
        id={name}
        variant="outlined"
        name={name}
        helperText={errors[name] || helperText}
        error={!!errors[name]}
        value={value}
        onChange={onChange}
        setFieldValue={setFieldValue}
        select={select}
        data-test={`${name}_input`}
        {...props}
    />
);

export default FormikSelectAdapter
