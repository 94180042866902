import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormHelperText from "@material-ui/core/FormHelperText";
import {rstatApi} from "../../helpers/api";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(2),
        minWidth: 120,
        width:'100%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export default function DivisionTeam(props) {
    const classes = useStyles();
    const [options, setOptions] = useState([]);
    useEffect(() => {
        (async () => {
            rstatApi.get(props.filter).then(function (response) {
                setOptions(response.data.data.map((item) => item));
            }).catch(function (error) {
                // TODO process error.
                console.log(error)
            });

        })();

    }, [props.filter]);
    return (
        <FormControl variant="outlined" className={classes.formControl} error={props.error}>
            <InputLabel htmlFor={props.labelID}>{props.label}</InputLabel>
            <Select
                native
                error={props.error}
                labelId={props.labelID}
                id={props.id}
                name={props.name}
                value={props.value}
                onChange={props.onChange}
                label={props.label}
            >
                <option aria-label="None" value="" />
                {options.map((model, i) =>
                    <option value={model.id}>{model.team.name} ({model.team.city.name})</option>
                )}
            </Select>
            <FormHelperText>{props.helperText}</FormHelperText>
        </FormControl>
    );
}