import React, {useState} from 'react'
import MaterialTable, {MTableToolbar} from 'material-table'
import {ENDPOINT, MANAGER} from "../../constants/Common";
import ComponentMenu from "../common/ComonentMenu";
import ProtocolIcon from '@material-ui/icons/AssignmentSharp';
import StatNoneIcon from '@material-ui/icons/BlockSharp';
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';
import grey from '@material-ui/core/colors/grey';
import MatchScore from "./MatchScore";
import Tooltip from "@material-ui/core/Tooltip";
import Fade from "@material-ui/core/Fade";
import green from "@material-ui/core/colors/green";
import {Icon} from "@mui/material";
import {
    MATCH_ALL,
    MATCH_CANCELED,
    MATCH_DELAYED,
    MATCH_FINISHED,
    MATCH_IN_PROCESS,
    MATCH_PLANNED, MATCH_STAT_NONE,
    MATCH_STAT_PROCESSED, MATCH_STAT_PROTOCOL, MATCH_STAT_TABLE
} from "../../constants/Match";
import {formatDate} from "../../helpers/api";
import Grid from "@mui/material/Grid";
import {ToggleButton, ToggleButtonGroup} from "@mui/lab";
import {FormattedMessage, useIntl} from "react-intl";


function MatchStatus(props) {
    let color = red[500]
    let title = "status"
    let icon = "flag"

    switch (props.status) {
        case MATCH_PLANNED:
            title = <FormattedMessage id="manager.match.status.planned" defaultMessage="not started"/>
            icon = "slideshow"
            color = blue[600]
            break
        case MATCH_FINISHED:
            title = <FormattedMessage id="manager.match.status.finished" defaultMessage="finished"/>
            icon = "flag"
            color = green[500]
            break;
        case MATCH_IN_PROCESS:
            title = <FormattedMessage id="manager.match.status.in-progress" defaultMessage="in progress"/>
            icon = "offlinebolt"
            color = green[500]
            break;
        case MATCH_DELAYED:
            title = <FormattedMessage id="manager.match.status.delayed" defaultMessage="delayed"/>
            icon = "accesstime"
            color = green[500]
            break;
        case MATCH_CANCELED:
            title = <FormattedMessage id="manager.match.status.canceled" defaultMessage="canceled"/>
            icon = "block"
            color = red[500]
            break;
        default:
            break;
    }

    return <Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 600}} title={title} arrow>
        <Icon style={{color: color}}>{icon}</Icon>
    </Tooltip>
}

function ProtocolPrint(props) {
    const processed = "PROCESSED"

    let color

    switch (props.status) {
        case processed:
            color = green[500]
            break;
        default:
            color = grey[500]
    }

    return <Icon style={{color: color}}>printer</Icon>
}

function MatchStatStatus(props) {

    let color = red[500]
    let title
    let icon

    switch (props.status) {
        case MATCH_STAT_TABLE:
            title = <FormattedMessage id="manager.match.stat.table" defaultMessage="in table"/>
            color = blue[600]
            icon = "fact_check"
            break;
        case MATCH_STAT_PROTOCOL:
            title = <FormattedMessage id="manager.match.stat.protocol" defaultMessage="in protocol"/>
            color = blue[500]
            icon = "feed"
            break;
        case MATCH_STAT_PROCESSED:
            title = <FormattedMessage id="manager.match.stat.processed" defaultMessage="stat processed"/>
            color = green[500]
            icon = "assessment"
            break;
        default:
            title = <FormattedMessage id="manager.match.stat.not" defaultMessage="no stats"/>
            icon = "highlight_off"
    }

    return <Tooltip TransitionComponent={Fade} TransitionProps={{timeout: 600}} title={title} arrow>
        <Icon style={{color: color}}>{icon}</Icon>
    </Tooltip>
}

export default function Matches(props) {
    const [selectedRow, setValue] = useState(0);

    const [selectedBtn, setBtnValue] = useState("ALL");

    const disabled = props.addDisabled

    let data = [];

    props.models.forEach(n => data.push(n));

    function handleShowForm() {
        props.showForm(true, MANAGER)
    }

    const intl = useIntl()

    function handleMatchStatus(e, status) {
        setBtnValue(status)
        switch (status) {
            case MATCH_STAT_PROTOCOL:
            case MATCH_STAT_TABLE:
            case MATCH_STAT_PROCESSED:
            case MATCH_STAT_NONE:
                props.list('?sort=number&filter=stage_id:' + props.stageID + ',stat_status:' + status, MANAGER)
                break;
            case MATCH_ALL:
                props.list('?sort=number&filter=stage_id:' + props.stageID, MANAGER)
                break;
            default:
                props.list('?sort=number&filter=stage_id:' + props.stageID + ',status:' + status, MANAGER)
        }
    }

    return (<MaterialTable
        columns={[{
            title: 'ID', field: 'id', cellStyle: {
                fontSize: 13, paddingLeft: 20,
            },
        }, {
            title: <FormattedMessage id="manager.match.number" defaultMessage="number"/>, field: 'number', cellStyle: {
                fontSize: 13, paddingLeft: 12,
            },
        }, {
            title: <FormattedMessage id="manager.match.group" defaultMessage="group"/>, field: 'group.name', cellStyle: {
                fontSize: 13, padding: 0, paddingLeft: 12,
            },
        }, {
            title:<FormattedMessage id="manager.match.tour" defaultMessage="tour"/>, field: 'tour.name', cellStyle: {
                fontSize: 13, padding: 0, paddingLeft: 12,
            },
        }, {
            field: 'home', title: <FormattedMessage id="manager.match.home" defaultMessage="home"/>, cellStyle: {
                fontSize: 13, padding: 0, paddingLeft: 12,
            }, render: rowData => <span>
                            {rowData.stageTeamHome.divisionTeam.team.name}
                ({rowData.stageTeamHome.divisionTeam.team.city.name})
                        </span>
        }, {
            field: 'score', title: <FormattedMessage id="manager.match.score" defaultMessage="score"/>, render: rowData => <MatchScore
                showForm={props.showMatchResult}
                setModel={props.setModel}
                model={rowData}
                home={rowData.goalHome}
                away={rowData.goalAway}/>, cellStyle: {
                textAlign: 'center', padding: 0, fontSize: 13, paddingLeft: 12,
            },
        }, {
            field: 'away', title: <FormattedMessage id="manager.match.away" defaultMessage="away"/>, cellStyle: {
                fontSize: 13, padding: 0, paddingLeft: 12,
            }, render: rowData => <span>
                            {rowData.stageTeamAway.divisionTeam.team.name}
                ({rowData.stageTeamAway.divisionTeam.team.city.name})
                        </span>
        },
            {
                title: <FormattedMessage id="manager.match.date" defaultMessage="date"/>, field: 'date', cellStyle: {
                    fontSize: 13, paddingLeft: 12,
                }, render: rowData => formatDate(rowData.date)
            }, {
                title: <FormattedMessage id="manager.match.time" defaultMessage="time"/>, field: 'time', cellStyle: {
                    fontSize: 13, padding: 0, paddingLeft: 12,
                }, render: rowData => rowData.time ? rowData.time.substring(0, 5) : '-'
            }, {
                field: 'stadium', title: <FormattedMessage id="manager.match.stadium" defaultMessage="stadium"/>, cellStyle: {
                    fontSize: 13, padding: 0, paddingLeft: 12,
                }, render: rowData => rowData.stadium ? <span>
                            {rowData.stadium.name}
                    ({rowData.stadium.city.name})
                        </span> : <StatNoneIcon style={{color: red[300]}}/>
            }, {
                field: 'proto', title: <FormattedMessage id="manager.match.protocol" defaultMessage="protocol"/>, cellStyle: {
                    fontSize: 13, padding: 0, paddingLeft: 12,
                }, render: rowData => <a target={"_blank"} href={"match/" + rowData.id}><ProtocolIcon
                    style={{color: red[300]}}/></a>
            }, {
                field: 'status', title: <FormattedMessage id="manager.match.status" defaultMessage="status"/>, cellStyle: {
                    fontSize: 13, padding: 0, paddingLeft: 20,
                }, render: rowData => <MatchStatus status={rowData.status}/>

            }, {
                field: 'statStatus', title: <FormattedMessage id="manager.match.stats" defaultMessage="stats"/>, cellStyle: {
                    fontSize: 13, padding: 0, paddingLeft: 12,
                }, render: rowData => <MatchStatStatus status={rowData.statStatus}/>
            }, {
                cellStyle: {
                    fontSize: 13, padding: 0, paddingLeft: 12,
                }, render: rowData => <a target={"_blank"} href={ENDPOINT + "matches/" + rowData.id + "/print"}>
                    <ProtocolPrint status={rowData.statStatus}/>
                </a>
            }, {
                field: 'menu',
                title: <FormattedMessage id="common.actions" defaultMessage="actions"/>,
                render: rowData => <ComponentMenu id={rowData.id}
                                                  delete={props.delete}
                                                  view={props.view}/>,
                cellStyle: {
                    padding: 0,
                },
            },]}
        onRowClick={((evt, selectedRow) => {
            // only td click - select rows
            if (evt.target.getAttribute("value")) {
                setValue(selectedRow);
            }
        })}
        options={{
            rowStyle: rowData => ({
                fontSize: 12,
                backgroundColor: (selectedRow && selectedRow.tableData.id === rowData.tableData.id) ? '#EEE' : '#FFF'
            }),
            paging: false,
            maxBodyHeight: 370,
            minBodyHeight: 370,
            header: true,
            actionsColumnIndex: -1,
            showTitle: true
        }}
        localization={{
            toolbar: {
                searchPlaceholder: intl.formatMessage({id: 'manager.match-title',defaultMessage:"Matches"}),
                searchTooltip: <FormattedMessage id="common.find" defaultMessage="Find"/>
            }, body: {
                emptyDataSourceMessage: <FormattedMessage id="manager.no_data" defaultMessage="no data"/>
            }
        }}
        actions={[{
            icon: 'add',
            disabled: props.addDisabled,
            tooltip: <FormattedMessage id="manager.match-add" defaultMessage="add match"/>,
            isFreeAction: true,
            onClick: (event) => handleShowForm()
        },]}
        isLoading={props.loading}
        data={data}
        components={{
            Toolbar: props => {
                const propsCopy = {...props};
                propsCopy.showTitle = false;
                return (
                    <Grid container direction="row">
                        <Grid item xs>
                            <ToggleButtonGroup
                                disabled={disabled}
                                size="medium"
                                style={{margin: 10}}
                                value={selectedBtn}
                                exclusive
                                onChange={handleMatchStatus}
                                aria-label="match status"
                            >
                                <ToggleButton value={MATCH_ALL} aria-label="all">
                                    <Tooltip
                                        TransitionComponent={Fade}
                                        TransitionProps={{timeout: 600}}
                                        title={<FormattedMessage id="manager.match.all" defaultMessage="all matches"/>}
                                        arrow
                                    >
                                        <Icon style={{color: blue[600]}}>loop</Icon>
                                    </Tooltip>
                                </ToggleButton>
                                <ToggleButton value={MATCH_PLANNED} aria-label="planned">
                                    <Tooltip
                                        TransitionComponent={Fade}
                                        TransitionProps={{timeout: 600}}
                                        title={<FormattedMessage id="manager.match.status.planned"
                                                                 defaultMessage="planned"/>}
                                        arrow
                                    >
                                        <Icon style={{color: blue[600]}}>slideshow</Icon>
                                    </Tooltip>
                                </ToggleButton>
                                <ToggleButton value={MATCH_FINISHED} aria-label="finished">
                                    <Tooltip
                                        TransitionComponent={Fade}
                                        TransitionProps={{timeout: 600}}
                                        title={<FormattedMessage id="manager.match.status.finished"
                                                                 defaultMessage="finished"/>}
                                        arrow
                                    >
                                        <Icon style={{color: green[600]}}>flag</Icon>
                                    </Tooltip>
                                </ToggleButton>
                                <ToggleButton value={MATCH_STAT_PROCESSED} aria-label="processed">
                                    <Tooltip
                                        TransitionComponent={Fade}
                                        TransitionProps={{timeout: 600}}
                                        title={<FormattedMessage id="manager.match.stat.processed"
                                                                 defaultMessage="processed"/>}
                                        arrow
                                    >
                                        <Icon style={{color: green[600]}}>assessment</Icon>
                                    </Tooltip>
                                </ToggleButton>
                                <ToggleButton value={MATCH_STAT_TABLE} aria-label="table">
                                    <Tooltip
                                        TransitionComponent={Fade}
                                        TransitionProps={{timeout: 600}}
                                        title={<FormattedMessage id="manager.match.stat.table"
                                                                 defaultMessage="in table"/>}
                                        arrow
                                    >
                                        <Icon style={{color: blue[600]}}>fact_check</Icon>
                                    </Tooltip>
                                </ToggleButton>
                                <ToggleButton value={MATCH_STAT_PROTOCOL} aria-label="protocol">
                                    <Tooltip
                                        TransitionComponent={Fade}
                                        TransitionProps={{timeout: 600}}
                                        title={<FormattedMessage id="manager.match.stat.protocol"
                                                                 defaultMessage="in protocol"/>}
                                        arrow
                                    >
                                        <Icon style={{color: blue[600]}}>assignment</Icon>
                                    </Tooltip>
                                </ToggleButton>
                                <ToggleButton value={MATCH_STAT_NONE} aria-label="none">
                                    <Tooltip
                                        TransitionComponent={Fade}
                                        TransitionProps={{timeout: 600}}
                                        title={<FormattedMessage id="manager.match.stat.none"
                                                                 defaultMessage="no stats"/>}
                                        arrow
                                    >
                                        <Icon style={{color: red[600]}}>highlight_off</Icon>
                                    </Tooltip>
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                        <Grid item xs>
                            <MTableToolbar {...propsCopy} />
                        </Grid>
                    </Grid>
                )
                    ;
            }
        }}
    />)
}