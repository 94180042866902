import React, {useEffect, useState} from 'react'
import MaterialTable from 'material-table'
import {CORE} from "../constants/Common";
import {FormattedMessage, useIntl} from "react-intl";

export default function Cities(props) {
    const [selectedRow, setValue] = useState(0);
    useEffect(() => {
        props.list('', CORE)
    }, []);

    let data = [];
    props.models.forEach(n => data.push(n));

    function handleShowForm() {
        props.showForm(true, CORE)
    }

    function handleUpdate(id) {
        props.view(id, CORE)
    }

    function handleDelete(id) {
        props.delete(id, CORE)
    }

    const intl = useIntl()

    return (
        <MaterialTable
            title={intl.formatMessage({id: 'city-title',defaultMessage:"Cities"})}
            columns={[
                {
                    title: 'id', field: 'id'
                },
                {
                    title: <FormattedMessage id="city.flag" defaultMessage="flag"/>,
                    render: rowData => <img alt="" src={"https://flagcdn.com/w20/"+rowData.country.code.toLowerCase()+".png"} />
                },
                {
                    title: <FormattedMessage id="city.country" defaultMessage="country"/>,
                    field: 'country.name',
                },
                {
                    title: <FormattedMessage id="city.kind" defaultMessage="kind"/>,
                    field: 'type.name',
                },
                {
                    title: <FormattedMessage id="city.name" defaultMessage="name"/>,
                    field: 'name',
                },
            ]}
            options={{
                rowStyle: rowData => ({
                    backgroundColor: (selectedRow && selectedRow.tableData.id === rowData.tableData.id) ? '#EEE' : '#FFF'
                }),
                paging: true,
                header: true,
                sorting: true,
                filtering: true,
                actionsColumnIndex: -1,
                pageSize:15,
                showTitle: true
            }}
            localization={
                {
                    toolbar: {
                        searchPlaceholder: intl.formatMessage({id: 'city-title',defaultMessage:"Cities"}),
                        searchTooltip: <FormattedMessage id="common.find" defaultMessage="find"/>
                    },
                    header: {
                        actions: <FormattedMessage id="common.actions" defaultMessage="actions"/>
                    },
                    body: {
                        emptyDataSourceMessage: <FormattedMessage id="common.no_data" defaultMessage="no data"/>
                    }
                }
            }
            actions={[
                {
                    icon: 'add',
                    tooltip: <FormattedMessage id="city.add" defaultMessage="add city"/>,
                    isFreeAction: true,
                    onClick: (event) => handleShowForm()
                },
                {
                    icon: 'edit',
                    tooltip: <FormattedMessage id="city.edit" defaultMessage="edit city"/>,
                    onClick: (event, rowData) => handleUpdate(rowData.id)
                },
                {
                    icon: 'delete',
                    tooltip: <FormattedMessage id="city.delete" defaultMessage="delete city"/>,
                    onClick: (event, rowData) => handleDelete(rowData.id)
                }
            ]}
            isLoading={props.loading}
            data={data}
        />
    )
}