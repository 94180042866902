import {
    CORE_PROFILE_VIEW_SUCCESS,
    CORE_USER_REGISTER_SUCCESS,
    CORE_USER_SIGNIN_FAILURE,
    CORE_USER_SIGNIN_SUCCESS
} from "../constants/User";

let {Map, List} = require('immutable');

const initialState = Map({
    message: 'loop',
    regLoading: false,
    models: List([]),
    model: {},
    count: 0,
    userID: 0
})

export default function user(state = initialState, action) {
    switch (action.type) {
        case CORE_USER_SIGNIN_SUCCESS:
            if (action.payload !== undefined) {
                localStorage.setItem('token', 'Bearer ' + action.payload.accessToken)
                window.location.href = '/'
            }

            return state.set('message', '')
        case CORE_USER_SIGNIN_FAILURE:
            return state.set('message', 'не верный пароль')
        case CORE_USER_REGISTER_SUCCESS:
            window.location.href = '/login'

            return state.set('message', '')
        case CORE_PROFILE_VIEW_SUCCESS:
            return state.set('userID', action.payload.id)
        default:
            return state;
    }

}
