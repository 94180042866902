import React from 'react'
import {MANAGER} from "../../constants/Common";
import ScoreIcon from '@material-ui/icons/OpenInBrowserSharp';

export default function MatchScore(props) {

    function handleShowForm() {
        props.setModel(props.model, MANAGER)
        props.showForm(true, MANAGER)
    }

    // FIXME
    if (props.home == null){
        return ( <ScoreIcon onClick={handleShowForm}/> )
    }

    return <span>{props.home}:{props.away}</span>
}