import React, {useEffect, useState} from 'react'
import MaterialTable from 'material-table'
import {CORE} from "../constants/Common";
import ArrowUp from '@material-ui/icons/ArrowUpward';
import {FormattedMessage, useIntl} from "react-intl";

export default function Peoples(props) {
    const [selectedRow, setValue] = useState(0);

    useEffect(() => {
        props.list('', CORE)
    }, []);

    let data = [];
    props.models.forEach(n => data.push(n));

    function handleShowForm() {
        props.showForm(true, CORE)
    }
    function handleShowImportForm() {
        props.showImportForm(true, CORE)
    }

    function handleUpdate(id) {
        props.view(id, CORE)
    }

    function handleDelete(id) {
        props.delete(id, CORE)
    }

    const intl = useIntl()

    return (
        <MaterialTable
            title={intl.formatMessage({id: 'people',defaultMessage:"Peoples"})}
            columns={[
                {
                    title: 'id',
                    field: 'id',
                },
                {
                    title: <FormattedMessage id="people.last-name" defaultMessage="last name"/>,
                    field: 'surname',
                },
                {
                    title: <FormattedMessage id="people.first-name" defaultMessage="first name"/>,
                    field: 'name',
                },
                {
                    title: <FormattedMessage id="people.patronymic" defaultMessage="patronymic"/>,
                    field: 'patronymic',
                },
                {
                    title: <FormattedMessage id="people.birthday" defaultMessage="birthday"/>,
                    field: 'birthday',
                },
            ]}
            options={{
                rowStyle: rowData => ({
                    fontSize: 12,
                    backgroundColor: (selectedRow && selectedRow.tableData.id === rowData.tableData.id) ? '#EEE' : '#FFF'
                }),
                pageSize:15,
                paging: true,
                header: true,
                sorting: true,
                filtering: true,
                actionsColumnIndex: -1,
                showTitle: true
            }}
            localization={
                {
                    toolbar: {
                        searchPlaceholder: intl.formatMessage({id: 'people-title',defaultMessage:"Peoples"}),
                        searchTooltip: <FormattedMessage id="common.find" defaultMessage="find"/>
                    },
                    body: {
                        emptyDataSourceMessage: <FormattedMessage id="common.no_data" defaultMessage="no data"/>
                    },
                    header: {
                        actions: <FormattedMessage id="common.actions" defaultMessage="actions"/>
                    },
                }
            }
            actions={[
                {
                    icon: 'add',
                    tooltip: <FormattedMessage id="people.add" defaultMessage="add people"/>,
                    isFreeAction: true,
                    onClick: (event) => handleShowForm()
                },
                {
                    icon: ArrowUp,
                    tooltip: <FormattedMessage id="people.import" defaultMessage="import peoples"/>,
                    isFreeAction: true,
                    onClick: (event) => handleShowImportForm()
                },
                {
                    icon: 'search',
                    tooltip: <FormattedMessage id="common.search" defaultMessage="search"/>,
                    onClick: (event, rowData) => window.location.href = "/people/" + rowData.id
                },
                {
                    icon: 'edit',
                    tooltip: <FormattedMessage id="common.edit" defaultMessage="edit"/>,
                    onClick: (event,rowData) => handleUpdate(rowData.id)
                },
                {
                    icon: 'delete',
                    tooltip: <FormattedMessage id="common.delete" defaultMessage="delete"/>,
                    onClick: (event,rowData) => handleDelete(rowData.id)
                }
            ]}
            data={data}
        />
    )
}