import React, {useState} from 'react'
import MaterialTable from 'material-table'
import {MANAGER} from "../../constants/Common";
import ComponentMenu from "../common/ComonentMenu";
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import IconButton from "@material-ui/core/IconButton";
import {Status} from "../common/Status";
import {FormattedMessage, useIntl} from "react-intl";

export default function Stages(props) {
    const [selectedRow, setValue] = useState(0);

    let data = [];
    props.models.forEach(n => data.push(n));

    function handleShowForm() {
        props.showForm(true, MANAGER)
    }

    function handleShowStatistic(e, data) {
        props.showStatistic(data.id, MANAGER)
    }

    const intl = useIntl()

    return (
        <MaterialTable
            columns={[
                {
                    title: 'ID',
                    field: 'id',
                    cellStyle: {
                        paddingLeft: 20,
                    },
                },
                {
                    title: 'Название',
                    field: 'name',
                    cellStyle: {
                        width: '100%',
                        padding: 12,
                    },
                },
                {
                    title: 'Статус',
                    render: rowData => <Status status={rowData.status}/>,
                },
                {
                    field: 'show',
                    render: rowData => <IconButton aria-label="show" color="primary" onClick={((e) => handleShowStatistic(e, rowData))}>
                        <LeaderboardIcon/>
                    </IconButton>,
                    cellStyle: {
                        width: 150,
                        padding: 0,
                    },
                },
                {
                    field: 'menu',
                    render: rowData => <ComponentMenu id={rowData.id}
                                                      delete={props.delete}
                                                      view={props.view}/>,
                    cellStyle: {
                        width: 150,
                        padding: 0,
                    },
                },
            ]}
            //evt.target.getAttribute("kind"))
            onRowClick={((evt, selectedRow) => {
                    // only td click - select rows
                    if (evt.target.getAttribute("value")) {
                        setValue(selectedRow);
                        props.listGroups('?filter=stage_id:' + selectedRow.id, MANAGER)
                        props.listTours('?filter=stage_id:' + selectedRow.id, MANAGER)
                        props.listMatches('?sort=number&filter=stage_id:' + selectedRow.id, MANAGER)
                        props.listStageTeams('?filter=stage_id:' + selectedRow.id, MANAGER)
                    }
                }
            )}
            options={{
                rowStyle: rowData => ({
                    fontSize: 12,
                    backgroundColor: (selectedRow && selectedRow.tableData.id === rowData.tableData.id) ? '#EEE' : '#FFF'
                }),
                paging: false,
                maxBodyHeight: 270,
                minBodyHeight: 270,
                header: false,
                actionsColumnIndex: -1,
                showTitle: false
            }}
            localization={
                {
                    toolbar: {
                        searchPlaceholder: intl.formatMessage({id: 'manager.stage-title',defaultMessage:"Stages"}),
                        searchTooltip: <FormattedMessage id="common.find" defaultMessage="Find"/>
                    },
                    body: {
                        emptyDataSourceMessage: <FormattedMessage id="manager.no_data" defaultMessage="no data"/>
                    }
                }
            }
            actions={[
                {
                    icon: 'add',
                    disabled: props.addDisabled,
                    tooltip: <FormattedMessage id="manager.stage-add" defaultMessage="add stage"/>,
                    isFreeAction: true,
                    onClick: (event) => handleShowForm()
                },
            ]}
            isLoading={props.loading}
            data={data}
        />
    )
}