import {makeDispatch, rstatApi} from '../helpers/api'
import {CORE} from "../constants/Common";
import {PROTOCOL, UPDATE, VIEW} from "../constants/Global";

export function viewProtocol(id) {
    return (dispatch) => {
        rstatApi.get('/matches/' + id + '/protocol').then(function (response) {
            dispatch(makeDispatch(CORE, VIEW, PROTOCOL, response));
        }).catch(function (error) {
            dispatch(makeDispatch(CORE, VIEW, PROTOCOL, error.response));
        });
    }
}

export function updateProtocol(id, body, type) {
    return (dispatch) => {
        rstatApi.put('/matches/' + id + '/protocol', body).then(function (response) {
            dispatch(makeDispatch(CORE, UPDATE, PROTOCOL, response));
        }).catch(function (error) {
            dispatch(makeDispatch(CORE, UPDATE, PROTOCOL, error.response));
        });
    }
}