
export const MANAGER_COUNTRY_LIST_REQUEST = 'MANAGER_COUNTRY_LIST_REQUEST'
export const MANAGER_COUNTRY_LIST_FAILURE = 'MANAGER_COUNTRY_LIST_FAILURE'
export const MANAGER_COUNTRY_LIST_SUCCESS = 'MANAGER_COUNTRY_LIST_SUCCESS'
export const MANAGER_COUNTRY_SHOW_FORM = 'MANAGER_COUNTRY_SHOW_MODAL'
export const MANAGER_COUNTRY_CREATE_REQUEST = 'MANAGER_COUNTRY_CREATE_REQUEST'
export const MANAGER_COUNTRY_CREATE_SUCCESS = 'MANAGER_COUNTRY_CREATE_SUCCESS'
export const MANAGER_COUNTRY_CREATE_FAILURE = 'MANAGER_COUNTRY_CREATE_FAILURE'
export const MANAGER_COUNTRY_UPDATE_REQUEST = 'MANAGER_COUNTRY_UPDATE_REQUEST'
export const MANAGER_COUNTRY_UPDATE_SUCCESS = 'MANAGER_COUNTRY_UPDATE_SUCCESS'
export const MANAGER_COUNTRY_UPDATE_FAILURE = 'MANAGER_COUNTRY_UPDATE_FAILURE'
export const MANAGER_COUNTRY_DELETE_REQUEST = 'MANAGER_COUNTRY_DELETE_REQUEST'
export const MANAGER_COUNTRY_DELETE_SUCCESS = 'MANAGER_COUNTRY_DELETE_SUCCESS'
export const MANAGER_COUNTRY_DELETE_FAILURE = 'MANAGER_COUNTRY_DELETE_FAILURE'
export const MANAGER_COUNTRY_SET_ID = 'MANAGER_COUNTRY_SET_ID'
export const MANAGER_COUNTRY_SELECT = 'MANAGER_COUNTRY_SELECT'

export const CORE_COUNTRY_LIST_REQUEST = 'CORE_COUNTRY_LIST_REQUEST'
export const CORE_COUNTRY_LIST_FAILURE = 'CORE_COUNTRY_LIST_FAILURE'
export const CORE_COUNTRY_LIST_SUCCESS = 'CORE_COUNTRY_LIST_SUCCESS'
export const CORE_COUNTRY_SHOW_FORM = 'CORE_COUNTRY_SHOW_FORM'
export const CORE_COUNTRY_CREATE_REQUEST = 'CORE_COUNTRY_CREATE_REQUEST'
export const CORE_COUNTRY_CREATE_SUCCESS = 'CORE_COUNTRY_CREATE_SUCCESS'
export const CORE_COUNTRY_CREATE_FAILURE = 'CORE_COUNTRY_CREATE_FAILURE'
export const CORE_COUNTRY_UPDATE_REQUEST = 'CORE_COUNTRY_UPDATE_REQUEST'
export const CORE_COUNTRY_UPDATE_SUCCESS = 'CORE_COUNTRY_UPDATE_SUCCESS'
export const CORE_COUNTRY_UPDATE_FAILURE = 'CORE_COUNTRY_UPDATE_FAILURE'
export const CORE_COUNTRY_DELETE_REQUEST = 'CORE_COUNTRY_DELETE_REQUEST'
export const CORE_COUNTRY_DELETE_SUCCESS = 'CORE_COUNTRY_DELETE_SUCCESS'
export const CORE_COUNTRY_DELETE_FAILURE = 'CORE_COUNTRY_DELETE_FAILURE'
export const CORE_COUNTRY_SET_ID = 'CORE_COUNTRY_SET_ID'
export const CORE_COUNTRY_VIEW_SUCCESS = 'CORE_COUNTRY_VIEW_SUCCESS'
export const CORE_COUNTRY_VIEW_FAILURE = 'CORE_COUNTRY_VIEW_FAILURE'

export const COUNTRY_LIST_REQUEST = 'COUNTRY_LIST_REQUEST'
export const COUNTRY_LIST_FAILURE = 'COUNTRY_LIST_FAILURE'
export const COUNTRY_LIST_SUCCESS = 'COUNTRY_LIST_SUCCESS'
export const COUNTRY_SHOW_FORM = 'COUNTRY_SHOW_FORM'
export const COUNTRY_CREATE_REQUEST = 'COUNTRY_CREATE_REQUEST'
export const COUNTRY_CREATE_SUCCESS = 'COUNTRY_CREATE_SUCCESS'
export const COUNTRY_CREATE_FAILURE = 'COUNTRY_CREATE_FAILURE'
export const COUNTRY_UPDATE_REQUEST = 'COUNTRY_UPDATE_REQUEST'
export const COUNTRY_UPDATE_SUCCESS = 'COUNTRY_UPDATE_SUCCESS'
export const COUNTRY_UPDATE_FAILURE = 'COUNTRY_UPDATE_FAILURE'
export const COUNTRY_DELETE_REQUEST = 'COUNTRY_DELETE_REQUEST'
export const COUNTRY_DELETE_SUCCESS = 'COUNTRY_DELETE_SUCCESS'
export const COUNTRY_DELETE_FAILURE = 'COUNTRY_DELETE_FAILURE'
export const COUNTRY_VIEW_SUCCESS = 'COUNTRY_VIEW_SUCCESS'
export const COUNTRY_VIEW_FAILURE = 'COUNTRY_VIEW_FAILURE'
export const COUNTRY_SET_ID = 'COUNTRY_SET_ID'