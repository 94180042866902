import {
    VIDEO_LIST_REQUEST, VIDEO_LIST_SUCCESS, VIDEO_LIST_FAILURE, VIDEO_DELETE_FAILURE, VIDEO_CREATE_SUCCESS,
    VIDEO_CREATE_FAILURE, VIDEO_UPDATE_SUCCESS, VIDEO_UPDATE_FAILURE, VIDEO_SHOW_FORM, VIDEO_SET_ID, VIDEO_VIEW_SUCCESS,
    VIDEO_VIEW_FAILURE, VIDEO_DELETE_SUCCESS
} from '../../constants/Site';
import {CONSTRUCTOR_API_URL} from '../../constants/Common';

export function showVideoForm(open, type) {

    return (dispatch) => {
        dispatch({
            type: type + VIDEO_SHOW_FORM,
            payload: open
        })

    }
}

export function setVideoID(id, type) {

    return (dispatch) => {
        dispatch({
            type: type + VIDEO_SET_ID,
            payload: id
        })

    }
}

export function viewVideo(id, type) {

    return (dispatch) => {

        let url = CONSTRUCTOR_API_URL + 'video/' + id;

        fetch(url, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(function (response) {
            response.json().then(function (json) {
                dispatch({
                    errorCode: json.errorCode,
                    errorMessage: json.errorMessage,
                    type: type + VIDEO_VIEW_SUCCESS,
                    payload: json.result
                })
            });

        }).catch(function (error) {
            dispatch({
                type: type + VIDEO_VIEW_FAILURE,
                payload: error,
                error: true
            })
        })

    }
}

export function listVideos(query, type) {

    return (dispatch) => {
        dispatch({
            type: type + VIDEO_LIST_REQUEST
        })

        if (query === undefined) {
            query = '';
        }

        let url = CONSTRUCTOR_API_URL + 'video?competitionID=' + query

        fetch(url, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(function (response) {
            response.json().then(function (json) {
                dispatch({
                    errorCode: json.errorCode,
                    errorMessage: json.errorMessage,
                    type: type + VIDEO_LIST_SUCCESS,
                    payload: json.result
                })
            });

        }).catch(function (error) {
            dispatch({
                type: type + VIDEO_LIST_FAILURE,
                payload: error
            })
        })

    }
}

export function deleteVideo(id, type) {

    return (dispatch) => {

        let url = CONSTRUCTOR_API_URL + 'video/' + id;
        let body = {deleted: true};

        fetch(url, {
            method: 'delete',
            body: JSON.stringify(body),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(function (response) {
            response.json().then(function (json) {
                dispatch({
                    errorCode: json.errorCode,
                    errorMessage: json.errorMessage,
                    type: type + VIDEO_DELETE_SUCCESS,
                    payload: parseInt(id,10)
                })
                dispatch({
                    type: type + VIDEO_SHOW_FORM,
                    payload: false
                });
            });

        }).catch(function (error) {
            dispatch({
                type: type + VIDEO_DELETE_FAILURE,
                payload: error
            })
        })

    }
}

export function createVideo(body, type) {

    return (dispatch) => {

        let url = CONSTRUCTOR_API_URL + 'video';

        fetch(url, {
            method: 'post',
            body: JSON.stringify(body),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(function (response) {
            response.json().then(function (json) {
                dispatch({
                    errorCode: json.errorCode,
                    errorMessage: json.errorMessage,
                    type: type + VIDEO_CREATE_SUCCESS,
                    payload: json.result
                })

            });

        }).catch(function (error) {
            dispatch({
                type: type + VIDEO_CREATE_FAILURE,
                payload: error
            })
        })

    }
}

export function updateVideo(id, body, type) {

    return (dispatch) => {

        let url = CONSTRUCTOR_API_URL + 'video/' + id;

        fetch(url, {
            method: 'put',
            body: JSON.stringify(body),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(function (response) {
            response.json().then(function (json) {
                dispatch({
                    errorCode: json.errorCode,
                    errorMessage: json.errorMessage,
                    type: type + VIDEO_UPDATE_SUCCESS,
                    payload: json.result
                })

            });

        }).catch(function (error) {
            dispatch({
                type: type + VIDEO_UPDATE_FAILURE,
                payload: error
            })

        })

    }
}
