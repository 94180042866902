export const SPORT_TYPE_SET = 'SPORT_TYPE_SET';
export const MANAGER_SPORT_TYPE_SET = 'MANAGER_SPORT_TYPE_SET';
export const INFO_SHOW_MESSAGE = 'INFO_SHOW_MESSAGE';
export const INFO_SHOW_WS_MESSAGE = 'INFO_SHOW_WS_MESSAGE';
export const HIDE_SNACKBAR = 'HIDE_SNACKBAR';
export const SHOW_SNACKBAR = 'SHOW_SNACKBAR';
export const MEMBER = 'member';
export const ADMIN = 'admin';
export const FOOTBALL = 'FOOTBALL';
export const HOCKEY = 'HOCKEY';

// state.
export const FAILURE = 'FAILURE';
export const SUCCESS = 'SUCCESS';
export const REQUEST = 'REQUEST';

// action.
export const CREATE = 'CREATE';
export const UPDATE = 'UPDATE';
export const IMPORT = 'IMPORT';
export const DELETE = 'DELETE';
export const LIST = 'LIST';
export const VIEW = 'VIEW';
export const COPY = 'COPY';
export const SIGNIN = 'SIGNIN';
export const REGISTER = 'REGISTER';

// entity.
export const PLAY_SITUATION = 'PLAY_SITUATION';
export const PENALTY = 'PENALTY';
export const STADIUM = 'STADIUM';
export const COUNTRY = 'COUNTRY';
export const MATCH = 'MATCH';
export const PROTOCOL = 'PROTOCOL';
export const SEASON = 'SEASON';
export const STAGE = 'STAGE';
export const GROUP = 'GROUP';
export const TOUR = 'TOUR';
export const USER = 'USER';
export const PROFILE = 'PROFILE';
export const PEOPLE = 'PEOPLE';
export const TEAM = 'TEAM';
export const CITY = 'CITY';
export const STAGE_TEAM = 'STAGE_TEAM';
export const DIVISION = 'DIVISION';
export const DIVISION_TEAM = 'DIVISION_TEAM';
export const DIVISION_PLAYER = 'DIVISION_PLAYER';
export const DIVISION_PERSONAL = 'DIVISION_PERSONAL';
export const COMPETITION = 'COMPETITION';

// sites entity.
export const NEWS = 'NEWS';
export const CONTACT = 'CONTACT';
export const DOC = 'DOC';
export const ALBUM = 'ALBUM';
export const PHOTO = 'PHOTO';