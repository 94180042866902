import {STATUS_LOCK, STATUS_HIDDEN} from "../../constants/Competition";
import blue from "@material-ui/core/colors/blue";
import green from "@material-ui/core/colors/green";
import {Icon} from "@mui/material";

export function Status(props) {
    let color
    let name = "visibility"

    switch (props.status) {
        case STATUS_LOCK:
            color = blue[500]
            name = "lock"
            break;
        case STATUS_HIDDEN:
            color = blue[500]
            name = "visibility_off"
            break;
        default:
            color = green[500]
    }

    return <Icon style={{color: color}}>{name}</Icon>
}