import React from 'react';
import Box from '@material-ui/core/Box';
import * as cityActions from '../actions/CityActions'

import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Cities from "../components/Cities";
import CityForm from "../components/CityForm";

function City(props) {
    const {city} = props;
    const {listCities, showCityForm, createCity, updateCity,viewCity,deleteCity} = props.cityActions;
    return (
        <Box style={{height:'100%'}}>
            <Cities
                models={city.get('models')}
                loading={city.get('loading')}
                showForm={showCityForm}
                view={viewCity}
                delete={deleteCity}
                list={listCities}
            />
            <CityForm
                model={city.get('model')}
                show={showCityForm}
                create={createCity}
                update={updateCity}
                open={city.get('showModal')}
            />
        </Box>
    );
}

function mapStateToProps(state) {
    return {
        city: state.city,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        cityActions: bindActionCreators(cityActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(City)

