import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {MANAGER} from "../../../constants/Common";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import {Field, Formik} from "formik";
import * as yup from 'yup'
import FormikAdapter from "../../common/FormikAdapter";
import FormikSelectAdapter from "../../common/FormikSelectAdapter";
import AsyncSelect from "../../form/AsyncSelect";
import MuiPickersUtilsProvider from "material-ui-pickers/MuiPickersUtilsProvider";
import DateFnsUtils from '@date-io/date-fns'
import DataSelect from "../../form/DataSelect";
import StageTeam from "../../form/StageTeam";
import AutocompleteSimple from "../../form/AutocompleteSimple";
import {
    MATCH_CANCELED,
    MATCH_DELAYED,
    MATCH_FINISHED,
    MATCH_IN_PROCESS,
    MATCH_PLANNED
} from "../../../constants/Match";
import {FormattedMessage} from "react-intl";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const MATCH_FIELDS = {
    MATCH_ID_FIELD: 'id',
    MATCH_STAGE_ID_FIELD: 'stageID',
    MATCH_TOUR_ID_FIELD: 'tourID',
    MATCH_STATUS_FIELD: 'status',
    MATCH_GROUP_ID_FIELD: 'groupID',
    MATCH_STAGE_TEAM_HOME_ID_FIELD: 'stageTeamHomeID',
    MATCH_STAGE_TEAM_AWAY_ID_FIELD: 'stageTeamAwayID',
    MATCH_NUMBER_FIELD: 'number',
    MATCH_DATE_FIELD: 'date',
    MATCH_TIME_FIELD: 'time',
    MATCH_CITY_ID_FIELD: 'cityID',
    MATCH_STADIUM_ID_FIELD: 'stadiumID',
};

export default function MatchForm(mainProps) {
    let statuses = [
        {key: MATCH_PLANNED, value: <FormattedMessage id="match.planned" defaultMessage="Planned"/>},
        {key: MATCH_IN_PROCESS, value: <FormattedMessage id="match.in-progress" defaultMessage="In progress"/>},
        {key: MATCH_FINISHED, value: <FormattedMessage id="match.finished" defaultMessage="Finished"/>},
        {key: MATCH_DELAYED, value: <FormattedMessage id="match.delayed" defaultMessage="Delayed"/>},
        {key: MATCH_CANCELED, value: <FormattedMessage id="match.canceled" defaultMessage="Canceled"/>},
    ]

    const handleClose = () => {
        mainProps.show(false, MANAGER)
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={mainProps.open}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                <FormattedMessage id="match-form" defaultMessage="Match form"/>
            </DialogTitle>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Formik
                    initialValues={{
                        [MATCH_FIELDS.MATCH_ID_FIELD]: mainProps.model.id,
                        [MATCH_FIELDS.MATCH_STAGE_ID_FIELD]: mainProps.stageID,
                        [MATCH_FIELDS.MATCH_TOUR_ID_FIELD]: mainProps.model.tour.id,
                        [MATCH_FIELDS.MATCH_GROUP_ID_FIELD]: mainProps.model.group?.id,
                        [MATCH_FIELDS.MATCH_NUMBER_FIELD]: mainProps.model.number,
                        [MATCH_FIELDS.MATCH_STATUS_FIELD]: mainProps.model.status,
                        [MATCH_FIELDS.MATCH_STAGE_TEAM_HOME_ID_FIELD]: mainProps.model.stageTeamHomeID,
                        [MATCH_FIELDS.MATCH_STAGE_TEAM_AWAY_ID_FIELD]: mainProps.model.stageTeamAwayID,
                        [MATCH_FIELDS.MATCH_DATE_FIELD]: mainProps.model.date,
                        [MATCH_FIELDS.MATCH_TIME_FIELD]: mainProps.model.time,
                        [MATCH_FIELDS.MATCH_CITY_ID_FIELD]: mainProps.model.stadium ? mainProps.model.stadium.city.id : null,
                        [MATCH_FIELDS.MATCH_STADIUM_ID_FIELD]: mainProps.model.stadium ? mainProps.model.stadium.id : null,
                    }}
                    onSubmit={(values, actions) => {
                        // TODO type assertions fix
                        values.stageTeamHomeID = parseInt(values.stageTeamHomeID)
                        values.stageTeamAwayID = parseInt(values.stageTeamAwayID)
                        values.groupID = parseInt(values.groupID)
                        values.tourID = parseInt(values.tourID)
                        values.stadiumID = parseInt(values.stadiumID)
                        if (values.id > 0) {
                            mainProps.update(values.id, values, MANAGER);
                        } else {
                            mainProps.create(values, MANAGER);
                        }
                    }}
                    validationSchema={yup.object().shape({
                        [MATCH_FIELDS.MATCH_STAGE_TEAM_HOME_ID_FIELD]: yup.string()
                            .required(<FormattedMessage id="form.required" defaultMessage="required field"/>),
                        [MATCH_FIELDS.MATCH_NUMBER_FIELD]: yup.string()
                            .required(<FormattedMessage id="form.required" defaultMessage="required field"/>),
                        [MATCH_FIELDS.MATCH_STAGE_TEAM_AWAY_ID_FIELD]: yup.string()
                            .required(<FormattedMessage id="form.required" defaultMessage="required field"/>),
                        [MATCH_FIELDS.MATCH_TOUR_ID_FIELD]: yup.string()
                            .required(<FormattedMessage id="form.required" defaultMessage="required field"/>),
                    })}
                >
                    {(props) => {
                        const {
                            isSubmitting,
                            handleSubmit,
                            handleChange,
                            setFieldValue
                        } = props;
                        return (
                            <form onSubmit={handleSubmit}>
                                <DialogContent dividers>
                                    <Grid container spacing={3}>
                                        <Grid item xs={6}>
                                            <Field
                                                name={MATCH_FIELDS.MATCH_NUMBER_FIELD}
                                                type="number"
                                                helperText=<FormattedMessage id="manager.match.form.number.helper" defaultMessage="match number"/>
                                                inputComponent={TextField}
                                                component={FormikAdapter}
                                                label=<FormattedMessage id="manager.match.form.number.label" defaultMessage="Number"/>
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Field
                                                name={MATCH_FIELDS.MATCH_STATUS_FIELD}
                                                type="select"
                                                helperText=<FormattedMessage id="manager.match.form.status.helper" defaultMessage="status"/>
                                                data={statuses}
                                                onChange={handleChange}
                                                inputComponent={DataSelect}
                                                component={FormikSelectAdapter}
                                                label=<FormattedMessage id="manager.match.form.status.label" defaultMessage="Status"/>
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Field
                                                name={MATCH_FIELDS.MATCH_GROUP_ID_FIELD}
                                                type="select"
                                                helperText=<FormattedMessage id="manager.match.form.group.helper" defaultMessage="group"/>
                                                onChange={handleChange}
                                                filter={"/groups?filter=stage_id:" + mainProps.stageID}
                                                inputComponent={AsyncSelect}
                                                component={FormikSelectAdapter}
                                                label=<FormattedMessage id="manager.match.form.group.label" defaultMessage="Group"/>
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Field
                                                name={MATCH_FIELDS.MATCH_TOUR_ID_FIELD}
                                                type="select"
                                                helperText=<FormattedMessage id="manager.match.form.tour.helper" defaultMessage="match tour"/>
                                                onChange={handleChange}
                                                filter={"/tours?filter=stage_id:" + mainProps.stageID}
                                                inputComponent={AsyncSelect}
                                                component={FormikSelectAdapter}
                                                label=<FormattedMessage id="manager.match.form.tour.label" defaultMessage="Tour"/>
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Field
                                                name={MATCH_FIELDS.MATCH_STAGE_TEAM_HOME_ID_FIELD}
                                                type="number"
                                                label=<FormattedMessage id="manager.match.form.home.label" defaultMessage="Home"/>
                                                helperText=<FormattedMessage id="manager.match.form.home.helper" defaultMessage="home"/>
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={handleChange}
                                                filter={"/stageTeams?filter=stage_id:" + mainProps.stageID}
                                                inputComponent={StageTeam}
                                                component={FormikSelectAdapter}
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Field
                                                name={MATCH_FIELDS.MATCH_STAGE_TEAM_AWAY_ID_FIELD}
                                                type="number"
                                                label=<FormattedMessage id="manager.match.form.away.label" defaultMessage="Away"/>
                                                helperText=<FormattedMessage id="manager.match.form.away.helper" defaultMessage="away"/>
                                                onChange={handleChange}
                                                filter={"/stageTeams?filter=stage_id:" + mainProps.stageID}
                                                inputComponent={StageTeam}
                                                component={FormikSelectAdapter}
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Field
                                                name={MATCH_FIELDS.MATCH_DATE_FIELD}
                                                type="date"
                                                helperText=<FormattedMessage id="manager.match.form.date.helper" defaultMessage="match date"/>
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputComponent={TextField}
                                                component={FormikAdapter}
                                                label=<FormattedMessage id="manager.match.form.date.label" defaultMessage="Date"/>
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Field
                                                name={MATCH_FIELDS.MATCH_TIME_FIELD}
                                                type="time"
                                                helperText=<FormattedMessage id="manager.match.form.time.helper" defaultMessage="match time"/>
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputComponent={TextField}
                                                component={FormikAdapter}
                                                label=<FormattedMessage id="manager.match.form.time.label" defaultMessage="Time"/>
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Field
                                                name={MATCH_FIELDS.MATCH_STADIUM_ID_FIELD}
                                                type="select"
                                                helperText=<FormattedMessage id="manager.match.form.stadium.helper" defaultMessage="stadium"/>
                                                onChange={handleChange}
                                                entity={"stadiums"}
                                                setFieldValue={setFieldValue}
                                                inputComponent={AutocompleteSimple}
                                                component={FormikSelectAdapter}
                                                label=<FormattedMessage id="manager.match.form.stadium.label" defaultMessage="Stadium"/>
                                                margin="normal"
                                            />
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        disabled={isSubmitting}>
                                        <FormattedMessage id="button.save" defaultMessage="Save"/>
                                    </Button>
                                </DialogActions>
                            </form>
                        );
                    }}
                </Formik>
            </MuiPickersUtilsProvider>
        </Dialog>
    );
}