import React from 'react';
import ReactDOM from "react-dom/client";
import {Provider} from 'react-redux'
import CssBaseline from '@material-ui/core/CssBaseline';
import {ThemeProvider} from '@material-ui/core/styles';
import {Navigate, Route, BrowserRouter, Routes} from 'react-router-dom';
import configureStore from "./store/configureStore";
import App from './App';
import MainPage from './MainPage';
import theme from './theme';
import Manager from "./containers/Manager";
import './app.css';
import People from "./containers/People";
import Team from "./containers/Team";
import City from "./containers/City";
import Stadium from "./containers/Stadium";
import Country from "./containers/Country";
import MatchView from "./containers/view/MatchView";
import Login from "./containers/Login";
import Registration from "./containers/Registration";
import Penalty from "./containers/Penalty";
import PlaySituation from "./containers/PlaySituation";
import {IntlProvider} from "react-intl";
import messages_ru from "./translations/ru.json";
import messages_en from "./translations/en.json";
import NewsView from "./components/manager/site/NewsView";

const messages = {
    'ru': messages_ru,
    'en': messages_en
};

const language = navigator.language.split(/[-_]/)[0];
const store = configureStore();

const userAuth = {
    isAuthenticated: (localStorage.getItem('token') !== null),
}

function PrivateRoute({children}) {
    const auth = userAuth.isAuthenticated === true
    return auth ? children : <Navigate to="/login"/>;
}

ReactDOM.createRoot(document.querySelector('#root')).render(
    <ThemeProvider theme={theme}>
        <IntlProvider locale={language} defaultLocale="en" messages={messages[language]}>
            <CssBaseline/>
            <Provider store={store}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<MainPage/>}>
                            <Route index element={<PrivateRoute><Manager/></PrivateRoute>}/>
                            <Route path="team" element={<PrivateRoute><Team/></PrivateRoute>}></Route>
                            <Route path="people" element={<PrivateRoute><People/></PrivateRoute>}></Route>
                            <Route path="country" element={<PrivateRoute><Country/></PrivateRoute>}></Route>
                            <Route path="city" element={<PrivateRoute><City/></PrivateRoute>}></Route>
                            <Route path="stadium" element={<PrivateRoute><Stadium/></PrivateRoute>}></Route>
                            <Route path="penalty" element={<PrivateRoute><Penalty/></PrivateRoute>}></Route>
                            <Route path="playSituation" element={<PrivateRoute><PlaySituation/></PrivateRoute>}></Route>
                            <Route path="match" element={<PrivateRoute><MatchView/></PrivateRoute>}>
                                <Route path=":matchID" element={<PrivateRoute><MatchView/></PrivateRoute>}/>
                            </Route>
                            <Route path="news">
                                <Route path=":id" element={<PrivateRoute><NewsView/></PrivateRoute>}/>
                            </Route>
                            <Route path="*" element={<App/>}/>
                        </Route>
                        <Route path="login" element={<Login/>}></Route>
                        <Route path="registration" element={<Registration/>}></Route>
                    </Routes>
                </BrowserRouter>
            </Provider>
        </IntlProvider>
    </ThemeProvider>,
);
