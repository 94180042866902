export const GET_SEASON_LIST_REQUEST = 'GET_SEASON_LIST_REQUEST'
export const GET_SEASON_LIST_SUCCESS = 'GET_SEASON_LIST_SUCCESS'
export const GET_SEASON_LIST_FAILURE = 'GET_SEASON_LIST_FAILURE'
export const DELETE_SEASON_REQUEST = 'DELETE_SEASON_REQUEST'

export const SHOW_MODAL_SEASON = 'SHOW_MODAL_SEASON'
export const CREATE_SEASON = 'CREATE_SEASON'
export const SAVE_SEASON_SUCCESS = 'SAVE_SEASON_SUCCESS'
export const SAVE_SEASON_FAILURE = 'SAVE_SEASON_FAILURE'

export const SET_SEASONS = 'SET_SEASONS'
export const SET_SEASON = 'SET_SEASON'
export const ADD_SEASON = 'ADD_SEASON'

export const MANAGER_SET_SPORT_TYPE = 'MANAGER_SET_SPORT_TYPE'

export const SITE_PAGE_NEWS = 'SITE_PAGE_NEWS';
export const SITE_PAGE_VIDEO = 'SITE_PAGE_VIDEO';
export const SITE_PAGE_DOC = 'SITE_PAGE_DOC';
export const SITE_PAGE_ALBUM = 'SITE_PAGE_ALBUM';
export const SITE_PAGE_CONTACT = 'SITE_PAGE_CONTACT';
export const SITE_PAGE_PHOTO = 'SITE_PAGE_PHOTO';





