
export const MANAGER_STADIUM_LIST_REQUEST = 'MANAGER_STADIUM_LIST_REQUEST'
export const MANAGER_STADIUM_LIST_FAILURE = 'MANAGER_STADIUM_LIST_FAILURE'
export const MANAGER_STADIUM_LIST_SUCCESS = 'MANAGER_STADIUM_LIST_SUCCESS'
export const MANAGER_STADIUM_SHOW_FORM = 'MANAGER_STADIUM_SHOW_MODAL'
export const MANAGER_STADIUM_CREATE_REQUEST = 'MANAGER_STADIUM_CREATE_REQUEST'
export const MANAGER_STADIUM_CREATE_SUCCESS = 'MANAGER_STADIUM_CREATE_SUCCESS'
export const MANAGER_STADIUM_CREATE_FAILURE = 'MANAGER_STADIUM_CREATE_FAILURE'
export const MANAGER_STADIUM_UPDATE_REQUEST = 'MANAGER_STADIUM_UPDATE_REQUEST'
export const MANAGER_STADIUM_UPDATE_SUCCESS = 'MANAGER_STADIUM_UPDATE_SUCCESS'
export const MANAGER_STADIUM_UPDATE_FAILURE = 'MANAGER_STADIUM_UPDATE_FAILURE'
export const MANAGER_STADIUM_DELETE_REQUEST = 'MANAGER_STADIUM_DELETE_REQUEST'
export const MANAGER_STADIUM_DELETE_SUCCESS = 'MANAGER_STADIUM_DELETE_SUCCESS'
export const MANAGER_STADIUM_DELETE_FAILURE = 'MANAGER_STADIUM_DELETE_FAILURE'
export const MANAGER_STADIUM_SET_ID = 'MANAGER_STADIUM_SET_ID'
export const MANAGER_STADIUM_SELECT = 'MANAGER_STADIUM_SELECT'

export const CORE_STADIUM_LIST_REQUEST = 'CORE_STADIUM_LIST_REQUEST'
export const CORE_STADIUM_LIST_FAILURE = 'CORE_STADIUM_LIST_FAILURE'
export const CORE_STADIUM_LIST_SUCCESS = 'CORE_STADIUM_LIST_SUCCESS'
export const CORE_STADIUM_SHOW_FORM = 'CORE_STADIUM_SHOW_FORM'
export const CORE_STADIUM_CREATE_REQUEST = 'CORE_STADIUM_CREATE_REQUEST'
export const CORE_STADIUM_CREATE_SUCCESS = 'CORE_STADIUM_CREATE_SUCCESS'
export const CORE_STADIUM_CREATE_FAILURE = 'CORE_STADIUM_CREATE_FAILURE'
export const CORE_STADIUM_UPDATE_REQUEST = 'CORE_STADIUM_UPDATE_REQUEST'
export const CORE_STADIUM_UPDATE_SUCCESS = 'CORE_STADIUM_UPDATE_SUCCESS'
export const CORE_STADIUM_UPDATE_FAILURE = 'CORE_STADIUM_UPDATE_FAILURE'
export const CORE_STADIUM_DELETE_REQUEST = 'CORE_STADIUM_DELETE_REQUEST'
export const CORE_STADIUM_DELETE_SUCCESS = 'CORE_STADIUM_DELETE_SUCCESS'
export const CORE_STADIUM_DELETE_FAILURE = 'CORE_STADIUM_DELETE_FAILURE'
export const CORE_STADIUM_SET_ID = 'CORE_STADIUM_SET_ID'
export const CORE_STADIUM_VIEW_SUCCESS = 'CORE_STADIUM_VIEW_SUCCESS'
export const CORE_STADIUM_VIEW_FAILURE = 'CORE_STADIUM_VIEW_FAILURE'

export const STADIUM_LIST_REQUEST = 'STADIUM_LIST_REQUEST'
export const STADIUM_LIST_FAILURE = 'STADIUM_LIST_FAILURE'
export const STADIUM_LIST_SUCCESS = 'STADIUM_LIST_SUCCESS'
export const STADIUM_SHOW_FORM = 'STADIUM_SHOW_FORM'
export const STADIUM_CREATE_REQUEST = 'STADIUM_CREATE_REQUEST'
export const STADIUM_CREATE_SUCCESS = 'STADIUM_CREATE_SUCCESS'
export const STADIUM_CREATE_FAILURE = 'STADIUM_CREATE_FAILURE'
export const STADIUM_UPDATE_REQUEST = 'STADIUM_UPDATE_REQUEST'
export const STADIUM_UPDATE_SUCCESS = 'STADIUM_UPDATE_SUCCESS'
export const STADIUM_UPDATE_FAILURE = 'STADIUM_UPDATE_FAILURE'
export const STADIUM_DELETE_REQUEST = 'STADIUM_DELETE_REQUEST'
export const STADIUM_DELETE_SUCCESS = 'STADIUM_DELETE_SUCCESS'
export const STADIUM_DELETE_FAILURE = 'STADIUM_DELETE_FAILURE'
export const STADIUM_VIEW_SUCCESS = 'STADIUM_VIEW_SUCCESS'
export const STADIUM_VIEW_FAILURE = 'STADIUM_VIEW_FAILURE'
export const STADIUM_SET_ID = 'STADIUM_SET_ID'