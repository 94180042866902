/**
 * Created by happy on 20.02.17.
 */
import React from 'react';
import {ENDPOINT} from "../../constants/Common";
import {FOOTBALL_PROTOCOL_ROW} from "../../constants/Protocol";
import ProtocolDropDown from "./ProtocolDropDown";
import {FormattedMessage} from "react-intl";


class ProtocolResultOld extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            situationItems: []
        };

        this.handleInputChange = this.handleInputChange.bind(this);
    }


    componentWillMount() {
        let timeMin;
        let goal;
        let pass1;
        let situation;

        let token = localStorage.getItem('token');

        let url = ENDPOINT + "playSituations?filter=sport_type:"+this.props.sportType;
        let ctx = this;

        fetch(url, {
            method: 'get',
            headers: {
                'Authorization': token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(function (response) {

            response.json().then(function (json) {
                ctx.setState(
                    {
                        situationItems: json.data
                    }
                );
            });

        }).catch(function (error) {
            console.log(error);
        })

        for (let i = 0; i < FOOTBALL_PROTOCOL_ROW; i++) {

            let dataTimeMin = 'timeMin_' + i;
            let dataGoal = 'goal_' + i;
            let dataPass = 'pass1_' + i;
            let dataSituation = 'situation_' + i;


            if (typeof this.props.matchResults[i] === 'undefined') {
                timeMin = undefined;
                goal = 0;
                pass1 = 0;
                situation = 0;
            } else {
                timeMin = this.props.matchResults[i].minute;
                goal = this.props.matchResults[i].matchPlayerID;
                pass1 = this.props.matchResults[i].firstPassID;
                situation = this.props.matchResults[i].playSituationID;
            }

            this.setState({
                [dataTimeMin]: timeMin,
                [dataGoal]: goal,
                [dataPass]: pass1,
                [dataSituation]: situation,
            });
        }

        let tmpPlayers = this.props.matchPlayers.filter(isPlayed)

        let playersNumber = tmpPlayers.map(function (model) {
            return {id: model.id, name: model.number};
        });

        playersNumber.sort(function (a, b) {
            return a.name - b.name
        });


        this.setState({
            playersNumber: playersNumber
        });
    }

    componentWillReceiveProps(nextProps) {
        let timeMin;
        let goal;
        let pass1;
        let situation;

        console.log(nextProps)

        for (let i = 0; i < FOOTBALL_PROTOCOL_ROW; i++) {

            let dataTimeMin = 'timeMin_' + i;
            let dataGoal = 'goal_' + i;
            let dataPass = 'pass1_' + i;
            let dataSituation = 'situation_' + i;


            if (typeof nextProps.matchResults[i] === 'undefined') {
                timeMin = undefined;
                goal = 0;
                pass1 = 0;
                situation = 0;
            } else {
                timeMin = nextProps.matchResults[i].minute;
                goal = nextProps.matchResults[i].matchPlayerID;
                pass1 = nextProps.matchResults[i].firstPassID;
                situation = nextProps.matchResults[i].playSituationID;
            }

            this.setState({
                [dataTimeMin]: timeMin,
                [dataGoal]: goal,
                [dataPass]: pass1,
                [dataSituation]: situation,
            });
        }

        let tmpPlayers = nextProps.matchPlayers.filter(isPlayed)

        let playersNumber = tmpPlayers.map(function (model) {
            return {id: model.id, name: model.number};
        });

        playersNumber.sort(function (a, b) {
            return a.name - b.name
        });


        this.setState({
            playersNumber: playersNumber
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        var name = target.attributes.getNamedItem('data').value

        this.setState({
            [name]: value
        });
    }

    render() {
        let rows = [];
        let id;
        for (let i = 0; i < FOOTBALL_PROTOCOL_ROW; i++) {

            let timeMin = 'timeMin_' + i;
            let goal = 'goal_' + i;
            let pass1 = 'pass1_' + i;
            let situation = 'situation_' + i;

            if (typeof this.props.matchResults[i] === 'undefined') {
                id = undefined
            } else {
                id = this.props.matchResults[i].id
            }

            rows.push(
                <tr key={i} id={this.props.prefix + "_result_" + i}>
                    <td>
                        {i + 1}
                        <input type='hidden' name={'matchResults[' + this.props.prefix + '][' + i + '][id]'}
                               value={id}/>
                    </td>

                    <td>
                        <input className='protocol-number'
                               onChange={this.handleInputChange}
                               data={timeMin}
                               value={this.state[timeMin]}
                               name={'matchResults[' + this.props.prefix + '][' + i + '][minute]'}/>
                    </td>
                    <td>
                        <ProtocolDropDown data={goal}
                                          handleChange={this.handleInputChange}
                                          value={this.state[goal]}
                                          name={'matchResults[' + this.props.prefix + '][' + i + '][matchPlayerID]'}
                                          items={this.state.playersNumber}/>
                    </td>
                    <td>
                        <ProtocolDropDown data={pass1}
                                          handleChange={this.handleInputChange}
                                          value={this.state[pass1]}
                                          name={'matchResults[' + this.props.prefix + '][' + i + '][firstPassID]'}
                                          items={this.state.playersNumber}/>
                    </td>
                    <td>
                        <ProtocolDropDown data={situation}
                                          handleChange={this.handleInputChange}
                                          value={this.state[situation]}
                                          name={'matchResults[' + this.props.prefix + '][' + i + '][playSituationID]'}
                                          items={this.state.situationItems}/>
                    </td>
                    <td>
                        <spa className='protocol-remove'>⊘</spa>
                    </td>
                </tr>
            );
        }

        return (
            <table className='protocol-table'>
                <thead>
                <tr>
                    <th>#</th>
                    <th><FormattedMessage id="protocol.minute" defaultMessage="min"/></th>
                    <th><FormattedMessage id="protocol.goal" defaultMessage="goal"/></th>
                    <th><FormattedMessage id="protocol.pass" defaultMessage="pass"/></th>
                    <th><FormattedMessage id="protocol.play-situation" defaultMessage="ps"/></th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {rows}
                </tbody>
            </table>
        );
    }
}

export default ProtocolResultOld;

export function isPlayed(player) {
    return player.played;
}