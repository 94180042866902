import {
    CITY_CREATE_FAILURE,
    CITY_DELETE_FAILURE,
    CITY_LIST_FAILURE,
    CITY_SHOW_FORM,
    CITY_UPDATE_FAILURE,
    CITY_VIEW_FAILURE
} from '../constants/City';

import {makeDispatch, makeDispatch500, rstatApi} from '../helpers/api'
import {CORE} from "../constants/Common";
import {CREATE, DELETE, LIST, CITY, UPDATE, VIEW} from "../constants/Global";

export function showCityForm(open, type) {
    return (dispatch) => {
        dispatch({
            type: type + CITY_SHOW_FORM,
            payload: open
        })
    }
}

export function viewCity(id, type) {
    return (dispatch) => {
        rstatApi.get('/cities/' + id).then(function (response) {
            dispatch(makeDispatch(CORE, VIEW, CITY, response));
        }).catch(function (error) {
            dispatch(makeDispatch500(type, CITY_VIEW_FAILURE, error))
        });
    }
}

export function updateCity(id, body, type) {
    return (dispatch) => {
        rstatApi.put('/cities/' + id, body).then(function (response) {
            dispatch(makeDispatch(CORE, UPDATE, CITY, response));
        }).catch(function (error) {
            dispatch(makeDispatch500(type, CITY_UPDATE_FAILURE, error))
        });
    }
}

export function listCities(query = '', type) {
    return (dispatch) => {
        rstatApi.get('/cities' + query).then(function (response) {
            dispatch(makeDispatch(CORE, LIST, CITY, response));
        }).catch(function (error) {
            dispatch(makeDispatch500(type, CITY_LIST_FAILURE, error))
        });
    }
}

export function createCity(body, type) {
    return (dispatch) => {
        rstatApi.post('/cities', body).then(function (response) {
            dispatch(makeDispatch(CORE, CREATE, CITY, response));
        }).catch(function (error) {
            dispatch(makeDispatch500(type, CITY_CREATE_FAILURE, error))
        });
    }
}

export function deleteCity(id, type) {
    return (dispatch) => {
        rstatApi.delete('/cities/' + id,).then(function (response) {
            dispatch(makeDispatch(CORE, DELETE, CITY, response, parseInt(id, 10)));
        }).catch(function (error) {
            dispatch(makeDispatch500(type, CITY_DELETE_FAILURE, error))
        })
    }
}