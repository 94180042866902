import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {MANAGER} from "../../../constants/Common";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import {Field, Formik} from "formik";
import * as yup from 'yup'
import FormikAdapter from "../../common/FormikAdapter";
import FormikSelectAdapter from "../../common/FormikSelectAdapter";
import DataSelect from "../../form/DataSelect";
import {RESULT_TYPE_ADDITIONAL_TIME, RESULT_TYPE_FULL_TIME} from "../../../constants/Match";
import {FormattedMessage} from "react-intl";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const MATCH_RESULT_FIELDS = {
    MATCH_RESULT_ID_FIELD: 'id',
    MATCH_RESULT_GOAL_HOME: 'goalHome',
    MATCH_RESULT_GOAL_AWAY: 'goalAway',
    MATCH_RESULT_RESULT_TYPE: 'resultType',
    MATCH_RESULT_STADIUM_ID_FIELD: 'stadiumID',
    MATCH_RESULT_DESCRIPTION_FIELD: 'description',
    MATCH_RESULT_DATE_FIELD: 'date',
    MATCH_RESULT_TIME_FIELD: 'time',
    MATCH_RESULT_TOUR_ID_FIELD: 'tourID',
    MATCH_RESULT_STATUS_FIELD: 'status',
};

export default function MatchResultForm(props) {
    let resultTypes = [
        {key: RESULT_TYPE_FULL_TIME, value: <FormattedMessage id="match.full-name" defaultMessage="Full name"/>},
        {key: RESULT_TYPE_ADDITIONAL_TIME, value: <FormattedMessage id="match.additional-time" defaultMessage="Additional time"/>},
    ]

    const handleClose = () => {
        props.show(false, MANAGER)
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={props.open}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                <FormattedMessage id="match-score-form" defaultMessage="Match score form"/>
            </DialogTitle>
            <Formik
                initialValues={{
                    [MATCH_RESULT_FIELDS.MATCH_RESULT_ID_FIELD]: props.model.id,
                    [MATCH_RESULT_FIELDS.MATCH_RESULT_GOAL_HOME]: props.model.goalHome,
                    [MATCH_RESULT_FIELDS.MATCH_RESULT_GOAL_HOME]: props.model.goalAway,
                    [MATCH_RESULT_FIELDS.MATCH_RESULT_RESULT_TYPE]: props.model.resultType,
                    [MATCH_RESULT_FIELDS.MATCH_STAGE_TEAM_HOME_ID_FIELD]: props.model.stageTeamHomeID,
                    [MATCH_RESULT_FIELDS.MATCH_STAGE_TEAM_AWAY_ID_FIELD]: props.model.stageTeamAwayID,
                    [MATCH_RESULT_FIELDS.MATCH_RESULT_DATE_FIELD]: props.model.date,
                    [MATCH_RESULT_FIELDS.MATCH_RESULT_TIME_FIELD]: props.model.time,
                    [MATCH_RESULT_FIELDS.MATCH_RESULT_TOUR_ID_FIELD]: props.model.tour.id,
                    [MATCH_RESULT_FIELDS.MATCH_RESULT_STATUS_FIELD]: props.model.status,
                    [MATCH_RESULT_FIELDS.MATCH_RESULT_STADIUM_ID_FIELD]: props.model.stadiumID,
                    [MATCH_RESULT_FIELDS.MATCH_RESULT_DESCRIPTION_FIELD]: props.model.description,
                }}

                onSubmit={(values, actions) => {
                    props.update(values.id, values, MANAGER);
                }}

                validationSchema={yup.object().shape({
                    [MATCH_RESULT_FIELDS.MATCH_RESULT_GOAL_HOME]: yup.number()
                        .required(<FormattedMessage id="form.required" defaultMessage="required field"/>),
                    [MATCH_RESULT_FIELDS.MATCH_RESULT_GOAL_AWAY]: yup.number()
                        .required(<FormattedMessage id="form.required" defaultMessage="required field"/>),
                    [MATCH_RESULT_FIELDS.MATCH_RESULT_RESULT_TYPE]: yup.string()
                        .required(<FormattedMessage id="form.required" defaultMessage="required field"/>),
                })}
            >
                {(props) => {
                    const {
                        isSubmitting,
                        handleSubmit,
                        handleChange,
                    } = props;
                    return (
                        <form onSubmit={handleSubmit}>
                            <DialogContent dividers>
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <Field
                                            name={MATCH_RESULT_FIELDS.MATCH_RESULT_GOAL_HOME}
                                            type="number"
                                            helperText=<FormattedMessage id="manager.match.goal-home.helper" defaultMessage="goal home"/>
                                            inputComponent={TextField}
                                            component={FormikAdapter}
                                            label=<FormattedMessage id="manager.match.goal-home.label" defaultMessage="Goal home"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Field
                                            name={MATCH_RESULT_FIELDS.MATCH_RESULT_GOAL_AWAY}
                                            type="number"
                                            helperText=<FormattedMessage id="manager.match.goal-away.helper" defaultMessage="goal away"/>
                                            inputComponent={TextField}
                                            component={FormikAdapter}
                                            label=<FormattedMessage id="manager.match.goal-away.label" defaultMessage="Goal away"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            name={MATCH_RESULT_FIELDS.MATCH_RESULT_RESULT_TYPE}
                                            type="select"
                                            helperText=<FormattedMessage id="manager.match.result-type.helper" defaultMessage="result type"/>
                                            data={resultTypes}
                                            onChange={handleChange}
                                            inputComponent={DataSelect}
                                            component={FormikSelectAdapter}
                                            label=<FormattedMessage id="manager.match.result-type.label" defaultMessage="Result type"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={isSubmitting}>
                                    <FormattedMessage id="button.save" defaultMessage="Save"/>
                                </Button>
                            </DialogActions>
                        </form>
                    );
                }}
            </Formik>
        </Dialog>
    );
}