import {
    INFO_SHOW_MESSAGE,
} from '../constants/Global';
import {HIDE_SNACKBAR, SHOW_SNACKBAR} from "../constants/Common";

export function showMessage(title, message) {
    return (dispatch) => {
        dispatch({
            type: INFO_SHOW_MESSAGE,
            title: title,
            message: message,
        })

    }
}
export function showVariantMessage(severity, title, message) {
    return (dispatch) => {
        dispatch({
            type: INFO_SHOW_MESSAGE,
            variant: severity,
            title: title,
            message: message,
        })

    }
}

export function showSnackbar(variant, message) {
    return (dispatch) => {
        dispatch({
            type: SHOW_SNACKBAR,
            variant: variant,
            message: message
        })

    }
}

export function hideSnackbar() {
    return (dispatch) => {
        dispatch({
            type: HIDE_SNACKBAR
        })

    }
}

