import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export function Redactor(props) {

    function handle(value) {
        props.setFieldValue(props.name, value)
    }

    return (
        <ReactQuill style={{height:250}} theme="snow" value={props.value} onChange={handle}/>
    );
}