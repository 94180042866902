import React from 'react';
import Box from '@material-ui/core/Box';
import * as playSituationActions from '../actions/PlaySituationActions'

import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import PlaySituationForm from "../components/PlaySituationForm";
import PlaySituations from "../components/PlaySituations";

function PlaySituation(props) {
    const {playSituation} = props;
    const {listCities, showPlaySituationForm, createPlaySituation, updatePlaySituation,viewPlaySituation,deletePlaySituation} = props.playSituationActions;
    return (
        <Box style={{height:'100%'}}>
            <PlaySituations
                models={playSituation.get('models')}
                loading={playSituation.get('loading')}
                showForm={showPlaySituationForm}
                view={viewPlaySituation}
                delete={deletePlaySituation}
                list={listCities}
            />
            <PlaySituationForm
                model={playSituation.get('model')}
                show={showPlaySituationForm}
                create={createPlaySituation}
                update={updatePlaySituation}
                open={playSituation.get('showModal')}
            />
        </Box>
    );
}

function mapStateToProps(state) {
    return {
        playSituation: state.playSituation,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        playSituationActions: bindActionCreators(playSituationActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlaySituation)

