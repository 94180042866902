export const MANAGER_DIVISION_TEAM_LIST_REQUEST = 'MANAGER_DIVISION_TEAM_LIST_REQUEST';
export const MANAGER_DIVISION_TEAM_LIST_FAILURE = 'MANAGER_DIVISION_TEAM_LIST_FAILURE'
export const MANAGER_DIVISION_TEAM_LIST_SUCCESS = 'MANAGER_DIVISION_TEAM_LIST_SUCCESS'
export const MANAGER_DIVISION_TEAM_SHOW_FORM = 'MANAGER_DIVISION_TEAM_SHOW_FORM';
export const MANAGER_DIVISION_TEAM_CREATE_SUCCESS = 'MANAGER_DIVISION_TEAM_CREATE_SUCCESS'
export const MANAGER_DIVISION_TEAM_CREATE_FAILURE = 'MANAGER_DIVISION_TEAM_CREATE_FAILURE'
export const MANAGER_DIVISION_TEAM_UPDATE_SUCCESS = 'MANAGER_DIVISION_TEAM_UPDATE_SUCCESS'
export const MANAGER_DIVISION_TEAM_UPDATE_FAILURE = 'MANAGER_DIVISION_TEAM_UPDATE_FAILURE'
export const MANAGER_DIVISION_TEAM_DELETE_SUCCESS = 'MANAGER_DIVISION_TEAM_DELETE_SUCCESS'
export const MANAGER_DIVISION_TEAM_DELETE_FAILURE = 'MANAGER_DIVISION_TEAM_DELETE_FAILURE'
export const MANAGER_DIVISION_TEAM_VIEW_SUCCESS = 'MANAGER_DIVISION_TEAM_VIEW_SUCCESS'
export const MANAGER_DIVISION_TEAM_CITY_SELECT = 'MANAGER_DIVISION_TEAM_CITY_SELECT'

export const CORE_DIVISION_TEAM_LIST_REQUEST = 'CORE_DIVISION_TEAM_LIST_REQUEST'
export const CORE_DIVISION_TEAM_LIST_FAILURE = 'CORE_DIVISION_TEAM_LIST_FAILURE'
export const CORE_DIVISION_TEAM_LIST_SUCCESS = 'CORE_DIVISION_TEAM_LIST_SUCCESS'
export const CORE_DIVISION_TEAM_SHOW_MODAL = 'CORE_DIVISION_TEAM_SHOW_MODAL'
export const CORE_DIVISION_TEAM_CREATE_SUCCESS = 'CORE_DIVISION_TEAM_CREATE_SUCCESS'
export const CORE_DIVISION_TEAM_DELETE_SUCCESS = 'CORE_DIVISION_TEAM_DELETE_SUCCESS'
export const CORE_DIVISION_TEAM_VIEW_SUCCESS = 'CORE_DIVISION_TEAM_VIEW_SUCCESS'
export const CORE_DIVISION_TEAM_VIEW_FAILURE = 'CORE_DIVISION_TEAM_VIEW_FAILURE'

export const DIVISION_TEAM_LIST_REQUEST = 'DIVISION_TEAM_LIST_REQUEST'
export const DIVISION_TEAM_LIST_FAILURE = 'DIVISION_TEAM_LIST_FAILURE'
export const DIVISION_TEAM_LIST_SUCCESS = 'DIVISION_TEAM_LIST_SUCCESS'
export const DIVISION_TEAM_SHOW_FORM = 'DIVISION_TEAM_SHOW_FORM';
export const DIVISION_TEAM_CREATE_SUCCESS = 'DIVISION_TEAM_CREATE_SUCCESS'
export const DIVISION_TEAM_CREATE_FAILURE = 'DIVISION_TEAM_CREATE_FAILURE'
export const DIVISION_TEAM_UPDATE_SUCCESS = 'DIVISION_TEAM_UPDATE_SUCCESS'
export const DIVISION_TEAM_UPDATE_FAILURE = 'DIVISION_TEAM_UPDATE_FAILURE'
export const DIVISION_TEAM_DELETE_SUCCESS = 'DIVISION_TEAM_DELETE_SUCCESS'
export const DIVISION_TEAM_DELETE_FAILURE = 'DIVISION_TEAM_DELETE_FAILURE'
export const DIVISION_TEAM_VIEW_SUCCESS = 'DIVISION_TEAM_VIEW_SUCCESS'
export const DIVISION_TEAM_VIEW_FAILURE = 'DIVISION_TEAM_VIEW_FAILURE'
