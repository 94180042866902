import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import {Field, Formik} from "formik";
import * as yup from 'yup'
import {CORE} from "../constants/Common";
import FormikAdapter from "./common/FormikAdapter";
import FormikSelectAdapter from "./common/FormikSelectAdapter";
import AsyncSelect from "./form/AsyncSelect";
import CountrySelect from "./form/CountrySelect";
import {FormattedMessage} from "react-intl";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const CITY_FIELDS = {
    CITY_ID_FIELD: 'id',
    CITY_TYPE_FIELD: 'typeID',
    CITY_COUNTRY_FIELD: 'countryID',
    CITY_NAME_FIELD: 'name',
    CITY_SORT_FIELD: 'sort',
};

export default function CityForm(props) {

    const handleClose = () => {
        props.show(false, CORE)
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={props.open}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                <FormattedMessage id="city-form" defaultMessage="City form"/>
            </DialogTitle>
            <Formik
                initialValues={{
                    [CITY_FIELDS.CITY_ID_FIELD]: props.model.id,
                    [CITY_FIELDS.CITY_NAME_FIELD]: props.model.name,
                    [CITY_FIELDS.CITY_TYPE_FIELD]: props.model.type.id,
                    [CITY_FIELDS.CITY_COUNTRY_FIELD]: props.model.country.id,
                    [CITY_FIELDS.CITY_SORT_FIELD]: props.model.sort,
                }}
                onSubmit={(values, actions) => {
                    values.typeID = parseInt(values.typeID)
                    values.countryID = parseInt(values.countryID)
                    if (values.id > 0) {
                        props.update(values.id, values, CORE);
                    } else {
                        props.create(values, CORE);
                    }
                }}
                validationSchema={yup.object().shape({
                    [CITY_FIELDS.CITY_NAME_FIELD]: yup.string()
                        .required(<FormattedMessage id="form.required" defaultMessage="required field"/>),
                    [CITY_FIELDS.CITY_TYPE_FIELD]: yup.number()
                        .required(<FormattedMessage id="form.required" defaultMessage="required field"/>),
                    [CITY_FIELDS.CITY_COUNTRY_FIELD]: yup.number()
                        .required(<FormattedMessage id="form.required" defaultMessage="required field"/>),
                })}
            >
                {(props) => {
                    const {
                        setFieldValue,
                        isSubmitting,
                        handleSubmit,
                        handleChange
                    } = props;
                    return (
                        <form onSubmit={handleSubmit}>
                            <DialogContent dividers>
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <Field
                                            name={CITY_FIELDS.CITY_TYPE_FIELD}
                                            type="select"
                                            helperText=<FormattedMessage id="city.form.type.helper" defaultMessage="city type"/>
                                            onChange={handleChange}
                                            filter={"/cityTypes"}
                                            inputComponent={AsyncSelect}
                                            component={FormikSelectAdapter}
                                            label=<FormattedMessage id="city.form.type.label" defaultMessage="Type"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Field
                                            name={CITY_FIELDS.CITY_NAME_FIELD}
                                            type="text"
                                            helperText=<FormattedMessage id="city.form.name.helper" defaultMessage="city name"/>
                                            inputComponent={TextField}
                                            component={FormikAdapter}
                                            label=<FormattedMessage id="city.form.name.helper" defaultMessage="Name"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Field
                                            name={CITY_FIELDS.CITY_COUNTRY_FIELD}
                                            type="select"
                                            helperText=<FormattedMessage id="city.form.country.helper" defaultMessage="city country"/>
                                            setFieldValue={setFieldValue}
                                            entity="countries"
                                            inputComponent={CountrySelect}
                                            component={FormikSelectAdapter}
                                            label=<FormattedMessage id="city.form.name.label" defaultMessage="Country"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Field
                                            name={CITY_FIELDS.CITY_SORT_FIELD}
                                            type="number"
                                            helperText=<FormattedMessage id="form.sort.helper" defaultMessage="sorting"/>
                                            inputComponent={TextField}
                                            component={FormikAdapter}
                                            label=<FormattedMessage id="form.name.label" defaultMessage="Sort"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={isSubmitting}>
                                    <FormattedMessage id="button.save" defaultMessage="Save"/>
                                </Button>
                            </DialogActions>
                        </form>
                    )
                        ;
                }}
            </Formik>
        </Dialog>
    );
}