import {
    DIVISION_PERSONAL_CREATE_FAILURE,
    DIVISION_PERSONAL_DELETE_FAILURE,
    DIVISION_PERSONAL_LIST_FAILURE,
    DIVISION_PERSONAL_LIST_REQUEST,
    DIVISION_PERSONAL_SHOW_FORM,
    DIVISION_PERSONAL_UPDATE_FAILURE,
    DIVISION_PERSONAL_VIEW_FAILURE
} from '../constants/DivisionPersonal';

import {makeDispatch, makeDispatch500, rstatApi} from '../helpers/api'
import {MANAGER} from "../constants/Common";
import {CREATE, DELETE, DIVISION_PERSONAL, LIST, UPDATE, VIEW} from "../constants/Global";

export function showDivisionPersonalForm(open, type) {
    return (dispatch) => {
        dispatch({
            type: type + DIVISION_PERSONAL_SHOW_FORM,
            payload: open
        })
    }
}

export function viewDivisionPersonal(id, type) {
    return (dispatch) => {
        rstatApi.get('/divisionPersonals/' + id).then(function (response) {
            dispatch(makeDispatch(MANAGER, VIEW, DIVISION_PERSONAL, response));
        }).catch(function (error) {
            dispatch(makeDispatch500(type, DIVISION_PERSONAL_VIEW_FAILURE, error))
        });
    }
}

export function updateDivisionPersonal(id, body, type) {
    return (dispatch) => {
        rstatApi.put('/divisionPersonals/' + id, body).then(function (response) {
            dispatch(makeDispatch(MANAGER, UPDATE, DIVISION_PERSONAL, response));
        }).catch(function (error) {
            dispatch(makeDispatch500(type, DIVISION_PERSONAL_UPDATE_FAILURE, error))
        });
    }
}

export function listDivisionPersonals(query = '', type) {
    let divisionID = 0;
    let result = query.match(/division_id:(\d+)/i);
    if (result !== null) {
        divisionID = parseInt(result[1], 10)
    }
    return (dispatch) => {
        dispatch({
            type: type + DIVISION_PERSONAL_LIST_REQUEST,
            payload: divisionID,
        });
        rstatApi.get('/divisionPersonals' + query).then(function (response) {
            dispatch(makeDispatch(MANAGER, LIST, DIVISION_PERSONAL, response));
        }).catch(function (error) {
            dispatch(makeDispatch500(type, DIVISION_PERSONAL_LIST_FAILURE, error))
        });
    }
}

export function createDivisionPersonal(body, type) {
    return (dispatch) => {
        rstatApi.post('/divisionPersonals', body).then(function (response) {
            dispatch(makeDispatch(MANAGER, CREATE, DIVISION_PERSONAL, response));
        }).catch(function (error) {
            dispatch(makeDispatch500(type, DIVISION_PERSONAL_CREATE_FAILURE, error))
        });
    }
}

export function deleteDivisionPersonal(id, type) {
    return (dispatch) => {
        rstatApi.delete('/divisionPersonals/' + id,).then(function (response) {
            dispatch(makeDispatch(MANAGER, DELETE, DIVISION_PERSONAL, response, parseInt(id, 10)));
        }).catch(function (error) {
            dispatch(makeDispatch500(type, DIVISION_PERSONAL_DELETE_FAILURE, error))
        })
    }
}