import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from "@material-ui/core/FormHelperText";
import {rstatApi} from "../../helpers/api";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from "@material-ui/core/TextField";
import Box from "@mui/material/Box";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width:'100%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export default function PeopleSelect(props) {
    const classes = useStyles();

    const [options, setOptions] = useState([]);

    useEffect(() => {
        (async () => {
            rstatApi.get(props.filter).then(function (response) {
                setOptions(response.data.data.map((item) => item));
            }).catch(function (error) {
                // TODO process error.
                console.log(error)
            });

        })();

    }, [props.filter]);

    const handleChange = (value) => {
        if (value !== null && value.id !== undefined) {
            props.setFieldValue(props.name, value.id)
        }
    };

    return (
        <FormControl variant="outlined" className={classes.formControl} error={props.error}>
            <Autocomplete
                id={props.name}
                name={props.name}
                sx={{ width: 300 }}
                value={options.find(v => v.id === props.value) || {}}
                options={options}
                defaultValue="0"
                autoHighlight
                onChange={(_, value) => handleChange(value)}
                onInputChange={(_, value) => handleChange(value)}
                getOptionLabel={(option) => (option.surname ? option.surname + " " + option.name : "не выбран")}
                getOptionSelected={(option, value) => value.value === option.value}
                renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        {option.surname} {option.name} [{option.birthday}]
                    </Box>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={props.label}
                        variant="outlined"
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                    />
                )}
            />
            <FormHelperText>{props.helperText}</FormHelperText>
        </FormControl>
    );
}