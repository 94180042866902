/**
 * Created by happy on 11.02.17.
 */
export const MANAGER_MATCH_LIST_REQUEST = 'MANAGER_MATCH_LIST_REQUEST';
export const MANAGER_MATCH_LIST_FAILURE = 'MANAGER_MATCH_LIST_FAILURE'
export const MANAGER_MATCH_LIST_SUCCESS = 'MANAGER_MATCH_LIST_SUCCESS'
export const MANAGER_MATCH_SHOW_FORM = 'MANAGER_MATCH_SHOW_FORM';
export const MANAGER_MATCH_CREATE_SUCCESS = 'MANAGER_MATCH_CREATE_SUCCESS'
export const MANAGER_MATCH_CREATE_FAILURE = 'MANAGER_MATCH_CREATE_FAILURE'
export const MANAGER_MATCH_UPDATE_SUCCESS = 'MANAGER_MATCH_UPDATE_SUCCESS'
export const MANAGER_MATCH_UPDATE_FAILURE = 'MANAGER_MATCH_UPDATE_FAILURE'
export const MANAGER_MATCH_DELETE_SUCCESS = 'MANAGER_MATCH_DELETE_SUCCESS'
export const MANAGER_MATCH_DELETE_FAILURE = 'MANAGER_MATCH_DELETE_FAILURE'
export const MANAGER_MATCH_VIEW_SUCCESS = 'MANAGER_MATCH_VIEW_SUCCESS'
export const MANAGER_MATCH_SHOW_RESULT = 'MANAGER_MATCH_SHOW_RESULT'
export const MANAGER_MATCH_SET_MODEL = 'MANAGER_MATCH_SET_MODEL'

export const CORE_MATCH_LIST_REQUEST = 'CORE_MATCH_LIST_REQUEST'
export const CORE_MATCH_LIST_FAILURE = 'CORE_MATCH_LIST_FAILURE'
export const CORE_MATCH_LIST_SUCCESS = 'CORE_MATCH_LIST_SUCCESS'
export const CORE_MATCH_SHOW_MODAL = 'CORE_MATCH_SHOW_MODAL'
export const CORE_MATCH_CREATE_SUCCESS = 'CORE_MATCH_CREATE_SUCCESS'
export const CORE_MATCH_DELETE_SUCCESS = 'CORE_MATCH_DELETE_SUCCESS'
export const CORE_MATCH_VIEW_SUCCESS = 'CORE_MATCH_VIEW_SUCCESS'
export const CORE_MATCH_VIEW_FAILURE = 'CORE_MATCH_VIEW_FAILURE'

export const MATCH_LIST_REQUEST = 'MATCH_LIST_REQUEST'
export const MATCH_LIST_FAILURE = 'MATCH_LIST_FAILURE'
export const MATCH_LIST_SUCCESS = 'MATCH_LIST_SUCCESS'
export const MATCH_SHOW_FORM = 'MATCH_SHOW_FORM';
export const MATCH_SHOW_RESULT = 'MATCH_SHOW_RESULT'
export const MATCH_CREATE_SUCCESS = 'MATCH_CREATE_SUCCESS'
export const MATCH_CREATE_FAILURE = 'MATCH_CREATE_FAILURE'
export const MATCH_UPDATE_SUCCESS = 'MATCH_UPDATE_SUCCESS'
export const MATCH_UPDATE_FAILURE = 'MATCH_UPDATE_FAILURE'
export const MATCH_DELETE_SUCCESS = 'MATCH_DELETE_SUCCESS'
export const MATCH_DELETE_FAILURE = 'MATCH_DELETE_FAILURE'
export const MATCH_VIEW_SUCCESS = 'MATCH_VIEW_SUCCESS'
export const MATCH_VIEW_FAILURE = 'MATCH_VIEW_FAILURE'
export const MATCH_SET_MODEL = 'MATCH_SET_MODEL'

export const MATCH_ALL = "ALL"
export const MATCH_PLANNED = "PLANNED"
export const MATCH_IN_PROCESS = "IN_PROCESS"
export const MATCH_FINISHED = "FINISHED"
export const MATCH_DELAYED = "DELAYED"
export const MATCH_CANCELED = "CANCELED"

export const MATCH_STAT_NONE = "NONE"
export const MATCH_STAT_TABLE = "TABLE"
export const MATCH_STAT_PROCESSED = "PROCESSED"
export const MATCH_STAT_PROTOCOL = "PROTOCOL"

export const RESULT_TYPE_FULL_TIME = "FULL_TIME"
export const RESULT_TYPE_ADDITIONAL_TIME = "ADDITIONAL_TIME"
