import React, {useEffect, useState} from 'react'
import MaterialTable from 'material-table'
import {CORE, ENDPOINT, MANAGER} from "../../../constants/Common";
import {FormattedMessage, useIntl} from "react-intl";
import {Avatar} from "@mui/material";

export default function Contacts(props) {
    const [selectedRow, setValue] = useState(0);

    let data = [];
    props.models.forEach(n => data.push(n));

    function handleShowForm() {
        props.showForm(true, MANAGER)
    }

    function handleUpdate(id) {
        props.view(id, MANAGER)
    }

    function handleDelete(id) {
        props.delete(id, MANAGER)
    }

    const intl = useIntl()

    return (
        <MaterialTable
            title={intl.formatMessage({id: 'manager.site.contact-title',defaultMessage:"Contacts"})}
            columns={[
                {
                    title: 'id',
                    field: 'id',
                },
                {
                    title: 'avatar',
                    render: rowData => <Avatar alt="contact" src={"https://r-stat.org/sport-site-api/contacts/photo/"+rowData.id} />,
                },
                {
                    title: <FormattedMessage id="manager.site.contact.name" defaultMessage="name"/>,
                    field: 'name',
                },
                {
                    title: <FormattedMessage id="manager.site.contact.last-name" defaultMessage="last name"/>,
                    field: 'surname',
                },
                {
                    title: <FormattedMessage id="manager.site.contact.position" defaultMessage="role"/>,
                    field: 'position',
                },
                {
                    title: <FormattedMessage id="manager.site.contact.phone" defaultMessage="phone"/>,
                    field: 'phone',
                },
                {
                    title: <FormattedMessage id="manager.site.contact.email" defaultMessage="email"/>,
                    field: 'email',
                },
                {
                    title: <FormattedMessage id="manager.site.contact.created" defaultMessage="created"/>,
                    field: 'createdAt',
                },
            ]}
            options={{
                rowStyle: rowData => ({
                    fontSize: 12,
                    backgroundColor: (selectedRow && selectedRow.tableData.id === rowData.tableData.id) ? '#EEE' : '#FFF'
                }),
                pageSize:15,
                paging: true,
                header: true,
                sorting: true,
                filtering: false,
                actionsColumnIndex: -1,
                showTitle: true
            }}
            localization={
                {
                    toolbar: {
                        searchPlaceholder: intl.formatMessage({id: 'manager.site.contact-title',defaultMessage:"Contacts"}),
                        searchTooltip: <FormattedMessage id="common.find" defaultMessage="Find"/>
                    },
                    body: {
                        emptyDataSourceMessage: <FormattedMessage id="common.no_data" defaultMessage="no data"/>
                    },
                    header: {
                        actions: <FormattedMessage id="common.actions" defaultMessage="actions"/>
                    },
                }
            }
            actions={[
                {
                    icon: 'add',
                    tooltip: <FormattedMessage id="manager.site.contact.add" defaultMessage="add contact"/>,
                    isFreeAction: true,
                    onClick: (event) => handleShowForm()
                },
                {
                    icon: 'search',
                    tooltip: <FormattedMessage id="manager.site.menu.search" defaultMessage="search"/>,
                    onClick: (event, rowData) => window.location.href = "/contact/" + rowData.id
                },
                {
                    icon: 'edit',
                    tooltip: <FormattedMessage id="manager.site.menu.edit" defaultMessage="edit"/>,
                    onClick: (event,rowData) => handleUpdate(rowData.id)
                },
                {
                    icon: 'delete',
                    tooltip: <FormattedMessage id="manager.site.menu.delete" defaultMessage="delete"/>,
                    onClick: (event,rowData) => handleDelete(rowData.id)
                }
            ]}
            data={data}
        />
    )
}