
export const MANAGER_PLAY_SITUATION_LIST_REQUEST = 'MANAGER_PLAY_SITUATION_LIST_REQUEST'
export const MANAGER_PLAY_SITUATION_LIST_FAILURE = 'MANAGER_PLAY_SITUATION_LIST_FAILURE'
export const MANAGER_PLAY_SITUATION_LIST_SUCCESS = 'MANAGER_PLAY_SITUATION_LIST_SUCCESS'
export const MANAGER_PLAY_SITUATION_SHOW_FORM = 'MANAGER_PLAY_SITUATION_SHOW_MODAL'
export const MANAGER_PLAY_SITUATION_CREATE_REQUEST = 'MANAGER_PLAY_SITUATION_CREATE_REQUEST'
export const MANAGER_PLAY_SITUATION_CREATE_SUCCESS = 'MANAGER_PLAY_SITUATION_CREATE_SUCCESS'
export const MANAGER_PLAY_SITUATION_CREATE_FAILURE = 'MANAGER_PLAY_SITUATION_CREATE_FAILURE'
export const MANAGER_PLAY_SITUATION_UPDATE_REQUEST = 'MANAGER_PLAY_SITUATION_UPDATE_REQUEST'
export const MANAGER_PLAY_SITUATION_UPDATE_SUCCESS = 'MANAGER_PLAY_SITUATION_UPDATE_SUCCESS'
export const MANAGER_PLAY_SITUATION_UPDATE_FAILURE = 'MANAGER_PLAY_SITUATION_UPDATE_FAILURE'
export const MANAGER_PLAY_SITUATION_DELETE_REQUEST = 'MANAGER_PLAY_SITUATION_DELETE_REQUEST'
export const MANAGER_PLAY_SITUATION_DELETE_SUCCESS = 'MANAGER_PLAY_SITUATION_DELETE_SUCCESS'
export const MANAGER_PLAY_SITUATION_DELETE_FAILURE = 'MANAGER_PLAY_SITUATION_DELETE_FAILURE'
export const MANAGER_PLAY_SITUATION_SET_ID = 'MANAGER_PLAY_SITUATION_SET_ID'
export const MANAGER_PLAY_SITUATION_SELECT = 'MANAGER_PLAY_SITUATION_SELECT'

export const CORE_PLAY_SITUATION_LIST_REQUEST = 'CORE_PLAY_SITUATION_LIST_REQUEST'
export const CORE_PLAY_SITUATION_LIST_FAILURE = 'CORE_PLAY_SITUATION_LIST_FAILURE'
export const CORE_PLAY_SITUATION_LIST_SUCCESS = 'CORE_PLAY_SITUATION_LIST_SUCCESS'
export const CORE_PLAY_SITUATION_SHOW_FORM = 'CORE_PLAY_SITUATION_SHOW_FORM'
export const CORE_PLAY_SITUATION_CREATE_REQUEST = 'CORE_PLAY_SITUATION_CREATE_REQUEST'
export const CORE_PLAY_SITUATION_CREATE_SUCCESS = 'CORE_PLAY_SITUATION_CREATE_SUCCESS'
export const CORE_PLAY_SITUATION_CREATE_FAILURE = 'CORE_PLAY_SITUATION_CREATE_FAILURE'
export const CORE_PLAY_SITUATION_UPDATE_REQUEST = 'CORE_PLAY_SITUATION_UPDATE_REQUEST'
export const CORE_PLAY_SITUATION_UPDATE_SUCCESS = 'CORE_PLAY_SITUATION_UPDATE_SUCCESS'
export const CORE_PLAY_SITUATION_UPDATE_FAILURE = 'CORE_PLAY_SITUATION_UPDATE_FAILURE'
export const CORE_PLAY_SITUATION_DELETE_REQUEST = 'CORE_PLAY_SITUATION_DELETE_REQUEST'
export const CORE_PLAY_SITUATION_DELETE_SUCCESS = 'CORE_PLAY_SITUATION_DELETE_SUCCESS'
export const CORE_PLAY_SITUATION_DELETE_FAILURE = 'CORE_PLAY_SITUATION_DELETE_FAILURE'
export const CORE_PLAY_SITUATION_SET_ID = 'CORE_PLAY_SITUATION_SET_ID'
export const CORE_PLAY_SITUATION_VIEW_SUCCESS = 'CORE_PLAY_SITUATION_VIEW_SUCCESS'
export const CORE_PLAY_SITUATION_VIEW_FAILURE = 'CORE_PLAY_SITUATION_VIEW_FAILURE'

export const PLAY_SITUATION_LIST_REQUEST = 'PLAY_SITUATION_LIST_REQUEST'
export const PLAY_SITUATION_LIST_FAILURE = 'PLAY_SITUATION_LIST_FAILURE'
export const PLAY_SITUATION_LIST_SUCCESS = 'PLAY_SITUATION_LIST_SUCCESS'
export const PLAY_SITUATION_SHOW_FORM = 'PLAY_SITUATION_SHOW_FORM'
export const PLAY_SITUATION_CREATE_REQUEST = 'PLAY_SITUATION_CREATE_REQUEST'
export const PLAY_SITUATION_CREATE_SUCCESS = 'PLAY_SITUATION_CREATE_SUCCESS'
export const PLAY_SITUATION_CREATE_FAILURE = 'PLAY_SITUATION_CREATE_FAILURE'
export const PLAY_SITUATION_UPDATE_REQUEST = 'PLAY_SITUATION_UPDATE_REQUEST'
export const PLAY_SITUATION_UPDATE_SUCCESS = 'PLAY_SITUATION_UPDATE_SUCCESS'
export const PLAY_SITUATION_UPDATE_FAILURE = 'PLAY_SITUATION_UPDATE_FAILURE'
export const PLAY_SITUATION_DELETE_REQUEST = 'PLAY_SITUATION_DELETE_REQUEST'
export const PLAY_SITUATION_DELETE_SUCCESS = 'PLAY_SITUATION_DELETE_SUCCESS'
export const PLAY_SITUATION_DELETE_FAILURE = 'PLAY_SITUATION_DELETE_FAILURE'
export const PLAY_SITUATION_VIEW_SUCCESS = 'PLAY_SITUATION_VIEW_SUCCESS'
export const PLAY_SITUATION_VIEW_FAILURE = 'PLAY_SITUATION_VIEW_FAILURE'
export const PLAY_SITUATION_SET_ID = 'PLAY_SITUATION_SET_ID'