import {STAGE_LIST_REQUEST, STAGE_SHOW_FORM, STAGE_SHOW_STATISTIC_MODAL} from '../constants/Stage';

import {makeDispatch, rstatApi} from '../helpers/api'
import {MANAGER} from "../constants/Common";
import {CREATE, DELETE, LIST, STAGE, UPDATE, VIEW} from "../constants/Global";

export function showStageForm(open, type) {
    return (dispatch) => {
        dispatch({
            type: type + STAGE_SHOW_FORM,
            payload: open
        })
    }
}

export function showStageStatisticModal(open, type) {
    return (dispatch) => {
        dispatch({
            type: type + STAGE_SHOW_STATISTIC_MODAL,
            payload: open
        })
    }
}

export function viewStage(id) {
    return (dispatch) => {
        rstatApi.get('/stages/' + id).then(function (response) {
            dispatch(makeDispatch(MANAGER, VIEW, STAGE, response));
        }).catch(function (error) {
            dispatch(makeDispatch(MANAGER, VIEW, STAGE, error.response));
        });
    }
}

export function updateStage(id, body) {
    return (dispatch) => {
        rstatApi.put('/stages/' + id, body).then(function (response) {
            dispatch(makeDispatch(MANAGER, UPDATE, STAGE, response));
        }).catch(function (error) {
            dispatch(makeDispatch(MANAGER, UPDATE, STAGE, error.response));
        });
    }
}

export function listStages(query = '', type) {
    let divisionID = 0;
    let result = query.match(/division_id:(\d+)/i);
    if (result !== null) {
        divisionID = parseInt(result[1], 10)
    }
    return (dispatch) => {
        dispatch({
            type: type + STAGE_LIST_REQUEST,
            payload: divisionID,
        });
        rstatApi.get('/stages' + query).then(function (response) {
            dispatch(makeDispatch(MANAGER, LIST, STAGE, response));
        }).catch(function (error) {
            dispatch(makeDispatch(MANAGER, LIST, STAGE, error.response));
        });
    }
}

export function createStage(body) {
    return (dispatch) => {
        rstatApi.post('/stages', body).then(function (response) {
            dispatch(makeDispatch(MANAGER, CREATE, STAGE, response));
        }).catch(function (error) {
            dispatch(makeDispatch(MANAGER, CREATE, STAGE, error.response));
        });
    }
}

export function deleteStage(id) {
    return (dispatch) => {
        rstatApi.delete('/stages/' + id,).then(function (response) {
            dispatch(makeDispatch(MANAGER, DELETE, STAGE, response, parseInt(id, 10)));
        }).catch(function (error) {
            dispatch(makeDispatch(MANAGER, DELETE, STAGE, error.response));
        })
    }
}