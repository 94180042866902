import {CORE_PROTOCOL_LIST_SUCCESS, CORE_PROTOCOL_VIEW_SUCCESS, PROTOCOL_SNACKBAR} from "../constants/Protocol";

var {Map, List, OrderedMap} = require('immutable');

const initialState = Map({
    showModal: false,
    showView: false,
    snackbar: Map({
        show: false,
        text: ''
    }),
    count: 0,
    reasons: [
        {
            id: 1,
            name: "name 1"
        },
        {
            id: 2,
            name: "name 2"
        },
        {
            id: 3,
            name: "name 3"
        },
        {
            id: 4,
            name: "name 4"
        }
    ],
    model: {
        id: 0,
        stage:{division:{competition:{sportType:'FOOTBALL'}}},
        goalHome: 0,
        goalAway: 0,
        divisionPlayersHome: [],
        divisionPlayersAway: [],
        matchPlayersHome: [],
        matchPlayersAway: [],
        resultsHome: [],
        penaltyHome: [],
        resultsAway: [],
        penaltyAway: [],
        stageTeamHome: {
            divisionTeam: {
                team: {
                    name: "команда А",
                    city: {
                        name: "город"
                    }
                }
            }
        },
        stageTeamAway: {
            divisionTeam: {
                team: {
                    name: "команда Б",
                    city: {
                        name: "город"
                    }
                }
            }
        }
    },
    models: List([]),
})


export default function protocol(state = initialState, action) {
    switch (action.type) {
        case CORE_PROTOCOL_LIST_SUCCESS:
            return state
                .set('models', OrderedMap().withMutations(map => {
                    action.payload.forEach(model => map.set(model.id, model));
                }))
                .set('count', action.count);
        case CORE_PROTOCOL_VIEW_SUCCESS:
            return state
                .set('model', action.payload)
                .set('showModal', true);
        case PROTOCOL_SNACKBAR:
            return state
                .setIn(['snackbar', 'show'], action.show)
                .setIn(['snackbar', 'text'], action.text)
        default:
            return state;
    }
}
