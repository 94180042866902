import * as React from 'react';

export default function ProtocolDropDown(props) {
    return (
        <select id={props.id} data={props.data} name={props.name} onChange={props.handleChange} value={props.value}>
            <option key={0} value=''>-</option>
            {props.items.map((model, i) =>
                <option key={i} value={model.id}>{model.name}</option>
            )}
        </select>
    );
}