/**
 * Created by happy on 13.03.17.
 */

import {Map} from 'immutable';
import {INFO_SHOW_MESSAGE} from "../constants/Global";
import {HIDE_SNACKBAR, SHOW_SNACKBAR} from "../constants/Global";

const initialState = Map({
    open: false,
    message: '',
    code: 0,
    errorCode: '',
    errorMessage: '',
    uuid: 0 // чтобы отличать одинаковые сообщения
});

export default function info(state = initialState, action) {
    if (action.type === INFO_SHOW_MESSAGE) {
        return state.set('message', action.message)
            .set('title', action.title)
            .set('variant', action.variant)
            .set('open', true);
    }

    if (
        (action.type.endsWith('_FAILURE')) || (action.errorCode)
    ) {
        let err = 'Неизвестная ошибка';
        let variant = "error";

        console.log(action)

        switch (action.code) {
            case 422:
                err = 'Ошибка валидации';
                variant = 'warning';
                break;
            case 403:
                err = translate(action);
                variant = 'warning';
                break;
            case 404:
                err = 'Страница не найдена';
                variant = 'info';
                break;
            case 400:
                err = translate(action);
                variant = 'warning';
                break;
            case 401:
                localStorage.removeItem('token')
                window.location.href = '/'
                break;
            case 500:
                err = 'Ошибка сервера';
                variant = 'error';
                break;
            default:
                console.log(action.errorCode)
        }

        return state.set('message', err)
            .set('variant', variant)
            .set('open', true);
    }

    switch (true) {
        case action.type.endsWith('COPY_SUCCESS'):
            return state.set('message', 'Скопировано')
                .set('variant', 'success')
                .set('open', true);
        case action.type.endsWith('CREATE_SUCCESS'):
            return state.set('message', 'Создано')
                .set('variant', 'success')
                .set('open', true);
        case action.type.endsWith('UPDATE_SUCCESS'):
            return state.set('message', 'Сохранено')
                .set('variant', 'success')
                .set('open', true);
        case action.type.endsWith('DELETE_SUCCESS'):
            return state.set('message', 'Удалено')
                .set('variant', 'success')
                .set('open', true);
        case action.type === SHOW_SNACKBAR:
            return state.set('message', action.message)
                .set('variant', action.variant)
                .set('open', true);
        case action.type === HIDE_SNACKBAR:
            return state.set('open', false);
    }

    return state
}

const INVALID_BODY_ERROR = "INVALID_BODY_ERROR"
const ROLE_NOT_MATCH_ERROR = "ROLE_NOT_MATCH"

function translate(action) {
    switch (action.errorCode) {
        case INVALID_BODY_ERROR:
            return "Ошибка в пользовательских данных"
        case ROLE_NOT_MATCH_ERROR:
            return "Не хватает прав для вашей роли"
        default:
            return action.errorMessage
    }
}
