import React from 'react';
import Box from '@material-ui/core/Box';
import * as teamActions from '../actions/TeamActions'

import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Teams from "../components/Teams";
import TeamForm from "../components/TeamForm";
import {viewTeam} from "../actions/TeamActions";

function Team(props) {
    const {team} = props;
    const {listTeams, showTeamForm, createTeam, updateTeam, viewTeam, deleteTeam} = props.teamActions;
    return (
        <Box style={{height: '100%'}}>
            <Teams
                models={team.get('models')}
                showForm={showTeamForm}
                view={viewTeam}
                delete={deleteTeam}
                list={listTeams}
            />
            <TeamForm
                model={team.get('model')}
                show={showTeamForm}
                create={createTeam}
                update={updateTeam}
                open={team.get('showModal')}
            />
        </Box>
    );
}

function mapStateToProps(state) {
    return {
        team: state.team,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        teamActions: bindActionCreators(teamActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Team)

