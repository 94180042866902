import React, {useEffect} from 'react';
import { w3cwebsocket as W3CWebSocket } from "websocket";
import {WS_ENDPOINT} from "../constants/Common";

export default function WebSocket(props) {
    const client = new W3CWebSocket(WS_ENDPOINT);

    useEffect( () => {
        client.onopen = () => {
            if (props.userID > 0){
                console.log('ws client connected:',props.userID);
                client.send(props.userID)
                console.log('ws was sent user id:', props.userID);
            }
        };

    }, [props.userID])

    client.onmessage = (message) => {
        let data = JSON.parse(message.data)
        console.log("receive ws message", data.severity, data.title, data.body)
        props.showMessage(data.severity, data.title, data.body)
    };

    return null;
}
