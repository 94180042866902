import {
    CORE_CITY_CREATE_SUCCESS,
    CORE_CITY_DELETE_SUCCESS,
    CORE_CITY_LIST_SUCCESS,
    CORE_CITY_SHOW_FORM,
    CORE_CITY_VIEW_SUCCESS,
    CORE_CITY_UPDATE_SUCCESS,
} from '../constants/City';

var {Map, List, OrderedMap} = require('immutable');
const initialState = Map({
    showModal: false,
    showView: false,
    snackbar: Map({
        show: false,
        text: ''
    }),
    count: 0,
    model: {
        id: 0,
        type:{id:0},
        country:{id:0},
        cityID:0,
    },
    models: List([]),
})


export default function city(state = initialState, action) {
    switch (action.type) {
        case CORE_CITY_SHOW_FORM:
            return state.set('showModal', action.payload).set('model',initialState.get('model'))
        case CORE_CITY_LIST_SUCCESS:
            return state
                .set('models', OrderedMap().withMutations(map => {
                    action.payload.forEach(model => map.set(model.id, model));
                }))
                .set('count', action.count);
        case CORE_CITY_VIEW_SUCCESS:
            return state
                .set('model', action.payload)
                .set('showModal', true);
        case CORE_CITY_DELETE_SUCCESS:
            return state
                .deleteIn(['models', action.payload])
        case CORE_CITY_CREATE_SUCCESS:
            return state
                .setIn(['models', action.payload.id], action.payload)
                .set('showModal', false)
        case CORE_CITY_UPDATE_SUCCESS:
            return state
                .setIn(['models', action.payload.id], action.payload)
                .set('showModal', false)

        default:
            return state;
    }
}
