import {
    STADIUM_SHOW_FORM,
} from '../constants/Stadium';

import {makeDispatch, rstatApi} from '../helpers/api'
import {CORE} from "../constants/Common";
import {CREATE, DELETE, LIST, STADIUM, UPDATE, VIEW} from "../constants/Global";

export function showStadiumForm(open, type) {
    return (dispatch) => {
        dispatch({
            type: type + STADIUM_SHOW_FORM,
            payload: open
        })
    }
}

export function viewStadium(id, type) {
    return (dispatch) => {
        rstatApi.get('/stadiums/' + id).then(function (response) {
            dispatch(makeDispatch(CORE, VIEW, STADIUM, response));
        }).catch(function (error) {
            dispatch(makeDispatch(CORE, VIEW, STADIUM, error.response));
        });
    }
}

export function updateStadium(id, body, type) {
    return (dispatch) => {
        rstatApi.put('/stadiums/' + id, body).then(function (response) {
            dispatch(makeDispatch(CORE, UPDATE, STADIUM, response));
        }).catch(function (error) {
            dispatch(makeDispatch(CORE, UPDATE, STADIUM, error.response));
        });
    }
}

export function listStadiums(query = '', type) {
    return (dispatch) => {
        rstatApi.get('/stadiums' + query).then(function (response) {
            dispatch(makeDispatch(CORE, LIST, STADIUM, response));
        }).catch(function (error) {
            dispatch(makeDispatch(CORE, LIST, STADIUM, error.response));
        });
    }
}

export function createStadium(body, type) {
    return (dispatch) => {
        rstatApi.post('/stadiums', body).then(function (response) {
            dispatch(makeDispatch(CORE, CREATE, STADIUM, response));
        }).catch(function (error) {
            dispatch(makeDispatch(CORE, CREATE, STADIUM, error.response));
        });
    }
}

export function deleteStadium(id, type) {
    return (dispatch) => {
        rstatApi.delete('/stadiums/' + id,).then(function (response) {
            dispatch(makeDispatch(CORE, DELETE, STADIUM, response, parseInt(id, 10)));
        }).catch(function (error) {
            dispatch(makeDispatch(CORE, DELETE, STADIUM, error.response));
        })
    }
}