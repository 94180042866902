import {MANAGER_CONFERENCE_SELECT} from "../constants/Competition";

export function conferenceSelect(id) {
    return (dispatch) => {
        dispatch({
            type: MANAGER_CONFERENCE_SELECT,
            payload: id
        })
    }
}

