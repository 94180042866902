import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {FOOTBALL, HOCKEY} from "../../constants/Global";
import FormHelperText from "@material-ui/core/FormHelperText";
import {FormattedMessage} from "react-intl";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(2),
        marginLeft: 0,
        minWidth: 140,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export default function SportTypeSelector(props) {
    const classes = useStyles();
    return (
        <FormControl variant="outlined" className={classes.formControl} error={props.error}>
            <InputLabel htmlFor="sportType"><FormattedMessage id="selector.sport-kind.label" defaultMessage="Sport kind"/></InputLabel>
            <Select
                native
                id={props.id}
                name={props.name}
                value={props.value}
                error={props.error}
                onChange={props.onChange}
                label=<FormattedMessage id="select.sport-kind.label" defaultMessage="Sport Kind"/>
                inputProps={{
                    name: 'sportType',
                    id: 'sportType',
                }}
            >
                <option aria-label="None" value=""/>
                <option value={FOOTBALL}><FormattedMessage id="sport-type.football" defaultMessage="Football"/></option>
                <option value={HOCKEY}><FormattedMessage id="sport-type.hockey" defaultMessage="Hockey"/></option>
            </Select>
            <FormHelperText><FormattedMessage id="select.sport-kind.helper" defaultMessage="sport Kind"/></FormHelperText>
        </FormControl>
    );
}