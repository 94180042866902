export const CORE_USER_LIST_REQUEST = 'CORE_USER_LIST_REQUEST'
export const CORE_USER_LIST_FAILURE = 'CORE_USER_LIST_FAILURE'
export const CORE_USER_LIST_SUCCESS = 'CORE_USER_LIST_SUCCESS'
export const CORE_USER_SHOW_MODAL = 'CORE_USER_SHOW_MODAL'
export const CORE_USER_CREATE_SUCCESS = 'CORE_USER_CREATE_SUCCESS'
export const CORE_USER_DELETE_SUCCESS = 'CORE_USER_DELETE_SUCCESS'
export const CORE_USER_VIEW_SUCCESS = 'CORE_USER_VIEW_SUCCESS'
export const CORE_USER_SIGNIN_SUCCESS = 'CORE_USER_SIGNIN_SUCCESS'
export const CORE_USER_SIGNIN_FAILURE = 'CORE_USER_SIGNIN_FAILURE'
export const CORE_PROFILE_VIEW_SUCCESS = 'CORE_PROFILE_VIEW_SUCCESS'
export const CORE_USER_REGISTER_SUCCESS = 'CORE_USER_REGISTER_SUCCESS'
export const CORE_USER_VIEW_FAILURE = 'CORE_USER_VIEW_FAILURE'

export const USER_LIST_REQUEST = 'USER_LIST_REQUEST'
export const USER_LIST_FAILURE = 'USER_LIST_FAILURE'
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS'
export const USER_SHOW_FORM = 'USER_SHOW_FORM';
export const USER_CREATE_SUCCESS = 'USER_CREATE_SUCCESS'
export const USER_CREATE_FAILURE = 'USER_CREATE_FAILURE'
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
export const USER_UPDATE_FAILURE = 'USER_UPDATE_FAILURE'
export const USER_SIGNIN_FAILURE = 'USER_SIGNIN_FAILURE'
export const USER_REGISTER_FAILURE = 'USER_REGISTER_FAILURE'
export const PROFILE_REGISTER_FAILURE = 'PROFILE_REGISTER_FAILURE'
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS'
export const USER_DELETE_FAILURE = 'USER_DELETE_FAILURE'
export const USER_VIEW_SUCCESS = 'USER_VIEW_SUCCESS'
export const USER_VIEW_FAILURE = 'USER_VIEW_FAILURE'
