import {
    TOUR_LIST_REQUEST,
    TOUR_LIST_FAILURE,
    TOUR_SHOW_FORM,
    TOUR_CREATE_FAILURE,
    TOUR_DELETE_FAILURE, TOUR_VIEW_FAILURE, TOUR_UPDATE_FAILURE
} from '../constants/Tour';

import {rstatApi, makeDispatch, makeDispatch500} from '../helpers/api'
import {CORE, MANAGER} from "../constants/Common";
import {CREATE, DELETE, LIST, TEAM, TOUR, UPDATE, VIEW} from "../constants/Global";

export function showTourForm(open, type) {
    return (dispatch) => {
        dispatch({
            type: type + TOUR_SHOW_FORM,
            payload: open
        })
    }
}

export function viewTour(id, type) {
    return (dispatch) => {
        rstatApi.get('/tours/' + id).then(function (response) {
            dispatch(makeDispatch(MANAGER, VIEW, TOUR, response));
        }).catch(function (error) {
            dispatch(makeDispatch(MANAGER, VIEW, TOUR, error.response));
        });
    }
}

export function updateTour(id, body, type) {
    return (dispatch) => {
        rstatApi.put('/tours/' + id, body).then(function (response) {
            dispatch(makeDispatch(MANAGER, UPDATE, TOUR, response));
        }).catch(function (error) {
            dispatch(makeDispatch(MANAGER, UPDATE, TOUR, error.response));
        });
    }
}

export function listTours(query = '', type) {
    let stageID = 0;
    let result = query.match(/stage_id:(\d+)/i);
    if (result !== null) {
        stageID = parseInt(result[1], 10)
    }
    return (dispatch) => {
        dispatch({
            type: type + TOUR_LIST_REQUEST,
            payload: stageID,
        });
        rstatApi.get('/tours' + query).then(function (response) {
            dispatch(makeDispatch(MANAGER, LIST, TOUR, response));
        }).catch(function (error) {
            dispatch(makeDispatch(MANAGER, LIST, TOUR, error.response));
        });
    }
}

export function createTour(body, type) {
    return (dispatch) => {
        rstatApi.post('/tours', body).then(function (response) {
            dispatch(makeDispatch(MANAGER, CREATE, TOUR, response));
        }).catch(function (error) {
            dispatch(makeDispatch(MANAGER, CREATE, TOUR, error.response));
        });
    }
}

export function deleteTour(id, type) {
    return (dispatch) => {
        rstatApi.delete('/tours/' + id,).then(function (response) {
            dispatch(makeDispatch(MANAGER, DELETE, TOUR, response, parseInt(id, 10)));
        }).catch(function (error) {
            dispatch(makeDispatch(MANAGER, DELETE, TOUR, error.response));
        })
    }
}