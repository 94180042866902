import {
    MANAGER_STAGE_TEAM_COPY_REQUEST,
    STAGE_TEAM_LIST_REQUEST,
    STAGE_TEAM_SHOW_FORM,
} from '../constants/StageTeam';

import {rstatApi, makeDispatch} from '../helpers/api'
import {MANAGER} from "../constants/Common";
import {COPY, CREATE, DELETE, LIST, STAGE_TEAM, UPDATE, VIEW} from "../constants/Global";
import {COMPETITION_LIST_REQUEST} from "../constants/Competition";

export function showStageTeamForm(open, type) {
    return (dispatch) => {
        dispatch({
            type: type + STAGE_TEAM_SHOW_FORM,
            payload: open
        })
    }
}

export function viewStageTeam(id) {
    return (dispatch) => {
        rstatApi.get('/stageTeams/' + id).then(function (response) {
            dispatch(makeDispatch(MANAGER, VIEW, STAGE_TEAM, response));
        }).catch(function (error) {
            dispatch(makeDispatch(MANAGER, VIEW, STAGE_TEAM, error.response));
        });
    }
}

export function copyStageTeams(id) {
    return (dispatch) => {
        dispatch({
            type: MANAGER_STAGE_TEAM_COPY_REQUEST,
            payload: id
        });

        rstatApi.post('/stages/'+id+'/stageTeams/copy', {}).then(function (response) {
            dispatch(makeDispatch(MANAGER, COPY, STAGE_TEAM, response));
        }).catch(function (error) {
            dispatch(makeDispatch(MANAGER, COPY, STAGE_TEAM, error.response));
        });
    }
}

export function updateStageTeam(id, body, type) {
    return (dispatch) => {
        rstatApi.put('/stageTeams/' + id, body).then(function (response) {
            dispatch(makeDispatch(MANAGER, UPDATE, STAGE_TEAM, response));
        }).catch(function (error) {
            dispatch(makeDispatch(MANAGER, UPDATE, STAGE_TEAM, error.response));
        });
    }
}

export function listStageTeams(query = '', type) {
    let stageID = 0;
    let result = query.match(/stage_id:(\d+)/i);

    if (result !== null) {
        stageID = parseInt(result[1], 10)
    }

    return (dispatch) => {
        dispatch({
            type: type + STAGE_TEAM_LIST_REQUEST,
            payload: stageID,
        });
        rstatApi.get('/stageTeams' + query).then(function (response) {
            dispatch(makeDispatch(MANAGER, LIST, STAGE_TEAM, response));
        }).catch(function (error) {
            dispatch(makeDispatch(MANAGER, LIST, STAGE_TEAM, error.response));
        });
    }
}

export function createStageTeam(body, type) {
    return (dispatch) => {
        rstatApi.post('/stageTeams', body).then(function (response) {
            dispatch(makeDispatch(MANAGER, CREATE, STAGE_TEAM, response));
        }).catch(function (error) {
            dispatch(makeDispatch(MANAGER, CREATE, STAGE_TEAM, error.response));
        });
    }
}

export function deleteStageTeam(id, type) {
    return (dispatch) => {
        rstatApi.delete('/stageTeams/' + id,).then(function (response) {
            dispatch(makeDispatch(MANAGER, DELETE, STAGE_TEAM, response, parseInt(id, 10)));
        }).catch(function (error) {
            dispatch(makeDispatch(MANAGER, DELETE, STAGE_TEAM, error.response));
        })
    }
}