import {
    PEOPLE_CREATE_FAILURE,
    PEOPLE_DELETE_FAILURE,
    PEOPLE_LIST_FAILURE,
    PEOPLE_LIST_REQUEST,
    PEOPLE_SHOW_FORM, PEOPLE_SHOW_IMPORT_FORM,
    PEOPLE_UPDATE_FAILURE,
    PEOPLE_VIEW_FAILURE
} from '../constants/People';

import {makeDispatch, rstatApi, rstatFormApi} from '../helpers/api'
import {CORE} from "../constants/Common";
import {CREATE, DELETE, IMPORT, LIST, MATCH, PEOPLE, TEAM, UPDATE, VIEW} from "../constants/Global";

export function showPeopleForm(open, type) {
    return (dispatch) => {
        dispatch({
            type: type + PEOPLE_SHOW_FORM,
            payload: open
        })
    }
}
export function showImportForm(open, type) {
    return (dispatch) => {
        dispatch({
            type: type + PEOPLE_SHOW_IMPORT_FORM,
            payload: open
        })
    }
}

export function importPeople(body, type) {
    return (dispatch) => {
        rstatFormApi.post('/peoples/import', body).then(function (response) {
            dispatch(makeDispatch(type, IMPORT, PEOPLE, response));
        }).catch(function (error) {
            dispatch(makeDispatch(type, IMPORT, PEOPLE, error.response));
        });
    }
}

export function viewPeople(id, type) {
    return (dispatch) => {
        rstatApi.get('/peoples/' + id).then(function (response) {
            dispatch(makeDispatch(CORE, VIEW, PEOPLE, response));
        }).catch(function (error) {
            dispatch(makeDispatch(CORE, VIEW, PEOPLE, error.response));
        });
    }
}

export function updatePeople(id, body, type) {
    return (dispatch) => {
        rstatApi.put('/peoples/' + id, body).then(function (response) {
            dispatch(makeDispatch(CORE, UPDATE, PEOPLE, response));
        }).catch(function (error) {
            dispatch(makeDispatch(CORE, UPDATE, PEOPLE, error.response));
        });
    }
}

export function listPeoples(query = '', type) {
    let competitionID = 0;
    let result = query.match(/competition_id:(\d+)/i);
    if (result !== null) {
        competitionID = parseInt(result[1], 10)
    }
    return (dispatch) => {
        dispatch({
            type: type + PEOPLE_LIST_REQUEST,
            payload: competitionID,
        });
        rstatApi.get('/peoples' + query).then(function (response) {
            dispatch(makeDispatch(CORE, LIST, PEOPLE, response));
        }).catch(function (error) {
            dispatch(makeDispatch(CORE, LIST, PEOPLE, error.response));
        });
    }
}

export function createPeople(body, type) {
    return (dispatch) => {
        rstatApi.post('/peoples', body).then(function (response) {
            dispatch(makeDispatch(CORE, CREATE, PEOPLE, response));
        }).catch(function (error) {
            dispatch(makeDispatch(CORE, CREATE, PEOPLE, error.response));
        });
    }
}

export function deletePeople(id, type) {
    return (dispatch) => {
        rstatApi.delete('/peoples/' + id,).then(function (response) {
            dispatch(makeDispatch(CORE, DELETE, PEOPLE, response, parseInt(id, 10)));
        }).catch(function (error) {
            dispatch(makeDispatch(CORE, DELETE, PEOPLE, error.response));
        })
    }
}