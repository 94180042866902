import {
    SEASON_LIST_REQUEST,
    SEASON_SHOW_FORM,
} from '../constants/Season';

import {rstatApi, makeDispatch} from '../helpers/api'
import {MANAGER} from "../constants/Common";
import {CREATE, DELETE, LIST, SEASON, UPDATE, VIEW} from "../constants/Global";

export function showSeasonForm(open, type) {
    return (dispatch) => {
        dispatch({
            type: type + SEASON_SHOW_FORM,
            payload: open
        })
    }
}

export function viewSeason(id) {
    return (dispatch) => {
        rstatApi.get('/seasons/' + id).then(function (response) {
            dispatch(makeDispatch(MANAGER, VIEW, SEASON, response));
        }).catch(function (error) {
            dispatch(makeDispatch(MANAGER, VIEW, SEASON, error.response));
        });
    }
}

export function updateSeason(id, body) {
    return (dispatch) => {
        rstatApi.put('/seasons/' + id, body).then(function (response) {
            dispatch(makeDispatch(MANAGER, UPDATE, SEASON, response));
        }).catch(function (error) {
            dispatch(makeDispatch(MANAGER, UPDATE, SEASON, error.response));
        });
    }
}

export function listSeasons(query = '', type) {
    let competitionID = 0;
    let result = query.match(/competition_id:(\d+)/i);
    if (result !== null) {
        competitionID = parseInt(result[1], 10)
    }
    return (dispatch) => {
        dispatch({
            type: type + SEASON_LIST_REQUEST,
            payload: competitionID,
        });
        rstatApi.get('/seasons' + query).then(function (response) {
            dispatch(makeDispatch(MANAGER, LIST, SEASON, response));
        }).catch(function (error) {
            dispatch(makeDispatch(MANAGER, LIST, SEASON, error.response));
        });
    }
}

export function createSeason(body) {
    return (dispatch) => {
        rstatApi.post('/seasons', body).then(function (response) {
            dispatch(makeDispatch(MANAGER, CREATE, SEASON, response));
        }).catch(function (error) {
            dispatch(makeDispatch(MANAGER, CREATE, SEASON, error.response));
        });
    }
}

export function deleteSeason(id) {
    return (dispatch) => {
        rstatApi.delete('/seasons/' + id,).then(function (response) {
            dispatch(makeDispatch(MANAGER, DELETE, SEASON, response, parseInt(id, 10)));
        }).catch(function (error) {
            dispatch(makeDispatch(MANAGER, DELETE, SEASON, error.response));
        })
    }
}