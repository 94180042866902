import {
    CORE_PEOPLE_CREATE_SUCCESS,
    CORE_PEOPLE_DELETE_SUCCESS,
    CORE_PEOPLE_LIST_SUCCESS,
    CORE_PEOPLE_VIEW_SUCCESS,
    CORE_PEOPLE_UPDATE_SUCCESS,
    CORE_PEOPLE_SHOW_VIEW,
    SIMPLE_PEOPLE_VIEW_SUCCESS,
    PEOPLE_SNACKBAR,
    SIMPLE_PEOPLE_SHOW_MODAL,
    CORE_PEOPLE_TEAMS_VIEW_SUCCESS,
    CORE_PEOPLE_SHOW_FORM, CORE_PEOPLE_SHOW_IMPORT_FORM, CORE_PEOPLE_IMPORT_SUCCESS
} from '../constants/People';

var {Map, List, OrderedMap} = require('immutable');
const initialState = Map({
    showModal: false,
    showImportModal: false,
    showView: false,
    snackbar: Map({
        show: false,
        text: ''
    }),
    count: 0,
    model: {
        id: 0,
        base: {},
        data: {},
        sport: {},
    },
    teams: [],
    models: List([]),
})


export default function people(state = initialState, action) {

    switch (action.type) {
        case CORE_PEOPLE_SHOW_FORM:
            return state.set('showModal', action.payload).set('model',initialState.get('model'))
        case CORE_PEOPLE_SHOW_IMPORT_FORM:
            return state.set('showImportModal', action.payload)
        case CORE_PEOPLE_LIST_SUCCESS:
            return state
                .set('models', OrderedMap().withMutations(map => {
                    action.payload.forEach(model => map.set(model.id, model));
                }))
                .set('count', action.count);
        case CORE_PEOPLE_IMPORT_SUCCESS:
            return state
                .set('showImportModal', false);
        case CORE_PEOPLE_VIEW_SUCCESS:
            return state
                .set('model', action.payload)
                .set('showModal', true);
        case CORE_PEOPLE_TEAMS_VIEW_SUCCESS:
            return state
                .set('teams', action.payload);
        case SIMPLE_PEOPLE_VIEW_SUCCESS:
            return state
                .set('model', action.payload)
                .set('showView', true)
        case CORE_PEOPLE_DELETE_SUCCESS:
            return state
                .deleteIn(['models', action.payload])
        case CORE_PEOPLE_CREATE_SUCCESS:
            return state
                .setIn(['models', action.payload.id], action.payload)
                .set('showModal', false)
        case CORE_PEOPLE_UPDATE_SUCCESS:
            return state
                .setIn(['models', action.payload.id], action.payload)
                .set('showModal', false)
        case CORE_PEOPLE_SHOW_VIEW:
            return state
                .set('showView', action.payload);
        case SIMPLE_PEOPLE_SHOW_MODAL:
            return state
                .set('showView', action.payload);
        case PEOPLE_SNACKBAR:
            return state
                .setIn(['snackbar', 'show'], action.show)
                .setIn(['snackbar', 'text'], action.text)

        default:
            return state;
    }

}
