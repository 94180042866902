import {
    PENALTY_CREATE_FAILURE,
    PENALTY_DELETE_FAILURE,
    PENALTY_LIST_FAILURE,
    PENALTY_SHOW_FORM,
    PENALTY_UPDATE_FAILURE,
    PENALTY_VIEW_FAILURE
} from '../constants/Penalty';

import {makeDispatch, makeDispatch500, rstatApi} from '../helpers/api'
import {CORE} from "../constants/Common";
import {CREATE, DELETE, LIST, PENALTY, UPDATE, VIEW} from "../constants/Global";

export function showPenaltyForm(open, type) {
    return (dispatch) => {
        dispatch({
            type: type + PENALTY_SHOW_FORM,
            payload: open
        })
    }
}

export function viewPenalty(id, type) {
    return (dispatch) => {
        rstatApi.get('/penaltyReasons/' + id).then(function (response) {
            dispatch(makeDispatch(CORE, VIEW, PENALTY, response));
        }).catch(function (error) {
            dispatch(makeDispatch500(type, PENALTY_VIEW_FAILURE, error))
        });
    }
}

export function updatePenalty(id, body, type) {
    return (dispatch) => {
        rstatApi.put('/penaltyReasons/' + id, body).then(function (response) {
            dispatch(makeDispatch(CORE, UPDATE, PENALTY, response));
        }).catch(function (error) {
            dispatch(makeDispatch500(type, PENALTY_UPDATE_FAILURE, error))
        });
    }
}

export function listPenalties(query = '', type) {
    return (dispatch) => {
        rstatApi.get('/penaltyReasons' + query).then(function (response) {
            dispatch(makeDispatch(CORE, LIST, PENALTY, response));
        }).catch(function (error) {
            dispatch(makeDispatch500(type, PENALTY_LIST_FAILURE, error))
        });
    }
}

export function createPenalty(body, type) {
    return (dispatch) => {
        rstatApi.post('/penaltyReasons', body).then(function (response) {
            dispatch(makeDispatch(CORE, CREATE, PENALTY, response));
        }).catch(function (error) {
            dispatch(makeDispatch500(type, PENALTY_CREATE_FAILURE, error))
        });
    }
}

export function deletePenalty(id, type) {
    return (dispatch) => {
        rstatApi.delete('/penaltyReasons/' + id,).then(function (response) {
            dispatch(makeDispatch(CORE, DELETE, PENALTY, response, parseInt(id, 10)));
        }).catch(function (error) {
            dispatch(makeDispatch500(type, PENALTY_DELETE_FAILURE, error))
        })
    }
}