import React, {useEffect} from 'react'
import {SIMPLE} from "../../constants/Common";
import Box from "@material-ui/core/Box";
import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';
import FootballProtocol from "../protocol/football/FootballProtocol";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import HockeyProtocol from "../protocol/hockey/HockeyProtocol";
import {FormattedMessage} from "react-intl";

export default function ProtocolView(props) {
    useEffect(() => {
        props.view(props.id, SIMPLE)
    }, [props.id]);

    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{width: '100%', typography: 'body1'}}>
            <TabContext value={value}>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <div style={{float: "right"}}>
                        <FormControlLabel control={
                            <Switch checked={props.finished} onChange={props.setProtocolFinished} defaultChecked/>
                        } label=<FormattedMessage id="protocol.filled" defaultMessage="fill in completely"/>/>
                    </div>
                    <TabList onChange={handleChange}>
                        <Tab
                            label={
                                props.model.stageTeamHome.divisionTeam.team.name +
                                " [" +
                                props.model.stageTeamHome.divisionTeam.team.city.name +
                                "]"
                            }
                            value="1"
                        />
                        <Tab
                            label={
                                props.model.stageTeamAway.divisionTeam.team.name +
                                " [" +
                                props.model.stageTeamAway.divisionTeam.team.city.name +
                                "]"
                            }
                            value="2"
                        />
                    </TabList>
                </Box>
                <TabPanel value="1">
                    {props.sportType === "FOOTBALL" ?
                        <FootballProtocol
                            players={props.model.divisionPlayersHome}
                            matchPlayers={props.model.matchPlayersHome}
                            matchResults={props.model.resultsHome}
                            matchPenalties={props.model.penaltyHome}
                            reasons={props.reasons}
                            prefix="home"
                            sportType={props.sportType}
                        />
                        :
                        <HockeyProtocol
                            players={props.model.divisionPlayersHome}
                            matchPlayers={props.model.matchPlayersHome}
                            matchResults={props.model.resultsHome}
                            matchPenalties={props.model.penaltyHome}
                            matchKeepers={props.model.keepersHome}
                            reasons={props.reasons}
                            prefix="home"
                            sportType={props.sportType}
                        />
                    }
                </TabPanel>
                <TabPanel value="2">
                    {props.sportType === "FOOTBALL" ?
                        <FootballProtocol
                            players={props.model.divisionPlayersAway}
                            matchPlayers={props.model.matchPlayersAway}
                            matchResults={props.model.resultsAway}
                            matchPenalties={props.model.penaltyAway}
                            matchKeepers={props.model.keepersAway}
                            reasons={props.reasons}
                            prefix="away"
                            sportType={props.sportType}
                        />
                        :
                        <HockeyProtocol
                            players={props.model.divisionPlayersAway}
                            matchPlayers={props.model.matchPlayersAway}
                            matchResults={props.model.resultsAway}
                            matchPenalties={props.model.penaltyAway}
                            reasons={props.reasons}
                            prefix="away"
                            sportType={props.sportType}
                        />
                    }
                </TabPanel>
            </TabContext>
        </Box>
    )
}