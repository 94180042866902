import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import {Formik} from "formik";
import * as yup from 'yup'
import {CORE} from "../constants/Common";
import {FormattedMessage} from "react-intl";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const PEOPLE_FIELDS = {
    PEOPLE_IMPORT_FIELD: 'import',
};

export default function PeopleImportForm(props) {
    const handleClose = () => {
        props.show(false, CORE)
    };

    const FILE_SIZE = 160 * 1024;
    const SUPPORTED_FORMATS = [
        "text/csv",
    ];

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={props.open}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                <FormattedMessage id="people.import-form" defaultMessage="Import"/>
            </DialogTitle>
            <Formik
                initialValues={{}}
                onSubmit={(values, actions) => {
                    console.log({
                        fileName: values.import.name,
                        type: values.import.type,
                        size: `${values.import.size} bytes`
                    })

                    let data = new FormData();
                    data.append("file", values.import)

                    props.import(data, CORE);
                    console.log(props)
                    console.log(data)
                }}
                validationSchema={yup.object().shape({
                    [PEOPLE_FIELDS.PEOPLE_IMPORT_FIELD]: yup.mixed()
                        .required(<FormattedMessage id="form.required" defaultMessage="required field"/>)
                        .test("FILE_FORMAT", "Uploaded file has unsupported format.",
                            value => !value || (value && SUPPORTED_FORMATS.includes(value.type)))
                        .test("FILE_SIZE", "Uploaded file is too big.",
                            value => !value || (value && value.size <= FILE_SIZE))
                })}
            >
                {(props) => {
                    const {
                        errors,
                        isSubmitting,
                        setFieldValue,
                        handleSubmit,
                    } = props;
                    return (
                        <form onSubmit={handleSubmit}>
                            <DialogContent dividers>
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <Button
                                            variant="outlined"
                                            component="label"
                                        >
                                            <FormattedMessage id="people.import.file" defaultMessage="File"/>
                                        <input hidden id="import" name="import" type="file" onChange={(event) => {
                                            setFieldValue("import", event.currentTarget.files[0]);
                                        }} className="form-control"/>
                                        </Button>
                                        <div>{errors.import}</div>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={isSubmitting}>
                                    <FormattedMessage id="button.save" defaultMessage="Save"/>
                                </Button>
                            </DialogActions>
                        </form>
                    );
                }}
            </Formik>
        </Dialog>
    );
}