import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import {Field, Formik} from "formik";
import * as yup from 'yup'
import {CORE} from "../../constants/Common";
import FormikAdapter from "./FormikAdapter";
import history from '../../history';
import {FormattedMessage} from "react-intl";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const onReg = () =>{
    history.push('/registration');
}

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const FORM_FIELDS = {
    FORM_EMAIL_FIELD: 'email',
    FORM_PASSWORD_FIELD: 'password',
};

export default function LoginForm(props) {
    return (
        <Dialog
            maxWidth={"sm"}
            aria-labelledby="customized-dialog-title"
            open={true}>
            <DialogTitle id="customized-dialog-title">
                R-STAT {props.message}
            </DialogTitle>
            <Formik
                initialValues={{}}
                onSubmit={(values, actions) => {
                    props.signIn(values, CORE);
                }}
                validationSchema={yup.object().shape({
                    [FORM_FIELDS.FORM_EMAIL_FIELD]: yup.string().email()
                        .required(<FormattedMessage id="form.required" defaultMessage="required field"/>),
                    [FORM_FIELDS.FORM_PASSWORD_FIELD]: yup.string()
                        .required(<FormattedMessage id="form.required" defaultMessage="required field"/>),
                })}
            >
                {(props) => {
                    const {
                        isSubmitting,
                        handleSubmit
                    } = props;
                    return (
                        <form onSubmit={handleSubmit}>
                            <DialogContent dividers>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Field
                                            name={FORM_FIELDS.FORM_EMAIL_FIELD}
                                            type="text"
                                            inputComponent={TextField}
                                            component={FormikAdapter}
                                            label="Email"
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            name={FORM_FIELDS.FORM_PASSWORD_FIELD}
                                            type="password"
                                            inputComponent={TextField}
                                            component={FormikAdapter}
                                            label=<FormattedMessage id="login.pass" defaultMessage="Password"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={onReg}
                                    variant="contained"
                                    color="primary">
                                    <FormattedMessage id="login.registration" defaultMessage="Sign up"/>
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={isSubmitting}>
                                    <FormattedMessage id="login.enter" defaultMessage="Sign in"/>
                                </Button>
                            </DialogActions>
                        </form>
                    );
                }}
            </Formik>
        </Dialog>
    );
}