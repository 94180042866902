import React from 'react';
import Box from '@material-ui/core/Box';

import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as UserActions from '../actions/UserActions'
import {register} from "../actions/UserActions";
import RegForm from "../components/common/RegForm";

function Registration(props) {
    const {register} = props.userActions

    return (
        <Box style={{height: '100%'}}>
            <RegForm register={register}/>
        </Box>
    );
}

function mapStateToProps(state) {
    return {
        user: state.user
    }
}

function mapDispatchToProps(dispatch) {
    return {
        userActions: bindActionCreators(UserActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Registration)

