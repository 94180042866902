export const MANAGER_DIVISION_PERSONAL_LIST_REQUEST = 'MANAGER_DIVISION_PERSONAL_LIST_REQUEST';
export const MANAGER_DIVISION_PERSONAL_LIST_FAILURE = 'MANAGER_DIVISION_PERSONAL_LIST_FAILURE'
export const MANAGER_DIVISION_PERSONAL_LIST_SUCCESS = 'MANAGER_DIVISION_PERSONAL_LIST_SUCCESS'
export const MANAGER_DIVISION_PERSONAL_SHOW_FORM = 'MANAGER_DIVISION_PERSONAL_SHOW_FORM';
export const MANAGER_DIVISION_PERSONAL_CREATE_SUCCESS = 'MANAGER_DIVISION_PERSONAL_CREATE_SUCCESS'
export const MANAGER_DIVISION_PERSONAL_CREATE_FAILURE = 'MANAGER_DIVISION_PERSONAL_CREATE_FAILURE'
export const MANAGER_DIVISION_PERSONAL_UPDATE_SUCCESS = 'MANAGER_DIVISION_PERSONAL_UPDATE_SUCCESS'
export const MANAGER_DIVISION_PERSONAL_UPDATE_FAILURE = 'MANAGER_DIVISION_PERSONAL_UPDATE_FAILURE'
export const MANAGER_DIVISION_PERSONAL_DELETE_SUCCESS = 'MANAGER_DIVISION_PERSONAL_DELETE_SUCCESS'
export const MANAGER_DIVISION_PERSONAL_DELETE_FAILURE = 'MANAGER_DIVISION_PERSONAL_DELETE_FAILURE'
export const MANAGER_DIVISION_PERSONAL_VIEW_SUCCESS = 'MANAGER_DIVISION_PERSONAL_VIEW_SUCCESS'
export const MANAGER_DIVISION_PERSONAL_CITY_SELECT = 'MANAGER_DIVISION_PERSONAL_CITY_SELECT'

export const CORE_DIVISION_PERSONAL_LIST_REQUEST = 'CORE_DIVISION_PERSONAL_LIST_REQUEST'
export const CORE_DIVISION_PERSONAL_LIST_FAILURE = 'CORE_DIVISION_PERSONAL_LIST_FAILURE'
export const CORE_DIVISION_PERSONAL_LIST_SUCCESS = 'CORE_DIVISION_PERSONAL_LIST_SUCCESS'
export const CORE_DIVISION_PERSONAL_SHOW_MODAL = 'CORE_DIVISION_PERSONAL_SHOW_MODAL'
export const CORE_DIVISION_PERSONAL_CREATE_SUCCESS = 'CORE_DIVISION_PERSONAL_CREATE_SUCCESS'
export const CORE_DIVISION_PERSONAL_DELETE_SUCCESS = 'CORE_DIVISION_PERSONAL_DELETE_SUCCESS'
export const CORE_DIVISION_PERSONAL_VIEW_SUCCESS = 'CORE_DIVISION_PERSONAL_VIEW_SUCCESS'
export const CORE_DIVISION_PERSONAL_VIEW_FAILURE = 'CORE_DIVISION_PERSONAL_VIEW_FAILURE'

export const DIVISION_PERSONAL_LIST_REQUEST = 'DIVISION_PERSONAL_LIST_REQUEST'
export const DIVISION_PERSONAL_LIST_FAILURE = 'DIVISION_PERSONAL_LIST_FAILURE'
export const DIVISION_PERSONAL_LIST_SUCCESS = 'DIVISION_PERSONAL_LIST_SUCCESS'
export const DIVISION_PERSONAL_SHOW_FORM = 'DIVISION_PERSONAL_SHOW_FORM';
export const DIVISION_PERSONAL_CREATE_SUCCESS = 'DIVISION_PERSONAL_CREATE_SUCCESS'
export const DIVISION_PERSONAL_CREATE_FAILURE = 'DIVISION_PERSONAL_CREATE_FAILURE'
export const DIVISION_PERSONAL_UPDATE_SUCCESS = 'DIVISION_PERSONAL_UPDATE_SUCCESS'
export const DIVISION_PERSONAL_UPDATE_FAILURE = 'DIVISION_PERSONAL_UPDATE_FAILURE'
export const DIVISION_PERSONAL_DELETE_SUCCESS = 'DIVISION_PERSONAL_DELETE_SUCCESS'
export const DIVISION_PERSONAL_DELETE_FAILURE = 'DIVISION_PERSONAL_DELETE_FAILURE'
export const DIVISION_PERSONAL_VIEW_SUCCESS = 'DIVISION_PERSONAL_VIEW_SUCCESS'
export const DIVISION_PERSONAL_VIEW_FAILURE = 'DIVISION_PERSONAL_VIEW_FAILURE'


export const STATUS_UNDECLARED = 'UNDECLARED';
export const STATUS_DECLARED = 'DECLARED';
export const STATUS_IN_SQUAD = 'IN_SQUAD';
export const STATUS_DISQUALIFIED = 'DISQUALIFIED';
export const STATUS_REJECT = 'REJECT';

export const TYPE_SIMPLE = 'SIMPLE';
export const TYPE_CAPTAIN = 'CAPTAIN';
export const TYPE_MATE = 'MATE';

export const PERSONAL_TYPE_COACH = 'COACH';
export const PERSONAL_TYPE_DOCTOR = 'DOCTOR';