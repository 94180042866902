import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {MANAGER} from "../../../constants/Common";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import {Field, Formik} from "formik";
import * as yup from 'yup'
import FormikAdapter from "../../common/FormikAdapter";
import FormikSelectAdapter from "../../common/FormikSelectAdapter";
import DataSelect from "../../form/DataSelect";
import {
    AMPLUA_DEFENDER, AMPLUA_FORWARD,
    AMPLUA_GOALKEEPER, AMPLUA_MIDFIELDER,
    STATUS_DECLARED,
    STATUS_DISQUALIFIED,
    STATUS_IN_SQUAD, STATUS_REJECT,
    STATUS_UNDECLARED, TYPE_CAPTAIN, TYPE_MATE, TYPE_SIMPLE
} from "../../../constants/DivisionPlayer";
import PeopleSelect from "../../form/PeopleSelect";
import {FormattedMessage} from "react-intl";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const DIVISION_PLAYER_FIELDS = {
    DIVISION_PLAYER_ID_FIELD: 'id',
    DIVISION_PLAYER_SEASON_ID_FIELD: 'seasonID',
    DIVISION_PLAYER_STATUS_FIELD: 'status',
    DIVISION_PLAYER_COMPETITION_ID_FIELD: 'competitionID',
    DIVISION_PLAYER_DIVISION_TEAM_ID_FIELD: 'divisionTeamID',
    DIVISION_PLAYER_AMPLUA_FIELD: 'amplua',
    DIVISION_PLAYER_PLAY_NUMBER_FIELD: 'playNumber',
    DIVISION_PLAYER_PEOPLE_ID_FIELD: 'peopleID',
    DIVISION_PLAYER_PLAYER_TYPE_FIELD: 'playerType',
    DIVISION_PLAYER_DESCRIPTION_FIELD: 'description',
    DIVISION_PLAYER_SORT_FIELD: 'sort',
};

export default function DivisionPlayerForm(mainProps) {
    let statuses = [
        {key: STATUS_UNDECLARED, value: <FormattedMessage id="division-player.undeclared" defaultMessage="Undeclared"/>},
        {key: STATUS_DECLARED, value: <FormattedMessage id="division-player.declared" defaultMessage="Declared"/>},
        {key: STATUS_IN_SQUAD, value: <FormattedMessage id="division-player.in-squad" defaultMessage="In squad"/>},
        {key: STATUS_DISQUALIFIED, value: <FormattedMessage id="division-player.disqualified" defaultMessage="Disqualified"/>},
        {key: STATUS_REJECT, value: <FormattedMessage id="division-player.reject" defaultMessage="Reject"/>},
    ]

    let types = [
        {key: TYPE_SIMPLE, value: <FormattedMessage id="division-player.type-simple" defaultMessage="Simple"/>},
        {key: TYPE_CAPTAIN, value: <FormattedMessage id="division-player.type-сaptain" defaultMessage="Captain"/>},
        {key: TYPE_MATE, value: <FormattedMessage id="division-player.type-mate" defaultMessage="Mate"/>},
    ]

    let amplua = [
        {key: AMPLUA_GOALKEEPER, value: <FormattedMessage id="division-player.amplua-goalkeeper" defaultMessage="Goalkeeper"/>},
        {key: AMPLUA_DEFENDER, value: <FormattedMessage id="division-player.amplua-defender" defaultMessage="Defender"/>},
        {key: AMPLUA_MIDFIELDER, value: <FormattedMessage id="division-player.amplua-midfielder" defaultMessage="Midfielder"/>},
        {key: AMPLUA_FORWARD, value: <FormattedMessage id="division-player.amplua-forward" defaultMessage="Forward"/>},
    ]

    const handleClose = () => {
        mainProps.show(false, MANAGER)
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"md"}
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={mainProps.open}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                <FormattedMessage id="division-player-form" defaultMessage="Division player form"/>
            </DialogTitle>
            <Formik
                initialValues={{
                    [DIVISION_PLAYER_FIELDS.DIVISION_PLAYER_ID_FIELD]: mainProps.model.id,
                    [DIVISION_PLAYER_FIELDS.DIVISION_PLAYER_SEASON_ID_FIELD]: mainProps.seasonID,
                    [DIVISION_PLAYER_FIELDS.DIVISION_PLAYER_COMPETITION_ID_FIELD]: mainProps.competitionID,
                    [DIVISION_PLAYER_FIELDS.DIVISION_PLAYER_DIVISION_TEAM_ID_FIELD]: mainProps.divisionTeamID,
                    [DIVISION_PLAYER_FIELDS.DIVISION_PLAYER_STATUS_FIELD]: mainProps.model.status,
                    [DIVISION_PLAYER_FIELDS.DIVISION_PLAYER_PEOPLE_ID_FIELD]: mainProps.model.people.id,
                    [DIVISION_PLAYER_FIELDS.DIVISION_PLAYER_PLAYER_TYPE_FIELD]: mainProps.model.playerType,
                    [DIVISION_PLAYER_FIELDS.DIVISION_PLAYER_AMPLUA_FIELD]: mainProps.model.amplua,
                    [DIVISION_PLAYER_FIELDS.DIVISION_PLAYER_PLAY_NUMBER_FIELD]: mainProps.model.playNumber,
                    [DIVISION_PLAYER_FIELDS.DIVISION_PLAYER_SORT_FIELD]: mainProps.model.sort,
                    [DIVISION_PLAYER_FIELDS.DIVISION_PLAYER_DESCRIPTION_FIELD]: mainProps.model.description,
                }}

                onSubmit={(values, actions) => {
                    values.peopleID = parseInt(values.peopleID)
                    values.ampluaID = parseInt(values.ampluaID)
                    if (values.id > 0) {
                        mainProps.update(values.id, values, MANAGER);
                    } else {
                        mainProps.create(values, MANAGER);
                    }
                }}

                validationSchema={yup.object().shape({
                    [DIVISION_PLAYER_FIELDS.DIVISION_PLAYER_PEOPLE_ID_FIELD]: yup.string()
                        .required(<FormattedMessage id="form.required" defaultMessage="required field"/>),
                    [DIVISION_PLAYER_FIELDS.DIVISION_PLAYER_PLAYER_TYPE_FIELD]: yup.string()
                        .required(<FormattedMessage id="form.required" defaultMessage="required field"/>),
                    [DIVISION_PLAYER_FIELDS.DIVISION_PLAYER_AMPLUA_FIELD]: yup.string()
                        .required(<FormattedMessage id="form.required" defaultMessage="required field"/>),
                    [DIVISION_PLAYER_FIELDS.DIVISION_PLAYER_PEOPLE_ID_FIELD]: yup.string()
                        .required(<FormattedMessage id="form.required" defaultMessage="required field"/>),
                    [DIVISION_PLAYER_FIELDS.DIVISION_PLAYER_STATUS_FIELD]: yup.string()
                        .required(<FormattedMessage id="form.required" defaultMessage="required field"/>),
                })}
            >
                {(props) => {
                    const {
                        setFieldValue,
                        isSubmitting,
                        handleSubmit,
                        handleChange,
                    } = props;
                    return (
                        <form onSubmit={handleSubmit}>
                            <DialogContent dividers>
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <Field
                                            name={DIVISION_PLAYER_FIELDS.DIVISION_PLAYER_STATUS_FIELD}
                                            type="select"
                                            helperText=<FormattedMessage id="manager.division-player.form.status.helper" defaultMessage="status"/>
                                            data={statuses}
                                            onChange={handleChange}
                                            inputComponent={DataSelect}
                                            component={FormikSelectAdapter}
                                            label=<FormattedMessage id="manager.division-player.form.status.label" defaultMessage="Status"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Field
                                            name={DIVISION_PLAYER_FIELDS.DIVISION_PLAYER_PLAYER_TYPE_FIELD}
                                            type="select"
                                            helperText=<FormattedMessage id="manager.division-player.form.type.helper" defaultMessage="player type"/>
                                            data={types}
                                            onChange={handleChange}
                                            inputComponent={DataSelect}
                                            component={FormikSelectAdapter}
                                            label=<FormattedMessage id="manager.division-player.form.type.label" defaultMessage="type"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Field
                                            name={DIVISION_PLAYER_FIELDS.DIVISION_PLAYER_AMPLUA_FIELD}
                                            type="select"
                                            helperText=<FormattedMessage id="manager.division-player.form.amplua.helper" defaultMessage="amplua"/>
                                            data={amplua}
                                            onChange={handleChange}
                                            inputComponent={DataSelect}
                                            component={FormikSelectAdapter}
                                            label=<FormattedMessage id="manager.division-player.form.amplua.label" defaultMessage="Amplua"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Field
                                            name={DIVISION_PLAYER_FIELDS.DIVISION_PLAYER_PLAY_NUMBER_FIELD}
                                            type="number"
                                            helperText=<FormattedMessage id="manager.division-player.form.number.helper" defaultMessage="number"/>
                                            inputComponent={TextField}
                                            component={FormikAdapter}
                                            label=<FormattedMessage id="manager.division-player.form.number.label" defaultMessage="Number"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Field
                                            name={DIVISION_PLAYER_FIELDS.DIVISION_PLAYER_PEOPLE_ID_FIELD}
                                            helperText=<FormattedMessage id="manager.division-player.form.player.helper" defaultMessage="player"/>
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            setFieldValue={setFieldValue}
                                            filter={"/peoples?sort=surname"}
                                            inputComponent={PeopleSelect}
                                            component={FormikSelectAdapter}
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Field
                                            name={DIVISION_PLAYER_FIELDS.DIVISION_PLAYER_SORT_FIELD}
                                            type="number"
                                            helperText=<FormattedMessage id="manager.division-player.form.sort.helper" defaultMessage="sorting"/>
                                            inputComponent={TextField}
                                            component={FormikAdapter}
                                            label=<FormattedMessage id="manager.division-player.form.sort.helper" defaultMessage="Sort"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            name={DIVISION_PLAYER_FIELDS.DIVISION_PLAYER_DESCRIPTION_FIELD}
                                            fullWidth
                                            helperText=<FormattedMessage id="manager.division-player.form.description.helper" defaultMessage="description"/>
                                            inputComponent={TextField}
                                            component={FormikAdapter}
                                            label=<FormattedMessage id="manager.division-player.form.description.label" defaultMessage="Description"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={isSubmitting}>
                                    <FormattedMessage id="button.save" defaultMessage="Save"/>
                                </Button>
                            </DialogActions>
                        </form>
                    )
                        ;
                }}
            </Formik>
        </Dialog>
    );
}