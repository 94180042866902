/**
 * Created by happy on 11.02.17.
 */
export const MANAGER_SEASON_LIST_REQUEST = 'MANAGER_SEASON_LIST_REQUEST';
export const MANAGER_SEASON_LIST_FAILURE = 'MANAGER_SEASON_LIST_FAILURE'
export const MANAGER_SEASON_LIST_SUCCESS = 'MANAGER_SEASON_LIST_SUCCESS'
export const MANAGER_SEASON_SHOW_FORM = 'MANAGER_SEASON_SHOW_FORM';
export const MANAGER_SEASON_CREATE_SUCCESS = 'MANAGER_SEASON_CREATE_SUCCESS'
export const MANAGER_SEASON_CREATE_FAILURE = 'MANAGER_SEASON_CREATE_FAILURE'
export const MANAGER_SEASON_UPDATE_SUCCESS = 'MANAGER_SEASON_UPDATE_SUCCESS'
export const MANAGER_SEASON_UPDATE_FAILURE = 'MANAGER_SEASON_UPDATE_FAILURE'
export const MANAGER_SEASON_DELETE_SUCCESS = 'MANAGER_SEASON_DELETE_SUCCESS'
export const MANAGER_SEASON_DELETE_FAILURE = 'MANAGER_SEASON_DELETE_FAILURE'
export const MANAGER_SEASON_VIEW_SUCCESS = 'MANAGER_SEASON_VIEW_SUCCESS'

export const CORE_SEASON_LIST_REQUEST = 'CORE_SEASON_LIST_REQUEST'
export const CORE_SEASON_LIST_FAILURE = 'CORE_SEASON_LIST_FAILURE'
export const CORE_SEASON_LIST_SUCCESS = 'CORE_SEASON_LIST_SUCCESS'
export const CORE_SEASON_SHOW_MODAL = 'CORE_SEASON_SHOW_MODAL'
export const CORE_SEASON_CREATE_SUCCESS = 'CORE_SEASON_CREATE_SUCCESS'
export const CORE_SEASON_DELETE_SUCCESS = 'CORE_SEASON_DELETE_SUCCESS'
export const CORE_SEASON_VIEW_SUCCESS = 'CORE_SEASON_VIEW_SUCCESS'
export const CORE_SEASON_VIEW_FAILURE = 'CORE_SEASON_VIEW_FAILURE'

export const SEASON_LIST_REQUEST = 'SEASON_LIST_REQUEST'
export const SEASON_LIST_FAILURE = 'SEASON_LIST_FAILURE'
export const SEASON_LIST_SUCCESS = 'SEASON_LIST_SUCCESS'
export const SEASON_SHOW_FORM = 'SEASON_SHOW_FORM';
export const SEASON_CREATE_SUCCESS = 'SEASON_CREATE_SUCCESS'
export const SEASON_CREATE_FAILURE = 'SEASON_CREATE_FAILURE'
export const SEASON_UPDATE_SUCCESS = 'SEASON_UPDATE_SUCCESS'
export const SEASON_UPDATE_FAILURE = 'SEASON_UPDATE_FAILURE'
export const SEASON_DELETE_SUCCESS = 'SEASON_DELETE_SUCCESS'
export const SEASON_DELETE_FAILURE = 'SEASON_DELETE_FAILURE'
export const SEASON_VIEW_SUCCESS = 'SEASON_VIEW_SUCCESS'
export const SEASON_VIEW_FAILURE = 'SEASON_VIEW_FAILURE'
