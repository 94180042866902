import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {MANAGER} from "../../../constants/Common";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import {Field, Formik} from "formik";
import * as yup from 'yup'
import FormikAdapter from "../../common/FormikAdapter";
import FormikSelectAdapter from "../../common/FormikSelectAdapter";
import AsyncSelect from "../../form/AsyncSelect";
import DivisionTeam from "../../form/DivisionTeam";
import {FormattedMessage} from "react-intl";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const STAGE_TEAM_FIELDS = {
    STAGE_TEAM_ID_FIELD: 'id',
    STAGE_TEAM_SEASON_ID_FIELD: 'seasonID',
    STAGE_TEAM_COMPETITION_ID_FIELD: 'competitionID',
    STAGE_TEAM_DIVISION_ID_FIELD: 'divisionID',
    STAGE_TEAM_STAGE_ID_FIELD: 'stageID',
    STAGE_TEAM_GROUP_ID_FIELD: 'groupID',
    STAGE_TEAM_TEAM_ID_FIELD: 'teamID',
    STAGE_TEAM_DIVISION_TEAM_ID_FIELD: 'divisionTeamID',
    STAGE_TEAM_DESCRIPTION_FIELD: 'description',
    STAGE_TEAM_SORT_FIELD: 'sort',
};

export default function StageTeamForm(mainProps) {

    const handleClose = () => {
        mainProps.show(false, MANAGER)
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={mainProps.open}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                <FormattedMessage id="stage-team-form" defaultMessage="Stage team form"/>
            </DialogTitle>
            <Formik
                initialValues={{
                    [STAGE_TEAM_FIELDS.STAGE_TEAM_ID_FIELD]: mainProps.model.id,
                    [STAGE_TEAM_FIELDS.STAGE_TEAM_SEASON_ID_FIELD]: mainProps.seasonID,
                    [STAGE_TEAM_FIELDS.STAGE_TEAM_COMPETITION_ID_FIELD]: mainProps.competitionID,
                    [STAGE_TEAM_FIELDS.STAGE_TEAM_DIVISION_ID_FIELD]: mainProps.divisionID,
                    [STAGE_TEAM_FIELDS.STAGE_TEAM_STAGE_ID_FIELD]: mainProps.stageID,
                    [STAGE_TEAM_FIELDS.STAGE_TEAM_GROUP_ID_FIELD]: mainProps.model.groupID,
                    [STAGE_TEAM_FIELDS.STAGE_TEAM_DIVISION_TEAM_ID_FIELD]: mainProps.model.divisionTeam.id,
                    [STAGE_TEAM_FIELDS.STAGE_TEAM_TEAM_ID_FIELD]: mainProps.model.teamID,
                    [STAGE_TEAM_FIELDS.STAGE_TEAM_SORT_FIELD]: mainProps.model.sort,
                }}
                onSubmit={(values, actions) => {
                    values.divisionTeamID = parseInt(values.divisionTeamID)
                    values.groupID = parseInt(values.groupID)
                    if (values.id > 0) {
                        mainProps.update(values.id, values, MANAGER);
                    } else {
                        mainProps.create(values, MANAGER);
                    }
                }}
                validationSchema={yup.object().shape({
                    [STAGE_TEAM_FIELDS.STAGE_TEAM_DIVISION_TEAM_ID_FIELD]: yup.string()
                        .required(<FormattedMessage id="form.required" defaultMessage="required field"/>),
                })}
            >
                {(props) => {
                    const {
                        isSubmitting,
                        handleSubmit,
                        handleChange,
                    } = props;
                    console.log(props)
                    return (
                        <form onSubmit={handleSubmit}>
                            <DialogContent dividers>
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <Field
                                            name={STAGE_TEAM_FIELDS.STAGE_TEAM_DIVISION_TEAM_ID_FIELD}
                                            type="select"
                                            helperText=<FormattedMessage id="manager.stage-team.form.team.helper" defaultMessage="team"/>
                                            onChange={handleChange}
                                            filter={"/divisionTeams?filter=division_id:" + mainProps.divisionID}
                                            inputComponent={DivisionTeam}
                                            component={FormikSelectAdapter}
                                            label=<FormattedMessage id="manager.stage-team.form.team.label" defaultMessage="Team"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Field
                                            name={STAGE_TEAM_FIELDS.STAGE_TEAM_GROUP_ID_FIELD}
                                            type="select"
                                            helperText=<FormattedMessage id="manager.stage-team.form.group.helper" defaultMessage="group"/>
                                            onChange={handleChange}
                                            filter={"/groups?filter=stage_id:" + mainProps.stageID}
                                            inputComponent={AsyncSelect}
                                            component={FormikSelectAdapter}
                                            label=<FormattedMessage id="manager.stage-team.form.group.label" defaultMessage="Group"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            name={STAGE_TEAM_FIELDS.STAGE_TEAM_SORT_FIELD}
                                            type="number"
                                            helperText=<FormattedMessage id="manager.stage-team.form.sort.helper" defaultMessage="sorting"/>
                                            inputComponent={TextField}
                                            component={FormikAdapter}
                                            label=<FormattedMessage id="manager.stage-team.form.sort.label" defaultMessage="Sort"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={isSubmitting}>
                                    <FormattedMessage id="button.save" defaultMessage="Save"/>
                                </Button>
                            </DialogActions>
                        </form>
                    );
                }}
            </Formik>
        </Dialog>
    );
}