import {
    TEAM_LIST_REQUEST,
    TEAM_SHOW_FORM,
} from '../constants/Team';

import {makeDispatch, rstatApi} from '../helpers/api'
import {CORE} from "../constants/Common";
import {CREATE, DELETE, LIST, STAGE_TEAM, TEAM, UPDATE, VIEW} from "../constants/Global";

export function showTeamForm(open, type) {
    return (dispatch) => {
        dispatch({
            type: type + TEAM_SHOW_FORM,
            payload: open
        })
    }
}

export function viewTeam(id, type) {
    return (dispatch) => {
        rstatApi.get('/teams/' + id).then(function (response) {
            dispatch(makeDispatch(CORE, VIEW, TEAM, response));
        }).catch(function (error) {
            dispatch(makeDispatch(CORE, VIEW, TEAM, error.response));
        });
    }
}

export function updateTeam(id, body, type) {
    return (dispatch) => {
        rstatApi.put('/teams/' + id, body).then(function (response) {
            dispatch(makeDispatch(CORE, UPDATE, TEAM, response));
        }).catch(function (error) {
            dispatch(makeDispatch(CORE, UPDATE, TEAM, error.response));
        });
    }
}

export function listTeams(query = '', type) {
    let competitionID = 0;
    let result = query.match(/competition_id:(\d+)/i);
    if (result !== null) {
        competitionID = parseInt(result[1], 10)
    }
    return (dispatch) => {
        dispatch({
            type: type + TEAM_LIST_REQUEST,
            payload: competitionID,
        });
        rstatApi.get('/teams' + query).then(function (response) {
            dispatch(makeDispatch(CORE, LIST, TEAM, response));
        }).catch(function (error) {
            dispatch(makeDispatch(CORE, LIST, TEAM, error.response));
        });
    }
}

export function createTeam(body, type) {
    return (dispatch) => {
        rstatApi.post('/teams', body).then(function (response) {
            dispatch(makeDispatch(CORE, CREATE, TEAM, response));
        }).catch(function (error) {
            dispatch(makeDispatch(CORE, CREATE, TEAM, error.response));
        });
    }
}

export function deleteTeam(id, type) {
    return (dispatch) => {
        rstatApi.delete('/teams/' + id,).then(function (response) {
            dispatch(makeDispatch(CORE, DELETE, TEAM, response, parseInt(id, 10)));
        }).catch(function (error) {
            dispatch(makeDispatch(CORE, DELETE, TEAM, error.response));
        })
    }
}