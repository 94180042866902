/**
 * Created by happy on 11.02.17.
 */
export const MANAGER_DIVISION_LIST_REQUEST = 'MANAGER_DIVISION_LIST_REQUEST';
export const MANAGER_DIVISION_LIST_FAILURE = 'MANAGER_DIVISION_LIST_FAILURE'
export const MANAGER_DIVISION_LIST_SUCCESS = 'MANAGER_DIVISION_LIST_SUCCESS'
export const MANAGER_DIVISION_SHOW_FORM = 'MANAGER_DIVISION_SHOW_FORM';
export const MANAGER_DIVISION_CREATE_SUCCESS = 'MANAGER_DIVISION_CREATE_SUCCESS'
export const MANAGER_DIVISION_CREATE_FAILURE = 'MANAGER_DIVISION_CREATE_FAILURE'
export const MANAGER_DIVISION_UPDATE_SUCCESS = 'MANAGER_DIVISION_UPDATE_SUCCESS'
export const MANAGER_DIVISION_UPDATE_FAILURE = 'MANAGER_DIVISION_UPDATE_FAILURE'
export const MANAGER_DIVISION_DELETE_SUCCESS = 'MANAGER_DIVISION_DELETE_SUCCESS'
export const MANAGER_DIVISION_DELETE_FAILURE = 'MANAGER_DIVISION_DELETE_FAILURE'
export const MANAGER_DIVISION_VIEW_SUCCESS = 'MANAGER_DIVISION_VIEW_SUCCESS'

export const CORE_DIVISION_LIST_REQUEST = 'CORE_DIVISION_LIST_REQUEST'
export const CORE_DIVISION_LIST_FAILURE = 'CORE_DIVISION_LIST_FAILURE'
export const CORE_DIVISION_LIST_SUCCESS = 'CORE_DIVISION_LIST_SUCCESS'
export const CORE_DIVISION_SHOW_MODAL = 'CORE_DIVISION_SHOW_MODAL'
export const CORE_DIVISION_CREATE_SUCCESS = 'CORE_DIVISION_CREATE_SUCCESS'
export const CORE_DIVISION_DELETE_SUCCESS = 'CORE_DIVISION_DELETE_SUCCESS'
export const CORE_DIVISION_VIEW_SUCCESS = 'CORE_DIVISION_VIEW_SUCCESS'
export const CORE_DIVISION_VIEW_FAILURE = 'CORE_DIVISION_VIEW_FAILURE'

export const DIVISION_LIST_REQUEST = 'DIVISION_LIST_REQUEST'
export const DIVISION_LIST_FAILURE = 'DIVISION_LIST_FAILURE'
export const DIVISION_LIST_SUCCESS = 'DIVISION_LIST_SUCCESS'
export const DIVISION_SHOW_FORM = 'DIVISION_SHOW_FORM';
export const DIVISION_CREATE_SUCCESS = 'DIVISION_CREATE_SUCCESS'
export const DIVISION_CREATE_FAILURE = 'DIVISION_CREATE_FAILURE'
export const DIVISION_UPDATE_SUCCESS = 'DIVISION_UPDATE_SUCCESS'
export const DIVISION_UPDATE_FAILURE = 'DIVISION_UPDATE_FAILURE'
export const DIVISION_DELETE_SUCCESS = 'DIVISION_DELETE_SUCCESS'
export const DIVISION_DELETE_FAILURE = 'DIVISION_DELETE_FAILURE'
export const DIVISION_VIEW_SUCCESS = 'DIVISION_VIEW_SUCCESS'
export const DIVISION_VIEW_FAILURE = 'DIVISION_VIEW_FAILURE'
