import React from 'react';
import Box from '@material-ui/core/Box';
import * as penaltyActions from '../actions/PenaltyActions'

import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Penalties from "../components/Penalties";
import PenaltyForm from "../components/PenaltyForm";

function Penalty(props) {
    const {penalty} = props;
    const {listPenalties, showPenaltyForm, createPenalty, updatePenalty,viewPenalty,deletePenalty} = props.penaltyActions;
    return (
        <Box style={{height:'100%'}}>
            <Penalties
                models={penalty.get('models')}
                loading={penalty.get('loading')}
                showForm={showPenaltyForm}
                view={viewPenalty}
                delete={deletePenalty}
                list={listPenalties}
            />
            <PenaltyForm
                model={penalty.get('model')}
                show={showPenaltyForm}
                create={createPenalty}
                update={updatePenalty}
                open={penalty.get('showModal')}
            />
        </Box>
    );
}

function mapStateToProps(state) {
    return {
        penalty: state.penalty,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        penaltyActions: bindActionCreators(penaltyActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Penalty)

