/**
 * Created by happy on 11.02.17.
 */
export const MANAGER_STAGE_TEAM_LIST_REQUEST = 'MANAGER_STAGE_TEAM_LIST_REQUEST';
export const MANAGER_STAGE_TEAM_COPY_REQUEST = 'MANAGER_STAGE_TEAM_COPY_REQUEST';
export const MANAGER_STAGE_TEAM_LIST_FAILURE = 'MANAGER_STAGE_TEAM_LIST_FAILURE'
export const MANAGER_STAGE_TEAM_LIST_SUCCESS = 'MANAGER_STAGE_TEAM_LIST_SUCCESS'
export const MANAGER_STAGE_TEAM_COPY_SUCCESS = 'MANAGER_STAGE_TEAM_COPY_SUCCESS'
export const MANAGER_STAGE_TEAM_SHOW_FORM = 'MANAGER_STAGE_TEAM_SHOW_FORM';
export const MANAGER_STAGE_TEAM_CREATE_SUCCESS = 'MANAGER_STAGE_TEAM_CREATE_SUCCESS'
export const MANAGER_STAGE_TEAM_CREATE_FAILURE = 'MANAGER_STAGE_TEAM_CREATE_FAILURE'
export const MANAGER_STAGE_TEAM_UPDATE_SUCCESS = 'MANAGER_STAGE_TEAM_UPDATE_SUCCESS'
export const MANAGER_STAGE_TEAM_UPDATE_FAILURE = 'MANAGER_STAGE_TEAM_UPDATE_FAILURE'
export const MANAGER_STAGE_TEAM_DELETE_SUCCESS = 'MANAGER_STAGE_TEAM_DELETE_SUCCESS'
export const MANAGER_STAGE_TEAM_DELETE_FAILURE = 'MANAGER_STAGE_TEAM_DELETE_FAILURE'
export const MANAGER_STAGE_TEAM_VIEW_SUCCESS = 'MANAGER_STAGE_TEAM_VIEW_SUCCESS'

export const CORE_STAGE_TEAM_LIST_REQUEST = 'CORE_STAGE_TEAM_LIST_REQUEST'
export const CORE_STAGE_TEAM_LIST_FAILURE = 'CORE_STAGE_TEAM_LIST_FAILURE'
export const CORE_STAGE_TEAM_LIST_SUCCESS = 'CORE_STAGE_TEAM_LIST_SUCCESS'
export const CORE_STAGE_TEAM_SHOW_MODAL = 'CORE_STAGE_TEAM_SHOW_MODAL'
export const CORE_STAGE_TEAM_CREATE_SUCCESS = 'CORE_STAGE_TEAM_CREATE_SUCCESS'
export const CORE_STAGE_TEAM_DELETE_SUCCESS = 'CORE_STAGE_TEAM_DELETE_SUCCESS'
export const CORE_STAGE_TEAM_VIEW_SUCCESS = 'CORE_STAGE_TEAM_VIEW_SUCCESS'
export const CORE_STAGE_TEAM_VIEW_FAILURE = 'CORE_STAGE_TEAM_VIEW_FAILURE'

export const STAGE_TEAM_LIST_REQUEST = 'STAGE_TEAM_LIST_REQUEST'
export const STAGE_TEAM_LIST_FAILURE = 'STAGE_TEAM_LIST_FAILURE'
export const STAGE_TEAM_LIST_SUCCESS = 'STAGE_TEAM_LIST_SUCCESS'
export const STAGE_TEAM_SHOW_FORM = 'STAGE_TEAM_SHOW_FORM';
export const STAGE_TEAM_CREATE_SUCCESS = 'STAGE_TEAM_CREATE_SUCCESS'
export const STAGE_TEAM_CREATE_FAILURE = 'STAGE_TEAM_CREATE_FAILURE'
export const STAGE_TEAM_UPDATE_SUCCESS = 'STAGE_TEAM_UPDATE_SUCCESS'
export const STAGE_TEAM_UPDATE_FAILURE = 'STAGE_TEAM_UPDATE_FAILURE'
export const STAGE_TEAM_DELETE_SUCCESS = 'STAGE_TEAM_DELETE_SUCCESS'
export const STAGE_TEAM_DELETE_FAILURE = 'STAGE_TEAM_DELETE_FAILURE'
export const STAGE_TEAM_VIEW_SUCCESS = 'STAGE_TEAM_VIEW_SUCCESS'
export const STAGE_TEAM_VIEW_FAILURE = 'STAGE_TEAM_VIEW_FAILURE'
