import React from 'react';
import ProtocolDropDown from "./ProtocolDropDown";
import {FOOTBALL_PROTOCOL_ROW} from "../../constants/Protocol";
import {FormattedMessage} from "react-intl";

class ProtocolKeeper extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            penaltyReasons: []
        };

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        let name = target.attributes.getNamedItem('data').value

        this.setState({
            [name]: value
        });
    }


    componentWillMount() {
        var startMin
        var startSec
        var endMin
        var endSec
        var shoot
        var player

        this.setState({
            matchPlayers: this.props.matchPlayers,
            matchKeepers: this.props.matchKeepers,
        });

        for (let i = 0; i < FOOTBALL_PROTOCOL_ROW; i++) {
            let dataStartMin = 'startMin_' + i;
            let dataStartSec = 'startSec_' + i;
            let dataEndMin = 'endMin_' + i;
            let dataEndSec = 'endSec_' + i;
            let dataShoot = 'shoot_' + i;
            let dataPlayer = 'player_' + i;


            if (typeof this.props.matchKeepers[i] === 'undefined') {
                startMin = undefined;
                startSec = undefined;
                endMin = undefined;
                endSec = undefined;
                shoot = undefined;
                player = 0
            } else {
                startMin = this.props.matchKeepers[i].startMin
                startSec = this.props.matchKeepers[i].startSec
                endMin = this.props.matchKeepers[i].endMin
                endSec = this.props.matchKeepers[i].endSec
                shoot = this.props.matchKeepers[i].shoot
                player = this.props.matchKeepers[i].matchPlayerID
            }

            this.setState({
                [dataStartMin]: startMin,
                [dataStartSec]: startSec,
                [dataEndMin]: endMin,
                [dataEndSec]: endSec,
                [dataShoot]: shoot,
                [dataPlayer]: player,
            });
        }

        let tmpPlayers = this.props.matchPlayers.filter(isPlayed)

        let playersNumber = tmpPlayers.map(function (model) {
            return {id: model.id, name: model.number};
        });

        playersNumber.sort(function (a, b) {
            return a.name - b.name
        });


        this.setState({
            playersNumber: playersNumber
        });
    }

    componentWillReceiveProps(nextProps) {
        var startMin
        var startSec
        var endMin
        var endSec
        var shoot
        var player

        this.setState({
            matchPlayers: nextProps.matchPlayers,
            matchKeepers: nextProps.matchKeepers,
        });

        for (let i = 0; i < FOOTBALL_PROTOCOL_ROW; i++) {
            let dataStartMin = 'startMin_' + i
            let dataStartSec = 'startSec_' + i
            let dataEndMin = 'endMin_' + i
            let dataEndSec = 'endSec_' + i
            let dataShoot = 'shoot_' + i
            let dataPlayer = 'player_' + i


            if (typeof nextProps.matchKeepers[i] === 'undefined') {
                startMin = undefined
                startSec = undefined
                endMin = undefined
                endSec = undefined
                shoot = undefined
                player = 0
            } else {
                startMin = nextProps.matchKeepers[i].startMin
                startSec = nextProps.matchKeepers[i].startSec
                endMin = nextProps.matchKeepers[i].endMin
                endSec = nextProps.matchKeepers[i].endSec
                shoot = nextProps.matchKeepers[i].shoot
                player = nextProps.matchKeepers[i].matchPlayerID
            }

            this.setState({
                [dataStartMin]: startMin,
                [dataStartSec]: startSec,
                [dataEndMin]: endMin,
                [dataEndSec]: endSec,
                [dataShoot]: shoot,
                [dataPlayer]: player,
            });
        }

        let tmpPlayers = nextProps.matchPlayers.filter(isPlayed)

        let playersNumber = tmpPlayers.map(function (model) {
            return {id: model.id, name: model.number};
        });

        playersNumber.sort(function (a, b) {
            return a.name - b.name
        });

        this.setState({
            playersNumber: playersNumber
        });
    }

    render() {
        let playersNumber = this.state.matchPlayers.map(function (model) {
            return {id: model.id, name: model.number};
        });

        playersNumber.sort(function (a, b) {
            return a.name - b.name
        });

        let rows = [];
        let id;

        for (let i = 0; i < FOOTBALL_PROTOCOL_ROW; i++) {
            let dataStartMin = 'startMin_' + i
            let dataStartSec = 'startSec_' + i
            let dataEndMin = 'endMin_' + i
            let dataEndSec = 'endSec_' + i
            let dataShoot = 'shoot_' + i
            let dataPlayer = 'player_' + i


            if (typeof this.state.matchKeepers[i] === 'undefined') {
                id = undefined
            } else {
                id = this.state.matchKeepers[i].id
            }


            rows.push(
                <tr key={i} id={this.props.prefix + "_penalty_" + i}>
                    <td>
                        <input type='hidden' name={'matchKeepers[' + this.props.prefix + '][' + i + '][id]'}
                               value={id}/>
                        <ProtocolDropDown
                            handleChange={this.handleInputChange}
                            name={'matchKeepers[' + this.props.prefix + '][' + i + '][matchPlayerID]'}
                            data={dataPlayer}
                            value={this.state[dataPlayer]}
                            items={this.state.playersNumber}/>
                    </td>
                    <td>
                        <input className='protocol-number'
                               data={dataStartMin}
                               value={this.state[dataStartMin]}
                               onChange={this.handleInputChange}
                               name={'matchKeepers[' + this.props.prefix + '][' + i + '][startMinute]'}/>
                        :
                        <input className='protocol-number'
                               data={dataStartSec}
                               value={this.state[dataStartSec]}
                               onChange={this.handleInputChange}
                               name={'matchKeepers[' + this.props.prefix + '][' + i + '][startSecond]'}/>
                    </td>
                    <td>
                        <input className='protocol-number'
                               data={dataEndMin}
                               value={this.state[dataEndMin]}
                               onChange={this.handleInputChange}
                               name={'matchKeepers[' + this.props.prefix + '][' + i + '][endMinute]'}/>
                        :
                        <input className='protocol-number'
                               data={dataEndSec}
                               value={this.state[dataEndSec]}
                               onChange={this.handleInputChange}
                               name={'matchKeepers[' + this.props.prefix + '][' + i + '][endSecond]'}/>
                    </td>
                    <td>
                        <input className='protocol-number'
                               data={dataShoot}
                               value={this.state[dataShoot]}
                               onChange={this.handleInputChange}
                               name={'matchKeepers[' + this.props.prefix + '][' + i + '][shoot]'}/>
                    </td>
                    <td>
                        <spa className='protocol-remove'>⊘</spa>
                    </td>
                </tr>
            );
        }

        return (
            <table className='protocol-table'>
                <thead>
                <tr>
                    <th>#</th>
                    <th><FormattedMessage id="protocol.in" defaultMessage="in"/></th>
                    <th><FormattedMessage id="protocol.out" defaultMessage="out"/></th>
                    <th><FormattedMessage id="protocol.shoot" defaultMessage="shoot"/></th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {rows}
                </tbody>
            </table>
        );
    }
}

export default ProtocolKeeper;

export function isPlayed(player) {
    return player.played;
}