import React, {useEffect, useState} from 'react'
import MaterialTable from 'material-table'
import {CORE} from "../constants/Common";
import {FormattedMessage, useIntl} from "react-intl";

export default function Countries(props) {
    const [selectedRow, setValue] = useState(0);

    useEffect(() => {
        props.list('', CORE)
    }, []);

    let data = [];
    props.models.forEach(n => data.push(n));

    function handleShowForm() {
        props.showForm(true, CORE)
    }

    function handleUpdate(id) {
        props.view(id, CORE)
    }

    function handleDelete(id) {
        props.delete(id, CORE)
    }

    const intl = useIntl()

    return (
        <MaterialTable
            title={intl.formatMessage({id: 'country',defaultMessage:"Countries"})}
            columns={[
                {
                    title: 'id',
                    field: 'id',
                },
                {
                    title:<FormattedMessage id="country.flag" defaultMessage="flag"/>,
                    render: rowData => <img alt="" src={"https://flagcdn.com/w20/"+rowData.code.toLowerCase()+".png"} />
                },
                {
                    title: <FormattedMessage id="country.name" defaultMessage="name"/>,
                    field: 'name',
                },
            ]}
            options={{
                rowStyle: rowData => ({
                    fontSize: 12,
                    backgroundColor: (selectedRow && selectedRow.tableData.id === rowData.tableData.id) ? '#EEE' : '#FFF'
                }),
                pageSize:15,
                paging: true,
                header: true,
                sorting: true,
                filtering: true,
                actionsColumnIndex: -1,
                showTitle: true
            }}
            localization={
                {
                    toolbar: {
                        searchPlaceholder: intl.formatMessage({id: 'countries-title',defaultMessage:"Countries"}),
                        searchTooltip: <FormattedMessage id="common.find" defaultMessage="find"/>
                    },
                    header: {
                        actions: <FormattedMessage id="common.actions" defaultMessage="actions"/>
                    },
                    body: {
                        emptyDataSourceMessage: <FormattedMessage id="common.no_data" defaultMessage="no data"/>
                    }
                }
            }
            actions={[
                {
                    icon: 'add',
                    tooltip: <FormattedMessage id="country.add" defaultMessage="add country"/>,
                    isFreeAction: true,
                    onClick: (event) => handleShowForm()
                },
                {
                    icon: 'search',
                    tooltip: <FormattedMessage id="country.view" defaultMessage="view country"/>,
                    onClick: (event, rowData) => alert("show " + rowData.name)
                },
                {
                    icon: 'edit',
                    tooltip: <FormattedMessage id="country.edit" defaultMessage="edit country"/>,
                    onClick: (event, rowData) => handleUpdate(rowData.id)
                },
                {
                    icon: 'delete',
                    tooltip: <FormattedMessage id="country.delete" defaultMessage="delete country"/>,
                    onClick: (event, rowData) => handleDelete(rowData.id)
                }
            ]}
            data={data}
        />
    )
}