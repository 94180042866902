import {
    CORE_COUNTRY_CREATE_SUCCESS,
    CORE_COUNTRY_DELETE_SUCCESS,
    CORE_COUNTRY_LIST_SUCCESS,
    CORE_COUNTRY_SHOW_FORM,
    CORE_COUNTRY_VIEW_SUCCESS,
    CORE_COUNTRY_UPDATE_SUCCESS,
} from '../constants/Country';

var {Map, List, OrderedMap} = require('immutable');
const initialState = Map({
    showModal: false,
    showView: false,
    snackbar: Map({
        show: false,
        text: ''
    }),
    count: 0,
    model: {
        id: 0,
        cityID:0,
    },
    models: List([]),
})


export default function country(state = initialState, action) {

    switch (action.type) {
        case CORE_COUNTRY_SHOW_FORM:
            return state.set('showModal', action.payload).set('model',initialState.get('model'))
        case CORE_COUNTRY_LIST_SUCCESS:
            return state
                .set('models', OrderedMap().withMutations(map => {
                    action.payload.forEach(model => map.set(model.id, model));
                }))
                .set('count', action.count);
        case CORE_COUNTRY_VIEW_SUCCESS:
            return state
                .set('model', action.payload)
                .set('showModal', true);
        case CORE_COUNTRY_DELETE_SUCCESS:
            return state
                .deleteIn(['models', action.payload])
        case CORE_COUNTRY_CREATE_SUCCESS:
            return state
                .setIn(['models', action.payload.id], action.payload)
                .set('showModal', false)
        case CORE_COUNTRY_UPDATE_SUCCESS:
            return state
                .setIn(['models', action.payload.id], action.payload)
                .set('showModal', false)

        default:
            return state;
    }
}
