import React, {useEffect} from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@mui/material/Alert';
import {AlertTitle} from "@mui/material";
import {CORE} from "../../constants/Common";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


export default function InfoSnackbar(props) {
    useEffect(() => {
       props.getProfile(CORE)
    },[]);

    return (
        <Snackbar
            open={props.open}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            autoHideDuration={5000}
            onClose={props.hide}>
            <Alert onClose={props.hide} severity={props.variant}>
                <AlertTitle>{props.title}</AlertTitle>
                {props.message}
            </Alert>
        </Snackbar>
    );
}