import React, {useState} from 'react'
import MaterialTable from 'material-table'
import {MANAGER} from "../../../constants/Common";
import {Icon} from "@mui/material";
import {green} from "@mui/material/colors";
import {FormattedMessage, useIntl} from "react-intl";

export default function News(props) {
    const [selectedRow, setValue] = useState(0);

    let data = [];
    props.models.forEach(n => data.push(n));

    const intl = useIntl()

    function handleShowForm() {
        props.showForm(true, MANAGER)
    }

    function handleUpdate(id) {
        props.view(id, MANAGER)
    }

    function handleDelete(id) {
        props.delete(id, MANAGER)
    }

    return (
        <MaterialTable
            title={intl.formatMessage({id: 'manager.site.news-title',defaultMessage:"News"})}
            columns={[
                {
                    title: 'id',
                    field: 'id',
                },
                {
                    title: <FormattedMessage id="manager.site.news.title" defaultMessage="name"/>,
                    field: 'title',
                },
                {
                    title: <FormattedMessage id="manager.site.news.main" defaultMessage="main"/>,
                    field: 'isMain',
                    render: rowData => rowData.isMain?<Icon sx={{ color: green[500] }}>check</Icon>:rowData.isMain
                },
                {
                    title: <FormattedMessage id="manager.site.news.created" defaultMessage="created"/>,
                    field: 'createdAt',
                },
            ]}
            options={{
                rowStyle: rowData => ({
                    fontSize: 12,
                    backgroundColor: (selectedRow && selectedRow.tableData.id === rowData.tableData.id) ? '#EEE' : '#FFF'
                }),
                pageSize:15,
                paging: true,
                header: true,
                sorting: true,
                filtering: true,
                actionsColumnIndex: -1,
                showTitle: true
            }}
            localization={
                {
                    toolbar: {
                        searchPlaceholder: intl.formatMessage({id: 'manager.site.news-title',defaultMessage:"News"}),
                        searchTooltip: <FormattedMessage id="common.find" defaultMessage="find"/>
                    },
                    body: {
                        emptyDataSourceMessage: <FormattedMessage id="common.no_data" defaultMessage="no data"/>
                    },
                    header: {
                        actions: <FormattedMessage id="common.actions" defaultMessage="actions"/>
                    },
                }
            }
            actions={[
                {
                    icon: 'add',
                    tooltip: <FormattedMessage id="manager.site.news.add" defaultMessage="add news"/>,
                    isFreeAction: true,
                    onClick: (event) => handleShowForm()
                },
                {
                    icon: 'search',
                    tooltip: <FormattedMessage id="manager.site.menu.view" defaultMessage="view"/>,
                    onClick: (event, rowData) => window.open("/news/" + rowData.id, '_blank')
                },
                {
                    icon: 'edit',
                    tooltip: <FormattedMessage id="manager.site.menu.edit" defaultMessage="edit"/>,
                    onClick: (event,rowData) => handleUpdate(rowData.id)
                },
                {
                    icon: 'delete',
                    tooltip: <FormattedMessage id="manager.site.menu.delete" defaultMessage="delete"/>,
                    onClick: (event,rowData) => handleDelete(rowData.id)
                }
            ]}
            data={data}
        />
    )
}