import React, {useEffect, useState} from 'react'
import {ImageList, ImageListItem} from "@mui/material";
import {CONSTRUCTOR_API_URL} from "../../../constants/Common";

function srcset(image, size, rows = 1, cols = 1) {
    return {
        src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
        srcSet: `${image}?w=${size * cols}&h=${
            size * rows
        }&fit=crop&auto=format&dpr=2 2x`,
    };
}

export default function Photos(props) {
    let items = []

    props.models.map((model, i) =>
        items.push({
            img: CONSTRUCTOR_API_URL+"photos/"+model.id,
            rows: 2,
        })
    );

    return (
        <ImageList
            sx={{ width: '100%', height: 450 }}
            variant="quilted"
            cols={4}
            rowHeight={121}
        >
            {items.map((item) => (
                <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
                    <img
                        {...srcset(item.img, 121, item.rows, item.cols)}
                        alt={item.title}
                        loading="lazy"
                    />
                </ImageListItem>
            ))}
        </ImageList>
    );
}