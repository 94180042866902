import {
    GROUP_LIST_REQUEST,
    GROUP_LIST_FAILURE,
    GROUP_SHOW_FORM,
    GROUP_CREATE_FAILURE,
    GROUP_DELETE_FAILURE, GROUP_VIEW_FAILURE, GROUP_UPDATE_FAILURE
} from '../constants/Group';

import {rstatApi, makeDispatch, makeDispatch500} from '../helpers/api'
import {MANAGER} from "../constants/Common";
import {CREATE, DELETE, LIST, GROUP, UPDATE, VIEW, DIVISION_TEAM} from "../constants/Global";

export function showGroupForm(open, type) {
    return (dispatch) => {
        dispatch({
            type: type + GROUP_SHOW_FORM,
            payload: open
        })
    }
}

export function viewGroup(id, type) {
    return (dispatch) => {
        rstatApi.get('/groups/' + id).then(function (response) {
            dispatch(makeDispatch(MANAGER, VIEW, GROUP, response));
        }).catch(function (error) {
            dispatch(makeDispatch(MANAGER, VIEW, GROUP, error.response));
        });
    }
}

export function updateGroup(id, body, type) {
    return (dispatch) => {
        rstatApi.put('/groups/' + id, body).then(function (response) {
            dispatch(makeDispatch(MANAGER, UPDATE, GROUP, response));
        }).catch(function (error) {
            dispatch(makeDispatch(MANAGER, UPDATE, GROUP, error.response));
        });
    }
}

export function listGroups(query = '', type) {
    let stageID = 0;
    let result = query.match(/stage_id:(\d+)/i);
    if (result !== null) {
        stageID = parseInt(result[1], 10)
    }
    return (dispatch) => {
        dispatch({
            type: type + GROUP_LIST_REQUEST,
            payload: stageID,
        });
        rstatApi.get('/groups' + query).then(function (response) {
            dispatch(makeDispatch(MANAGER, LIST, GROUP, response));
        }).catch(function (error) {
            dispatch(makeDispatch(MANAGER, LIST, GROUP, error.response));
        });
    }
}

export function createGroup(body, type) {
    return (dispatch) => {
        rstatApi.post('/groups', body).then(function (response) {
            dispatch(makeDispatch(MANAGER, CREATE, GROUP, response));
        }).catch(function (error) {
            dispatch(makeDispatch(MANAGER, CREATE, GROUP, error.response));
        });
    }
}

export function deleteGroup(id, type) {
    return (dispatch) => {
        rstatApi.delete('/groups/' + id,).then(function (response) {
            dispatch(makeDispatch(MANAGER, DELETE, GROUP, response, parseInt(id, 10)));
        }).catch(function (error) {
            dispatch(makeDispatch(MANAGER, DELETE, GROUP, error.response));
        })
    }
}