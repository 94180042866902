import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {MANAGER} from "../../../../constants/Common";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import {Field, Formik} from "formik";
import * as yup from 'yup'
import FormikAdapter from "../../../common/FormikAdapter";
import {FormattedMessage} from "react-intl";
import {FormHelperText} from "@mui/material";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const CONTACT_FIELDS = {
    CONTACT_ID_FIELD: 'id',
    CONTACT_NAME_FIELD: 'name',
    CONTACT_SURNAME_FIELD: 'surname',
    CONTACT_POSITION_FIELD: 'position',
    CONTACT_PHONE_FIELD: 'phone',
    CONTACT_EMAIL_FIELD: 'email',
    CONTACT_DESCRIPTION_FIELD: 'description',
    CONTACT_COMPETITION_ID_FIELD: 'competitionID',
    CONTACT_SORT_FIELD: 'sort',
};

export default function ContactForm(props) {
    const handleClose = () => {
        props.show(false, MANAGER)
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"md"}
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={props.open}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                <FormattedMessage id="contact-form" defaultMessage="Contact form"/>
            </DialogTitle>
            <Formik
                initialValues={{
                    [CONTACT_FIELDS.CONTACT_ID_FIELD]: props.model.id,
                    [CONTACT_FIELDS.CONTACT_COMPETITION_ID_FIELD]: props.model.competitionID,
                    [CONTACT_FIELDS.CONTACT_NAME_FIELD]: props.model.name,
                    [CONTACT_FIELDS.CONTACT_SURNAME_FIELD]: props.model.surname,
                    [CONTACT_FIELDS.CONTACT_POSITION_FIELD]: props.model.position,
                    [CONTACT_FIELDS.CONTACT_PHONE_FIELD]: props.model.phone,
                    [CONTACT_FIELDS.CONTACT_EMAIL_FIELD]: props.model.email,
                    [CONTACT_FIELDS.CONTACT_DESCRIPTION_FIELD]: props.model.description,
                    [CONTACT_FIELDS.CONTACT_SORT_FIELD]: props.model.sort,
                }}
                onSubmit={(values, actions) => {
                    let data = new FormData();

                    data.append("competitionID", props.competitionID);
                    data.append("file", values.file)
                    data.append("name", values.name)
                    data.append("surname", values.surname)
                    data.append("phone", values.phone)
                    data.append("position", values.position)
                    data.append("email", values.email)
                    data.append("photo", values.photo)
                    data.append("description", values.description)
                    data.append("sort", values.sort)

                    if (values.id > 0) {
                        props.update(values.id, data, MANAGER);
                    } else {
                        props.create(data, MANAGER);
                    }
                }}
                validationSchema={yup.object().shape({
                    [CONTACT_FIELDS.CONTACT_NAME_FIELD]: yup.string()
                        .required(<FormattedMessage id="form.required" defaultMessage="required field"/>),
                    [CONTACT_FIELDS.CONTACT_SURNAME_FIELD]: yup.string()
                        .required(<FormattedMessage id="form.required" defaultMessage="required field"/>),
                    [CONTACT_FIELDS.CONTACT_EMAIL_FIELD]: yup.string()
                        .required(<FormattedMessage id="form.required" defaultMessage="required field"/>),
                })}
            >
                {(props) => {
                    const {
                        errors,
                        setFieldValue,
                        isSubmitting,
                        handleSubmit,
                    } = props;

                    return (
                        <form onSubmit={handleSubmit}>
                            <DialogContent dividers>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Button
                                            variant="outlined"
                                            component="label"
                                        >
                                            <FormattedMessage id="manager.site.contact.photo" defaultMessage="Photo"/>
                                            <input hidden id="file" name="file" type="file" onChange={(event) => {
                                                setFieldValue("file", event.currentTarget.files[0]);
                                            }} className="form-control"/>
                                        </Button>
                                        <FormHelperText><FormattedMessage id="manager.site.news.contact.helper" defaultMessage="850px"/></FormHelperText>
                                        <div>{errors.import}</div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Field
                                            name={CONTACT_FIELDS.CONTACT_NAME_FIELD}
                                            type="text"
                                            fullWidth
                                            helperText=<FormattedMessage id="manager.site.contact.name.helper" defaultMessage="name"/>
                                            inputComponent={TextField}
                                            component={FormikAdapter}
                                            label=<FormattedMessage id="manager.site.contact.name.helper" defaultMessage="Name"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Field
                                            name={CONTACT_FIELDS.CONTACT_SURNAME_FIELD}
                                            type="text"
                                            fullWidth
                                            helperText=<FormattedMessage id="manager.site.contact.last-name.helper" defaultMessage="last name"/>
                                            inputComponent={TextField}
                                            component={FormikAdapter}
                                            label=<FormattedMessage id="manager.site.contact.last-name.label" defaultMessage="Surname"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Field
                                            name={CONTACT_FIELDS.CONTACT_EMAIL_FIELD}
                                            type="text"
                                            fullWidth
                                            helperText=<FormattedMessage id="manager.site.contact.email.helper" defaultMessage="e-mail"/>
                                            inputComponent={TextField}
                                            component={FormikAdapter}
                                            label=<FormattedMessage id="manager.site.contact.email.label" defaultMessage="Email"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Field
                                            name={CONTACT_FIELDS.CONTACT_PHONE_FIELD}
                                            type="text"
                                            fullWidth
                                            helperText=<FormattedMessage id="manager.site.contact.phone.helper" defaultMessage="phone"/>
                                            inputComponent={TextField}
                                            component={FormikAdapter}
                                            label=<FormattedMessage id="manager.site.contact.phone.label" defaultMessage="Phone"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Field
                                            name={CONTACT_FIELDS.CONTACT_POSITION_FIELD}
                                            type="text"
                                            fullWidth
                                            helperText=<FormattedMessage id="manager.site.contact.position.helper" defaultMessage="position"/>
                                            inputComponent={TextField}
                                            component={FormikAdapter}
                                            label=<FormattedMessage id="manager.site.contact.position.label" defaultMessage="Role"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            name={CONTACT_FIELDS.CONTACT_DESCRIPTION_FIELD}
                                            type="text"
                                            fullWidth
                                            helperText=<FormattedMessage id="form.description.helper" defaultMessage="description"/>
                                            inputComponent={TextField}
                                            component={FormikAdapter}
                                            label=<FormattedMessage id="form.description.label" defaultMessage="Description"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={isSubmitting}>
                                    <FormattedMessage id="button.save" defaultMessage="Save"/>
                                </Button>
                            </DialogActions>
                        </form>
                    );
                }}
            </Formik>
        </Dialog>
    );
}