import React from 'react';
import Box from '@material-ui/core/Box';
import * as countryActions from '../actions/CountryActions'

import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import CountryForm from "../components/CountryForm";
import Countries from "../components/Countries";

function Country(props) {
    const {country} = props;
    const {listCountries, showCountryForm, createCountry, updateCountry, deleteCountry, viewCountry} = props.countryActions;
    return (
        <Box style={{height:'100%'}}>
            <Countries
                models={country.get('models')}
                showForm={showCountryForm}
                view={viewCountry}
                delete={deleteCountry}
                list={listCountries}
            />
            <CountryForm
                model={country.get('model')}
                show={showCountryForm}
                create={createCountry}
                update={updateCountry}
                open={country.get('showModal')}
            />
        </Box>
    );
}

function mapStateToProps(state) {
    return {
        country: state.country,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        countryActions: bindActionCreators(countryActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Country)

