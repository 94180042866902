import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {rstatApi} from "../../helpers/api";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    option: {
        fontSize: 15,
        '& > span': {
            margin: 20,
            fontSize: 18,
        },
    },
    formControl: {
        margin: 17
    }
});

export default function CountrySelect(props) {
    const [options, setOptions] = React.useState([]);
    const classes = useStyles();

    React.useEffect(() => {
        let active = true;

        (async () => {
            rstatApi.get('/' + props.entity).then(function (response) {
                if (active) {
                    setOptions(response.data.data.map((item) => item));
                }
            }).catch(function (error) {
                console.log(error)
            });

        })();

        return () => {
            active = false;
        };
    }, [props.entity]);

    const handleChange = (value) => {
        if (value !== null && value.id !== undefined) {
            props.setFieldValue(props.name, value.id)
        }
    };

    return (
        <FormControl variant="outlined" className={classes.formControl} error={props.error}>
            <Autocomplete
                id={props.name}
                name={props.name}
                style={{width:200}}
                options={options}
                value={options.find(v => v.id === props.value) || {}}
                onChange={(_, value) => handleChange(value)}
                onInputChange={(_, value) => handleChange(value)}
                autoHighlight
                getOptionLabel={(option) => option.name?option.name:""}
                renderOption={(props, option) => (
                    <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                        <img
                            loading="lazy"
                            width="20"
                            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                            alt="flag"
                        />
                        {option.name}
                    </Box>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={props.label}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                    />
                )}
            />
            <FormHelperText>{props.helperText}</FormHelperText>
        </FormControl>
    );
}