import React, {useState} from 'react'
import MaterialTable from 'material-table'
import {ENDPOINT, MANAGER} from "../../constants/Common";
import ComponentMenu from "../common/ComonentMenu";
import {FormattedMessage, useIntl} from "react-intl";

export default function DivisionTeams(props) {
    const [selectedRow, setValue] = useState(0);

    let data = [];
    props.models.forEach(n => data.push(n));

    function handleShowForm() {
        props.showForm(true, MANAGER)
    }

    const intl = useIntl()

    return (
        <MaterialTable
            columns={[
                {
                    title: 'ID',
                    field: 'id',
                    cellStyle: {
                        paddingLeft: 20,
                    },
                },
                {
                    field: 'url',
                    title: 'Avatar',
                    render: rowData => <img src={ENDPOINT+"teams/"+rowData.team.id+"/logo"} style={{width: 30, borderRadius: '50%'}} alt="logo"/>
                },
                {title: 'Название', field: 'team.name'},
                {title: 'Город', field: 'team.city.name'},
                {
                    title: 'Место',
                    field: 'place',
                    render: rowData => (rowData.place ? "# "+rowData.place : "-")
                },
                {
                    field: 'menu',
                    render: rowData => <ComponentMenu id={rowData.id}
                                                      delete={props.delete}
                                                      view={props.view}/>,
                    cellStyle: {
                        width: 10,
                        padding: 0,
                    },
                },
            ]}
            onRowClick={((evt, selectedRow) => {
                    if (evt.target.getAttribute("value")) {
                        setValue(selectedRow);
                        props.listDivisionPlayers('?filter=division_team_id:' + selectedRow.id, MANAGER)
                        props.listDivisionPersonals('?filter=division_team_id:' + selectedRow.id, MANAGER)
                    }
                }
            )}
            options={{
                rowStyle: rowData => ({
                    fontSize: 12,
                    backgroundColor: (selectedRow && selectedRow.tableData.id === rowData.tableData.id) ? '#EEE' : '#FFF'
                }),
                cellStyle: rowData => ({
                    padding:0,
                    paddingLeft:10,
                    margin:0,
                }),
                paging:false,
                maxBodyHeight:270,
                minBodyHeight: 270,
                header: false,
                showTitle: false
            }}
            localization={
                {
                    toolbar: {
                        searchPlaceholder: intl.formatMessage({id: 'manager.division-team-title',defaultMessage:"Division teams"}),
                        searchTooltip: <FormattedMessage id="common.find" defaultMessage="Find"/>
                    },
                    body: {
                        emptyDataSourceMessage: <FormattedMessage id="manager.no_data" defaultMessage="no data"/>
                    }
                }
            }
            actions={[
                {
                    icon: 'add',
                    disabled: props.addDisabled,
                    tooltip: <FormattedMessage id="manager.division-team-add" defaultMessage="add division team"/>,
                    isFreeAction: true,
                    onClick: (event) => handleShowForm()
                }
            ]}
            isLoading={props.loading}
            data={data}
        />
    )
}