export const MANAGER_PROTOCOL_LIST_REQUEST = 'MANAGER_PROTOCOL_LIST_REQUEST'
export const MANAGER_PROTOCOL_LIST_FAILURE = 'MANAGER_PROTOCOL_LIST_FAILURE'
export const MANAGER_PROTOCOL_LIST_SUCCESS = 'MANAGER_PROTOCOL_LIST_SUCCESS'
export const MANAGER_PROTOCOL_SHOW_MODAL = 'MANAGER_PROTOCOL_SHOW_MODAL'
export const MANAGER_PROTOCOL_CREATE_REQUEST = 'MANAGER_PROTOCOL_CREATE_REQUEST'
export const MANAGER_PROTOCOL_CREATE_SUCCESS = 'MANAGER_PROTOCOL_CREATE_SUCCESS'
export const MANAGER_PROTOCOL_CREATE_FAILURE = 'MANAGER_PROTOCOL_CREATE_FAILURE'
export const MANAGER_PROTOCOL_UPDATE_REQUEST = 'MANAGER_PROTOCOL_UPDATE_REQUEST'
export const MANAGER_PROTOCOL_UPDATE_SUCCESS = 'MANAGER_PROTOCOL_UPDATE_SUCCESS'
export const MANAGER_PROTOCOL_UPDATE_FAILURE = 'MANAGER_PROTOCOL_UPDATE_FAILURE'
export const MANAGER_PROTOCOL_DELETE_REQUEST = 'MANAGER_PROTOCOL_DELETE_REQUEST'
export const MANAGER_PROTOCOL_DELETE_SUCCESS = 'MANAGER_PROTOCOL_DELETE_SUCCESS'
export const MANAGER_PROTOCOL_DELETE_FAILURE = 'MANAGER_PROTOCOL_DELETE_FAILURE'
export const MANAGER_PROTOCOL_SET_ID = 'MANAGER_PROTOCOL_SET_ID'

export const CORE_PROTOCOL_LIST_REQUEST = 'CORE_PROTOCOL_LIST_REQUEST'
export const CORE_PROTOCOL_LIST_FAILURE = 'CORE_PROTOCOL_LIST_FAILURE'
export const CORE_PROTOCOL_LIST_SUCCESS = 'CORE_PROTOCOL_LIST_SUCCESS'
export const CORE_PROTOCOL_SHOW_FORM = 'CORE_PROTOCOL_SHOW_FORM'
export const CORE_PROTOCOL_CREATE_REQUEST = 'CORE_PROTOCOL_CREATE_REQUEST'
export const CORE_PROTOCOL_CREATE_SUCCESS = 'CORE_PROTOCOL_CREATE_SUCCESS'
export const CORE_PROTOCOL_CREATE_FAILURE = 'CORE_PROTOCOL_CREATE_FAILURE'
export const CORE_PROTOCOL_UPDATE_REQUEST = 'CORE_PROTOCOL_UPDATE_REQUEST'
export const CORE_PROTOCOL_UPDATE_SUCCESS = 'CORE_PROTOCOL_UPDATE_SUCCESS'
export const CORE_PROTOCOL_UPDATE_FAILURE = 'CORE_PROTOCOL_UPDATE_FAILURE'
export const CORE_PROTOCOL_DELETE_REQUEST = 'CORE_PROTOCOL_DELETE_REQUEST'
export const CORE_PROTOCOL_DELETE_SUCCESS = 'CORE_PROTOCOL_DELETE_SUCCESS'
export const CORE_PROTOCOL_DELETE_FAILURE = 'CORE_PROTOCOL_DELETE_FAILURE'
export const CORE_PROTOCOL_UPLOAD_SUCCESS = 'CORE_PROTOCOL_UPLOAD_SUCCESS'
export const CORE_PROTOCOL_UPLOAD_FAILURE = 'CORE_PROTOCOL_UPLOAD_FAILURE'
export const CORE_PROTOCOL_VIEW_SUCCESS = 'CORE_PROTOCOL_VIEW_SUCCESS'
export const CORE_PROTOCOL_SHOW_VIEW = 'CORE_PROTOCOL_SHOW_VIEW';
export const SIMPLE_PROTOCOL_SHOW_MODAL = 'SIMPLE_PROTOCOL_SHOW_MODAL';
export const SIMPLE_PROTOCOL_VIEW_SUCCESS = 'SIMPLE_PROTOCOL_VIEW_SUCCESS';

export const PROTOCOL_LIST_REQUEST = 'PROTOCOL_LIST_REQUEST'
export const PROTOCOL_LIST_FAILURE = 'PROTOCOL_LIST_FAILURE'
export const PROTOCOL_LIST_SUCCESS = 'PROTOCOL_LIST_SUCCESS'
export const PROTOCOL_SHOW_MODAL = 'PROTOCOL_SHOW_MODAL'
export const PROTOCOL_SHOW_FORM = 'PROTOCOL_SHOW_FORM'
export const PROTOCOL_CREATE_REQUEST = 'PROTOCOL_CREATE_REQUEST'
export const PROTOCOL_CREATE_SUCCESS = 'PROTOCOL_CREATE_SUCCESS'
export const PROTOCOL_CREATE_FAILURE = 'PROTOCOL_CREATE_FAILURE'
export const PROTOCOL_UPDATE_REQUEST = 'PROTOCOL_UPDATE_REQUEST'
export const PROTOCOL_UPDATE_SUCCESS = 'PROTOCOL_UPDATE_SUCCESS'
export const PROTOCOL_UPDATE_FAILURE = 'PROTOCOL_UPDATE_FAILURE'
export const PROTOCOL_DELETE_REQUEST = 'PROTOCOL_DELETE_REQUEST'
export const PROTOCOL_DELETE_SUCCESS = 'PROTOCOL_DELETE_SUCCESS'
export const PROTOCOL_DELETE_FAILURE = 'PROTOCOL_DELETE_FAILURE'
export const PROTOCOL_VIEW_SUCCESS = 'PROTOCOL_VIEW_SUCCESS'
export const PROTOCOL_TEAMS_VIEW_SUCCESS = 'PROTOCOL_TEAMS_VIEW_SUCCESS';
export const CORE_PROTOCOL_TEAMS_VIEW_SUCCESS = 'CORE_PROTOCOL_TEAMS_VIEW_SUCCESS';
export const PROTOCOL_TEAM_VIEW_FAILURE = 'PROTOCOL_TEAM_VIEW_FAILURE';
export const PROTOCOL_VIEW_FAILURE = 'PROTOCOL_VIEW_FAILURE'
export const PROTOCOL_SET_ID = 'PROTOCOL_SET_ID'
export const PROTOCOL_SHOW_VIEW = 'PROTOCOL_SHOW_VIEW'

export const PROTOCOL_SNACKBAR = 'PROTOCOL_SNACKBAR'

export const FOOTBALL_PROTOCOL_ROW = 25;
