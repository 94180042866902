import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {MANAGER} from "../../../constants/Common";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import {Field, Formik} from "formik";
import * as yup from 'yup'
import FormikAdapter from "../../common/FormikAdapter";
import SportTypeSelector from "../../form/SportTypeSelector";
import DataSelect from "../../form/DataSelect";
import {STATUS_ACTIVE, STATUS_LOCK, STATUS_HIDDEN} from "../../../constants/Competition";
import FormikSelectAdapter from "../../common/FormikSelectAdapter";
import CountrySelect from "../../form/CountrySelect";
import {FormattedMessage} from "react-intl";
import {FormHelperText} from "@mui/material";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const COMPETITION_FIELDS = {
    COMPETITION_ID_FIELD: 'id',
    COMPETITION_SEASON_ID_FIELD: 'seasonID',
    COMPETITION_COUNTRY_FIELD: 'countryID',
    COMPETITION_SPORT_TYPE_FIELD: 'sportType',
    COMPETITION_STATUS_FIELD: 'status',
    COMPETITION_NAME_FIELD: 'name',
    COMPETITION_FULL_NAME_FIELD: 'fullName',
    COMPETITION_DESCRIPTION_NAME_FIELD: 'description',
    COMPETITION_SORT_FIELD: 'sort',
};

export default function CompetitionForm(mainProps) {

    const handleClose = () => {
        mainProps.show(false, MANAGER)
    };
    let competitionStatus = [
        {key: STATUS_ACTIVE, value: "Активное"},
        {key: STATUS_HIDDEN, value: "Скрыто"},
        {key: STATUS_LOCK, value: "Закрыто"},
    ]
    return (
        <Dialog
            fullWidth={true}
            maxWidth={"md"}
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={mainProps.open}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                <FormattedMessage id="competition-form" defaultMessage="Competition form"/>
            </DialogTitle>
            <Formik
                initialValues={{
                    [COMPETITION_FIELDS.COMPETITION_ID_FIELD]: mainProps.model.id,
                    [COMPETITION_FIELDS.COMPETITION_SPORT_TYPE_FIELD]: mainProps.model.sportType,
                    [COMPETITION_FIELDS.COMPETITION_STATUS_FIELD]: mainProps.model.status,
                    [COMPETITION_FIELDS.COMPETITION_COUNTRY_FIELD]: mainProps.model.country.id,
                    [COMPETITION_FIELDS.COMPETITION_NAME_FIELD]: mainProps.model.name,
                    [COMPETITION_FIELDS.COMPETITION_FULL_NAME_FIELD]: mainProps.model.fullName,
                    [COMPETITION_FIELDS.COMPETITION_DESCRIPTION_NAME_FIELD]: mainProps.model.description,
                    [COMPETITION_FIELDS.COMPETITION_SORT_FIELD]: mainProps.model.sort,
                    [COMPETITION_FIELDS.COMPETITION_SEASON_ID_FIELD]: mainProps.seasonID,
                }}
                onSubmit={(values, actions) => {
                    let data = new FormData();

                    data.append("logo", values.file)
                    data.append("name", values.name)
                    data.append("fullName", values.fullName)
                    data.append("sportType", values.sportType)
                    data.append("status", values.status)
                    data.append("countryID", values.countryID)
                    data.append("description", values.description)
                    data.append("sort", values.sort)

                    if (values.id > 0) {
                        mainProps.update(values.id, data, MANAGER);
                    } else {
                        data.append("seasonID", values.seasonID)
                        mainProps.create(data, MANAGER);
                    }
                }}
                validationSchema={yup.object().shape({
                    [COMPETITION_FIELDS.COMPETITION_NAME_FIELD]: yup.string()
                        .required(<FormattedMessage id="form.required" defaultMessage="required field"/>),
                    [COMPETITION_FIELDS.COMPETITION_FULL_NAME_FIELD]: yup.string()
                        .required(<FormattedMessage id="form.required" defaultMessage="required field"/>),
                    [COMPETITION_FIELDS.COMPETITION_SPORT_TYPE_FIELD]: yup.string()
                        .required(<FormattedMessage id="form.required" defaultMessage="required field"/>),
                    [COMPETITION_FIELDS.COMPETITION_STATUS_FIELD]: yup.string()
                        .required(<FormattedMessage id="form.required" defaultMessage="required field"/>),
                    [COMPETITION_FIELDS.COMPETITION_COUNTRY_FIELD]: yup.number()
                        .required(<FormattedMessage id="form.required" defaultMessage="required field"/>),
                })}
            >
                {(props) => {
                    const {
                        isSubmitting,
                        handleSubmit,
                        handleChange,
                        setFieldValue,
                        values
                    } = props;

                    console.log(values)
                    return (
                        <form onSubmit={handleSubmit}>
                            <DialogContent dividers>
                                <Grid container spacing={3}>
                                    <Grid item xs={3}>
                                        <Field
                                            name={COMPETITION_FIELDS.COMPETITION_SPORT_TYPE_FIELD}
                                            type="text"
                                            helperText=<FormattedMessage id="manager.competition.form.sport-kind.helper" defaultMessage="sport kind"/>
                                            inputComponent={SportTypeSelector}
                                            component={FormikSelectAdapter}
                                            label=<FormattedMessage id="manager.competition.form.sport-kind.label" defaultMessage="Sport kind"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Field
                                            name={COMPETITION_FIELDS.COMPETITION_STATUS_FIELD}
                                            type="select"
                                            helperText=<FormattedMessage id="manager.competition.form.status.helper" defaultMessage="status"/>
                                            data={competitionStatus}
                                            onChange={handleChange}
                                            inputComponent={DataSelect}
                                            component={FormikSelectAdapter}
                                            label=<FormattedMessage id="manager.competition.form.status.label" defaultMessage="Status"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Field
                                            name={COMPETITION_FIELDS.COMPETITION_COUNTRY_FIELD}
                                            type="select"
                                            helperText=<FormattedMessage id="manager.competition.form.country.helper" defaultMessage="country"/>
                                            setFieldValue={setFieldValue}
                                            entity="countries"
                                            inputComponent={CountrySelect}
                                            component={FormikSelectAdapter}
                                            label=<FormattedMessage id="manager.competition.form.country.label" defaultMessage="Country"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button style={{marginTop:25}} variant="contained" component="label" helperText="Incorrect entry.">
                                            <FormattedMessage id="manager.competition.form.logo" defaultMessage="Logo"/>
                                            <input id="file" name="file" hidden accept="image/*" type="file" onChange={(event) => {
                                                setFieldValue("file", event.currentTarget.files[0]);
                                            }}/>
                                        </Button>
                                        <FormHelperText id="my-helper-text">300px * 300px</FormHelperText>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Field
                                            name={COMPETITION_FIELDS.COMPETITION_NAME_FIELD}
                                            type="text"
                                            fullWidth
                                            helperText=<FormattedMessage id="manager.competition.form.name.helper" defaultMessage="competition name"/>
                                            inputComponent={TextField}
                                            component={FormikAdapter}
                                            label=<FormattedMessage id="manager.competition.form.name.label" defaultMessage="Name"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Field
                                            name={COMPETITION_FIELDS.COMPETITION_FULL_NAME_FIELD}
                                            type="text"
                                            fullWidth
                                            helperText=<FormattedMessage id="manager.competition.form.full-name.helper" defaultMessage="full name"/>
                                            inputComponent={TextField}
                                            component={FormikAdapter}
                                            label=<FormattedMessage id="manager.competition.form.full-name.label" defaultMessage="Full name"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            name={COMPETITION_FIELDS.COMPETITION_DESCRIPTION_NAME_FIELD}
                                            type="text"
                                            fullWidth
                                            helperText=<FormattedMessage id="manager.competition.form.description.helper" defaultMessage="description"/>
                                            inputComponent={TextField}
                                            component={FormikAdapter}
                                            label=<FormattedMessage id="manager.competition.form.description.label" defaultMessage="Description"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            name={COMPETITION_FIELDS.COMPETITION_SORT_FIELD}
                                            type="number"
                                            helperText=<FormattedMessage id="manager.competition.form.sort.helper" defaultMessage="sorting"/>
                                            inputComponent={TextField}
                                            component={FormikAdapter}
                                            label=<FormattedMessage id="manager.competition.form.sort.label" defaultMessage="sort"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={isSubmitting}>
                                    <FormattedMessage id="button.save" defaultMessage="Save"/>
                                </Button>
                            </DialogActions>
                        </form>
                    );
                }}
            </Formik>
        </Dialog>
    );
}