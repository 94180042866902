import React, {Component} from 'react'
import Navigation from "./containers/Navigation";
import {Outlet} from 'react-router-dom';

class MainPage extends Component {
    render() {
        return (
            <div style={{height:'100%'}}>
                <Navigation />
                <div className='routerView' style={{padding:20, height:'100%'}}>
                    <Outlet />
                </div>
            </div>
        )
    }
}

export default MainPage;