import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import {Field, Formik} from "formik";
import * as yup from 'yup'
import {CORE} from "../constants/Common";
import FormikAdapter from "./common/FormikAdapter";
import {FormattedMessage} from "react-intl";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const PEOPLE_FIELDS = {
    PEOPLE_ID_FIELD: 'id',
    PEOPLE_NAME_FIELD: 'name',
    PEOPLE_SURNAME_FIELD: 'surname',
    PEOPLE_PATRONYMIC_FIELD: 'patronymic',
    PEOPLE_BIRTHDAY_FIELD: 'birthday',
    PEOPLE_WEIGHT_FIELD: 'weight',
    PEOPLE_HEIGHT_FIELD: 'height',
    PEOPLE_EMAIL_FIELD: 'email',
    PEOPLE_PHONE_FIELD: 'phone',
    PEOPLE_SORT_FIELD: 'sort',
};

export default function PeopleForm(props) {

    const handleClose = () => {
        props.show(false, CORE)
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"md"}
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={props.open}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                <FormattedMessage id="people-form" defaultMessage="People form"/>
            </DialogTitle>
            <Formik
                initialValues={{
                    [PEOPLE_FIELDS.PEOPLE_ID_FIELD]: props.model.id,
                    [PEOPLE_FIELDS.PEOPLE_NAME_FIELD]: props.model.name,
                    [PEOPLE_FIELDS.PEOPLE_SURNAME_FIELD]: props.model.surname,
                    [PEOPLE_FIELDS.PEOPLE_PATRONYMIC_FIELD]: props.model.patronymic,
                    [PEOPLE_FIELDS.PEOPLE_BIRTHDAY_FIELD]: props.model.birthday,
                    [PEOPLE_FIELDS.PEOPLE_WEIGHT_FIELD]: props.model.weight,
                    [PEOPLE_FIELDS.PEOPLE_HEIGHT_FIELD]: props.model.height,
                    [PEOPLE_FIELDS.PEOPLE_EMAIL_FIELD]: props.model.email,
                    [PEOPLE_FIELDS.PEOPLE_PHONE_FIELD]: props.model.phone,
                    [PEOPLE_FIELDS.PEOPLE_SORT_FIELD]: props.model.sort,
                }}
                onSubmit={(values, actions) => {
                    let data = new FormData();

                    data.append("photo", values.photo)
                    data.append("name", values.name)
                    data.append("surname", values.surname)
                    data.append("patronymic", values.patronymic)
                    data.append("birthday", values.birthday)
                    data.append("weight", values.weight)
                    data.append("height", values.height)
                    data.append("email", values.email)
                    data.append("phone", values.phone)
                    data.append("sort", values.sort)

                    if (values.id > 0) {
                        props.update(values.id, data, CORE);
                    } else {
                        props.create(data, CORE);
                    }
                }}
                validationSchema={yup.object().shape({
                    [PEOPLE_FIELDS.PEOPLE_NAME_FIELD]: yup.string()
                        .required(<FormattedMessage id="form.required" defaultMessage="required field"/>),
                    [PEOPLE_FIELDS.PEOPLE_SURNAME_FIELD]: yup.string()
                        .required(<FormattedMessage id="form.required" defaultMessage="required field"/>),
                    [PEOPLE_FIELDS.PEOPLE_BIRTHDAY_FIELD]: yup.string()
                        .required(<FormattedMessage id="form.required" defaultMessage="required field"/>),
                    [PEOPLE_FIELDS.PEOPLE_EMAIL_FIELD]: yup.string()
                        .email(<FormattedMessage id="form.invalid-email" defaultMessage="invalid email"/>),
                    [PEOPLE_FIELDS.PEOPLE_WEIGHT_FIELD]: yup.number()
                        .min(20, <FormattedMessage id="form.weight-low" defaultMessage="too light weight"/>),
                    [PEOPLE_FIELDS.PEOPLE_HEIGHT_FIELD]: yup.number()
                        .min(100, <FormattedMessage id="form.height-low" defaultMessage="too low"/>),
                })}
            >
                {(props) => {
                    const {
                        setFieldValue,
                        isSubmitting,
                        handleSubmit,
                    } = props;
                    return (
                        <form onSubmit={handleSubmit}>
                            <DialogContent dividers>
                                <Grid container spacing={3}>
                                    <Grid item xs={3}>
                                        <Field
                                            name={PEOPLE_FIELDS.PEOPLE_NAME_FIELD}
                                            type="text"
                                            helperText=<FormattedMessage id="people.form.name.helper" defaultMessage="people name"/>
                                            inputComponent={TextField}
                                            component={FormikAdapter}
                                            label=<FormattedMessage id="people.form.name.label" defaultMessage="First Name"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Field
                                            name={PEOPLE_FIELDS.PEOPLE_SURNAME_FIELD}
                                            type="text"
                                            helperText=<FormattedMessage id="people.form.last-name.helper" defaultMessage="people last family"/>
                                            inputComponent={TextField}
                                            component={FormikAdapter}
                                            label=<FormattedMessage id="people.form.last-name.label" defaultMessage="Last Name"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Field
                                            name={PEOPLE_FIELDS.PEOPLE_PATRONYMIC_FIELD}
                                            type="text"
                                            helperText=<FormattedMessage id="people.form.patronymic.helper" defaultMessage="people patronymic"/>
                                            inputComponent={TextField}
                                            component={FormikAdapter}
                                            label=<FormattedMessage id="people.form.patronymic.label" defaultMessage="Patronymic"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Field
                                            name={PEOPLE_FIELDS.PEOPLE_BIRTHDAY_FIELD}
                                            type="date"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            helperText=<FormattedMessage id="people.form.birthday.helper" defaultMessage="birthday"/>
                                            inputComponent={TextField}
                                            component={FormikAdapter}
                                            label=<FormattedMessage id="people.form.birthday.label" defaultMessage="Birthday"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Field
                                            name={PEOPLE_FIELDS.PEOPLE_HEIGHT_FIELD}
                                            type="number"
                                            helperText=<FormattedMessage id="people.form.height.helper" defaultMessage="people height"/>
                                            inputComponent={TextField}
                                            component={FormikAdapter}
                                            label=<FormattedMessage id="people.form.height.label" defaultMessage="Height"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Field
                                            name={PEOPLE_FIELDS.PEOPLE_WEIGHT_FIELD}
                                            type="number"
                                            helperText=<FormattedMessage id="people.form.weight.helper" defaultMessage="weight"/>
                                            inputComponent={TextField}
                                            component={FormikAdapter}
                                            label=<FormattedMessage id="people.form.weight.label" defaultMessage="Weight"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Field
                                            name={PEOPLE_FIELDS.PEOPLE_EMAIL_FIELD}
                                            type="text"
                                            helperText=<FormattedMessage id="people.form.email.helper" defaultMessage="e-mail"/>
                                            inputComponent={TextField}
                                            component={FormikAdapter}
                                            label="Email"
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Field
                                            name={PEOPLE_FIELDS.PEOPLE_PHONE_FIELD}
                                            type="text"
                                            helperText=<FormattedMessage id="people.form.phone.helper" defaultMessage="phone"/>
                                            inputComponent={TextField}
                                            component={FormikAdapter}
                                            label=<FormattedMessage id="people.form.phone.label" defaultMessage="Phone"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Grid item xs={4}>
                                            <Button variant="contained" component="label">
                                                <FormattedMessage id="form.photo.label" defaultMessage="photo"/>
                                                <input id="photo" name="photo" hidden accept="image/*" multiple type="file"
                                                       onChange={(event) => {
                                                           setFieldValue("photo", event.currentTarget.files[0]);
                                                       }}/>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={isSubmitting}>
                                    <FormattedMessage id="button.save" defaultMessage="Save"/>
                                </Button>
                            </DialogActions>
                        </form>
                    );
                }}
            </Formik>
        </Dialog>
    );
}