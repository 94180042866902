import {MANAGER} from '../constants/Common';
import {
    COMPETITION_LIST_REQUEST,
    COMPETITION_SHOW_FORM,
} from "../constants/Competition";
import {makeDispatch, rstatApi} from "../helpers/api";
import {COMPETITION, CREATE, DELETE, LIST, UPDATE, VIEW} from "../constants/Global";
import {MANAGER_SET_SPORT_TYPE} from "../constants/Manager";

export function showCompetitionForm(open, type) {
    return (dispatch) => {
        dispatch({
            type: type + COMPETITION_SHOW_FORM,
            payload: open
        })
    }
}

export function setSportType(sportType) {
    return (dispatch) => {
        dispatch({
            type: MANAGER_SET_SPORT_TYPE,
            payload: sportType
        })
    }
}

export function listCompetitions(query = '', type) {
    let seasonID = 0;
    let result = query.match(/season_id:(\d+)/i);
    if (result !== null) {
        seasonID = parseInt(result[1], 10)
    }
    return (dispatch) => {
        dispatch({
            type: type + COMPETITION_LIST_REQUEST,
            payload: seasonID
        });
        rstatApi.get('/competitions' + query).then(function (response) {
            dispatch(makeDispatch(MANAGER, LIST, COMPETITION, response));
        }).catch(function (error) {
            dispatch(makeDispatch(MANAGER, LIST, COMPETITION, error.response));
        });
    }
}

export function updateCompetition(id, body, type) {
    return (dispatch) => {
        rstatApi.put('/competitions/' + id, body, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }).then(function (response) {
            dispatch(makeDispatch(MANAGER, UPDATE, COMPETITION, response));
        }).catch(function (error) {
            dispatch(makeDispatch(MANAGER, UPDATE, COMPETITION, error.response));
        });
    }
}

export function createCompetition(body, type) {
    return (dispatch) => {
        rstatApi.post('/competitions', body, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }).then(function (response) {
            dispatch(makeDispatch(MANAGER, CREATE, COMPETITION, response));
        }).catch(function (error) {
            console.log(error.response)
            dispatch(makeDispatch(MANAGER, CREATE, COMPETITION, error.response));
        });
    }
}

export function deleteCompetition(id, type) {
    return (dispatch) => {
        rstatApi.delete('/competitions/' + id,).then(function (response) {
            dispatch(makeDispatch(MANAGER, DELETE, COMPETITION, response, parseInt(id, 10)));
        }).catch(function (error) {
            dispatch(makeDispatch(MANAGER, DELETE, COMPETITION, error.response));
        })
    }
}


export function viewCompetition(id, type) {
    return (dispatch) => {
        rstatApi.get('/competitions/' + id).then(function (response) {
            dispatch(makeDispatch(MANAGER, VIEW, COMPETITION, response));
        }).catch(function (error) {
            dispatch(makeDispatch(MANAGER, VIEW, COMPETITION, error.response));
        });
    }
}

