import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import {MANAGER} from "../../constants/Common";
import {FormattedMessage} from "react-intl";

const ITEM_HEIGHT = 48;

export default function ComponentMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleUpdate = () => {
        setAnchorEl(null);
        props.view(props.id, MANAGER)
    };

    const handleDelete = () => {
        setAnchorEl(null);
        props.delete(props.id, MANAGER)
    };

    return (
        <div>
            <IconButton
                aria-label="more"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon/>
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '20ch',
                    },
                }}
            >
                <MenuItem key={1} onClick={handleUpdate}>
                    <ListItemIcon>
                        <EditIcon fontSize="small"/>
                    </ListItemIcon>
                    <FormattedMessage id="common.edit" defaultMessage="Edit"/>
                </MenuItem>
                <MenuItem key={2} onClick={handleDelete}>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small"/>
                    </ListItemIcon>
                    <FormattedMessage id="common.delete" defaultMessage="Delete"/>
                </MenuItem>
                <MenuItem key={3} onClick={handleClose}>
                    <ListItemIcon>
                        <CloseIcon fontSize="small"/>
                    </ListItemIcon>
                    <FormattedMessage id="common.close" defaultMessage="Close"/>
                </MenuItem>
            </Menu>
        </div>
    );
}