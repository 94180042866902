import {
    CONTACT_SHOW_FORM, CONTACT_SET_ID,
} from '../../constants/Site';
import {MANAGER} from '../../constants/Common';
import {makeSiteDispatch, siteApi} from "../../helpers/api";
import {CONTACT, CREATE, DELETE, LIST, UPDATE, VIEW} from "../../constants/Global";

export function showContactForm(open, type) {

    return (dispatch) => {
        dispatch({
            type: type + CONTACT_SHOW_FORM,
            payload: open
        })

    }
}

export function setContactID(id, type) {

    return (dispatch) => {
        dispatch({
            type: type + CONTACT_SET_ID,
            payload: id
        })

    }
}

export function viewContact(id, type) {
    return (dispatch) => {
        siteApi.get('contacts/' + id).then(function (response) {
            dispatch(makeSiteDispatch(MANAGER, VIEW, CONTACT, response));
        }).catch(function (error) {
            console.log(error)
            dispatch(makeSiteDispatch(MANAGER, VIEW, CONTACT, error.response));
        });
    }
}

export function listContacts(id, type) {
    return (dispatch) => {
        siteApi.get('contacts/list/' + id).then(function (response) {
            dispatch(makeSiteDispatch(type, LIST, CONTACT, response));
        }).catch(function (error) {
            dispatch(makeSiteDispatch(type, LIST, CONTACT, error))
        });
    }
}

export function deleteContact(id, type) {
    return (dispatch) => {
        siteApi.delete('contacts/' + id).then(function (response) {
            dispatch(makeSiteDispatch(MANAGER, DELETE, CONTACT, response,parseInt(id, 10)));
        }).catch(function (error) {
            dispatch(makeSiteDispatch(MANAGER, DELETE, CONTACT, error.response));
        });
    }
}

export function createContact(body, type) {
    return (dispatch) => {
        siteApi.post('contacts', body).then(function (response) {
            dispatch(makeSiteDispatch(MANAGER, CREATE, CONTACT, response));
        }).catch(function (error) {
            dispatch(makeSiteDispatch(MANAGER, CREATE, CONTACT, error.response));
        });
    }
}

export function updateContact(id, body, type) {
    return (dispatch) => {
        siteApi.put('contacts/' + id, body).then(function (response) {
            dispatch(makeSiteDispatch(MANAGER, UPDATE, CONTACT, response));
        }).catch(function (error) {
            dispatch(makeSiteDispatch(MANAGER, UPDATE, CONTACT, error.response));
        });
    }
}
