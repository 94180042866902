export const MANAGER_TOUR_LIST_REQUEST = 'MANAGER_TOUR_LIST_REQUEST';
export const MANAGER_TOUR_LIST_FAILURE = 'MANAGER_TOUR_LIST_FAILURE'
export const MANAGER_TOUR_LIST_SUCCESS = 'MANAGER_TOUR_LIST_SUCCESS'
export const MANAGER_TOUR_SHOW_FORM = 'MANAGER_TOUR_SHOW_FORM';
export const MANAGER_TOUR_CREATE_SUCCESS = 'MANAGER_TOUR_CREATE_SUCCESS'
export const MANAGER_TOUR_CREATE_FAILURE = 'MANAGER_TOUR_CREATE_FAILURE'
export const MANAGER_TOUR_UPDATE_SUCCESS = 'MANAGER_TOUR_UPDATE_SUCCESS'
export const MANAGER_TOUR_UPDATE_FAILURE = 'MANAGER_TOUR_UPDATE_FAILURE'
export const MANAGER_TOUR_DELETE_SUCCESS = 'MANAGER_TOUR_DELETE_SUCCESS'
export const MANAGER_TOUR_DELETE_FAILURE = 'MANAGER_TOUR_DELETE_FAILURE'
export const MANAGER_TOUR_VIEW_SUCCESS = 'MANAGER_TOUR_VIEW_SUCCESS'

export const CORE_TOUR_LIST_REQUEST = 'CORE_TOUR_LIST_REQUEST'
export const CORE_TOUR_LIST_FAILURE = 'CORE_TOUR_LIST_FAILURE'
export const CORE_TOUR_LIST_SUCCESS = 'CORE_TOUR_LIST_SUCCESS'
export const CORE_TOUR_SHOW_MODAL = 'CORE_TOUR_SHOW_MODAL'
export const CORE_TOUR_CREATE_SUCCESS = 'CORE_TOUR_CREATE_SUCCESS'
export const CORE_TOUR_DELETE_SUCCESS = 'CORE_TOUR_DELETE_SUCCESS'
export const CORE_TOUR_VIEW_SUCCESS = 'CORE_TOUR_VIEW_SUCCESS'
export const CORE_TOUR_VIEW_FAILURE = 'CORE_TOUR_VIEW_FAILURE'

export const TOUR_LIST_REQUEST = 'TOUR_LIST_REQUEST'
export const TOUR_LIST_FAILURE = 'TOUR_LIST_FAILURE'
export const TOUR_LIST_SUCCESS = 'TOUR_LIST_SUCCESS'
export const TOUR_SHOW_FORM = 'TOUR_SHOW_FORM';
export const TOUR_CREATE_SUCCESS = 'TOUR_CREATE_SUCCESS'
export const TOUR_CREATE_FAILURE = 'TOUR_CREATE_FAILURE'
export const TOUR_UPDATE_SUCCESS = 'TOUR_UPDATE_SUCCESS'
export const TOUR_UPDATE_FAILURE = 'TOUR_UPDATE_FAILURE'
export const TOUR_DELETE_SUCCESS = 'TOUR_DELETE_SUCCESS'
export const TOUR_DELETE_FAILURE = 'TOUR_DELETE_FAILURE'
export const TOUR_VIEW_SUCCESS = 'TOUR_VIEW_SUCCESS'
export const TOUR_VIEW_FAILURE = 'TOUR_VIEW_FAILURE'
