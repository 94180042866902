import {makeDispatch, makeDispatch500, rstatApi} from '../helpers/api'
import {CORE} from "../constants/Common";
import {PROFILE, REGISTER, SIGNIN, UPDATE, USER, VIEW} from "../constants/Global";
import {
    PROFILE_REGISTER_FAILURE,
    USER_REGISTER_FAILURE,
    USER_SIGNIN_FAILURE,
    USER_UPDATE_FAILURE,
    USER_VIEW_FAILURE
} from "../constants/User";

export function viewUser(id, type) {
    return (dispatch) => {
        rstatApi.get('/users/' + id).then(function (response) {
            dispatch(makeDispatch(CORE, VIEW, USER, response));
        }).catch(function (error) {
            dispatch(makeDispatch500(type, USER_VIEW_FAILURE, error))
        });
    }
}

export function updateUser(id, body, type) {
    return (dispatch) => {
        rstatApi.put('/users/' + id, body).then(function (response) {
            dispatch(makeDispatch(type, UPDATE, USER, response));
        }).catch(function (error) {
            dispatch(makeDispatch500(type, USER_UPDATE_FAILURE, error))
        });
    }
}

export function signIn(body, type) {
    return (dispatch) => {
        rstatApi.post('/users/login', body).then(function (response) {
            dispatch(makeDispatch(type, SIGNIN, USER, response));
        }).catch(function (error) {
            dispatch(makeDispatch500(type, USER_SIGNIN_FAILURE, error))
        });
    }
}

export function register(body, type) {
    return (dispatch) => {
        rstatApi.post('/users/register', body).then(function (response) {
            dispatch(makeDispatch(type, REGISTER, USER, response));
        }).catch(function (error) {
            dispatch(makeDispatch500(type, USER_REGISTER_FAILURE, error))
        });
    }
}

export function profile(type) {
    return (dispatch) => {
        rstatApi.get('/profile').then(function (response) {
            dispatch(makeDispatch(type, VIEW, PROFILE, response));
        }).catch(function (error) {
            dispatch(makeDispatch500(type, PROFILE_REGISTER_FAILURE, error))
        });
    }
}