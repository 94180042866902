
export const MANAGER_CITY_LIST_REQUEST = 'MANAGER_CITY_LIST_REQUEST'
export const MANAGER_CITY_LIST_FAILURE = 'MANAGER_CITY_LIST_FAILURE'
export const MANAGER_CITY_LIST_SUCCESS = 'MANAGER_CITY_LIST_SUCCESS'
export const MANAGER_CITY_SHOW_FORM = 'MANAGER_CITY_SHOW_MODAL'
export const MANAGER_CITY_CREATE_REQUEST = 'MANAGER_CITY_CREATE_REQUEST'
export const MANAGER_CITY_CREATE_SUCCESS = 'MANAGER_CITY_CREATE_SUCCESS'
export const MANAGER_CITY_CREATE_FAILURE = 'MANAGER_CITY_CREATE_FAILURE'
export const MANAGER_CITY_UPDATE_REQUEST = 'MANAGER_CITY_UPDATE_REQUEST'
export const MANAGER_CITY_UPDATE_SUCCESS = 'MANAGER_CITY_UPDATE_SUCCESS'
export const MANAGER_CITY_UPDATE_FAILURE = 'MANAGER_CITY_UPDATE_FAILURE'
export const MANAGER_CITY_DELETE_REQUEST = 'MANAGER_CITY_DELETE_REQUEST'
export const MANAGER_CITY_DELETE_SUCCESS = 'MANAGER_CITY_DELETE_SUCCESS'
export const MANAGER_CITY_DELETE_FAILURE = 'MANAGER_CITY_DELETE_FAILURE'
export const MANAGER_CITY_SET_ID = 'MANAGER_CITY_SET_ID'
export const MANAGER_CITY_SELECT = 'MANAGER_CITY_SELECT'

export const CORE_CITY_LIST_REQUEST = 'CORE_CITY_LIST_REQUEST'
export const CORE_CITY_LIST_FAILURE = 'CORE_CITY_LIST_FAILURE'
export const CORE_CITY_LIST_SUCCESS = 'CORE_CITY_LIST_SUCCESS'
export const CORE_CITY_SHOW_FORM = 'CORE_CITY_SHOW_FORM'
export const CORE_CITY_CREATE_REQUEST = 'CORE_CITY_CREATE_REQUEST'
export const CORE_CITY_CREATE_SUCCESS = 'CORE_CITY_CREATE_SUCCESS'
export const CORE_CITY_CREATE_FAILURE = 'CORE_CITY_CREATE_FAILURE'
export const CORE_CITY_UPDATE_REQUEST = 'CORE_CITY_UPDATE_REQUEST'
export const CORE_CITY_UPDATE_SUCCESS = 'CORE_CITY_UPDATE_SUCCESS'
export const CORE_CITY_UPDATE_FAILURE = 'CORE_CITY_UPDATE_FAILURE'
export const CORE_CITY_DELETE_REQUEST = 'CORE_CITY_DELETE_REQUEST'
export const CORE_CITY_DELETE_SUCCESS = 'CORE_CITY_DELETE_SUCCESS'
export const CORE_CITY_DELETE_FAILURE = 'CORE_CITY_DELETE_FAILURE'
export const CORE_CITY_SET_ID = 'CORE_CITY_SET_ID'
export const CORE_CITY_VIEW_SUCCESS = 'CORE_CITY_VIEW_SUCCESS'
export const CORE_CITY_VIEW_FAILURE = 'CORE_CITY_VIEW_FAILURE'

export const CITY_LIST_REQUEST = 'CITY_LIST_REQUEST'
export const CITY_LIST_FAILURE = 'CITY_LIST_FAILURE'
export const CITY_LIST_SUCCESS = 'CITY_LIST_SUCCESS'
export const CITY_SHOW_FORM = 'CITY_SHOW_FORM'
export const CITY_CREATE_REQUEST = 'CITY_CREATE_REQUEST'
export const CITY_CREATE_SUCCESS = 'CITY_CREATE_SUCCESS'
export const CITY_CREATE_FAILURE = 'CITY_CREATE_FAILURE'
export const CITY_UPDATE_REQUEST = 'CITY_UPDATE_REQUEST'
export const CITY_UPDATE_SUCCESS = 'CITY_UPDATE_SUCCESS'
export const CITY_UPDATE_FAILURE = 'CITY_UPDATE_FAILURE'
export const CITY_DELETE_REQUEST = 'CITY_DELETE_REQUEST'
export const CITY_DELETE_SUCCESS = 'CITY_DELETE_SUCCESS'
export const CITY_DELETE_FAILURE = 'CITY_DELETE_FAILURE'
export const CITY_VIEW_SUCCESS = 'CITY_VIEW_SUCCESS'
export const CITY_VIEW_FAILURE = 'CITY_VIEW_FAILURE'
export const CITY_SET_ID = 'CITY_SET_ID'