import React, {useEffect, useState} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import {ENDPOINT, MANAGER} from "../../constants/Common";
import {rstatApi} from "../../helpers/api";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {FormattedMessage} from "react-intl";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function StageStat(props) {
    const handleClose = () => {
        props.show(0, MANAGER)
    };

    const [rows, setRows] = useState([]);

    useEffect(() => {
        (async () => {
            rstatApi.get("/stages/"+props.id+"/table").then(function (response) {
                setRows(response.data.data.map((item) => item));
            }).catch(function (error) {
                // TODO process error.
                console.log(error)
            });

        })();

    }, [props.id]);

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"md"}
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={props.id > 0}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                <FormattedMessage id="stage-stat" defaultMessage="Stage statistic"/>
            </DialogTitle>
            <DialogContent>
                <h3>Таблица</h3>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell align="left"><FormattedMessage id="table-team" defaultMessage="Team"/></TableCell>
                                <TableCell align="center"><FormattedMessage id="table-win" defaultMessage="Wins"/></TableCell>
                                <TableCell align="center"><FormattedMessage id="table-draw" defaultMessage="Draw"/></TableCell>
                                <TableCell align="center"><FormattedMessage id="table-loss" defaultMessage="Loss"/></TableCell>
                                <TableCell align="center"><FormattedMessage id="table-diff" defaultMessage="Diff"/></TableCell>
                                <TableCell align="center"><FormattedMessage id="table-score" defaultMessage="Score"/></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow
                                    key={row.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        <img src={ENDPOINT+"teams/"+row.stageTeam.divisionTeam.team.id+"/logo"} style={{width: 30, borderRadius: '50%'}} alt="logo"/>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.stageTeam.divisionTeam.team.name}
                                    </TableCell>
                                    <TableCell align="center">{row.win}</TableCell>
                                    <TableCell align="center">{row.draw}</TableCell>
                                    <TableCell align="center">{row.loss}</TableCell>
                                    <TableCell align="center"><span>{row.goal} - {row.missed}</span></TableCell>
                                    <TableCell align="center"><strong>{row.score}</strong></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}><FormattedMessage id="button-close" defaultMessage="Close"/></Button>
            </DialogActions>
        </Dialog>
    );
}