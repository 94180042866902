import React, {useState} from 'react';
import Box from '@material-ui/core/Box';
import * as protocolActions from '../../actions/ProtocolActions'

import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import ProtocolView from "../../components/view/ProtocolView";
import {Avatar, Chip, ListItem, ListItemAvatar, ListItemText} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import Button from "@material-ui/core/Button";
import {ENDPOINT, MANAGER} from "../../constants/Common";
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import WatchIcon from '@material-ui/icons/Watch';
import { useParams } from "react-router-dom";
import {FormattedMessage} from "react-intl";

function MatchView(props) {
    const {protocol} = props;
    const {viewProtocol, updateProtocol} = props.protocolActions;
    const PROTOCOL="PROTOCOL"

    let params = useParams();

    let model = protocol.get('model')

    const [finished, setProtocolFinished] = useState(model.statStatus===PROTOCOL);

    const switchHandler = (event) => {
        setProtocolFinished(event.target.checked);
    };

    let handleSave = () => {
        let serialize = require('form-serialize');
        let form = document.querySelector('#protocol-form');
        let body = serialize(form, { hash: true, empty: false });

        if (finished=== true){
            body.statStatus=PROTOCOL
        }

        updateProtocol(model.id, body, MANAGER)
    }

    return (
        <Box style={{height: '100%', marginTop: 20}}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" href="/">
                    R-STAT
                </Link>
                <Link color="inherit" href="/matches">
                    <FormattedMessage id="matches" defaultMessage="Matches"/>
                </Link>
                <Typography color="textPrimary">{model.id}</Typography>
            </Breadcrumbs>
            <br/>
            <form id="protocol-form" method="post">
                <Paper elevation={5} style={{width: '100%',padding:10, marginBottom:10}}>
                <Grid container spacing={3}>
                    <Grid item xs={4}>
                        <Chip
                            avatar={<Avatar alt="sport " src={ENDPOINT+"teams/"+model.stageTeamHome.divisionTeam.team.id+"/logo"} />}
                            label={model.stageTeamHome.divisionTeam.team.name}
                            variant="outlined"
                        />
                        &nbsp;
                        &nbsp;
                        <strong>{model.goalHome} : {model.goalAway}</strong>
                        &nbsp;
                        &nbsp;
                        <Chip
                            avatar={<Avatar alt="Natacha" src={ENDPOINT+"teams/"+model.stageTeamAway.divisionTeam.team.id+"/logo"} />}
                            label={model.stageTeamAway.divisionTeam.team.name}
                            variant="outlined"/>
                    </Grid>
                    <Grid item xs={8} style={{textAlign:"right"}}>
                        <Button disabled variant="contained">
                            <FormattedMessage id="button.fill" defaultMessage="Fill"/>
                        </Button>
                        &nbsp;
                        &nbsp;
                        <Button  color={finished?"secondary":""} variant="contained" onClick={handleSave}>
                            <FormattedMessage id="button.save" defaultMessage="Save"/>
                        </Button>
                    </Grid>
                </Grid>
                </Paper>
                <Paper elevation={5} style={{width: '100%', padding: 20}}>
                    <ProtocolView
                        id={params.matchID}
                        finished={finished}
                        setProtocolFinished={switchHandler}
                        model={model}
                        reasons={protocol.get('reasons')}
                        view={viewProtocol}
                        sportType={model.stage.division.competition.sportType}
                    />
                </Paper>
            </form>
        </Box>
    );
}

function mapStateToProps(state) {
    return {
        protocol: state.protocol,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        protocolActions: bindActionCreators(protocolActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MatchView)

