import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {MANAGER} from "../../../../constants/Common";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import {Field, Formik} from "formik";
import * as yup from 'yup'
import FormikAdapter from "../../../common/FormikAdapter";
import {FormattedMessage} from "react-intl";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const DOC_FIELDS = {
    DOC_ID_FIELD: 'id',
    DOC_TITLE_FIELD: 'title',
    DOC_DOWNLOAD_NAME_FIELD: 'downloadName',
    DOC_DESCRIPTION_FIELD: 'description',
    DOC_COMPETITION_ID_FIELD: 'competitionID',
    DOC_SORT_FIELD: 'sort',
};

export default function DocForm(props) {
    const handleClose = () => {
        props.show(false, MANAGER)
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"md"}
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={props.open}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                <FormattedMessage id="document-form" defaultMessage="Document form"/>
            </DialogTitle>
            <Formik
                initialValues={{
                    [DOC_FIELDS.DOC_ID_FIELD]: props.model.id,
                    [DOC_FIELDS.DOC_COMPETITION_ID_FIELD]: props.model.competitionID,
                    [DOC_FIELDS.DOC_TITLE_FIELD]: props.model.title,
                    [DOC_FIELDS.DOC_DOWNLOAD_NAME_FIELD]: props.model.downloadName,
                    [DOC_FIELDS.DOC_DESCRIPTION_FIELD]: props.model.description,
                    [DOC_FIELDS.DOC_SORT_FIELD]: props.model.sort,
                }}
                onSubmit={(values, actions) => {
                    let data = new FormData();

                    data.append("competitionID", props.competitionID);
                    data.append("file", values.file)
                    data.append("title", values.title)
                    data.append("downloadName", values.downloadName)
                    data.append("description", values.description)
                    data.append("sort", values.sort)

                    if (values.id > 0) {
                        props.update(values.id, data, MANAGER);
                    } else {
                        props.create(data, MANAGER);
                    }
                }}
                validationSchema={yup.object().shape({
                    [DOC_FIELDS.DOC_TITLE_FIELD]: yup.string()
                        .required(<FormattedMessage id="form.required" defaultMessage="required field"/>),
                    [DOC_FIELDS.DOC_DOWNLOAD_NAME_FIELD]: yup.string()
                        .required(<FormattedMessage id="form.required" defaultMessage="required field"/>),
                })}
            >
                {(props) => {
                    const {
                        errors,
                        setFieldValue,
                        isSubmitting,
                        handleSubmit,
                    } = props;
                    return (
                        <form onSubmit={handleSubmit}>
                            <DialogContent dividers>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Button
                                            variant="outlined"
                                            component="label"
                                        >
                                            Файл
                                            <input hidden id="import" name="file" type="file" onChange={(event) => {
                                                setFieldValue("file", event.currentTarget.files[0]);
                                            }} className="form-control"/>
                                        </Button>
                                        <div>{errors.import}</div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            name={DOC_FIELDS.DOC_TITLE_FIELD}
                                            type="text"
                                            fullWidth
                                            helperText=<FormattedMessage id="manager.site.document.title.helper" defaultMessage="doc title"/>
                                            inputComponent={TextField}
                                            component={FormikAdapter}
                                            label=<FormattedMessage id="manager.site.document.title.label" defaultMessage="Title"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            name={DOC_FIELDS.DOC_DOWNLOAD_NAME_FIELD}
                                            type="text"
                                            fullWidth
                                            helperText=<FormattedMessage id="manager.site.document.download-name.helper" defaultMessage="downloaded file name"/>
                                            inputComponent={TextField}
                                            component={FormikAdapter}
                                            label=<FormattedMessage id="manager.site.document.download-name.label" defaultMessage="File name"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            name={DOC_FIELDS.DOC_DESCRIPTION_FIELD}
                                            type="text"
                                            fullWidth
                                            helperText=<FormattedMessage id="form.description.helper" defaultMessage="description"/>
                                            inputComponent={TextField}
                                            component={FormikAdapter}
                                            label=<FormattedMessage id="form.description.label" defaultMessage="Description"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={isSubmitting}>
                                    <FormattedMessage id="button.save" defaultMessage="Save"/>
                                </Button>
                            </DialogActions>
                        </form>
                    );
                }}
            </Formik>
        </Dialog>
    );
}