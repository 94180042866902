import {
    DIVISION_LIST_REQUEST,
    DIVISION_LIST_FAILURE,
    DIVISION_SHOW_FORM,
    DIVISION_CREATE_FAILURE,
    DIVISION_DELETE_FAILURE, DIVISION_VIEW_FAILURE, DIVISION_UPDATE_FAILURE
} from '../constants/Division';

import {rstatApi, makeDispatch, makeDispatch500} from '../helpers/api'
import {MANAGER} from "../constants/Common";
import {CREATE, DELETE, LIST, DIVISION, UPDATE, VIEW, STAGE} from "../constants/Global";

export function showDivisionForm(open, type) {
    return (dispatch) => {
        dispatch({
            type: type + DIVISION_SHOW_FORM,
            payload: open
        })
    }
}

export function viewDivision(id, type) {
    return (dispatch) => {
        rstatApi.get('/divisions/' + id).then(function (response) {
            dispatch(makeDispatch(MANAGER, VIEW, DIVISION, response));
        }).catch(function (error) {
            dispatch(makeDispatch(MANAGER, VIEW, DIVISION, error.response));
        });
    }
}

export function updateDivision(id, body, type) {
    return (dispatch) => {
        rstatApi.put('/divisions/' + id, body).then(function (response) {
            dispatch(makeDispatch(MANAGER, UPDATE, DIVISION, response));
        }).catch(function (error) {
            dispatch(makeDispatch(MANAGER, UPDATE, DIVISION, error.response));
        });
    }
}

export function listDivisions(query = '', type) {
    let competitionID = 0;
    let result = query.match(/competition_id:(\d+)/i);
    if (result !== null) {
        competitionID = parseInt(result[1], 10)
    }
    return (dispatch) => {
        dispatch({
            type: type + DIVISION_LIST_REQUEST,
            payload: competitionID,
        });
        rstatApi.get('/divisions' + query).then(function (response) {
            dispatch(makeDispatch(MANAGER, LIST, DIVISION, response));
        }).catch(function (error) {
            dispatch(makeDispatch(MANAGER, LIST, DIVISION, error.response));
        });
    }
}

export function createDivision(body, type) {
    return (dispatch) => {
        rstatApi.post('/divisions', body).then(function (response) {
            dispatch(makeDispatch(MANAGER, CREATE, DIVISION, response));
        }).catch(function (error) {
            dispatch(makeDispatch(MANAGER, CREATE, DIVISION, error.response));
        });
    }
}

export function deleteDivision(id, type) {
    return (dispatch) => {
        rstatApi.delete('/divisions/' + id,).then(function (response) {
            dispatch(makeDispatch(MANAGER, DELETE, DIVISION, response, parseInt(id, 10)));
        }).catch(function (error) {
            dispatch(makeDispatch(MANAGER, DELETE, DIVISION, error.response));
        })
    }
}