import {
    MATCH_LIST_REQUEST,
    MATCH_LIST_FAILURE,
    MATCH_SHOW_FORM,
    MATCH_CREATE_FAILURE,
    MATCH_DELETE_FAILURE, MATCH_VIEW_FAILURE, MATCH_UPDATE_FAILURE, MATCH_SHOW_RESULT, MATCH_SET_MODEL
} from '../constants/Match';

import {rstatApi, makeDispatch, makeDispatch500} from '../helpers/api'
import {MANAGER} from "../constants/Common";
import {CREATE, DELETE, GROUP, LIST, MATCH, PROTOCOL, UPDATE, VIEW} from "../constants/Global";

export function showMatchForm(open, type) {
    return (dispatch) => {
        dispatch({
            type: type + MATCH_SHOW_FORM,
            payload: open
        })
    }
}

export function viewMatch(id, type) {
    return (dispatch) => {
        rstatApi.get('/matches/' + id).then(function (response) {
            dispatch(makeDispatch(MANAGER, VIEW, MATCH, response));
        }).catch(function (error) {
            dispatch(makeDispatch500(type, MATCH_VIEW_FAILURE, error))
        });
    }
}

export function viewProtocol(id) {
    return (dispatch) => {
        rstatApi.get('/matches/' + id + '/protocol').then(function (response) {
            dispatch(makeDispatch(MANAGER, PROTOCOL, MATCH, response));
        }).catch(function (error) {
            dispatch(makeDispatch(MANAGER, PROTOCOL, MATCH, error.response));
        });
    }
}

export function updateMatch(id, body, type) {
    return (dispatch) => {
        rstatApi.put('/matches/' + id, body).then(function (response) {
            dispatch(makeDispatch(MANAGER, UPDATE, MATCH, response));
        }).catch(function (error) {
            dispatch(makeDispatch500(type, MATCH_UPDATE_FAILURE, error))
        });
    }
}

export function listMatches(query = '', type) {
    let stageID = 0;
    let result = query.match(/stage_id:(\d+)/i);
    if (result !== null) {
        stageID = parseInt(result[1], 10)
    }
    return (dispatch) => {
        dispatch({
            type: type + MATCH_LIST_REQUEST,
            payload: stageID,
        });
        rstatApi.get('/matches' + query).then(function (response) {
            dispatch(makeDispatch(MANAGER, LIST, MATCH, response));
        }).catch(function (error) {
            dispatch(makeDispatch500(type, MATCH_LIST_FAILURE, error))
        });
    }
}

export function createMatch(body, type) {
    return (dispatch) => {
        rstatApi.post('/matches', body).then(function (response) {
            dispatch(makeDispatch(MANAGER, CREATE, MATCH, response));
        }).catch(function (error) {
            dispatch(makeDispatch(MANAGER, CREATE, MATCH, error.response));
        });
    }
}

export function deleteMatch(id, type) {
    return (dispatch) => {
        rstatApi.delete('/matches/' + id,).then(function (response) {
            dispatch(makeDispatch(MANAGER, DELETE, MATCH, response, parseInt(id, 10)));
        }).catch(function (error) {
            dispatch(makeDispatch(MANAGER, DELETE, MATCH, error.response));
        })
    }
}

export function showMatchResult(open, type) {
    return (dispatch) => {
        dispatch({
            type: type + MATCH_SHOW_RESULT,
            payload: open
        })
    }
}

export function setMatchModel(model, type) {
    return (dispatch) => {
        dispatch({
            type: type + MATCH_SET_MODEL,
            payload: model
        })
    }
}