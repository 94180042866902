import * as axios from "axios";
import {CONSTRUCTOR_API_URL, ENDPOINT} from "../constants/Common";
import {DELETE, FAILURE, LIST, SUCCESS} from "../constants/Global";

let token = localStorage.getItem('token')

export const rstatApi = axios.create({
    baseURL: ENDPOINT,
    timeout: 3000,
    withCredentials: false,
    headers: {
        'Authorization': token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
});

export const rstatFormApi = axios.create({
    baseURL: ENDPOINT,
    timeout: 3000,
    withCredentials: false,
    headers: {
        'Authorization': token,
        'Accept': 'application/json'
    }
});

export const siteApi = axios.create({
    baseURL: CONSTRUCTOR_API_URL,
    timeout: 2000,
    withCredentials: false,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
});

export const siteFormApi = axios.create({
    baseURL: CONSTRUCTOR_API_URL,
    timeout: 2000,
    withCredentials: false,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data'
    }
});

export function makeDispatch(type, action, entity, response, id = 0) {
    if (response === undefined) {
        return {
            type: "ALL_FAILURE",
            code: 500,
            errorMessage: "network problems"
        };
    }

    let actionType = type + entity + "_" + action;
    let isFail = (response.status >= 400);

    actionType = actionType + "_" + (isFail ? FAILURE : SUCCESS);

    let dispatch = {type: actionType};

    if (isFail) {
        dispatch.code = response.status;
        dispatch.errorCode = response.data.code;
        dispatch.errorMessage = response.data.errMessage

        return dispatch
    }

    switch (action){
        case DELETE:
            dispatch.payload = id;
            break;
        case LIST:
            dispatch.payload = id;
            if (response.data.count === 0) {
                dispatch.payload = []
            } else {
                dispatch.payload = response.data.data
            }
            break;
        default:
            dispatch.payload = response.data
    }

    return dispatch
}

// FIXME: rewrite sites api.
export function makeSiteDispatch(type, action, entity, response, id = 0) {
    if (response === undefined) {
        return {
            type: "ALL_FAILURE",
            code: 500,
            errorMessage: "network problems"
        };
    }

    let actionType = type + entity + "_" + action;
    let isFail = (response.status >= 400);

    actionType = actionType + "_" + (isFail ? FAILURE : SUCCESS);

    let dispatch = {type: actionType};

    if (isFail) {
        dispatch.code = response.status;
        dispatch.errorCode = response.data.errorCode;
        dispatch.errorMessage = response.data.errorMessage

        return dispatch
    }

    console.log(action,response.data)

    switch (action){
        case DELETE:
            dispatch.payload = id;
            break;
        default:
            dispatch.payload = response.data.result
    }

    return dispatch
}

export function makeDispatch500(type, actionType, error) {
    return {
        type: type + actionType,
        code: 500,
        errorMessage: error.toString()
    };
}

export function formatTime(time) {
    return time.getHours() + ':' + (time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes()) + ':' + time.getSeconds();
}

export function formatDate(txtDate) {
    let date = new Date(txtDate)
    return ('0' + date.getDate()).slice(-2) + '.' + ('0' + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear().toString().slice(-2);
}