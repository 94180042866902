
export const MANAGER_PENALTY_LIST_REQUEST = 'MANAGER_PENALTY_LIST_REQUEST'
export const MANAGER_PENALTY_LIST_FAILURE = 'MANAGER_PENALTY_LIST_FAILURE'
export const MANAGER_PENALTY_LIST_SUCCESS = 'MANAGER_PENALTY_LIST_SUCCESS'
export const MANAGER_PENALTY_SHOW_FORM = 'MANAGER_PENALTY_SHOW_MODAL'
export const MANAGER_PENALTY_CREATE_REQUEST = 'MANAGER_PENALTY_CREATE_REQUEST'
export const MANAGER_PENALTY_CREATE_SUCCESS = 'MANAGER_PENALTY_CREATE_SUCCESS'
export const MANAGER_PENALTY_CREATE_FAILURE = 'MANAGER_PENALTY_CREATE_FAILURE'
export const MANAGER_PENALTY_UPDATE_REQUEST = 'MANAGER_PENALTY_UPDATE_REQUEST'
export const MANAGER_PENALTY_UPDATE_SUCCESS = 'MANAGER_PENALTY_UPDATE_SUCCESS'
export const MANAGER_PENALTY_UPDATE_FAILURE = 'MANAGER_PENALTY_UPDATE_FAILURE'
export const MANAGER_PENALTY_DELETE_REQUEST = 'MANAGER_PENALTY_DELETE_REQUEST'
export const MANAGER_PENALTY_DELETE_SUCCESS = 'MANAGER_PENALTY_DELETE_SUCCESS'
export const MANAGER_PENALTY_DELETE_FAILURE = 'MANAGER_PENALTY_DELETE_FAILURE'
export const MANAGER_PENALTY_SET_ID = 'MANAGER_PENALTY_SET_ID'
export const MANAGER_PENALTY_SELECT = 'MANAGER_PENALTY_SELECT'

export const CORE_PENALTY_LIST_REQUEST = 'CORE_PENALTY_LIST_REQUEST'
export const CORE_PENALTY_LIST_FAILURE = 'CORE_PENALTY_LIST_FAILURE'
export const CORE_PENALTY_LIST_SUCCESS = 'CORE_PENALTY_LIST_SUCCESS'
export const CORE_PENALTY_SHOW_FORM = 'CORE_PENALTY_SHOW_FORM'
export const CORE_PENALTY_CREATE_REQUEST = 'CORE_PENALTY_CREATE_REQUEST'
export const CORE_PENALTY_CREATE_SUCCESS = 'CORE_PENALTY_CREATE_SUCCESS'
export const CORE_PENALTY_CREATE_FAILURE = 'CORE_PENALTY_CREATE_FAILURE'
export const CORE_PENALTY_UPDATE_REQUEST = 'CORE_PENALTY_UPDATE_REQUEST'
export const CORE_PENALTY_UPDATE_SUCCESS = 'CORE_PENALTY_UPDATE_SUCCESS'
export const CORE_PENALTY_UPDATE_FAILURE = 'CORE_PENALTY_UPDATE_FAILURE'
export const CORE_PENALTY_DELETE_REQUEST = 'CORE_PENALTY_DELETE_REQUEST'
export const CORE_PENALTY_DELETE_SUCCESS = 'CORE_PENALTY_DELETE_SUCCESS'
export const CORE_PENALTY_DELETE_FAILURE = 'CORE_PENALTY_DELETE_FAILURE'
export const CORE_PENALTY_SET_ID = 'CORE_PENALTY_SET_ID'
export const CORE_PENALTY_VIEW_SUCCESS = 'CORE_PENALTY_VIEW_SUCCESS'
export const CORE_PENALTY_VIEW_FAILURE = 'CORE_PENALTY_VIEW_FAILURE'

export const PENALTY_LIST_REQUEST = 'PENALTY_LIST_REQUEST'
export const PENALTY_LIST_FAILURE = 'PENALTY_LIST_FAILURE'
export const PENALTY_LIST_SUCCESS = 'PENALTY_LIST_SUCCESS'
export const PENALTY_SHOW_FORM = 'PENALTY_SHOW_FORM'
export const PENALTY_CREATE_REQUEST = 'PENALTY_CREATE_REQUEST'
export const PENALTY_CREATE_SUCCESS = 'PENALTY_CREATE_SUCCESS'
export const PENALTY_CREATE_FAILURE = 'PENALTY_CREATE_FAILURE'
export const PENALTY_UPDATE_REQUEST = 'PENALTY_UPDATE_REQUEST'
export const PENALTY_UPDATE_SUCCESS = 'PENALTY_UPDATE_SUCCESS'
export const PENALTY_UPDATE_FAILURE = 'PENALTY_UPDATE_FAILURE'
export const PENALTY_DELETE_REQUEST = 'PENALTY_DELETE_REQUEST'
export const PENALTY_DELETE_SUCCESS = 'PENALTY_DELETE_SUCCESS'
export const PENALTY_DELETE_FAILURE = 'PENALTY_DELETE_FAILURE'
export const PENALTY_VIEW_SUCCESS = 'PENALTY_VIEW_SUCCESS'
export const PENALTY_VIEW_FAILURE = 'PENALTY_VIEW_FAILURE'
export const PENALTY_SET_ID = 'PENALTY_SET_ID'