import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import {Field, Formik} from "formik";
import * as yup from 'yup'
import {CORE} from "../constants/Common";
import FormikAdapter from "./common/FormikAdapter";
import FormikSelectAdapter from "./common/FormikSelectAdapter";
import AsyncSelect from "./form/AsyncSelect";
import SportTypeSelector from "./form/SportTypeSelector";
import {FormattedMessage} from "react-intl";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const STADIUM_FIELDS = {
    STADIUM_ID_FIELD: 'id',
    STADIUM_CITY_FIELD: 'cityID',
    STADIUM_SPORT_TYPE_FIELD: 'sportType',
    STADIUM_NAME_FIELD: 'name',
    STADIUM_FULL_NAME_FIELD: 'fullName',
    STADIUM_CAPACITY_FIELD: 'capacity',
    STADIUM_ABBREVIATION_FIELD: 'abbreviation',
    STADIUM_SORT_FIELD: 'sort',
};

export default function CityForm(props) {

    const handleClose = () => {
        props.show(false, CORE)
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={props.open}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                <FormattedMessage id="stadium-form" defaultMessage="Stadium form"/>
            </DialogTitle>
            <Formik
                initialValues={{
                    [STADIUM_FIELDS.STADIUM_ID_FIELD]: props.model.id,
                    [STADIUM_FIELDS.STADIUM_SPORT_TYPE_FIELD]: props.model.sportType,
                    [STADIUM_FIELDS.STADIUM_NAME_FIELD]: props.model.name,
                    [STADIUM_FIELDS.STADIUM_FULL_NAME_FIELD]: props.model.fullName,
                    [STADIUM_FIELDS.STADIUM_CITY_FIELD]: props.model.cityID,
                    [STADIUM_FIELDS.STADIUM_CAPACITY_FIELD]: props.model.capacity,
                    [STADIUM_FIELDS.STADIUM_ABBREVIATION_FIELD]: props.model.abbreviation,
                    [STADIUM_FIELDS.STADIUM_SORT_FIELD]: props.model.sort,
                }}

                onSubmit={(values) => {
                    values.cityID = parseInt(values.cityID)
                    if (values.id > 0) {
                        props.update(values.id, values, CORE);
                    } else {
                        props.create(values, CORE);
                    }
                }}

                validationSchema={yup.object().shape({
                    [STADIUM_FIELDS.STADIUM_NAME_FIELD]: yup.string()
                        .required(<FormattedMessage id="form.required" defaultMessage="required field"/>),
                    [STADIUM_FIELDS.STADIUM_FULL_NAME_FIELD]: yup.string()
                        .required(<FormattedMessage id="form.required" defaultMessage="required field"/>),
                    [STADIUM_FIELDS.STADIUM_CITY_FIELD]: yup.number()
                        .required(<FormattedMessage id="form.required" defaultMessage="required field"/>),
                })}
            >
                {(props) => {
                    const {
                        isSubmitting,
                        handleSubmit,
                        handleChange,
                    } = props;
                    return (
                        <form onSubmit={handleSubmit}>
                            <DialogContent dividers>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Field
                                            name={STADIUM_FIELDS.STADIUM_SPORT_TYPE_FIELD}
                                            type="text"
                                            helperText=<FormattedMessage id="stadium.form.sport-kind.helper" defaultMessage="sport kind"/>
                                            inputComponent={SportTypeSelector}
                                            component={FormikSelectAdapter}
                                            label=<FormattedMessage id="stadium.form.sport-kind.label" defaultMessage="Sport kind"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Field
                                            name={STADIUM_FIELDS.STADIUM_NAME_FIELD}
                                            type="text"
                                            helperText=<FormattedMessage id="stadium.form.name.helper" defaultMessage="stadium name"/>
                                            inputComponent={TextField}
                                            component={FormikAdapter}
                                            label=<FormattedMessage id="stadium.form.name.helper" defaultMessage="Name"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Field
                                            name={STADIUM_FIELDS.STADIUM_FULL_NAME_FIELD}
                                            type="text"
                                            helperText=<FormattedMessage id="stadium.form.full-name.helper" defaultMessage="full name"/>
                                            inputComponent={TextField}
                                            component={FormikAdapter}
                                            label=<FormattedMessage id="stadium.form.full-name.label" defaultMessage="Full name"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Field
                                            name={STADIUM_FIELDS.STADIUM_CITY_FIELD}
                                            type="select"
                                            helperText=<FormattedMessage id="stadium.form.city.helper" defaultMessage="geolocation"/>
                                            onChange={handleChange}
                                            filter={"/cities"}
                                            inputComponent={AsyncSelect}
                                            component={FormikSelectAdapter}
                                            label=<FormattedMessage id="stadium.form.city.label" defaultMessage="City"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Field
                                            name={STADIUM_FIELDS.STADIUM_ABBREVIATION_FIELD}
                                            type="text"
                                            helperText=<FormattedMessage id="stadium.form.abbreviation.helper" defaultMessage="4 char"/>
                                            inputComponent={TextField}
                                            component={FormikAdapter}
                                            label=<FormattedMessage id="stadium.form.abbreviation.label" defaultMessage="Abbreviation"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Field
                                            name={STADIUM_FIELDS.STADIUM_CAPACITY_FIELD}
                                            type="number"
                                            helperText=<FormattedMessage id="stadium.form.capacity.helper" defaultMessage="capacity"/>
                                            inputComponent={TextField}
                                            component={FormikAdapter}
                                            label=<FormattedMessage id="stadium.form.capacity.label" defaultMessage="Capacity"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Field
                                            name={STADIUM_FIELDS.STADIUM_SORT_FIELD}
                                            type="number"
                                            helperText=<FormattedMessage id="form.sort.helper" defaultMessage="sorting"/>
                                            inputComponent={TextField}
                                            component={FormikAdapter}
                                            label=<FormattedMessage id="form.sort.helper" defaultMessage="Sort"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={isSubmitting}>
                                    <FormattedMessage id="button.save" defaultMessage="Save"/>
                                </Button>
                            </DialogActions>
                        </form>
                    );
                }}
            </Formik>
        </Dialog>
    );
}