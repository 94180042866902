import React, {useRef} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {MANAGER} from "../../../../constants/Common";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import {Field, Formik} from "formik";
import * as yup from 'yup'
import FormikAdapter from "../../../common/FormikAdapter";
import {FormattedMessage} from "react-intl";
import {FormHelperText} from "@mui/material";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const ALBUM_FIELDS = {
    ALBUM_ID_FIELD: 'id',
    ALBUM_GOOGLE_ID_FIELD: 'googleID',
    ALBUM_TITLE_FIELD: 'title',
    ALBUM_COMPETITION_ID_FIELD: 'competitionID',
    ALBUM_SORT_FIELD: 'sort',
};

export default function AlbumForm(props) {
    const handleClose = () => {
        props.show(false, MANAGER)
    };

    const fileRef = useRef();

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"md"}
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={props.open}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                <FormattedMessage id="album-form" defaultMessage="Album form"/>
            </DialogTitle>
            <Formik
                initialValues={{
                    [ALBUM_FIELDS.ALBUM_ID_FIELD]: props.model.id,
                    [ALBUM_FIELDS.ALBUM_COMPETITION_ID_FIELD]: props.competitionID,
                    [ALBUM_FIELDS.ALBUM_TITLE_FIELD]: props.model.title,
                    [ALBUM_FIELDS.ALBUM_GOOGLE_ID_FIELD]: props.model.googleID,
                    [ALBUM_FIELDS.ALBUM_SORT_FIELD]: props.model.sort,
                }}
                onSubmit={(values, actions) => {
                    if (values.id > 0) {
                        let data = new FormData();

                        if (values.file !== undefined){
                            values.file.map(function (file, index) {
                                data.append("files", file)
                            });
                        }

                        data.append("competitionID", props.competitionID);
                        data.append("files", values.file)
                        data.append("title", values.title)
                        data.append("googleID", values.googleID)
                        data.append("sort", values.sort)

                        props.update(values.id, data, MANAGER);
                    } else {
                        props.create(values, MANAGER);
                    }
                }}
                validationSchema={yup.object().shape({
                    [ALBUM_FIELDS.ALBUM_TITLE_FIELD]: yup.string()
                        .required(<FormattedMessage id="form.required" defaultMessage="required field"/>),
                })}
            >
                {(props) => {
                    const {
                        isSubmitting,
                        setFieldValue,
                        handleSubmit,
                        values,
                        errors,
                    } = props;

                    return (
                        <form onSubmit={handleSubmit}>
                            <DialogContent dividers>
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <Field
                                            name={ALBUM_FIELDS.ALBUM_TITLE_FIELD}
                                            type="text"
                                            fullWidth
                                            helperText=<FormattedMessage id="manager.site.album.title.helper" defaultMessage="album title"/>
                                            inputComponent={TextField}
                                            component={FormikAdapter}
                                            label=<FormattedMessage id="manager.site.album.title.label" defaultMessage="Title"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Field
                                            name={ALBUM_FIELDS.ALBUM_GOOGLE_ID_FIELD}
                                            type="text"
                                            fullWidth
                                            helperText=<FormattedMessage id="manager.site.album.googleID" defaultMessage="google id"/>
                                            inputComponent={TextField}
                                            component={FormikAdapter}
                                            label="Google ID"
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button
                                            style={{marginTop:20}}
                                            disabled={values===undefined}
                                            variant="outlined"
                                            component="label"
                                        >
                                            <FormattedMessage id="manager.site.album.photo" defaultMessage="Photos"/>
                                            <input hidden id="files" multiple name="files" type="file" onChange={(event) => {
                                                const files = event.currentTarget.files;
                                                let myFiles =Array.from(files);
                                                setFieldValue("file", myFiles);
                                            }} className="form-control"/>
                                        </Button>
                                        <FormHelperText><FormattedMessage id="manager.site.news.contact.helper" defaultMessage="850px"/></FormHelperText>
                                        <div>{errors.import}</div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Field
                                            name={ALBUM_FIELDS.ALBUM_SORT_FIELD}
                                            type="number"
                                            helperText=<FormattedMessage id="form.sort.helper" defaultMessage="sorting"/>
                                        inputComponent={TextField}
                                        component={FormikAdapter}
                                        label=<FormattedMessage id="form.name.label" defaultMessage="Sort"/>
                                        margin="normal"
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={isSubmitting}>
                                    <FormattedMessage id="button.save" defaultMessage="Save"/>
                                </Button>
                            </DialogActions>
                        </form>
                    );
                }}
            </Formik>
        </Dialog>
    );
}