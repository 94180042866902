import {MANAGER} from '../../constants/Common';
import {
    NEWS_SET_ID,
    NEWS_SHOW_FORM,
} from "../../constants/Site";
import {makeSiteDispatch, siteApi} from "../../helpers/api";
import {CREATE, DELETE, LIST, NEWS, UPDATE, VIEW} from "../../constants/Global";

export function showNewsForm(open, type) {
    return (dispatch) => {
        dispatch({
            type: type + NEWS_SHOW_FORM,
            payload: open
        })
    }
}

export function setNewsID(id, type) {
    return (dispatch) => {
        dispatch({
            type: type + NEWS_SET_ID,
            payload: id
        })
    }
}

export function viewNews(id, type) {
    return (dispatch) => {
        siteApi.get('news/' + id).then(function (response) {
            dispatch(makeSiteDispatch(MANAGER, VIEW, NEWS, response));
        }).catch(function (error) {
            console.log(error)
            dispatch(makeSiteDispatch(MANAGER, VIEW, NEWS, error.response));
        });
    }
}

export function listNews(id, type) {
    return (dispatch) => {
        siteApi.get('news/list/' + id).then(function (response) {
            dispatch(makeSiteDispatch(type, LIST, NEWS, response));
        }).catch(function (error) {
            dispatch(makeSiteDispatch(type, LIST, NEWS, error))
        });
    }
}

export function deleteNews(id, type) {
    return (dispatch) => {
        siteApi.delete('news/' + id).then(function (response) {
            dispatch(makeSiteDispatch(MANAGER, DELETE, NEWS, response,parseInt(id, 10)));
        }).catch(function (error) {
            dispatch(makeSiteDispatch(MANAGER, DELETE, NEWS, error.response));
        });
    }
}

export function createNews(body, type) {
    return (dispatch) => {
        siteApi.post('news', body).then(function (response) {
            dispatch(makeSiteDispatch(MANAGER, CREATE, NEWS, response));
        }).catch(function (error) {
            dispatch(makeSiteDispatch(MANAGER, CREATE, NEWS, error.response));
        });
    }
}

export function updateNews(id, body, type) {
    return (dispatch) => {
        siteApi.put('news/' + id, body).then(function (response) {
            dispatch(makeSiteDispatch(MANAGER, UPDATE, NEWS, response));
        }).catch(function (error) {
            dispatch(makeSiteDispatch(MANAGER, UPDATE, NEWS, error.response));
        });
    }
}
