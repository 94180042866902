import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {MANAGER} from "../../../constants/Common";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import {Field, Formik} from "formik";
import * as yup from 'yup'
import FormikAdapter from "../../common/FormikAdapter";
import FormikSelectAdapter from "../../common/FormikSelectAdapter";
import PeopleSelect from "../../form/PeopleSelect";
import DataSelect from "../../form/DataSelect";
import {PERSONAL_TYPE_COACH, PERSONAL_TYPE_DOCTOR} from "../../../constants/DivisionPersonal";
import {FormattedMessage} from "react-intl";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const DIVISION_PERSONAL_FIELDS = {
    DIVISION_PERSONAL_ID_FIELD: 'id',
    DIVISION_PERSONAL_STATUS_FIELD: 'status',
    DIVISION_PERSONAL_DIVISION_TEAM_ID_FIELD: 'divisionTeamID',
    DIVISION_PERSONAL_PERSONAL_TYPE_FIELD: 'personalType',
    DIVISION_PERSONAL_PEOPLE_ID_FIELD: 'peopleID',
    DIVISION_PERSONAL_DESCRIPTION_FIELD: 'description',
    DIVISION_PERSONAL_SORT_FIELD: 'sort',
};

export default function DivisionPersonalForm(mainProps) {
    let personalTypes = [
        {key: PERSONAL_TYPE_COACH, value: <FormattedMessage id="division-personal.coach" defaultMessage="Coach"/>},
        {key: PERSONAL_TYPE_DOCTOR, value: <FormattedMessage id="division-personal.doctor" defaultMessage="Doctor"/>},
    ]
    const handleClose = () => {
        mainProps.show(false, MANAGER)
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"md"}
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={mainProps.open}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                <FormattedMessage id="division-personal-form" defaultMessage="Division personal form"/>
            </DialogTitle>
            <Formik
                initialValues={{
                    [DIVISION_PERSONAL_FIELDS.DIVISION_PERSONAL_ID_FIELD]: mainProps.model.id,
                    [DIVISION_PERSONAL_FIELDS.DIVISION_PERSONAL_DIVISION_TEAM_ID_FIELD]: mainProps.divisionTeamID,
                    [DIVISION_PERSONAL_FIELDS.DIVISION_PERSONAL_PEOPLE_ID_FIELD]: mainProps.model.people.id,
                    [DIVISION_PERSONAL_FIELDS.DIVISION_PERSONAL_PERSONAL_TYPE_FIELD]: mainProps.model.personalType,
                    [DIVISION_PERSONAL_FIELDS.DIVISION_PERSONAL_SORT_FIELD]: mainProps.model.sort,
                    [DIVISION_PERSONAL_FIELDS.DIVISION_PERSONAL_DESCRIPTION_FIELD]: mainProps.model.description,
                }}
                onSubmit={(values, actions) => {
                    if (values.id > 0) {
                        mainProps.update(values.id, values, MANAGER);
                    } else {
                        mainProps.create(values, MANAGER);
                    }
                }}
                validationSchema={yup.object().shape({
                    [DIVISION_PERSONAL_FIELDS.DIVISION_PERSONAL_PEOPLE_ID_FIELD]: yup.number()
                        .required(<FormattedMessage id="form.required" defaultMessage="required field"/>),
                    [DIVISION_PERSONAL_FIELDS.DIVISION_PERSONAL_PERSONAL_TYPE_FIELD]: yup.string()
                        .required(<FormattedMessage id="form.required" defaultMessage="required field"/>),
                })}
            >
                {(props) => {
                    const {
                        setFieldValue,
                        isSubmitting,
                        handleSubmit,
                        handleChange,
                    } = props;
                    return (
                        <form onSubmit={handleSubmit}>
                            <DialogContent dividers>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Field
                                            name={DIVISION_PERSONAL_FIELDS.DIVISION_PERSONAL_PERSONAL_TYPE_FIELD}
                                            type="select"
                                            helperText=<FormattedMessage id="manager.division-personal.form.type.helper" defaultMessage="personal type"/>
                                            data={personalTypes}
                                            onChange={handleChange}
                                            inputComponent={DataSelect}
                                            component={FormikSelectAdapter}
                                            label=<FormattedMessage id="manager.division-personal.form.type.label" defaultMessage="Type"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            name={DIVISION_PERSONAL_FIELDS.DIVISION_PERSONAL_PEOPLE_ID_FIELD}
                                            helperText=<FormattedMessage id="manager.division-personal.form.personal.helper" defaultMessage="personal"/>
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            setFieldValue={setFieldValue}
                                            filter={"/peoples?sort=surname"}
                                            inputComponent={PeopleSelect}
                                            component={FormikSelectAdapter}
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            name={DIVISION_PERSONAL_FIELDS.DIVISION_PERSONAL_DESCRIPTION_FIELD}
                                            fullWidth
                                            helperText=<FormattedMessage id="manager.division-personal.form.description.helper" defaultMessage="description"/>
                                            inputComponent={TextField}
                                            component={FormikAdapter}
                                            label=<FormattedMessage id="manager.division-personal.form.personal.label" defaultMessage="Description"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            name={DIVISION_PERSONAL_FIELDS.DIVISION_PERSONAL_SORT_FIELD}
                                            type="number"
                                            helperText=<FormattedMessage id="manager.division-personal.form.sort.helper" defaultMessage="sorting"/>
                                            inputComponent={TextField}
                                            component={FormikAdapter}
                                            label=<FormattedMessage id="manager.division-personal.form.sort.label" defaultMessage="sort"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={isSubmitting}>
                                    <FormattedMessage id="button.save" defaultMessage="Save"/>
                                </Button>
                            </DialogActions>
                        </form>
                    );
                }}
            </Formik>
        </Dialog>
    );
}