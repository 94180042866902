export const MANAGER_CONFERENCE_SELECT = 'MANAGER_CONFERENCE_SELECT'
export const MANAGER_CITY_SELECT = 'MANAGER_CITY_SELECT'
export const MANAGER_COMPETITION_LIST_REQUEST = 'MANAGER_COMPETITION_LIST_REQUEST'
export const MANAGER_COMPETITION_LIST_FAILURE = 'MANAGER_COMPETITION_LIST_FAILURE'
export const MANAGER_COMPETITION_LIST_SUCCESS = 'MANAGER_COMPETITION_LIST_SUCCESS'
export const MANAGER_COMPETITION_SHOW_FORM = 'MANAGER_COMPETITION_SHOW_FORM'
export const MANAGER_COMPETITION_CREATE_SUCCESS = 'MANAGER_COMPETITION_CREATE_SUCCESS'
export const MANAGER_COMPETITION_CREATE_FAILURE = 'MANAGER_COMPETITION_CREATE_FAILURE'
export const MANAGER_COMPETITION_UPDATE_SUCCESS = 'MANAGER_COMPETITION_UPDATE_SUCCESS'
export const MANAGER_COMPETITION_UPDATE_FAILURE = 'MANAGER_COMPETITION_UPDATE_FAILURE'
export const MANAGER_COMPETITION_DELETE_SUCCESS = 'MANAGER_COMPETITION_DELETE_SUCCESS'
export const MANAGER_COMPETITION_DELETE_FAILURE = 'MANAGER_COMPETITION_DELETE_FAILURE'
export const MANAGER_COMPETITION_VIEW_SUCCESS = 'MANAGER_COMPETITION_VIEW_SUCCESS'

export const CORE_COMPETITION_LIST_REQUEST = 'CORE_COMPETITION_LIST_REQUEST'
export const CORE_COMPETITION_LIST_FAILURE = 'CORE_COMPETITION_LIST_FAILURE'
export const CORE_COMPETITION_LIST_SUCCESS = 'CORE_COMPETITION_LIST_SUCCESS'
export const CORE_COMPETITION_SHOW_MODAL = 'CORE_COMPETITION_SHOW_MODAL'
export const CORE_COMPETITION_CREATE_SUCCESS = 'CORE_COMPETITION_CREATE_SUCCESS'
export const CORE_COMPETITION_DELETE_SUCCESS = 'CORE_COMPETITION_DELETE_SUCCESS'
export const CORE_COMPETITION_VIEW_SUCCESS = 'CORE_COMPETITION_VIEW_SUCCESS'
export const CORE_COMPETITION_VIEW_FAILURE = 'CORE_COMPETITION_VIEW_FAILURE'

export const COMPETITION_LIST_REQUEST = 'COMPETITION_LIST_REQUEST'
export const COMPETITION_LIST_FAILURE = 'COMPETITION_LIST_FAILURE'
export const COMPETITION_LIST_SUCCESS = 'COMPETITION_LIST_SUCCESS'
export const COMPETITION_SHOW_FORM = 'COMPETITION_SHOW_FORM';
export const COMPETITION_CREATE_SUCCESS = 'COMPETITION_CREATE_SUCCESS'
export const COMPETITION_CREATE_FAILURE = 'COMPETITION_CREATE_FAILURE'
export const COMPETITION_UPDATE_SUCCESS = 'COMPETITION_UPDATE_SUCCESS'
export const COMPETITION_UPDATE_FAILURE = 'COMPETITION_UPDATE_FAILURE'
export const COMPETITION_DELETE_SUCCESS = 'COMPETITION_DELETE_SUCCESS'
export const COMPETITION_DELETE_FAILURE = 'COMPETITION_DELETE_FAILURE'
export const COMPETITION_VIEW_SUCCESS = 'COMPETITION_VIEW_SUCCESS'
export const COMPETITION_VIEW_FAILURE = 'COMPETITION_VIEW_FAILURE'

export const STATUS_ACTIVE = 'ACTIVE';
export const STATUS_HIDDEN = 'HIDDEN';
export const STATUS_LOCK = 'LOCK';

export const STAGE_KIND_PLAYOFF = 'PLAYOFF';
export const STAGE_KIND_ROUNDED = 'ROUND_ROBIN';
