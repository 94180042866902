import {
    DIVISION_PLAYER_CREATE_FAILURE,
    DIVISION_PLAYER_DELETE_FAILURE,
    DIVISION_PLAYER_LIST_FAILURE,
    DIVISION_PLAYER_LIST_REQUEST,
    DIVISION_PLAYER_SHOW_FORM,
    DIVISION_PLAYER_UPDATE_FAILURE,
    DIVISION_PLAYER_VIEW_FAILURE
} from '../constants/DivisionPlayer';

import {makeDispatch, makeDispatch500, rstatApi} from '../helpers/api'
import {MANAGER} from "../constants/Common";
import {COMPETITION, CREATE, DELETE, DIVISION_PLAYER, LIST, UPDATE, VIEW} from "../constants/Global";

export function showDivisionPlayerForm(open, type) {
    return (dispatch) => {
        dispatch({
            type: type + DIVISION_PLAYER_SHOW_FORM,
            payload: open
        })
    }
}

export function viewDivisionPlayer(id, type) {
    return (dispatch) => {
        rstatApi.get('/divisionPlayers/' + id).then(function (response) {
            dispatch(makeDispatch(MANAGER, VIEW, DIVISION_PLAYER, response));
        }).catch(function (error) {
            dispatch(makeDispatch(MANAGER, VIEW, DIVISION_PLAYER, error.response));
        });
    }
}

export function updateDivisionPlayer(id, body, type) {
    return (dispatch) => {
        rstatApi.put('/divisionPlayers/' + id, body).then(function (response) {
            dispatch(makeDispatch(MANAGER, UPDATE, DIVISION_PLAYER, response));
        }).catch(function (error) {
            dispatch(makeDispatch(MANAGER, UPDATE, DIVISION_PLAYER, error.response));
        });
    }
}

export function listDivisionPlayers(query = '', type) {
    let divisionTeamID = 0;
    let result = query.match(/division_team_id:(\d+)/i);
    if (result !== null) {
        divisionTeamID = parseInt(result[1], 10)
    }
    return (dispatch) => {
        dispatch({
            type: type + DIVISION_PLAYER_LIST_REQUEST,
            payload: divisionTeamID,
        });
        rstatApi.get('/divisionPlayers' + query).then(function (response) {
            dispatch(makeDispatch(MANAGER, LIST, DIVISION_PLAYER, response));
        }).catch(function (error) {
            dispatch(makeDispatch(MANAGER, LIST, DIVISION_PLAYER, error.response));
        });
    }
}

export function createDivisionPlayer(body, type) {
    return (dispatch) => {
        rstatApi.post('/divisionPlayers', body).then(function (response) {
            dispatch(makeDispatch(MANAGER, CREATE, DIVISION_PLAYER, response));
        }).catch(function (error) {
            dispatch(makeDispatch(MANAGER, CREATE, DIVISION_PLAYER, error.response));
        });
    }
}

export function deleteDivisionPlayer(id, type) {
    return (dispatch) => {
        rstatApi.delete('/divisionPlayers/' + id,).then(function (response) {
            dispatch(makeDispatch(MANAGER, DELETE, DIVISION_PLAYER, response, parseInt(id, 10)));
        }).catch(function (error) {
            dispatch(makeDispatch(MANAGER, DELETE, DIVISION_PLAYER, error.response));
        })
    }
}