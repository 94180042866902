import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {MANAGER} from "../../../../constants/Common";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import {Field, Formik} from "formik";
import * as yup from 'yup'
import FormikAdapter from "../../../common/FormikAdapter";
import {Redactor} from "./Redactor";
import FormikCheckbox from "../../../common/FormikCheckbox";
import {FormattedMessage} from "react-intl";
import {FormHelperText} from "@mui/material";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const NEWS_FIELDS = {
    NEWS_ID_FIELD: 'id',
    NEWS_TITLE_FIELD: 'title',
    NEWS_PREVIEW_FIELD: 'preview',
    NEWS_MAIN_FIELD: 'isMain',
    NEWS_BODY_FIELD: 'body',
    NEWS_COMPETITION_ID_FIELD: 'competitionID',
    NEWS_SORT_FIELD: 'sort',
};

export default function NewsForm(props) {
    const handleClose = () => {
        props.show(false, MANAGER)
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"md"}
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={props.open}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                <FormattedMessage id="news-form" defaultMessage="News form"/>
            </DialogTitle>
            <Formik
                initialValues={{
                    [NEWS_FIELDS.NEWS_ID_FIELD]: props.model.id,
                    [NEWS_FIELDS.NEWS_COMPETITION_ID_FIELD]: props.model.competitionID,
                    [NEWS_FIELDS.NEWS_TITLE_FIELD]: props.model.title,
                    [NEWS_FIELDS.NEWS_PREVIEW_FIELD]: props.model.preview,
                    [NEWS_FIELDS.NEWS_MAIN_FIELD]: props.model.isMain,
                    [NEWS_FIELDS.NEWS_BODY_FIELD]: props.model.body || "",
                    [NEWS_FIELDS.NEWS_SORT_FIELD]: props.model.sort,
                }}
                onSubmit={(values, actions) => {
                    let data = new FormData();

                    data.append("competitionID", props.competitionID);
                    data.append("file", values.file)
                    data.append("title", values.title)
                    data.append("preview", values.preview)
                    data.append("isMain", values.isMain)
                    data.append("body", values.body)
                    data.append("countryID", values.countryID)
                    data.append("description", values.description)
                    data.append("sort", values.sort)

                    if (values.id > 0) {
                        props.update(values.id, data, MANAGER);
                    } else {
                        props.create(data, MANAGER);
                    }
                }}
                validationSchema={yup.object().shape({
                    [NEWS_FIELDS.NEWS_TITLE_FIELD]: yup.string()
                        .required(<FormattedMessage id="form.required" defaultMessage="required field"/>),
                    [NEWS_FIELDS.NEWS_PREVIEW_FIELD]: yup.string()
                        .required(<FormattedMessage id="form.required" defaultMessage="required field"/>),
                })}
            >
                {(props) => {
                    const {
                        errors,
                        setFieldValue,
                        isSubmitting,
                        handleSubmit,
                    } = props;
                    return (
                        <form onSubmit={handleSubmit}>
                            <DialogContent dividers>
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <Field
                                            name={NEWS_FIELDS.NEWS_TITLE_FIELD}
                                            type="text"
                                            fullWidth
                                            helperText=<FormattedMessage id="manager.site.news.title.helper"
                                            defaultMessage="news title"/>
                                        inputComponent={TextField}
                                        component={FormikAdapter}
                                        label=<FormattedMessage id="manager.site.news.title.label"
                                                                defaultMessage="Title"/>
                                        margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Button
                                            style={{marginTop: 35}}
                                            variant="outlined"
                                            component="label"
                                        >
                                            <FormattedMessage id="manager.site.news.photo" defaultMessage="Photo"/>
                                            <input hidden id="import" name="file" type="file" onChange={(event) => {
                                                setFieldValue("file", event.currentTarget.files[0]);
                                            }} className="form-control"/>
                                        </Button>
                                        <FormHelperText><FormattedMessage id="manager.site.news.photo.helper" defaultMessage="850px"/></FormHelperText>
                                        <div>{errors.import}</div>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Field
                                            style={{marginTop: 35}}
                                            name={NEWS_FIELDS.NEWS_MAIN_FIELD}
                                            type="checkbox"
                                            helperText=<FormattedMessage id="manager.site.news.main.helper"
                                            defaultMessage="on main page"/>
                                        inputComponent={FormikCheckbox}
                                        component={FormikAdapter}
                                        setFieldValue={setFieldValue}
                                        label=<FormattedMessage id="manager.site.news.main.label"
                                                                defaultMessage="Is main"/>
                                        margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            name={NEWS_FIELDS.NEWS_PREVIEW_FIELD}
                                            type="text"
                                            fullWidth
                                            helperText=<FormattedMessage id="manager.site.news.preview.helper"
                                            defaultMessage="a few sentences"/>
                                        inputComponent={TextField}
                                        component={FormikAdapter}
                                        label=<FormattedMessage id="manager.site.news.preview.label"
                                                                defaultMessage="Preview"/>
                                        margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            name={NEWS_FIELDS.NEWS_BODY_FIELD}
                                            type="text"
                                            fullWidth
                                            helperText=<FormattedMessage id="manager.site.news.body.helper"
                                            defaultMessage="news body"/>
                                        inputComponent={Redactor}
                                        setFieldValue={setFieldValue}
                                        component={FormikAdapter}
                                        label=<FormattedMessage id="manager.site.news.main.label"
                                                                defaultMessage="Body"/>
                                        margin="normal"
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={isSubmitting}>
                                    <FormattedMessage id="button.save" defaultMessage="Save"/>
                                </Button>
                            </DialogActions>
                        </form>
                    );
                }}
            </Formik>
        </Dialog>
    );
}