import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {MANAGER} from "../../../constants/Common";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import {Field, Formik} from "formik";
import * as yup from 'yup'
import FormikAdapter from "../../common/FormikAdapter";
import FormikSelectAdapter from "../../common/FormikSelectAdapter";
import AutocompleteSimple from "../../form/AutocompleteSimple";
import {FormattedMessage} from "react-intl";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const DIVISION_TEAM_FIELDS = {
    DIVISION_TEAM_ID_FIELD: 'id',
    DIVISION_TEAM_SEASON_ID_FIELD: 'seasonID',
    DIVISION_TEAM_COMPETITION_ID_FIELD: 'competitionID',
    DIVISION_TEAM_DIVISION_ID_FIELD: 'divisionID',
    DIVISION_TEAM_CITY_ID_FIELD: 'cityID',
    DIVISION_TEAM_TEAM_ID_FIELD: 'teamID',
    DIVISION_TEAM_PLACE_FIELD: 'place',
    DIVISION_TEAM_DESCRIPTION_FIELD: 'description',
    DIVISION_TEAM_SORT_FIELD: 'sort',
};

export default function DivisionTeamForm(mainProps) {

    const handleClose = () => {
        mainProps.show(false, MANAGER)
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"md"}
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={mainProps.open}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                <FormattedMessage id="division-team-form" defaultMessage="Division team form"/>
            </DialogTitle>
            <Formik
                initialValues={{
                    [DIVISION_TEAM_FIELDS.DIVISION_TEAM_ID_FIELD]: mainProps.model.id,
                    [DIVISION_TEAM_FIELDS.DIVISION_TEAM_SEASON_ID_FIELD]: mainProps.seasonID,
                    [DIVISION_TEAM_FIELDS.DIVISION_TEAM_COMPETITION_ID_FIELD]: mainProps.competitionID,
                    [DIVISION_TEAM_FIELDS.DIVISION_TEAM_DIVISION_ID_FIELD]: mainProps.divisionID,
                    [DIVISION_TEAM_FIELDS.DIVISION_TEAM_DESCRIPTION_FIELD]: mainProps.model.description,
                    [DIVISION_TEAM_FIELDS.DIVISION_TEAM_TEAM_ID_FIELD]: mainProps.model.team.id,
                    [DIVISION_TEAM_FIELDS.DIVISION_TEAM_CITY_ID_FIELD]: mainProps.model.team.city.id,
                    [DIVISION_TEAM_FIELDS.DIVISION_TEAM_PLACE_FIELD]: mainProps.model.place,
                    [DIVISION_TEAM_FIELDS.DIVISION_TEAM_SORT_FIELD]: mainProps.model.sort,
                }}

                onSubmit={(values, actions) => {
                    values.teamID = parseInt(values.teamID)
                    if (values.id > 0) {
                        mainProps.update(values.id, values, MANAGER);
                    } else {
                        mainProps.create(values, MANAGER);
                    }
                }}

                validationSchema={yup.object().shape({
                    [DIVISION_TEAM_FIELDS.DIVISION_TEAM_TEAM_ID_FIELD]: yup.number()
                        .required(<FormattedMessage id="form.required" defaultMessage="required field"/>),
                })}
            >
                {(props) => {
                    const {
                        isSubmitting,
                        handleSubmit,
                        handleChange,
                        setFieldValue,
                        values
                    } = props;

                    return (
                        <form onSubmit={handleSubmit}>
                            <DialogContent dividers>
                                <Grid container spacing={3}>
                                    <Grid item xs={4}>
                                        <Field
                                            name={DIVISION_TEAM_FIELDS.DIVISION_TEAM_CITY_ID_FIELD}
                                            type="select"
                                            helperText=<FormattedMessage id="manager.division-player.city.helper" defaultMessage="geolocation"/>
                                            setFieldValue={setFieldValue}
                                            entity="cities"
                                            inputComponent={AutocompleteSimple}
                                            component={FormikSelectAdapter}
                                            label=<FormattedMessage id="manager.division-player.city.label" defaultMessage="City"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Field
                                            name={DIVISION_TEAM_FIELDS.DIVISION_TEAM_TEAM_ID_FIELD}
                                            type="select"
                                            helperText=<FormattedMessage id="manager.division-team.form.team.helper" defaultMessage="team"/>
                                            onChange={handleChange}
                                            entity={"teams?filter=sport_type:"+mainProps.sportType+",city_id:" + values.cityID}
                                            setFieldValue={setFieldValue}
                                            inputComponent={AutocompleteSimple}
                                            component={FormikSelectAdapter}
                                            label=<FormattedMessage id="manager.division-team.form.team.label" defaultMessage="Team"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Field
                                            name={DIVISION_TEAM_FIELDS.DIVISION_TEAM_PLACE_FIELD}
                                            type="number"
                                            helperText=<FormattedMessage id="manager.division-team.form.place.helper" defaultMessage="rating place"/>
                                            inputComponent={TextField}
                                            component={FormikAdapter}
                                            label=<FormattedMessage id="manager.division-team.form.place.label" defaultMessage="Place"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            name={DIVISION_TEAM_FIELDS.DIVISION_TEAM_DESCRIPTION_FIELD}
                                            fullWidth
                                            helperText=<FormattedMessage id="manager.division-team.form.description.helper" defaultMessage="description"/>
                                            inputComponent={TextField}
                                            component={FormikAdapter}
                                            label=<FormattedMessage id="manager.division-team.form.description.helper" defaultMessage="Description"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            name={DIVISION_TEAM_FIELDS.DIVISION_TEAM_SORT_FIELD}
                                            type="number"
                                            helperText=<FormattedMessage id="manager.division-team.form.sort.helper" defaultMessage="sorting"/>
                                            inputComponent={TextField}
                                            component={FormikAdapter}
                                            label=<FormattedMessage id="manager.division-team.form.sort.label" defaultMessage="Sort"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={isSubmitting}>
                                    <FormattedMessage id="button.save" defaultMessage="Save"/>
                                </Button>
                            </DialogActions>
                        </form>
                    )
                        ;
                }}
            </Formik>
        </Dialog>
    );
}