import React from 'react'
import MaterialTable from 'material-table'
import {MANAGER} from "../../constants/Common";
import ComponentMenu from "../common/ComonentMenu";
import {FormattedMessage, useIntl} from "react-intl";

export default function DivisionPersonal(props) {
    let data = [];
    props.models.forEach(n => data.push(n));

    function handleShowForm() {
        props.showForm(true, MANAGER)
    }

    const intl = useIntl()

    return (
        <MaterialTable
            columns={[
                {title: 'ID', field: 'id'},
                {title: <FormattedMessage id="manager.division-personal.type" defaultMessage="type"/>, field: 'personalType'},
                {title: <FormattedMessage id="manager.division-personal.last-name" defaultMessage="last name"/>, field: 'people.surname'},
                {title: <FormattedMessage id="manager.division-personal.first-name" defaultMessage="first name"/>, field: 'people.name'},
                {title: <FormattedMessage id="manager.division-personal.patronymic" defaultMessage="patronymic"/>, field: 'people.patronymic'},
                {title: <FormattedMessage id="manager.division-personal.birthday" defaultMessage="birthday"/>, field: 'people.birthday'},
                {
                    field: 'menu',
                    render: rowData => <ComponentMenu id={rowData.id}
                                                      delete={props.delete}
                                                      view={props.view}/>,
                    cellStyle: {
                        width: 10,
                        padding: 0,
                    },
                },
            ]}
            options={{
                showTitle: true
            }}
            localization={
                {
                    toolbar: {
                        searchPlaceholder: intl.formatMessage({id: 'manager.division-personal-title',defaultMessage:"Personals"}),
                        searchTooltip: <FormattedMessage id="common.find" defaultMessage="Find"/>
                    },
                    body: {
                        emptyDataSourceMessage: <FormattedMessage id="manager.no_data" defaultMessage="no data"/>
                    }
                }
            }
            actions={[
                {
                    icon: 'add',
                    disabled: props.addDisabled,
                    tooltip: <FormattedMessage id="manager.division-personal-add" defaultMessage="add division personal"/>,
                    isFreeAction: true,
                    onClick: (event) => handleShowForm()
                }
            ]}
            title={intl.formatMessage({id: 'manager.division-personal.roster',defaultMessage:"Division team personals"})}
            data={data}
        />
    )
}