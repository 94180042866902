import React from 'react'
import MaterialTable from 'material-table'
import {ENDPOINT, MANAGER} from "../../constants/Common";
import ComponentMenu from "../common/ComonentMenu";
import red from "@material-ui/core/colors/red";
import blue from "@material-ui/core/colors/blue";
import green from "@material-ui/core/colors/green";
import grey from "@material-ui/core/colors/grey";
import yellow from "@material-ui/core/colors/yellow";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from '@mui/material/Zoom';
import {Avatar} from "@mui/material";
import CameraIcon from '@material-ui/icons/CameraAlt';
import InIcon from '@material-ui/icons/ArrowBack';
import OutIcon from '@material-ui/icons/ArrowForward';
import InTeamIcon from '@material-ui/icons/InsertEmoticon';
import PriorityIcon from '@material-ui/icons/PriorityHigh';
import FormIcon from '@material-ui/icons/Assignment';
import MedicIcon from '@material-ui/icons/LocalHospital';
import {FormattedMessage, useIntl} from "react-intl";
import {formatDate} from "../../helpers/api";

function PlayerAmplua(props) {
    const keeper = "GOALKEEPER"
    const defender = "DEFENDER"
    const midfield = "MIDFIELDER"
    const forward = "FORWARD"
    let color = red[500]
    let title = "status"
    let value = "status"

    switch (props.amplua) {
        case keeper:
            title = <FormattedMessage id="manager.division-player.amplua.keeper" defaultMessage="keeper"/>
            color = green[600]
            value = <FormattedMessage id="manager.division-player.amplua.keeper.letter" defaultMessage="K"/>
            break
        case defender:
            title = <FormattedMessage id="manager.division-player.amplua.defender" defaultMessage="defender"/>
            color = blue[600]
            value = <FormattedMessage id="manager.division-player.amplua.defender.letter" defaultMessage="D"/>
            break
        case midfield:
            title = <FormattedMessage id="manager.division-player.amplua.midfielder" defaultMessage="midfielder"/>
            color = green[500]
            value = <FormattedMessage id="manager.division-player.amplua.midfielder.letter" defaultMessage="M"/>
            break
        case forward:
            title = <FormattedMessage id="manager.division-player.amplua.forward" defaultMessage="forward"/>
            color = red[500]
            value = <FormattedMessage id="manager.division-player.amplua.forward.letter" defaultMessage="F"/>
            break;
        default:
            break;
    }

    return <Tooltip TransitionComponent={Zoom} TransitionProps={{timeout: 600}} title={title} arrow>
        <Avatar sx={{width: 30, height: 30, fontSize:14,bgcolor: color}}>{value}</Avatar>
    </Tooltip>
}

function PlayNumber(props) {
    let color = grey[600]

    switch (props.kind){
        case "CAPTAIN":
            color = yellow[700]
            break
    }

    return <Avatar sx={{width: 30, height: 30, fontSize:14, bgcolor: color}}>{props.number}</Avatar>
}

function showStatus(status) {
    let result = '';

    switch (status) {
        case "IN_SQUAD":
            result = <Tooltip title={<FormattedMessage id="manager.division-player.status.in-squad" defaultMessage="in squad"/>}><InTeamIcon style={{color: green[300]}}/></Tooltip>
            break;
        case "DECLARED":
            result = <Tooltip title={<FormattedMessage id="manager.division-player.status.declared" defaultMessage="declared"/>}><InIcon style={{color: green[300]}}/></Tooltip>
            break;
        case "UNDECLARED":
            result = <Tooltip title={<FormattedMessage id="manager.division-player.status.undeclared" defaultMessage="undeclared"/>}><OutIcon style={{color: blue[300]}}/></Tooltip>
            break;
        case "DISQUALIFIED":
            result = <Tooltip title={<FormattedMessage id="manager.division-player.status.disqualified" defaultMessage="disqualified"/>}><PriorityIcon style={{color: red[300]}}/></Tooltip>
            break;
        default:
            result = '';
            break;
    }
    return result
}

export default function DivisionPlayers(props) {
    let data = [];

    props.models.forEach(n => data.push(n));

    function handleShowForm() {
        props.showForm(true, MANAGER)
    }

    function handleAssigment() {
        let url = ENDPOINT + 'divisionTeams/'+props.divisionTeamID+'/roster';
        window.open(url, '_blank').focus();
    }

    const intl = useIntl()

    return (
        <MaterialTable
            columns={[
                {
                    title: 'id', field: 'id', cellStyle: {padding: 0, paddingLeft:20},
                },
                {
                    title: <FormattedMessage id="manager.division-player.avatar" defaultMessage="avatar"/>,
                    field: 'avatar',
                    cellStyle: {padding: 0, paddingLeft:20},
                    render: rowData => <Avatar alt="contact" src={"https://r-stat.org/api/v2/peoples/"+rowData.people.id+"/photo"} />
                },
                {
                    title: <FormattedMessage id="manager.division-player.number" defaultMessage="number"/>, field: 'playNumber',
                    cellStyle: {padding: 0, paddingLeft:20},
                    render: rowData => <PlayNumber number={rowData.playNumber} kind={rowData.playerType}/>
                },
                {
                    title: <FormattedMessage id="manager.division-player.amplua" defaultMessage="amplua"/>,
                    field: 'amplua',
                    cellStyle: {padding: 0, paddingLeft:20},
                    render: rowData => <PlayerAmplua amplua={rowData.amplua}/>
                },
                {
                    title: <FormattedMessage id="manager.division-player.last-name" defaultMessage="last name"/>,
                    field: 'people.surname',
                    cellStyle: {padding: 0, paddingLeft:20},
                },
                {
                    title: <FormattedMessage id="manager.division-player.first-name" defaultMessage="first name"/>,
                    field: 'people.name',
                    cellStyle: {padding: 0, paddingLeft:20},
                },
                {
                    title: <FormattedMessage id="manager.division-player.patronymic" defaultMessage="patronymic"/>,
                    field: 'people.patronymic',
                    cellStyle: {padding: 0, paddingLeft:20},
                },
                {
                    title: <FormattedMessage id="manager.division-player.birthday" defaultMessage="birthday"/>,
                    field: 'people.birthday',
                    render: rowData => formatDate(rowData.people.birthday),
                    cellStyle: {padding: 0, paddingLeft:20},
                },
                {
                    render: rowData => <CameraIcon color="disabled"/>,
                    cellStyle: {padding: 0, paddingLeft:20},
                },
                {
                    render: rowData => <FormIcon color="disabled"/>,
                    cellStyle: {padding: 0, paddingLeft:20},
                },
                {
                    render: rowData => <MedicIcon color="disabled"/>,
                    cellStyle: {padding: 0, paddingLeft:20},
                },
                {
                    render: rowData => showStatus(rowData.status),
                    cellStyle: {padding: 0, paddingLeft:20},
                },
                {
                    field: 'menu',
                    render: rowData => <ComponentMenu id={rowData.id} delete={props.delete} view={props.view}/>,
                    cellStyle: {width: 5, padding: 0},
                },
            ]}
            options={{
                header: false,
                showTitle: true,
                pageSize: 5,
                rowStyle: {fontSize: 12}
            }}
            localization={
                {
                    toolbar: {
                        searchPlaceholder: intl.formatMessage({id: 'manager.division-player-title',defaultMessage:"Players"}),
                        searchTooltip: <FormattedMessage id="common.find" defaultMessage="Find"/>
                    },
                    body: {
                        emptyDataSourceMessage: <FormattedMessage id="manager.no_data" defaultMessage="no data"/>
                    }
                }
            }
            actions={[
                {
                    icon: 'assignment_ind',
                    tooltip: <FormattedMessage id="manager.division-player.application" defaultMessage="application"/>,
                    disabled: props.addDisabled,
                    isFreeAction: true,
                    onClick: (event) => handleAssigment()
                },
                {
                    icon: 'add',
                    tooltip: <FormattedMessage id="manager.division-player-add" defaultMessage="add division player"/>,
                    disabled: props.addDisabled,
                    isFreeAction: true,
                    onClick: (event) => handleShowForm()
                }
            ]}
            title={intl.formatMessage({id: 'manager.division-player.roster',defaultMessage:"Players"})}
            isLoading={props.loading}
            data={data}
        />
    )
}