import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import NewsIcon from '@material-ui/icons/Receipt';
import AlbumIcon from '@material-ui/icons/Photo';
import VideoIcon from '@material-ui/icons/Videocam';
import DocIcon from '@material-ui/icons/Save';
import PersonIcon from '@material-ui/icons/Person';
import {
    SITE_PAGE_ALBUM,
    SITE_PAGE_CONTACT,
    SITE_PAGE_DOC,
    SITE_PAGE_NEWS, SITE_PAGE_PHOTO,
    SITE_PAGE_VIDEO
} from "../../../constants/Manager";
import Paper from "@mui/material/Paper";
import List from "@material-ui/core/List";
import {ListItem} from "@mui/material";
import {FormattedMessage} from "react-intl";

const styles = theme => ({
    menuItem: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& $primary, & $icon': {
                color: theme.palette.common.white,
            },
        },
    },
    primary: {},
    icon: {},
    root: {
        width: '100%',
        maxWidth: 230,
        backgroundColor: theme.palette.background.paper,
    },
});

function SiteMenu(props) {
    return (
        <Paper>
            <List component="nav" aria-label="main mailbox folders">
                <ListItem
                    button
                    selected={props.page === SITE_PAGE_NEWS}
                    onClick={(event) => props.handleListItemClick(event, SITE_PAGE_NEWS)}
                >
                    <ListItemIcon>
                        <NewsIcon />
                    </ListItemIcon>
                    <ListItemText primary=<FormattedMessage id="manager.site.news" defaultMessage="News"/> />
                </ListItem>
                <ListItem
                    button
                    selected={props.page === SITE_PAGE_ALBUM}
                    onClick={(event) => props.handleListItemClick(event, SITE_PAGE_ALBUM)}
                >
                    <ListItemIcon>
                        <AlbumIcon />
                    </ListItemIcon>
                    <ListItemText primary=<FormattedMessage id="manager.site.album" defaultMessage="Albums"/> />
                </ListItem>
                <ListItem
                    button
                    selected={props.page === SITE_PAGE_PHOTO}
                    onClick={(event) => props.handleListItemClick(event, SITE_PAGE_PHOTO)}
                >
                    <ListItemIcon>
                        <AlbumIcon />
                    </ListItemIcon>
                    <ListItemText primary=<FormattedMessage id="manager.site.photo" defaultMessage="Photos"/> />
                </ListItem>
                <ListItem
                    button selected={props.page === SITE_PAGE_VIDEO}
                    onClick={(event) => props.handleListItemClick(event, SITE_PAGE_VIDEO)}
                >
                    <ListItemIcon>
                        <VideoIcon />
                    </ListItemIcon>
                    <ListItemText primary=<FormattedMessage id="manager.site.video" defaultMessage="Videos"/> />
                </ListItem>
                <ListItem
                    button
                    selected={props.page === SITE_PAGE_DOC}
                    onClick={(event) => props.handleListItemClick(event, SITE_PAGE_DOC)}
                >
                    <ListItemIcon>
                        <DocIcon />
                    </ListItemIcon>
                    <ListItemText primary=<FormattedMessage id="manager.site.documents" defaultMessage="Documents"/> />
                </ListItem>
                <ListItem
                    button
                    selected={props.page === SITE_PAGE_CONTACT}
                    onClick={(event) => props.handleListItemClick(event, SITE_PAGE_CONTACT)}
                >
                    <ListItemIcon>
                        <PersonIcon />
                    </ListItemIcon>
                    <ListItemText primary=<FormattedMessage id="manager.site.contact" defaultMessage="Contacts"/> />
                </ListItem>
            </List>
        </Paper>
    );
}

SiteMenu.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SiteMenu);