import {
    ALBUM_SHOW_FORM, ALBUM_SET_ID,
} from '../../constants/Site';
import {MANAGER} from '../../constants/Common';
import {makeSiteDispatch, rstatFormApi, siteApi, siteFormApi} from "../../helpers/api";
import {ALBUM, CREATE, DELETE, LIST, PHOTO, UPDATE, VIEW} from "../../constants/Global";

export function showAlbumForm(open, type) {
    return (dispatch) => {
        dispatch({
            type: type + ALBUM_SHOW_FORM,
            payload: open
        })
    }
}

export function setAlbumID(id, type) {
    return (dispatch) => {
        dispatch({
            type: type + ALBUM_SET_ID,
            payload: id
        })
    }
}

export function viewAlbum(id, type) {
    return (dispatch) => {
        siteApi.get('album/' + id).then(function (response) {
            dispatch(makeSiteDispatch(MANAGER, VIEW, ALBUM, response));
        }).catch(function (error) {
            console.log(error)
            dispatch(makeSiteDispatch(MANAGER, VIEW, ALBUM, error.response));
        });
    }
}

export function listAlbums(id, type) {
    return (dispatch) => {
        siteApi.get('album/list/' + id).then(function (response) {
            dispatch(makeSiteDispatch(type, LIST, ALBUM, response));
        }).catch(function (error) {
            dispatch(makeSiteDispatch(type, LIST, ALBUM, error))
        });
    }
}

export function listPhotos(id, type) {
    return (dispatch) => {
        siteApi.get('photos?albumID=' + id).then(function (response) {
            dispatch(makeSiteDispatch(type, LIST, PHOTO, response));
        }).catch(function (error) {
            dispatch(makeSiteDispatch(type, LIST, PHOTO, error))
        });
    }
}

export function deleteAlbum(id, type) {
    return (dispatch) => {
        siteApi.delete('album/' + id).then(function (response) {
            dispatch(makeSiteDispatch(MANAGER, DELETE, ALBUM, response,parseInt(id, 10)));
        }).catch(function (error) {
            dispatch(makeSiteDispatch(MANAGER, DELETE, ALBUM, error.response));
        });
    }
}

export function createAlbum(body, type) {
    return (dispatch) => {
        siteApi.post('album', body).then(function (response) {
            dispatch(makeSiteDispatch(MANAGER, CREATE, ALBUM, response));
        }).catch(function (error) {
            dispatch(makeSiteDispatch(MANAGER, CREATE, ALBUM, error.response));
        });
    }
}

export function updateAlbum(id, body, type) {
    return (dispatch) => {
        siteFormApi.put('album/' + id, body).then(function (response) {
            dispatch(makeSiteDispatch(MANAGER, UPDATE, ALBUM, response));
        }).catch(function (error) {
            dispatch(makeSiteDispatch(MANAGER, UPDATE, ALBUM, error.response));
        });
    }
}
