/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import {makeStyles} from '@material-ui/core/styles';
import {rstatApi} from "../../helpers/api";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import theme from "../../theme";


const useStyles = makeStyles({
    option: {
        fontSize: 15,
        '& > span': {
            margin: 20,
            fontSize: 18,
        },
    },
    formControl: {
        marginTop: theme.spacing(2),
        marginLeft:0,
    }
});

export default function AutocompleteSelect(props) {
    const classes = useStyles();
    const [options, setOptions] = React.useState([]);

    React.useEffect(() => {
        let active = true;

        (async () => {
            rstatApi.get('/' + props.entity).then(function (response) {
                if (active) {
                    setOptions(response.data.data.map((item) => item));
                }
            }).catch(function (error) {
                console.log(error)
            });

        })();

        return () => {
            active = false;
        };
    }, [props.entity]);

    const handleChange = (value) => {
        if (value !== null && value.id !== undefined) {
            props.setFieldValue(props.name, value.id)
        }
    };

    return (
        <FormControl variant="outlined" className={classes.formControl} error={props.error}>
            <Autocomplete
                id={props.name}
                name={props.name}
                sx={{ width: 200 }}
                options={options}
                autoHighlight
                fullWidth
                value={options.find(v => v.id === props.value) || {}}
                getOptionLabel={(option) => option.name || ""}
                onChange={(_, value) => handleChange(value)}
                onInputChange={(_, value) => handleChange(value)}
                renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        {option.name}
                    </Box>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={props.label}
                        variant="outlined"
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                    />
                )}
            />
            <FormHelperText>{props.helperText}</FormHelperText>
        </FormControl>
    );
}