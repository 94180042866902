import {
    COUNTRY_CREATE_FAILURE,
    COUNTRY_DELETE_FAILURE,
    COUNTRY_LIST_FAILURE,
    COUNTRY_SHOW_FORM,
    COUNTRY_UPDATE_FAILURE,
    COUNTRY_VIEW_FAILURE
} from '../constants/Country';

import {makeDispatch, makeDispatch500, rstatApi} from '../helpers/api'
import {CORE} from "../constants/Common";
import {CREATE, DELETE, LIST, COUNTRY, UPDATE, VIEW} from "../constants/Global";

export function showCountryForm(open, type) {
    return (dispatch) => {
        dispatch({
            type: type + COUNTRY_SHOW_FORM,
            payload: open
        })
    }
}

export function viewCountry(id, type) {
    return (dispatch) => {
        rstatApi.get('/countries/' + id).then(function (response) {
            dispatch(makeDispatch(CORE, VIEW, COUNTRY, response));
        }).catch(function (error) {
            dispatch(makeDispatch500(type, COUNTRY_VIEW_FAILURE, error))
        });
    }
}

export function updateCountry(id, body, type) {
    return (dispatch) => {
        rstatApi.put('/countries/' + id, body).then(function (response) {
            dispatch(makeDispatch(CORE, UPDATE, COUNTRY, response));
        }).catch(function (error) {
            dispatch(makeDispatch500(type, COUNTRY_UPDATE_FAILURE, error))
        });
    }
}

export function listCountries(query = '', type) {
    return (dispatch) => {
        rstatApi.get('/countries' + query).then(function (response) {
            dispatch(makeDispatch(CORE, LIST, COUNTRY, response));
        }).catch(function (error) {
            dispatch(makeDispatch500(type, COUNTRY_LIST_FAILURE, error))
        });
    }
}

export function createCountry(body, type) {
    return (dispatch) => {
        rstatApi.post('/countries', body).then(function (response) {
            dispatch(makeDispatch(CORE, CREATE, COUNTRY, response));
        }).catch(function (error) {
            dispatch(makeDispatch500(type, COUNTRY_CREATE_FAILURE, error))
        });
    }
}

export function deleteCountry(id, type) {
    return (dispatch) => {
        rstatApi.delete('/countries/' + id,).then(function (response) {
            dispatch(makeDispatch(CORE, DELETE, COUNTRY, response, parseInt(id, 10)));
        }).catch(function (error) {
            dispatch(makeDispatch500(type, COUNTRY_DELETE_FAILURE, error))
        })
    }
}