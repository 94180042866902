import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import {FormControl, FormControlLabel, FormGroup, FormHelperText} from "@mui/material";
import Box from "@mui/material/Box";

export default function FormikCheckbox(props) {
    function handleClick(e, value) {
        props.setFieldValue(props.name, value)
    }

    return (
        <Box sx={{display: 'flex'}} style={{paddingLeft:10}}>
            <FormControl sx={{m: 3}} component="fieldset" variant="standard" error={props.error}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox checked={props.value} onChange={handleClick} name={props.name}/>
                        }
                        label={props.label}
                    />
                </FormGroup>
                <FormHelperText>{props.helperText}</FormHelperText>
            </FormControl>
        </Box>
    );
}