export const MANAGER_NEWS_LIST_REQUEST = 'MANAGER_NEWS_LIST_REQUEST';
export const MANAGER_NEWS_LIST_FAILURE = 'MANAGER_NEWS_LIST_FAILURE';
export const MANAGER_NEWS_LIST_SUCCESS = 'MANAGER_NEWS_LIST_SUCCESS';
export const MANAGER_NEWS_SHOW_FORM = 'MANAGER_NEWS_SHOW_FORM';
export const MANAGER_NEWS_CREATE_REQUEST = 'MANAGER_NEWS_CREATE_REQUEST';
export const MANAGER_NEWS_CREATE_SUCCESS = 'MANAGER_NEWS_CREATE_SUCCESS';
export const MANAGER_NEWS_CREATE_FAILURE = 'MANAGER_NEWS_CREATE_FAILURE';
export const MANAGER_NEWS_VIEW_SUCCESS = 'MANAGER_NEWS_VIEW_SUCCESS';
export const MANAGER_NEWS_UPDATE_REQUEST = 'MANAGER_NEWS_UPDATE_REQUEST';
export const MANAGER_NEWS_UPDATE_SUCCESS = 'MANAGER_NEWS_UPDATE_SUCCESS';
export const MANAGER_NEWS_UPDATE_FAILURE = 'MANAGER_NEWS_UPDATE_FAILURE';
export const MANAGER_NEWS_DELETE_REQUEST = 'MANAGER_NEWS_DELETE_REQUEST';
export const MANAGER_NEWS_DELETE_SUCCESS = 'MANAGER_NEWS_DELETE_SUCCESS';
export const MANAGER_NEWS_DELETE_FAILURE = 'MANAGER_NEWS_DELETE_FAILURE';
export const MANAGER_NEWS_SET_ID = 'MANAGER_NEWS_SET_ID';

export const NEWS_LIST_REQUEST = 'NEWS_LIST_REQUEST';
export const NEWS_LIST_FAILURE = 'NEWS_LIST_FAILURE';
export const NEWS_LIST_SUCCESS = 'NEWS_LIST_SUCCESS';
export const NEWS_SHOW_FORM = 'NEWS_SHOW_FORM';
export const NEWS_CREATE_REQUEST = 'NEWS_CREATE_REQUEST';
export const NEWS_CREATE_SUCCESS = 'NEWS_CREATE_SUCCESS';
export const NEWS_CREATE_FAILURE = 'NEWS_CREATE_FAILURE';
export const NEWS_UPDATE_REQUEST = 'NEWS_UPDATE_REQUEST';
export const NEWS_UPDATE_SUCCESS = 'NEWS_UPDATE_SUCCESS';
export const NEWS_UPDATE_FAILURE = 'NEWS_UPDATE_FAILURE';
export const NEWS_DELETE_REQUEST = 'NEWS_DELETE_REQUEST';
export const NEWS_DELETE_SUCCESS = 'NEWS_DELETE_SUCCESS';
export const NEWS_DELETE_FAILURE = 'NEWS_DELETE_FAILURE';
export const NEWS_VIEW_SUCCESS = 'NEWS_VIEW_SUCCESS';
export const NEWS_VIEW_FAILURE = 'NEWS_VIEW_FAILURE';
export const NEWS_SET_ID = 'NEWS_SET_ID';

export const MANAGER_PHOTO_LIST_SUCCESS = 'MANAGER_PHOTO_LIST_SUCCESS';

export const MANAGER_VIDEO_LIST_REQUEST = 'MANAGER_VIDEO_LIST_REQUEST';
export const MANAGER_VIDEO_LIST_FAILURE = 'MANAGER_VIDEO_LIST_FAILURE';
export const MANAGER_VIDEO_LIST_SUCCESS = 'MANAGER_VIDEO_LIST_SUCCESS';
export const MANAGER_VIDEO_SHOW_FORM = 'MANAGER_VIDEO_SHOW_FORM';
export const MANAGER_VIDEO_CREATE_REQUEST = 'MANAGER_VIDEO_CREATE_REQUEST';
export const MANAGER_VIDEO_CREATE_SUCCESS = 'MANAGER_VIDEO_CREATE_SUCCESS';
export const MANAGER_VIDEO_CREATE_FAILURE = 'MANAGER_VIDEO_CREATE_FAILURE';
export const MANAGER_VIDEO_VIEW_SUCCESS = 'MANAGER_VIDEO_VIEW_SUCCESS';
export const MANAGER_VIDEO_UPDATE_REQUEST = 'MANAGER_VIDEO_UPDATE_REQUEST';
export const MANAGER_VIDEO_UPDATE_SUCCESS = 'MANAGER_VIDEO_UPDATE_SUCCESS';
export const MANAGER_VIDEO_UPDATE_FAILURE = 'MANAGER_VIDEO_UPDATE_FAILURE';
export const MANAGER_VIDEO_DELETE_REQUEST = 'MANAGER_VIDEO_DELETE_REQUEST';
export const MANAGER_VIDEO_DELETE_SUCCESS = 'MANAGER_VIDEO_DELETE_SUCCESS';
export const MANAGER_VIDEO_DELETE_FAILURE = 'MANAGER_VIDEO_DELETE_FAILURE';
export const MANAGER_VIDEO_SET_ID = 'MANAGER_VIDEO_SET_ID';

export const VIDEO_LIST_REQUEST = 'VIDEO_LIST_REQUEST';
export const VIDEO_LIST_FAILURE = 'VIDEO_LIST_FAILURE';
export const VIDEO_LIST_SUCCESS = 'VIDEO_LIST_SUCCESS';
export const VIDEO_SHOW_FORM = 'VIDEO_SHOW_FORM';
export const VIDEO_CREATE_REQUEST = 'VIDEO_CREATE_REQUEST';
export const VIDEO_CREATE_SUCCESS = 'VIDEO_CREATE_SUCCESS';
export const VIDEO_CREATE_FAILURE = 'VIDEO_CREATE_FAILURE';
export const VIDEO_UPDATE_REQUEST = 'VIDEO_UPDATE_REQUEST';
export const VIDEO_UPDATE_SUCCESS = 'VIDEO_UPDATE_SUCCESS';
export const VIDEO_UPDATE_FAILURE = 'VIDEO_UPDATE_FAILURE';
export const VIDEO_DELETE_REQUEST = 'VIDEO_DELETE_REQUEST';
export const VIDEO_DELETE_SUCCESS = 'VIDEO_DELETE_SUCCESS';
export const VIDEO_DELETE_FAILURE = 'VIDEO_DELETE_FAILURE';
export const VIDEO_VIEW_SUCCESS = 'VIDEO_VIEW_SUCCESS';
export const VIDEO_VIEW_FAILURE = 'VIDEO_VIEW_FAILURE';
export const VIDEO_SET_ID = 'VIDEO_SET_ID';


export const MANAGER_ALBUM_LIST_REQUEST = 'MANAGER_ALBUM_LIST_REQUEST';
export const MANAGER_ALBUM_LIST_FAILURE = 'MANAGER_ALBUM_LIST_FAILURE';
export const MANAGER_ALBUM_LIST_SUCCESS = 'MANAGER_ALBUM_LIST_SUCCESS';
export const MANAGER_ALBUM_SHOW_FORM = 'MANAGER_ALBUM_SHOW_FORM';
export const MANAGER_ALBUM_CREATE_REQUEST = 'MANAGER_ALBUM_CREATE_REQUEST';
export const MANAGER_ALBUM_CREATE_SUCCESS = 'MANAGER_ALBUM_CREATE_SUCCESS';
export const MANAGER_ALBUM_CREATE_FAILURE = 'MANAGER_ALBUM_CREATE_FAILURE';
export const MANAGER_ALBUM_VIEW_SUCCESS = 'MANAGER_ALBUM_VIEW_SUCCESS';
export const MANAGER_ALBUM_UPDATE_REQUEST = 'MANAGER_ALBUM_UPDATE_REQUEST';
export const MANAGER_ALBUM_UPDATE_SUCCESS = 'MANAGER_ALBUM_UPDATE_SUCCESS';
export const MANAGER_ALBUM_UPDATE_FAILURE = 'MANAGER_ALBUM_UPDATE_FAILURE';
export const MANAGER_ALBUM_DELETE_REQUEST = 'MANAGER_ALBUM_DELETE_REQUEST';
export const MANAGER_ALBUM_DELETE_SUCCESS = 'MANAGER_ALBUM_DELETE_SUCCESS';
export const MANAGER_ALBUM_DELETE_FAILURE = 'MANAGER_ALBUM_DELETE_FAILURE';
export const MANAGER_ALBUM_SET_ID = 'MANAGER_ALBUM_SET_ID';

export const ALBUM_LIST_REQUEST = 'ALBUM_LIST_REQUEST';
export const ALBUM_LIST_FAILURE = 'ALBUM_LIST_FAILURE';
export const ALBUM_LIST_SUCCESS = 'ALBUM_LIST_SUCCESS';
export const ALBUM_SHOW_FORM = 'ALBUM_SHOW_FORM';
export const ALBUM_CREATE_REQUEST = 'ALBUM_CREATE_REQUEST';
export const ALBUM_CREATE_SUCCESS = 'ALBUM_CREATE_SUCCESS';
export const ALBUM_CREATE_FAILURE = 'ALBUM_CREATE_FAILURE';
export const ALBUM_UPDATE_REQUEST = 'ALBUM_UPDATE_REQUEST';
export const ALBUM_UPDATE_SUCCESS = 'ALBUM_UPDATE_SUCCESS';
export const ALBUM_UPDATE_FAILURE = 'ALBUM_UPDATE_FAILURE';
export const ALBUM_DELETE_REQUEST = 'ALBUM_DELETE_REQUEST';
export const ALBUM_DELETE_SUCCESS = 'ALBUM_DELETE_SUCCESS';
export const ALBUM_DELETE_FAILURE = 'ALBUM_DELETE_FAILURE';
export const ALBUM_VIEW_SUCCESS = 'ALBUM_VIEW_SUCCESS';
export const ALBUM_VIEW_FAILURE = 'ALBUM_VIEW_FAILURE';
export const ALBUM_SET_ID = 'ALBUM_SET_ID';

export const MANAGER_DOC_LIST_REQUEST = 'MANAGER_DOC_LIST_REQUEST';
export const MANAGER_DOC_LIST_FAILURE = 'MANAGER_DOC_LIST_FAILURE';
export const MANAGER_DOC_LIST_SUCCESS = 'MANAGER_DOC_LIST_SUCCESS';
export const MANAGER_DOC_SHOW_FORM = 'MANAGER_DOC_SHOW_FORM';
export const MANAGER_DOC_CREATE_REQUEST = 'MANAGER_DOC_CREATE_REQUEST';
export const MANAGER_DOC_CREATE_SUCCESS = 'MANAGER_DOC_CREATE_SUCCESS';
export const MANAGER_DOC_CREATE_FAILURE = 'MANAGER_DOC_CREATE_FAILURE';
export const MANAGER_DOC_VIEW_SUCCESS = 'MANAGER_DOC_VIEW_SUCCESS';
export const MANAGER_DOC_UPDATE_REQUEST = 'MANAGER_DOC_UPDATE_REQUEST';
export const MANAGER_DOC_UPDATE_SUCCESS = 'MANAGER_DOC_UPDATE_SUCCESS';
export const MANAGER_DOC_UPDATE_FAILURE = 'MANAGER_DOC_UPDATE_FAILURE';
export const MANAGER_DOC_DELETE_REQUEST = 'MANAGER_DOC_DELETE_REQUEST';
export const MANAGER_DOC_DELETE_SUCCESS = 'MANAGER_DOC_DELETE_SUCCESS';
export const MANAGER_DOC_DELETE_FAILURE = 'MANAGER_DOC_DELETE_FAILURE';
export const MANAGER_DOC_SET_ID = 'MANAGER_DOC_SET_ID';

export const DOC_LIST_REQUEST = 'DOC_LIST_REQUEST';
export const DOC_LIST_FAILURE = 'DOC_LIST_FAILURE';
export const DOC_LIST_SUCCESS = 'DOC_LIST_SUCCESS';
export const DOC_SHOW_FORM = 'DOC_SHOW_FORM';
export const DOC_CREATE_REQUEST = 'DOC_CREATE_REQUEST';
export const DOC_CREATE_SUCCESS = 'DOC_CREATE_SUCCESS';
export const DOC_CREATE_FAILURE = 'DOC_CREATE_FAILURE';
export const DOC_UPDATE_REQUEST = 'DOC_UPDATE_REQUEST';
export const DOC_UPDATE_SUCCESS = 'DOC_UPDATE_SUCCESS';
export const DOC_UPDATE_FAILURE = 'DOC_UPDATE_FAILURE';
export const DOC_DELETE_REQUEST = 'DOC_DELETE_REQUEST';
export const DOC_DELETE_SUCCESS = 'DOC_DELETE_SUCCESS';
export const DOC_DELETE_FAILURE = 'DOC_DELETE_FAILURE';
export const DOC_VIEW_SUCCESS = 'DOC_VIEW_SUCCESS';
export const DOC_VIEW_FAILURE = 'DOC_VIEW_FAILURE';
export const DOC_SET_ID = 'DOC_SET_ID';

export const MANAGER_CONTACT_LIST_REQUEST = 'MANAGER_CONTACT_LIST_REQUEST';
export const MANAGER_CONTACT_LIST_FAILURE = 'MANAGER_CONTACT_LIST_FAILURE';
export const MANAGER_CONTACT_LIST_SUCCESS = 'MANAGER_CONTACT_LIST_SUCCESS';
export const MANAGER_CONTACT_SHOW_FORM = 'MANAGER_CONTACT_SHOW_FORM';
export const MANAGER_CONTACT_CREATE_REQUEST = 'MANAGER_CONTACT_CREATE_REQUEST';
export const MANAGER_CONTACT_CREATE_SUCCESS = 'MANAGER_CONTACT_CREATE_SUCCESS';
export const MANAGER_CONTACT_CREATE_FAILURE = 'MANAGER_CONTACT_CREATE_FAILURE';
export const MANAGER_CONTACT_VIEW_SUCCESS = 'MANAGER_CONTACT_VIEW_SUCCESS';
export const MANAGER_CONTACT_UPDATE_REQUEST = 'MANAGER_CONTACT_UPDATE_REQUEST';
export const MANAGER_CONTACT_UPDATE_SUCCESS = 'MANAGER_CONTACT_UPDATE_SUCCESS';
export const MANAGER_CONTACT_UPDATE_FAILURE = 'MANAGER_CONTACT_UPDATE_FAILURE';
export const MANAGER_CONTACT_DELETE_REQUEST = 'MANAGER_CONTACT_DELETE_REQUEST';
export const MANAGER_CONTACT_DELETE_SUCCESS = 'MANAGER_CONTACT_DELETE_SUCCESS';
export const MANAGER_CONTACT_DELETE_FAILURE = 'MANAGER_CONTACT_DELETE_FAILURE';
export const MANAGER_CONTACT_SET_ID = 'MANAGER_CONTACT_SET_ID';

export const CONTACT_LIST_REQUEST = 'CONTACT_LIST_REQUEST';
export const CONTACT_LIST_FAILURE = 'CONTACT_LIST_FAILURE';
export const CONTACT_LIST_SUCCESS = 'CONTACT_LIST_SUCCESS';
export const CONTACT_SHOW_FORM = 'CONTACT_SHOW_FORM';
export const CONTACT_CREATE_REQUEST = 'CONTACT_CREATE_REQUEST';
export const CONTACT_CREATE_SUCCESS = 'CONTACT_CREATE_SUCCESS';
export const CONTACT_CREATE_FAILURE = 'CONTACT_CREATE_FAILURE';
export const CONTACT_UPDATE_REQUEST = 'CONTACT_UPDATE_REQUEST';
export const CONTACT_UPDATE_SUCCESS = 'CONTACT_UPDATE_SUCCESS';
export const CONTACT_UPDATE_FAILURE = 'CONTACT_UPDATE_FAILURE';
export const CONTACT_DELETE_REQUEST = 'CONTACT_DELETE_REQUEST';
export const CONTACT_DELETE_SUCCESS = 'CONTACT_DELETE_SUCCESS';
export const CONTACT_DELETE_FAILURE = 'CONTACT_DELETE_FAILURE';
export const CONTACT_VIEW_SUCCESS = 'CONTACT_VIEW_SUCCESS';
export const CONTACT_VIEW_FAILURE = 'CONTACT_VIEW_FAILURE';
export const CONTACT_SET_ID = 'CONTACT_SET_ID';
