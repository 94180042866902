import {
    DIVISION_TEAM_CREATE_FAILURE,
    DIVISION_TEAM_DELETE_FAILURE,
    DIVISION_TEAM_LIST_FAILURE,
    DIVISION_TEAM_LIST_REQUEST,
    DIVISION_TEAM_SHOW_FORM,
    DIVISION_TEAM_UPDATE_FAILURE,
    DIVISION_TEAM_VIEW_FAILURE,
    MANAGER_DIVISION_TEAM_CITY_SELECT
} from '../constants/DivisionTeam';

import {makeDispatch, makeDispatch500, rstatApi} from '../helpers/api'
import {MANAGER} from "../constants/Common";
import {CREATE, DELETE, DIVISION_PLAYER, DIVISION_TEAM, LIST, UPDATE, VIEW} from "../constants/Global";

export function showDivisionTeamForm(open, type) {
    return (dispatch) => {
        dispatch({
            type: type + DIVISION_TEAM_SHOW_FORM,
            payload: open
        })
    }
}

export function viewDivisionTeam(id, type) {
    return (dispatch) => {
        rstatApi.get('/divisionTeams/' + id).then(function (response) {
            dispatch(makeDispatch(MANAGER, VIEW, DIVISION_TEAM, response));
        }).catch(function (error) {
            dispatch(makeDispatch(MANAGER, VIEW, DIVISION_TEAM, error.response));
        });
    }
}

export function updateDivisionTeam(id, body, type) {
    return (dispatch) => {
        rstatApi.put('/divisionTeams/' + id, body).then(function (response) {
            dispatch(makeDispatch(MANAGER, UPDATE, DIVISION_TEAM, response));
        }).catch(function (error) {
            dispatch(makeDispatch(MANAGER, UPDATE, DIVISION_TEAM, error.response));
        });
    }
}

export function listDivisionTeams(query = '', type) {
    let divisionID = 0;
    let result = query.match(/division_id:(\d+)/i);
    if (result !== null) {
        divisionID = parseInt(result[1], 10)
    }
    return (dispatch) => {
        dispatch({
            type: type + DIVISION_TEAM_LIST_REQUEST,
            payload: divisionID,
        });
        rstatApi.get('/divisionTeams' + query).then(function (response) {
            dispatch(makeDispatch(MANAGER, LIST, DIVISION_TEAM, response));
        }).catch(function (error) {
            dispatch(makeDispatch(MANAGER, LIST, DIVISION_TEAM, error.response));
        });
    }
}

export function createDivisionTeam(body, type) {
    return (dispatch) => {
        rstatApi.post('/divisionTeams', body).then(function (response) {
            dispatch(makeDispatch(MANAGER, CREATE, DIVISION_TEAM, response));
        }).catch(function (error) {
            dispatch(makeDispatch(MANAGER, CREATE, DIVISION_TEAM, error.response));
        });
    }
}

export function deleteDivisionTeam(id, type) {
    return (dispatch) => {
        rstatApi.delete('/divisionTeams/' + id,).then(function (response) {
            dispatch(makeDispatch(MANAGER, DELETE, DIVISION_TEAM, response, parseInt(id, 10)));
        }).catch(function (error) {
            dispatch(makeDispatch(MANAGER, DELETE, DIVISION_TEAM, error.response));
        })
    }
}

export function cityDivisionTeam(id) {
    return (dispatch) => {
        dispatch({
            type: MANAGER_DIVISION_TEAM_CITY_SELECT,
            payload: id
        })
    }
}