import React from 'react';
import Box from '@material-ui/core/Box';
import Seasons from "../components/manager/Seasons";
import Competitions from "../components/manager/Competitions";
import Divisions from "../components/manager/Divisions";
import DivisionTeams from "../components/manager/DivisionTeams";
import ManagerTabs from "../components/manager/ManagerTabs";
import * as seasonActions from '../actions/SeasonActions'
import * as albumActions from '../actions/site/AlbumActions'
import * as userActions from '../actions/UserActions'
import * as newsActions from '../actions/site/NewsActions'
import * as docActions from '../actions/site/DocActions'
import * as contactActions from '../actions/site/ContactActions'
import * as videoActions from '../actions/site/VideoActions'
import * as divisionActions from '../actions/DivisionActions'
import * as divisionTeamActions from '../actions/DivisionTeamActions'
import * as divisionPlayerActions from '../actions/DivisionPlayerActions'
import * as divisionPersonalActions from '../actions/DivisionPersonalActions'
import * as infoActions from '../actions/InfoActions'
import * as competitionActions from '../actions/CompetitionActions'
import * as conferenceActions from '../actions/ConferenceActions'
import * as cityActions from '../actions/CityActions'
import * as stageActions from '../actions/StageActions'
import * as groupActions from '../actions/GroupActions'
import * as stageTeamActions from '../actions/StageTeamActions'
import * as tourActions from '../actions/TourActions'
import * as matchActions from '../actions/MatchActions'

import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import SeasonForm from "../components/manager/forms/SeasonForm";
import CompetitionForm from "../components/manager/forms/CompetitionForm";
import DivisionForm from "../components/manager/forms/DivisionForm";
import DivisionTeamForm from "../components/manager/forms/DivisionTeamForm";
import StageForm from "../components/manager/forms/StageForm";
import GroupForm from "../components/manager/forms/GroupForm";
import StageTeamForm from "../components/manager/forms/StageTeamForm";
import TourForm from "../components/manager/forms/TourForm";
import MatchForm from "../components/manager/forms/MatchForm";
import MatchResultForm from "../components/manager/forms/MatchResultForm";
import DivisionPlayerForm from "../components/manager/forms/DivisionPlayerForm";
import DivisionPersonalForm from "../components/manager/forms/DivisionPersonalForm";
import {listNews, showNewsForm, viewNews} from "../actions/site/NewsActions";
import {listDocs, showDocForm, viewDoc} from "../actions/site/DocActions";
import NewsForm from "../components/manager/site/form/NewsForm";
import DocForm from "../components/manager/site/form/DocForm";
import ContactForm from "../components/manager/site/form/ContactForm";
import AlbumForm from "../components/manager/site/form/AlbumForm";
import VideoForm from "../components/manager/site/form/VideoForm";
import {showStageStatisticModal} from "../actions/StageActions";
import StageStat from "../components/manager/StageStat";
import {listPhotos} from "../actions/site/AlbumActions";

function Manager(props) {
    const {manager, user} = props;
    // FIXME scum for linked select.
    const {conferenceSelect} = props.conferenceActions;
    const {citySelect} = props.cityActions;
    const {listAlbums,listPhotos,showAlbumForm,createAlbum,updateAlbum,viewAlbum, deleteAlbum} = props.albumActions;
    const {listVideos,showVideoForm,createVideo,updateVideo,viewVideo, deleteVideo} = props.videoActions;
    const {listContacts,showContactForm,createContact,updateContact,viewContact, deleteContact} = props.contactActions;
    const {listNews,showNewsForm,createNews,updateNews,viewNews, deleteNews} = props.newsActions;
    const {listDocs,showDocForm,createDoc,updateDoc,viewDoc, deleteDoc} = props.docActions;
    const {listSeasons, showSeasonForm, createSeason, updateSeason, deleteSeason, viewSeason} = props.seasonActions;
    const {listDivisions, showDivisionForm, createDivision, updateDivision, deleteDivision, viewDivision} = props.divisionActions;
    const {listDivisionTeams, showDivisionTeamForm, createDivisionTeam, updateDivisionTeam, deleteDivisionTeam, viewDivisionTeam, cityDivisionTeam} = props.divisionTeamActions;
    const {listDivisionPlayers, showDivisionPlayerForm, createDivisionPlayer, updateDivisionPlayer, deleteDivisionPlayer, viewDivisionPlayer} = props.divisionPlayerActions;
    const {listDivisionPersonals, showDivisionPersonalForm, createDivisionPersonal, updateDivisionPersonal, deleteDivisionPersonal, viewDivisionPersonal} = props.divisionPersonalActions;
    const {setSportType, listCompetitions, viewCompetition, deleteCompetition, createCompetition, updateCompetition, showCompetitionForm} = props.competitionActions;
    const {listStages,showStageStatisticModal, showStageForm, createStage, updateStage, deleteStage, viewStage} = props.stageActions;
    const {listGroups, showGroupForm, createGroup, updateGroup, deleteGroup, viewGroup} = props.groupActions;
    const {listStageTeams, showStageTeamForm, createStageTeam, updateStageTeam, viewStageTeam, deleteStageTeam,copyStageTeams} = props.stageTeamActions;
    const {listTours, showTourForm, createTour, deleteTour, viewTour, updateTour} = props.tourActions;
    const {listMatches, showMatchForm, createMatch, updateMatch, viewMatch, deleteMatch, showMatchResult, setMatchModel} = props.matchActions;
    return (
        <Box>
            <ManagerTabs
                showMatchResult={showMatchResult}

                divisionPlayers={manager.get('divisionPlayer').get('models')}
                divisionPlayersLoading={manager.get('divisionPlayer').get('loading')}
                divisionPersonals={manager.get('divisionPersonal').get('models')}

                matches={manager.get('match').get('models')}
                matchesLoading={manager.get('match').get('loading')}
                stages={manager.get('stage').get('models')}
                stagesLoading={manager.get('stage').get('loading')}

                tours={manager.get('tour').get('models')}
                toursLoading={manager.get('tour').get('loading')}

                stageTeams={manager.get('stageTeam').get('models')}
                stageTeamsLoading={manager.get('stageTeam').get('loading')}

                groups={manager.get('group').get('models')}
                groupsLoading={manager.get('group').get('loading')}

                addMatchDisabled={manager.get('stage').get('id') === 0}
                addTourDisabled={manager.get('stage').get('id') === 0}
                addStageDisabled={manager.get('division').get('id') === 0}
                addStageTeamDisabled={manager.get('stage').get('id') === 0}
                addGroupDisabled={manager.get('stage').get('id') === 0}
                addDivisionPlayerDisabled={manager.get('divisionTeam').get('id') === 0}
                copyStageTeams={copyStageTeams}
                stageID={manager.get('stage').get('id')}

                showMatchForm={showMatchForm}
                showTourForm={showTourForm}
                showStageForm={showStageForm}
                showStageStatistic={showStageStatisticModal}
                showGroupForm={showGroupForm}
                showStageTeamForm={showStageTeamForm}
                showDivisionPlayerForm={showDivisionPlayerForm}
                showDivisionPersonalForm={showDivisionPersonalForm}

                listStages={listStages}
                listMatches={listMatches}
                listTours={listTours}
                listGroups={listGroups}
                listStageTeams={listStageTeams}

                viewDivisionPlayer={viewDivisionPlayer}
                viewDivisionPersonal={viewDivisionPersonal}
                viewStageTeam={viewStageTeam}
                viewMatch={viewMatch}
                viewStage={viewStage}
                viewGroup={viewGroup}
                viewTour={viewTour}

                updateMatch={updateMatch}

                deleteStageTeam={deleteStageTeam}
                deleteDivisionPlayer={deleteDivisionPlayer}
                deleteDivisionPersonal={deleteDivisionPersonal}
                deleteMatch={deleteMatch}
                deleteStage={deleteStage}
                deleteGroup={deleteGroup}
                deleteTour={deleteTour}

                setModel={setMatchModel}

                selectTabSite={manager.get('competition').get('id') === 0}

                news={manager.get('news').get('models')}
                showNewsForm={showNewsForm}
                viewNews={viewNews}
                deleteNews={deleteNews}

                docs={manager.get('doc').get('models')}
                showDocForm={showDocForm}
                viewDoc={viewDoc}
                deleteDoc={deleteDoc}

                contacts={manager.get('contact').get('models')}
                showContactForm={showContactForm}
                viewContact={viewContact}
                deleteContact={deleteContact}

                albums={manager.get('album').get('models')}
                showAlbumForm={showAlbumForm}
                viewAlbum={viewAlbum}
                deleteAlbum={deleteAlbum}

                photos={manager.get('photo').get('models')}

                videos={manager.get('video').get('models')}
                showVideoForm={showVideoForm}
                viewVideo={viewVideo}
                deleteVideo={deleteVideo}

                divisionTeamID={manager.get('divisionTeam').get('id')}
            />
            <Box id="manager-bottom">
                <Box id="manager-season">
                    <Seasons
                        models={manager.get('season').get('models')}
                        showForm={showSeasonForm}
                        viewSeasons={viewSeason}
                        listSeasons={listSeasons}
                        deleteSeason={deleteSeason}
                        listCompetitions={listCompetitions}
                    />
                </Box>
                <Box id="manager-competition">
                    <Competitions
                        addDisabled={manager.get('season').get('id') === 0}
                        models={manager.get('competition').get('models')}
                        loading={manager.get('competition').get('loading')}
                        viewCompetition={viewCompetition}
                        deleteCompetition={deleteCompetition}
                        showForm={showCompetitionForm}
                        setSportType={setSportType}
                        listDivisions={listDivisions}
                        listNews={listNews}
                        listDocs={listDocs}
                        listContacts={listContacts}
                        listAlbums={listAlbums}
                        listPhotos={listPhotos}
                        listVideos={listVideos}
                    />
                </Box>
                <Box id="manager-division">
                    <Divisions
                        addDisabled={manager.get('competition').get('id') === 0}
                        models={manager.get('division').get('models')}
                        loading={manager.get('division').get('loading')}
                        showForm={showDivisionForm}
                        view={viewDivision}
                        delete={deleteDivision}
                        listCompetitions={listDivisions}
                        listDivisionTeams={listDivisionTeams}
                        listStages={listStages}
                    />
                </Box>
                <Box id="manager-division-team">
                    <DivisionTeams
                        addDisabled={manager.get('division').get('id') === 0}
                        models={manager.get('divisionTeam').get('models')}
                        loading={manager.get('divisionTeam').get('loading')}
                        showForm={showDivisionTeamForm}
                        listDivisionPlayers={listDivisionPlayers}
                        listDivisionPersonals={listDivisionPersonals}
                        view={viewDivisionTeam}
                        delete={deleteDivisionTeam}
                    />
                </Box>
            </Box>

            <NewsForm
                open={manager.get('news').get('showForm')}
                model={manager.get('news').get('model')}
                competitionID={manager.get('competition').get('id')}
                create={createNews}
                update={updateNews}
                show={showNewsForm}
            />

            <AlbumForm
                open={manager.get('album').get('showForm')}
                model={manager.get('album').get('model')}
                competitionID={manager.get('competition').get('id')}
                create={createAlbum}
                update={updateAlbum}
                show={showAlbumForm}
            />

            <VideoForm
                open={manager.get('video').get('showForm')}
                model={manager.get('video').get('model')}
                competitionID={manager.get('competition').get('id')}
                create={createVideo}
                update={updateVideo}
                show={showVideoForm}
            />

            <DocForm
                open={manager.get('doc').get('showForm')}
                model={manager.get('doc').get('model')}
                competitionID={manager.get('competition').get('id')}
                create={createDoc}
                update={updateDoc}
                show={showDocForm}
            />

            <ContactForm
                open={manager.get('contact').get('showForm')}
                model={manager.get('contact').get('model')}
                competitionID={manager.get('competition').get('id')}
                create={createContact}
                update={updateContact}
                show={showContactForm}
            />

            <SeasonForm
                open={manager.get('season').get('showForm')}
                model={manager.get('season').get('model')}
                create={createSeason}
                update={updateSeason}
                show={showSeasonForm}
            />

            <StageForm
                open={manager.get('stage').get('showForm')}
                model={manager.get('stage').get('model')}
                seasonID={manager.get('season').get('id')}
                competitionID={manager.get('competition').get('id')}
                divisionID={manager.get('division').get('id')}
                create={createStage}
                update={updateStage}
                show={showStageForm}
            />

            <StageStat
                id={manager.get('stage').get('showStatistic')}
                model={manager.get('stage').get('model')}
                show={showStageStatisticModal}
            />

            <GroupForm
                open={manager.get('group').get('showForm')}
                model={manager.get('group').get('model')}
                seasonID={manager.get('season').get('id')}
                competitionID={manager.get('competition').get('id')}
                divisionID={manager.get('division').get('id')}
                stageID={manager.get('stage').get('id')}
                create={createGroup}
                update={updateGroup}
                show={showGroupForm}
            />

            <TourForm
                open={manager.get('tour').get('showForm')}
                model={manager.get('tour').get('model')}
                seasonID={manager.get('season').get('id')}
                competitionID={manager.get('competition').get('id')}
                divisionID={manager.get('division').get('id')}
                stageID={manager.get('stage').get('id')}
                create={createTour}
                update={updateTour}
                show={showTourForm}
            />

            <CompetitionForm
                open={manager.get('competition').get('showForm')}
                model={manager.get('competition').get('model')}
                seasonID={manager.get('season').get('id')}
                conferenceID={manager.get('conference').get('id')}
                conferenceSelect={conferenceSelect}
                create={createCompetition}
                update={updateCompetition}
                show={showCompetitionForm}
            />

            <DivisionForm
                open={manager.get('division').get('showForm')}
                model={manager.get('division').get('model')}
                seasonID={manager.get('season').get('id')}
                competitionID={manager.get('competition').get('id')}
                create={createDivision}
                update={updateDivision}
                show={showDivisionForm}
            />

            <MatchForm
                open={manager.get('match').get('showForm')}
                model={manager.get('match').get('model')}
                seasonID={manager.get('season').get('id')}
                competitionID={manager.get('competition').get('id')}
                divisionID={manager.get('division').get('id')}
                stageID={manager.get('stage').get('id')}
                cityID={manager.get('city').get('id')}
                citySelect={citySelect}
                create={createMatch}
                update={updateMatch}
                show={showMatchForm}
            />

            <DivisionTeamForm
                open={manager.get('divisionTeam').get('showForm')}
                model={manager.get('divisionTeam').get('model')}
                seasonID={manager.get('season').get('id')}
                competitionID={manager.get('competition').get('id')}
                sportType={manager.get('competition').get('sportType')}
                divisionID={manager.get('division').get('id')}
                cityID={manager.get('divisionTeam').get('city')}
                citySelect={cityDivisionTeam}
                create={createDivisionTeam}
                update={updateDivisionTeam}
                show={showDivisionTeamForm}
            />

            <DivisionPlayerForm
                open={manager.get('divisionPlayer').get('showForm')}
                model={manager.get('divisionPlayer').get('model')}
                seasonID={manager.get('season').get('id')}
                competitionID={manager.get('competition').get('id')}
                divisionID={manager.get('division').get('id')}
                divisionTeamID={manager.get('divisionTeam').get('id')}
                create={createDivisionPlayer}
                update={updateDivisionPlayer}
                show={showDivisionPlayerForm}
            />

            <DivisionPersonalForm
                open={manager.get('divisionPersonal').get('showForm')}
                model={manager.get('divisionPersonal').get('model')}
                seasonID={manager.get('season').get('id')}
                competitionID={manager.get('competition').get('id')}
                divisionID={manager.get('division').get('id')}
                divisionTeamID={manager.get('divisionTeam').get('id')}
                create={createDivisionPersonal}
                update={updateDivisionPersonal}
                show={showDivisionPersonalForm}
            />

            <StageTeamForm
                open={manager.get('stageTeam').get('showForm')}
                model={manager.get('stageTeam').get('model')}
                seasonID={manager.get('season').get('id')}
                competitionID={manager.get('competition').get('id')}
                divisionID={manager.get('division').get('id')}
                stageID={manager.get('stage').get('id')}
                cityID={manager.get('city').get('id')}
                create={createStageTeam}
                update={updateStageTeam}
                show={showStageTeamForm}
            />

            <MatchResultForm
                open={manager.get('match').get('showResultForm')}
                model={manager.get('match').get('model')}
                update={updateMatch}
                show={showMatchResult}
            />
        </Box>
    );
}

function mapStateToProps(state) {
    return {
        season: state.season,
        division: state.division,
        divisionTeam: state.divisionTeam,
        divisionPersonal: state.divisionPersonal,
        info: state.info,
        competition: state.competition,
        manager: state.manager,
        user: state.user,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        competitionActions: bindActionCreators(competitionActions, dispatch),
        cityActions: bindActionCreators(cityActions, dispatch),
        conferenceActions: bindActionCreators(conferenceActions, dispatch),
        divisionActions: bindActionCreators(divisionActions, dispatch),
        divisionTeamActions: bindActionCreators(divisionTeamActions, dispatch),
        divisionPlayerActions: bindActionCreators(divisionPlayerActions, dispatch),
        divisionPersonalActions: bindActionCreators(divisionPersonalActions, dispatch),
        groupActions: bindActionCreators(groupActions, dispatch),
        infoActions: bindActionCreators(infoActions, dispatch),
        matchActions: bindActionCreators(matchActions, dispatch),
        seasonActions: bindActionCreators(seasonActions, dispatch),
        stageTeamActions: bindActionCreators(stageTeamActions, dispatch),
        stageActions: bindActionCreators(stageActions, dispatch),
        tourActions: bindActionCreators(tourActions, dispatch),
        newsActions: bindActionCreators(newsActions, dispatch),
        docActions: bindActionCreators(docActions, dispatch),
        contactActions: bindActionCreators(contactActions, dispatch),
        albumActions: bindActionCreators(albumActions, dispatch),
        videoActions: bindActionCreators(videoActions, dispatch),
        userActions: bindActionCreators(userActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Manager)

