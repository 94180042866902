import React, {useEffect, useState} from 'react'
import MaterialTable from 'material-table'
import {CORE, MANAGER} from "../constants/Common";
import SportIcon from "./common/SportIcon";
import {FormattedMessage, useIntl} from "react-intl";

export default function PlaySituations(props) {
    const [selectedRow, setValue] = useState(0);
    useEffect(() => {
        props.list('', CORE)
    }, []);

    let data = [];
    props.models.forEach(n => data.push(n));

    function handleShowForm() {
        props.showForm(true, CORE)
    }

    function handleUpdate(id) {
        props.view(id, CORE)
    }

    function handleDelete(id) {
        props.delete(id, CORE)
    }

    const intl = useIntl()

    return (
        <MaterialTable
            title={intl.formatMessage({id: 'play-situation-title',defaultMessage:"Play situations"})}
            columns={[
                {
                    title: 'id',
                    field: 'id',
                },
                {
                    field: 'type',
                    title: <FormattedMessage id="play-situation.kind" defaultMessage="kind"/>,
                    cellStyle: {
                        width: 70,
                        padding: 0,
                    },
                    render: rowData => <SportIcon type={rowData.sportType}/>
                },
                {
                    title: <FormattedMessage id="play-situation.name" defaultMessage="name"/>,
                    field: 'name',
                },
            ]}
            options={{
                rowStyle: rowData => ({
                    backgroundColor: (selectedRow && selectedRow.tableData.id === rowData.tableData.id) ? '#EEE' : '#FFF'
                }),
                paging: true,
                header: true,
                sorting: true,
                filtering: true,
                actionsColumnIndex: -1,
                pageSize:15,
                showTitle: true
            }}
            localization={
                {
                    toolbar: {
                        searchPlaceholder: intl.formatMessage({id: 'play-situation-title',defaultMessage:"Play situations"}),
                        searchTooltip: <FormattedMessage id="common.find" defaultMessage="find"/>
                    },
                    header: {
                        actions: <FormattedMessage id="common.actions" defaultMessage="actions"/>
                    },
                    body: {
                        emptyDataSourceMessage: <FormattedMessage id="common.no_data" defaultMessage="no data"/>
                    }
                }
            }
            actions={[
                {
                    icon: 'add',
                    tooltip: <FormattedMessage id="play-situation.add" defaultMessage="add situation"/>,
                    isFreeAction: true,
                    onClick: (event) => handleShowForm()
                },
                {
                    icon: 'edit',
                    tooltip: <FormattedMessage id="play-situation.edit" defaultMessage="edit situation"/>,
                    onClick: (event, rowData) => handleUpdate(rowData.id)
                },
                {
                    icon: 'delete',
                    tooltip: <FormattedMessage id="play-situation.delete" defaultMessage="delete situation"/>,
                    onClick: (event, rowData) => handleDelete(rowData.id)
                }
            ]}
            isLoading={props.loading}
            data={data}
        />
    )
}