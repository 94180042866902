/**
 * Created by happy on 11.02.17.
 */
export const MANAGER_GROUP_LIST_REQUEST = 'MANAGER_GROUP_LIST_REQUEST';
export const MANAGER_GROUP_LIST_FAILURE = 'MANAGER_GROUP_LIST_FAILURE'
export const MANAGER_GROUP_LIST_SUCCESS = 'MANAGER_GROUP_LIST_SUCCESS'
export const MANAGER_GROUP_SHOW_FORM = 'MANAGER_GROUP_SHOW_FORM';
export const MANAGER_GROUP_CREATE_SUCCESS = 'MANAGER_GROUP_CREATE_SUCCESS'
export const MANAGER_GROUP_CREATE_FAILURE = 'MANAGER_GROUP_CREATE_FAILURE'
export const MANAGER_GROUP_UPDATE_SUCCESS = 'MANAGER_GROUP_UPDATE_SUCCESS'
export const MANAGER_GROUP_UPDATE_FAILURE = 'MANAGER_GROUP_UPDATE_FAILURE'
export const MANAGER_GROUP_DELETE_SUCCESS = 'MANAGER_GROUP_DELETE_SUCCESS'
export const MANAGER_GROUP_DELETE_FAILURE = 'MANAGER_GROUP_DELETE_FAILURE'
export const MANAGER_GROUP_VIEW_SUCCESS = 'MANAGER_GROUP_VIEW_SUCCESS'

export const CORE_GROUP_LIST_REQUEST = 'CORE_GROUP_LIST_REQUEST'
export const CORE_GROUP_LIST_FAILURE = 'CORE_GROUP_LIST_FAILURE'
export const CORE_GROUP_LIST_SUCCESS = 'CORE_GROUP_LIST_SUCCESS'
export const CORE_GROUP_SHOW_MODAL = 'CORE_GROUP_SHOW_MODAL'
export const CORE_GROUP_CREATE_SUCCESS = 'CORE_GROUP_CREATE_SUCCESS'
export const CORE_GROUP_DELETE_SUCCESS = 'CORE_GROUP_DELETE_SUCCESS'
export const CORE_GROUP_VIEW_SUCCESS = 'CORE_GROUP_VIEW_SUCCESS'
export const CORE_GROUP_VIEW_FAILURE = 'CORE_GROUP_VIEW_FAILURE'

export const GROUP_LIST_REQUEST = 'GROUP_LIST_REQUEST'
export const GROUP_LIST_FAILURE = 'GROUP_LIST_FAILURE'
export const GROUP_LIST_SUCCESS = 'GROUP_LIST_SUCCESS'
export const GROUP_SHOW_FORM = 'GROUP_SHOW_FORM';
export const GROUP_CREATE_SUCCESS = 'GROUP_CREATE_SUCCESS'
export const GROUP_CREATE_FAILURE = 'GROUP_CREATE_FAILURE'
export const GROUP_UPDATE_SUCCESS = 'GROUP_UPDATE_SUCCESS'
export const GROUP_UPDATE_FAILURE = 'GROUP_UPDATE_FAILURE'
export const GROUP_DELETE_SUCCESS = 'GROUP_DELETE_SUCCESS'
export const GROUP_DELETE_FAILURE = 'GROUP_DELETE_FAILURE'
export const GROUP_VIEW_SUCCESS = 'GROUP_VIEW_SUCCESS'
export const GROUP_VIEW_FAILURE = 'GROUP_VIEW_FAILURE'
