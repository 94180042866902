import React from 'react';
import Box from '@material-ui/core/Box';
import * as peopleActions from '../actions/PeopleActions'

import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Peoples from "../components/Peoples";
import PeopleForm from "../components/PeopleForm";
import PeopleImportForm from "../components/PeopleImportForm";

function People(props) {
    const {people} = props;
    const {importPeople, showImportForm, listPeoples, showPeopleForm, createPeople, updatePeople, deletePeople, viewPeople} = props.peopleActions;
    return (
        <Box style={{height:'100%'}}>
            <Peoples
                models={people.get('models')}
                showForm={showPeopleForm}
                showImportForm={showImportForm}
                view={viewPeople}
                delete={deletePeople}
                list={listPeoples}
            />
            <PeopleForm
                model={people.get('model')}
                show={showPeopleForm}
                create={createPeople}
                update={updatePeople}
                open={people.get('showModal')}
            />
            <PeopleImportForm
                model={people.get('model')}
                show={showImportForm}
                import={importPeople}
                open={people.get('showImportModal')}
            />
        </Box>
    );
}

function mapStateToProps(state) {
    return {
        people: state.people,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        peopleActions: bindActionCreators(peopleActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(People)

