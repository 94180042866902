import React, {useEffect, useState} from 'react'
import Container from "@material-ui/core/Container";
import {CONSTRUCTOR_API_URL} from "../../../constants/Common";
import {useParams} from "react-router-dom";
import {rstatApi} from "../../../helpers/api";

export default function NewsView(props) {
    let params = useParams();

    const [model, setModel] = useState({"id":10,"title":"Заголовок новости","preview":"превью новости", "body":"hello"});

    useEffect(() => {
        (async () => {
            rstatApi.get(CONSTRUCTOR_API_URL+"news/"+params.id).then(function (response) {
                setModel(response.data.result[0]);
            }).catch(function (error) {
                // TODO process error.
                console.log(error)
            });
        })();
    }, [params.id]);

    return (
        <Container maxWidth="md">
            <h2>{model.title}</h2>
            <div>
                <img alt="image" width={'100%'} src={CONSTRUCTOR_API_URL+"news/photo/"+params.id}/>
            </div>
            <i>{model.preview}</i>
            <p>
                <div dangerouslySetInnerHTML={{__html: model.body}} />
            </p>
        </Container>
        )
}