export const MANAGER_TEAM_LIST_REQUEST = 'MANAGER_TEAM_LIST_REQUEST'
export const MANAGER_TEAM_LIST_FAILURE = 'MANAGER_TEAM_LIST_FAILURE'
export const MANAGER_TEAM_LIST_SUCCESS = 'MANAGER_TEAM_LIST_SUCCESS'
export const MANAGER_TEAM_SHOW_MODAL = 'MANAGER_TEAM_SHOW_MODAL'
export const MANAGER_TEAM_CREATE_REQUEST = 'MANAGER_TEAM_CREATE_REQUEST'
export const MANAGER_TEAM_CREATE_SUCCESS = 'MANAGER_TEAM_CREATE_SUCCESS'
export const MANAGER_TEAM_CREATE_FAILURE = 'MANAGER_TEAM_CREATE_FAILURE'
export const MANAGER_TEAM_UPDATE_REQUEST = 'MANAGER_TEAM_UPDATE_REQUEST'
export const MANAGER_TEAM_UPDATE_SUCCESS = 'MANAGER_TEAM_UPDATE_SUCCESS'
export const MANAGER_TEAM_UPDATE_FAILURE = 'MANAGER_TEAM_UPDATE_FAILURE'
export const MANAGER_TEAM_DELETE_REQUEST = 'MANAGER_TEAM_DELETE_REQUEST'
export const MANAGER_TEAM_DELETE_SUCCESS = 'MANAGER_TEAM_DELETE_SUCCESS'
export const MANAGER_TEAM_DELETE_FAILURE = 'MANAGER_TEAM_DELETE_FAILURE'
export const MANAGER_TEAM_SET_ID = 'MANAGER_TEAM_SET_ID'

export const CORE_TEAM_LIST_REQUEST = 'CORE_TEAM_LIST_REQUEST'
export const CORE_TEAM_LIST_FAILURE = 'CORE_TEAM_LIST_FAILURE'
export const CORE_TEAM_LIST_SUCCESS = 'CORE_TEAM_LIST_SUCCESS'
export const CORE_TEAM_SHOW_FORM = 'CORE_TEAM_SHOW_FORM'
export const CORE_TEAM_CREATE_REQUEST = 'CORE_TEAM_CREATE_REQUEST'
export const CORE_TEAM_CREATE_SUCCESS = 'CORE_TEAM_CREATE_SUCCESS'
export const CORE_TEAM_CREATE_FAILURE = 'CORE_TEAM_CREATE_FAILURE'
export const CORE_TEAM_UPDATE_REQUEST = 'CORE_TEAM_UPDATE_REQUEST'
export const CORE_TEAM_UPDATE_SUCCESS = 'CORE_TEAM_UPDATE_SUCCESS'
export const CORE_TEAM_UPDATE_FAILURE = 'CORE_TEAM_UPDATE_FAILURE'
export const CORE_TEAM_DELETE_REQUEST = 'CORE_TEAM_DELETE_REQUEST'
export const CORE_TEAM_DELETE_SUCCESS = 'CORE_TEAM_DELETE_SUCCESS'
export const CORE_TEAM_DELETE_FAILURE = 'CORE_TEAM_DELETE_FAILURE'
export const CORE_TEAM_UPLOAD_SUCCESS = 'CORE_TEAM_UPLOAD_SUCCESS'
export const CORE_TEAM_UPLOAD_FAILURE = 'CORE_TEAM_UPLOAD_FAILURE'
export const CORE_TEAM_VIEW_SUCCESS = 'CORE_TEAM_VIEW_SUCCESS'
export const CORE_TEAM_SHOW_VIEW = 'CORE_TEAM_SHOW_VIEW';
export const SIMPLE_TEAM_SHOW_MODAL = 'SIMPLE_TEAM_SHOW_MODAL';
export const SIMPLE_TEAM_VIEW_SUCCESS = 'SIMPLE_TEAM_VIEW_SUCCESS';

export const TEAM_LIST_REQUEST = 'TEAM_LIST_REQUEST'
export const TEAM_LIST_FAILURE = 'TEAM_LIST_FAILURE'
export const TEAM_LIST_SUCCESS = 'TEAM_LIST_SUCCESS'
export const TEAM_SHOW_MODAL = 'TEAM_SHOW_MODAL'
export const TEAM_SHOW_FORM = 'TEAM_SHOW_FORM'
export const TEAM_CREATE_REQUEST = 'TEAM_CREATE_REQUEST'
export const TEAM_CREATE_SUCCESS = 'TEAM_CREATE_SUCCESS'
export const TEAM_CREATE_FAILURE = 'TEAM_CREATE_FAILURE'
export const TEAM_UPDATE_REQUEST = 'TEAM_UPDATE_REQUEST'
export const TEAM_UPDATE_SUCCESS = 'TEAM_UPDATE_SUCCESS'
export const TEAM_UPDATE_FAILURE = 'TEAM_UPDATE_FAILURE'
export const TEAM_DELETE_REQUEST = 'TEAM_DELETE_REQUEST'
export const TEAM_DELETE_SUCCESS = 'TEAM_DELETE_SUCCESS'
export const TEAM_DELETE_FAILURE = 'TEAM_DELETE_FAILURE'
export const TEAM_VIEW_SUCCESS = 'TEAM_VIEW_SUCCESS'
export const TEAM_TEAMS_VIEW_SUCCESS = 'TEAM_TEAMS_VIEW_SUCCESS';
export const CORE_TEAM_TEAMS_VIEW_SUCCESS = 'CORE_TEAM_TEAMS_VIEW_SUCCESS';
export const TEAM_TEAM_VIEW_FAILURE = 'TEAM_TEAM_VIEW_FAILURE';
export const TEAM_VIEW_FAILURE = 'TEAM_VIEW_FAILURE'
export const TEAM_SET_ID = 'TEAM_SET_ID'
export const TEAM_SHOW_VIEW = 'TEAM_SHOW_VIEW'

export const TEAM_SNACKBAR = 'TEAM_SNACKBAR'
