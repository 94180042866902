import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import {Field, Formik} from "formik";
import * as yup from 'yup'
import {CORE} from "../constants/Common";
import FormikAdapter from "./common/FormikAdapter";
import FormikSelectAdapter from "./common/FormikSelectAdapter";
import SportTypeSelector from "./form/SportTypeSelector";
import {FormattedMessage} from "react-intl";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const PENALTY_FIELDS = {
    PENALTY_ID_FIELD: 'id',
    PENALTY_SHORT_NAME_FIELD: 'shortName',
    PENALTY_NAME_FIELD: 'name',
    PENALTY_SORT_FIELD: 'sort',
    PENALTY_SPORT_TYPE_FIELD: 'sportType',
};

export default function PenaltyForm(props) {
    const handleClose = () => {
        props.show(false, CORE)
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={props.open}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                <FormattedMessage id="penalty-form" defaultMessage="Penalty form"/>
            </DialogTitle>
            <Formik
                initialValues={{
                    [PENALTY_FIELDS.PENALTY_ID_FIELD]: props.model.id,
                    [PENALTY_FIELDS.PENALTY_NAME_FIELD]: props.model.name,
                    [PENALTY_FIELDS.PENALTY_SHORT_NAME_FIELD]: props.model.shortName,
                    [PENALTY_FIELDS.PENALTY_SORT_FIELD]: props.model.sort,
                    [PENALTY_FIELDS.PENALTY_SPORT_TYPE_FIELD]: props.model.sportType,
                }}
                onSubmit={(values, actions) => {
                    if (values.id > 0) {
                        props.update(values.id, values, CORE);
                    } else {
                        props.create(values, CORE);
                    }
                }}
                validationSchema={yup.object().shape({
                    [PENALTY_FIELDS.PENALTY_NAME_FIELD]: yup.string()
                        .required(<FormattedMessage id="form.required" defaultMessage="required field"/>),
                    [PENALTY_FIELDS.PENALTY_SHORT_NAME_FIELD]: yup.string()
                        .required(<FormattedMessage id="form.required" defaultMessage="required field"/>),
                })}
            >
                {(props) => {
                    const {
                        isSubmitting,
                        handleSubmit,
                    } = props;
                    return (
                        <form onSubmit={handleSubmit}>
                            <DialogContent dividers>
                                <Grid container spacing={3}>
                                    <Grid item xs={4}>
                                        <Field
                                            name={PENALTY_FIELDS.PENALTY_SPORT_TYPE_FIELD}
                                            type="text"
                                            helperText=<FormattedMessage id="penalty.form.sport-kind.helper" defaultMessage="sport kind"/>
                                            inputComponent={SportTypeSelector}
                                            component={FormikSelectAdapter}
                                            label=<FormattedMessage id="penalty.form.sport-kind.label" defaultMessage="Sport kind"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Field
                                            name={PENALTY_FIELDS.PENALTY_NAME_FIELD}
                                            type="text"
                                            helperText=<FormattedMessage id="penalty.form.name.helper" defaultMessage="penalty name"/>
                                            inputComponent={TextField}
                                            component={FormikAdapter}
                                            label=<FormattedMessage id="penalty.form.name.label" defaultMessage="Name"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Field
                                            name={PENALTY_FIELDS.PENALTY_SHORT_NAME_FIELD}
                                            type="text"
                                            helperText=<FormattedMessage id="penalty.form.short-name.helper" defaultMessage="short name"/>
                                            inputComponent={TextField}
                                            component={FormikAdapter}
                                            label=<FormattedMessage id="penalty.form.sport-kind.label" defaultMessage="Short name"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Field
                                            name={PENALTY_FIELDS.PENALTY_SORT_FIELD}
                                            type="number"
                                            helperText=<FormattedMessage id="form.sort.helper" defaultMessage="sorting"/>
                                            inputComponent={TextField}
                                            component={FormikAdapter}
                                            label=<FormattedMessage id="form.sort.label" defaultMessage="Sort"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={isSubmitting}>
                                    <FormattedMessage id="button.save" defaultMessage="Save"/>
                                </Button>
                            </DialogActions>
                        </form>
                    );
                }}
            </Formik>
        </Dialog>
    );
}