import React from 'react';
import Box from '@material-ui/core/Box';
import * as stadiumActions from '../actions/StadiumActions'

import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import StadiumForm from "../components/StadiumForm";
import Stadiums from "../components/Stadiums";

function Stadium(props) {
    const {stadium} = props;
    const {listStadiums, showStadiumForm, createStadium, updateStadium,viewStadium,deleteStadium } = props.stadiumActions;
    return (
        <Box style={{height:'100%'}}>
            <Stadiums
                models={stadium.get('models')}
                showForm={showStadiumForm}
                view={viewStadium}
                delete={deleteStadium}
                list={listStadiums}
            />
            <StadiumForm
                model={stadium.get('model')}
                show={showStadiumForm}
                create={createStadium}
                update={updateStadium}
                open={stadium.get('showModal')}
            />
        </Box>
    );
}

function mapStateToProps(state) {
    return {
        stadium: state.stadium,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        stadiumActions: bindActionCreators(stadiumActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Stadium)

