import React from 'react'

const FormikAdapter = ({
                           field: { value, onChange, name },
                           form: { errors },
                           helperText,
                           inputComponent: InputComponent,
                           ...props
                       }) => (
    <InputComponent
        id={name}
        variant="outlined"
        name={name}
        helperText={errors[name] || helperText}
        error={!!errors[name]}
        value={value}
        onChange={onChange}
        data-test={`${name}_input`}
        {...props}
    />
);

export default FormikAdapter
