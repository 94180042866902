export const MANAGER_PEOPLE_LIST_REQUEST = 'MANAGER_PEOPLE_LIST_REQUEST'
export const MANAGER_PEOPLE_LIST_FAILURE = 'MANAGER_PEOPLE_LIST_FAILURE'
export const MANAGER_PEOPLE_LIST_SUCCESS = 'MANAGER_PEOPLE_LIST_SUCCESS'
export const MANAGER_PEOPLE_SHOW_MODAL = 'MANAGER_PEOPLE_SHOW_MODAL'
export const MANAGER_PEOPLE_CREATE_REQUEST = 'MANAGER_PEOPLE_CREATE_REQUEST'
export const MANAGER_PEOPLE_CREATE_SUCCESS = 'MANAGER_PEOPLE_CREATE_SUCCESS'
export const MANAGER_PEOPLE_CREATE_FAILURE = 'MANAGER_PEOPLE_CREATE_FAILURE'
export const MANAGER_PEOPLE_UPDATE_REQUEST = 'MANAGER_PEOPLE_UPDATE_REQUEST'
export const MANAGER_PEOPLE_UPDATE_SUCCESS = 'MANAGER_PEOPLE_UPDATE_SUCCESS'
export const MANAGER_PEOPLE_UPDATE_FAILURE = 'MANAGER_PEOPLE_UPDATE_FAILURE'
export const MANAGER_PEOPLE_DELETE_REQUEST = 'MANAGER_PEOPLE_DELETE_REQUEST'
export const MANAGER_PEOPLE_DELETE_SUCCESS = 'MANAGER_PEOPLE_DELETE_SUCCESS'
export const MANAGER_PEOPLE_DELETE_FAILURE = 'MANAGER_PEOPLE_DELETE_FAILURE'
export const MANAGER_PEOPLE_SET_ID = 'MANAGER_PEOPLE_SET_ID'

export const CORE_PEOPLE_LIST_REQUEST = 'CORE_PEOPLE_LIST_REQUEST'
export const CORE_PEOPLE_LIST_FAILURE = 'CORE_PEOPLE_LIST_FAILURE'
export const CORE_PEOPLE_LIST_SUCCESS = 'CORE_PEOPLE_LIST_SUCCESS'
export const CORE_PEOPLE_SHOW_FORM = 'CORE_PEOPLE_SHOW_FORM'
export const CORE_PEOPLE_SHOW_IMPORT_FORM = 'CORE_PEOPLE_SHOW_IMPORT_FORM'
export const CORE_PEOPLE_CREATE_REQUEST = 'CORE_PEOPLE_CREATE_REQUEST'
export const CORE_PEOPLE_CREATE_SUCCESS = 'CORE_PEOPLE_CREATE_SUCCESS'
export const CORE_PEOPLE_IMPORT_SUCCESS = 'CORE_PEOPLE_IMPORT_SUCCESS'
export const CORE_PEOPLE_CREATE_FAILURE = 'CORE_PEOPLE_CREATE_FAILURE'
export const CORE_PEOPLE_UPDATE_REQUEST = 'CORE_PEOPLE_UPDATE_REQUEST'
export const CORE_PEOPLE_UPDATE_SUCCESS = 'CORE_PEOPLE_UPDATE_SUCCESS'
export const CORE_PEOPLE_UPDATE_FAILURE = 'CORE_PEOPLE_UPDATE_FAILURE'
export const CORE_PEOPLE_DELETE_REQUEST = 'CORE_PEOPLE_DELETE_REQUEST'
export const CORE_PEOPLE_DELETE_SUCCESS = 'CORE_PEOPLE_DELETE_SUCCESS'
export const CORE_PEOPLE_DELETE_FAILURE = 'CORE_PEOPLE_DELETE_FAILURE'
export const CORE_PEOPLE_UPLOAD_SUCCESS = 'CORE_PEOPLE_UPLOAD_SUCCESS'
export const CORE_PEOPLE_UPLOAD_FAILURE = 'CORE_PEOPLE_UPLOAD_FAILURE'
export const CORE_PEOPLE_VIEW_SUCCESS = 'CORE_PEOPLE_VIEW_SUCCESS'
export const CORE_PEOPLE_SHOW_VIEW = 'CORE_PEOPLE_SHOW_VIEW';
export const SIMPLE_PEOPLE_SHOW_MODAL = 'SIMPLE_PEOPLE_SHOW_MODAL';
export const SIMPLE_PEOPLE_VIEW_SUCCESS = 'SIMPLE_PEOPLE_VIEW_SUCCESS';

export const PEOPLE_LIST_REQUEST = 'PEOPLE_LIST_REQUEST'
export const PEOPLE_LIST_FAILURE = 'PEOPLE_LIST_FAILURE'
export const PEOPLE_LIST_SUCCESS = 'PEOPLE_LIST_SUCCESS'
export const PEOPLE_SHOW_MODAL = 'PEOPLE_SHOW_MODAL'
export const PEOPLE_SHOW_FORM = 'PEOPLE_SHOW_FORM'
export const PEOPLE_SHOW_IMPORT_FORM = 'PEOPLE_SHOW_IMPORT_FORM'
export const PEOPLE_CREATE_REQUEST = 'PEOPLE_CREATE_REQUEST'
export const PEOPLE_CREATE_SUCCESS = 'PEOPLE_CREATE_SUCCESS'
export const PEOPLE_CREATE_FAILURE = 'PEOPLE_CREATE_FAILURE'
export const PEOPLE_UPDATE_REQUEST = 'PEOPLE_UPDATE_REQUEST'
export const PEOPLE_UPDATE_SUCCESS = 'PEOPLE_UPDATE_SUCCESS'
export const PEOPLE_UPDATE_FAILURE = 'PEOPLE_UPDATE_FAILURE'
export const PEOPLE_DELETE_REQUEST = 'PEOPLE_DELETE_REQUEST'
export const PEOPLE_DELETE_SUCCESS = 'PEOPLE_DELETE_SUCCESS'
export const PEOPLE_DELETE_FAILURE = 'PEOPLE_DELETE_FAILURE'
export const PEOPLE_VIEW_SUCCESS = 'PEOPLE_VIEW_SUCCESS'
export const PEOPLE_TEAMS_VIEW_SUCCESS = 'PEOPLE_TEAMS_VIEW_SUCCESS';
export const CORE_PEOPLE_TEAMS_VIEW_SUCCESS = 'CORE_PEOPLE_TEAMS_VIEW_SUCCESS';
export const PEOPLE_TEAM_VIEW_FAILURE = 'PEOPLE_TEAM_VIEW_FAILURE';
export const PEOPLE_VIEW_FAILURE = 'PEOPLE_VIEW_FAILURE'
export const PEOPLE_SET_ID = 'PEOPLE_SET_ID'
export const PEOPLE_SHOW_VIEW = 'PEOPLE_SHOW_VIEW'

export const PEOPLE_SNACKBAR = 'PEOPLE_SNACKBAR'
