import {
    CORE_TEAM_CREATE_SUCCESS,
    CORE_TEAM_DELETE_SUCCESS,
    CORE_TEAM_LIST_SUCCESS,
    CORE_TEAM_SHOW_FORM,
    CORE_TEAM_SHOW_VIEW,
    CORE_TEAM_TEAMS_VIEW_SUCCESS,
    CORE_TEAM_UPDATE_SUCCESS,
    CORE_TEAM_VIEW_SUCCESS,
    SIMPLE_TEAM_SHOW_MODAL,
    SIMPLE_TEAM_VIEW_SUCCESS,
    TEAM_SNACKBAR
} from '../constants/Team';

var {Map, List, OrderedMap} = require('immutable');
const initialState = Map({
    showModal: false,
    showView: false,
    snackbar: Map({
        show: false,
        text: ''
    }),
    count: 0,
    model: {
        id: 0,
        cityID:0,
    },
    models: List([]),
})


export default function team(state = initialState, action) {

    switch (action.type) {
        case CORE_TEAM_SHOW_FORM:
            return state.set('showModal', action.payload).set('model',initialState.get('model'))
        case CORE_TEAM_LIST_SUCCESS:
            return state
                .set('models', OrderedMap().withMutations(map => {
                    action.payload.forEach(model => map.set(model.id, model));
                }))
                .set('count', action.count);
        case CORE_TEAM_VIEW_SUCCESS:
            return state
                .set('model', action.payload)
                .set('showModal', true);
        case CORE_TEAM_TEAMS_VIEW_SUCCESS:
            return state
                .set('teams', action.payload);
        case SIMPLE_TEAM_VIEW_SUCCESS:
            return state
                .set('model', action.payload[0])
                .set('showView', true)
        case CORE_TEAM_DELETE_SUCCESS:
            return state
                .deleteIn(['models', action.payload])
        case CORE_TEAM_CREATE_SUCCESS:
            return state
                .setIn(['models', action.payload.id], action.payload)
                .set('showModal', false)
        case CORE_TEAM_UPDATE_SUCCESS:
            return state
                .setIn(['models', action.payload.id], action.payload)
                .set('showModal', false)
        case CORE_TEAM_SHOW_VIEW:
            return state
                .set('showView', action.payload);
        case SIMPLE_TEAM_SHOW_MODAL:
            return state
                .set('showView', action.payload);
        case TEAM_SNACKBAR:
            return state
                .setIn(['snackbar', 'show'], action.show)
                .setIn(['snackbar', 'text'], action.text)

        default:
            return state;
    }
}
