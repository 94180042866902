import {combineReducers} from 'redux'
import info from './info'
import people from './people'
import team from './team'
import country from './country'
import city from './city'
import penalty from './penalty'
import stadium from './stadium'
import protocol from './protocol'
import manager from './manager'
import user from "./user";
import playSituation from "./playSituation";

export default combineReducers({
    manager,
    user,
    people,
    team,
    city,
    penalty,
    playSituation,
    country,
    stadium,
    protocol,
    info,
})
