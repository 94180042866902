import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";
import DivisionPlayers from "./DivisionPlayers";
import Stages from "./Stages";
import Groups from "./Groups";
import StageTeams from "./StageTeams";
import Tours from "./Tours";
import Matches from "./Matches";
import DivisionPersonal from "./DivisionPersonal";
import {ENDPOINT} from "../../constants/Common";
import SiteMenu from "./site/SiteMenu";
import {
    SITE_PAGE_ALBUM,
    SITE_PAGE_CONTACT,
    SITE_PAGE_DOC,
    SITE_PAGE_NEWS, SITE_PAGE_PHOTO,
    SITE_PAGE_VIDEO
} from "../../constants/Manager";
import News from "./site/News";
import Documents from "./site/Documents";
import Contacts from "./site/Contacts";
import Albums from "./site/Albums";
import Videos from "./site/Videos";
import {FormattedMessage} from "react-intl";
import Photos from "./site/Photos";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3} style={{padding: 0}}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `nav-tab-${index}`,
        'aria-controls': `nav-tabpanel-${index}`,
    };
}

function LinkTab(props) {
    return (
        <Tab
            component="a"
            onClick={(event) => {
                event.preventDefault();
            }}
            {...props}
        />
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function ManagerTabs(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const [page, setPage] = useState(SITE_PAGE_NEWS);

    const handleListItemClick = (event, index) => {
        setPage(index);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <Paper square elevation={3} style={{marginTop: 20}}>
                <Tabs
                    variant="fullWidth"
                    value={value}
                    onChange={handleChange}
                    aria-label="nav tabs example"
                >
                    <LinkTab label=<FormattedMessage id="tabs.matches" defaultMessage="matches"/> href="/matches" {...a11yProps(0)} />
                    <LinkTab label=<FormattedMessage id="tabs.roster" defaultMessage="roster"/> href="/players" {...a11yProps(1)} />
                    <LinkTab label=<FormattedMessage id="tabs.personals" defaultMessage="personals"/> href="/personals" {...a11yProps(2)} />
                    <LinkTab label=<FormattedMessage id="tabs.site" defaultMessage="site"/> disabled={props.selectTabSite} href="/site" {...a11yProps(3)} />
                </Tabs>
            </Paper>
            <TabPanel value={value} index={1}>
                <Box id="manager-main">
                    <Card style={{textAlign: "center"}}>
                        <img src={ENDPOINT + "peoples/0/photo"}/>
                    </Card>
                    <DivisionPlayers
                        models={props.divisionPlayers}
                        divisionTeamID={props.divisionTeamID}
                        loading={props.divisionPlayersLoading}
                        addDisabled={props.addDivisionPlayerDisabled}
                        showForm={props.showDivisionPlayerForm}
                        view={props.viewDivisionPlayer}
                        delete={props.deleteDivisionPlayer}
                    />
                </Box>
            </TabPanel>
            <TabPanel value={value} index={0}>
                <Box id="manager-matches">
                    <Box id="manager-match">
                        <Matches
                            models={props.matches}
                            loading={props.matchesLoading}
                            showMatchResult={props.showMatchResult}
                            addDisabled={props.addMatchDisabled}
                            showForm={props.showMatchForm}
                            update={props.updateMatch}
                            setModel={props.setModel}
                            view={props.viewMatch}
                            list={props.listMatches}
                            delete={props.deleteMatch}
                            stageID={props.stageID}
                        />
                    </Box>
                    <Box id="manager-stage">
                        <Stages
                            models={props.stages}
                            loading={props.stagesLoading}
                            addDisabled={props.addStageDisabled}
                            showForm={props.showStageForm}
                            showStatistic={props.showStageStatistic}
                            view={props.viewStage}
                            listStages={props.listStages}
                            listTours={props.listTours}
                            listMatches={props.listMatches}
                            delete={props.deleteStage}
                            listStageTeams={props.listStageTeams}
                            listGroups={props.listGroups}
                        />
                    </Box>
                    <Box id="manager-stage-team">
                        <StageTeams
                            models={props.stageTeams}
                            loading={props.stageTeamsLoading}
                            addDisabled={props.addStageTeamDisabled}
                            showForm={props.showStageTeamForm}
                            view={props.viewStageTeam}
                            delete={props.deleteStageTeam}
                            listMatches={props.listMatches}
                            stageID={props.stageID}
                            copyStageTeams={props.copyStageTeams}
                        />
                    </Box>
                    <Box id="manager-group">
                        <Groups
                            models={props.groups}
                            loading={props.groupsLoading}
                            addDisabled={props.addGroupDisabled}
                            showForm={props.showGroupForm}
                            view={props.viewGroup}
                            listGroups={props.listGroups}
                            delete={props.deleteGroup}
                            listStageTeams={props.listStageTeams}
                        />
                    </Box>
                    <Box id="manager-tour">
                        <Tours
                            models={props.tours}
                            loading={props.toursLoading}
                            addDisabled={props.addTourDisabled}
                            showForm={props.showTourForm}
                            view={props.viewTour}
                            listMatches={props.listMatches}
                            delete={props.deleteTour}
                            list={props.listTours}
                        />
                    </Box>
                </Box>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Box id="manager-main">
                    <Card style={{textAlign: "center"}}>
                        <img src={ENDPOINT + "peoples/0/photo"}/>
                    </Card>
                    <DivisionPersonal
                        models={props.divisionPersonals}
                        addDisabled={props.divisionTeamID==0}
                        loading={props.divisionPersonalsLoading}
                        showForm={props.showDivisionPersonalForm}
                        view={props.viewDivisionPersonal}
                        delete={props.deleteDivisionPersonal}
                    />
                </Box>
            </TabPanel>
            <TabPanel value={value} index={3}>
                <Box id="manager-main">
                    <SiteMenu page={page} handleListItemClick={handleListItemClick}/>
                    <Paper>
                        {page === SITE_PAGE_NEWS &&
                            <News
                                models={props.news}
                                showForm={props.showNewsForm}
                                view={props.viewNews}
                                delete={props.deleteNews}
                            />
                        }
                        {page === SITE_PAGE_ALBUM &&
                            <Albums
                                models={props.albums}
                                showForm={props.showAlbumForm}
                                view={props.viewAlbum}
                                delete={props.deleteAlbum}
                            />
                        }
                        {page === SITE_PAGE_PHOTO &&
                            <Photos models={props.photos}/>
                        }
                        {page === SITE_PAGE_VIDEO &&
                            <Videos
                                models={props.videos}
                                showForm={props.showVideoForm}
                                view={props.viewVideo}
                                delete={props.deleteVideo}
                            />
                        }
                        {page === SITE_PAGE_DOC &&
                            <Documents
                                models={props.docs}
                                showForm={props.showDocForm}
                                view={props.viewDoc}
                                delete={props.deleteDoc}
                            />
                        }
                        {page === SITE_PAGE_CONTACT &&
                            <Contacts
                                models={props.contacts}
                                showForm={props.showContactForm}
                                view={props.viewContact}
                                delete={props.deleteContact}
                            />
                        }
                    </Paper>
                </Box>
            </TabPanel>
        </div>
    );
}