import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormHelperText from "@material-ui/core/FormHelperText";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export default function DataSelect(props) {
    const classes = useStyles();

    return (
        <FormControl style={{marginTop:16,width:'100%'}} variant="outlined" className={classes.formControl} error={props.error}>
            <InputLabel htmlFor={props.labelID}>{props.label}</InputLabel>
            <Select
                error={props.error}
                labelId={props.labelID}
                id={props.id}
                name={props.name}
                value={props.value}
                onChange={props.onChange}
                label={props.label}
            >
                <option aria-label="None" value="" />
                {props.data.map((model, i) =>
                    <option key={i} value={model.key}>{model.value}</option>
                )}
            </Select>
            <FormHelperText>{props.helperText}</FormHelperText>
        </FormControl>
    );
}