import React, {useState} from 'react'
import MaterialTable from 'material-table'
import {MANAGER} from "../../constants/Common";
import ComponentMenu from "../common/ComonentMenu";
import {Status} from "../common/Status";
import {FormattedMessage, useIntl} from "react-intl";

export default function Divisions(props) {
    const [selectedRow, setValue] = useState(0);

    let data = [];
    props.models.forEach(n => data.push(n));

    function handleShowForm() {
        props.showForm(true, MANAGER)
    }

    const intl = useIntl()

    return (
        <MaterialTable
            columns={[
                {
                    title: 'ID',
                    field: 'id',
                    cellStyle: {
                        paddingLeft: 20,
                    },
                },
                {
                    title: 'Название',
                    field: 'name',
                    cellStyle: {
                        padding: 12,
                        width: '100%'
                    },
                },
                {
                    title: 'Статус',
                    render: rowData => <Status status={rowData.status}/>,
                    cellStyle: {
                        width: 10,
                        padding: 0,
                    },
                },
                {
                    field: 'menu',
                    render: rowData => <ComponentMenu id={rowData.id}
                                                      delete={props.delete}
                                                      view={props.view}/>,
                    cellStyle: {
                        width: 10,
                        padding: 0,
                    },
                },
            ]}
            onRowClick={((evt, selectedRow) => {
                    // only td click - select rows
                    if (evt.target.getAttribute("value")) {
                        setValue(selectedRow);
                        props.listDivisionTeams('?filter=division_id:' + selectedRow.id, MANAGER)
                        props.listStages('?filter=division_id:' + selectedRow.id, MANAGER)
                    }
                }
            )}
            options={{
                rowStyle: rowData => ({
                    fontSize: 12,
                    backgroundColor: (selectedRow && selectedRow.tableData.id === rowData.tableData.id) ? '#EEE' : '#FFF'
                }),
                paging: false,
                maxBodyHeight: 270,
                minBodyHeight: 270,
                isLoading: true,
                header: false,
                showTitle: false
            }}
            localization={
                {
                    toolbar: {
                        searchPlaceholder: intl.formatMessage({id: 'manager.division-title',defaultMessage:"Divisions"}),
                        searchTooltip: <FormattedMessage id="common.find" defaultMessage="Find"/>
                    },
                    body: {
                        emptyDataSourceMessage: <FormattedMessage id="manager.no_data" defaultMessage="no data"/>
                    }
                }
            }
            actions={[
                {
                    icon: 'add',
                    disabled: props.addDisabled,
                    tooltip: <FormattedMessage id="manager.division-add" defaultMessage="add division"/>,
                    isFreeAction: true,
                    onClick: (event) => handleShowForm()
                }
            ]}
            isLoading={props.loading}
            data={data}
        />
    )
}