import {
    PLAY_SITUATION_CREATE_FAILURE,
    PLAY_SITUATION_DELETE_FAILURE,
    PLAY_SITUATION_LIST_FAILURE,
    PLAY_SITUATION_SHOW_FORM,
    PLAY_SITUATION_UPDATE_FAILURE,
    PLAY_SITUATION_VIEW_FAILURE
} from '../constants/PlaySituation';

import {makeDispatch, makeDispatch500, rstatApi} from '../helpers/api'
import {CORE} from "../constants/Common";
import {CREATE, DELETE, LIST, PLAY_SITUATION, UPDATE, VIEW} from "../constants/Global";

export function showPlaySituationForm(open, type) {
    return (dispatch) => {
        dispatch({
            type: type + PLAY_SITUATION_SHOW_FORM,
            payload: open
        })
    }
}

export function viewPlaySituation(id, type) {
    return (dispatch) => {
        rstatApi.get('/playSituations/' + id).then(function (response) {
            dispatch(makeDispatch(CORE, VIEW, PLAY_SITUATION, response));
        }).catch(function (error) {
            dispatch(makeDispatch500(type, PLAY_SITUATION_VIEW_FAILURE, error))
        });
    }
}

export function updatePlaySituation(id, body, type) {
    return (dispatch) => {
        rstatApi.put('/playSituations/' + id, body).then(function (response) {
            dispatch(makeDispatch(CORE, UPDATE, PLAY_SITUATION, response));
        }).catch(function (error) {
            dispatch(makeDispatch500(type, PLAY_SITUATION_UPDATE_FAILURE, error))
        });
    }
}

export function listCities(query = '', type) {
    return (dispatch) => {
        rstatApi.get('/playSituations' + query).then(function (response) {
            dispatch(makeDispatch(CORE, LIST, PLAY_SITUATION, response));
        }).catch(function (error) {
            dispatch(makeDispatch500(type, PLAY_SITUATION_LIST_FAILURE, error))
        });
    }
}

export function createPlaySituation(body, type) {
    return (dispatch) => {
        rstatApi.post('/playSituations', body).then(function (response) {
            dispatch(makeDispatch(CORE, CREATE, PLAY_SITUATION, response));
        }).catch(function (error) {
            dispatch(makeDispatch500(type, PLAY_SITUATION_CREATE_FAILURE, error))
        });
    }
}

export function deletePlaySituation(id, type) {
    return (dispatch) => {
        rstatApi.delete('/playSituations/' + id,).then(function (response) {
            dispatch(makeDispatch(CORE, DELETE, PLAY_SITUATION, response, parseInt(id, 10)));
        }).catch(function (error) {
            dispatch(makeDispatch500(type, PLAY_SITUATION_DELETE_FAILURE, error))
        })
    }
}