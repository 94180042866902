import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {MANAGER} from "../../../constants/Common";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import {Field, Formik} from "formik";
import * as yup from 'yup'
import FormikAdapter from "../../common/FormikAdapter";
import DataSelect from "../../form/DataSelect";
import FormikSelectAdapter from "../../common/FormikSelectAdapter";
import {
    STAGE_KIND_PLAYOFF,
    STAGE_KIND_ROUNDED,
    STATUS_ACTIVE,
    STATUS_LOCK,
    STATUS_HIDDEN
} from "../../../constants/Competition";
import {FormattedMessage} from "react-intl";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const STAGE_FIELDS = {
    STAGE_ID_FIELD: 'id',
    STAGE_KIND_FIELD: 'kind',
    STAGE_SEASON_ID_FIELD: 'seasonID',
    STAGE_COMPETITION_ID_FIELD: 'competitionID',
    STAGE_DIVISION_ID_FIELD: 'divisionID',
    STAGE_STATUS_FIELD: 'status',
    STAGE_NAME_FIELD: 'name',
    STAGE_SORT_FIELD: 'sort',
};

export default function StageForm(props) {

    const handleClose = () => {
        props.show(false, MANAGER)
    };

    let statuses = [
        {key: STATUS_ACTIVE, value: "Активное"},
        {key: STATUS_HIDDEN, value: "Скрыто"},
        {key: STATUS_LOCK, value: "Закрыто"},
    ]

    let stageType = [
        {key: STAGE_KIND_ROUNDED, value: "Круговой"},
        {key: STAGE_KIND_PLAYOFF, value: "Play-off"},
    ]

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={props.open}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                <FormattedMessage id="stage-form" defaultMessage="Stage form"/>
            </DialogTitle>
            <Formik
                initialValues={{
                    [STAGE_FIELDS.STAGE_ID_FIELD]: props.model.id,
                    [STAGE_FIELDS.STAGE_KIND_FIELD]: props.model.kind,
                    [STAGE_FIELDS.STAGE_SEASON_ID_FIELD]: props.seasonID,
                    [STAGE_FIELDS.STAGE_COMPETITION_ID_FIELD]: props.competitionID,
                    [STAGE_FIELDS.STAGE_DIVISION_ID_FIELD]: props.divisionID,
                    [STAGE_FIELDS.STAGE_STATUS_FIELD]: props.model.status,
                    [STAGE_FIELDS.STAGE_NAME_FIELD]: props.model.name,
                    [STAGE_FIELDS.STAGE_SORT_FIELD]: props.model.sort,
                }}
                onSubmit={(values, actions) => {
                    if (values.id > 0) {
                        props.update(values.id, values, MANAGER);
                    } else {
                        props.create(values, MANAGER);
                    }
                }}
                validationSchema={yup.object().shape({
                    [STAGE_FIELDS.STAGE_NAME_FIELD]: yup.string()
                        .required(<FormattedMessage id="form.required" defaultMessage="required field"/>),
                    [STAGE_FIELDS.STAGE_STATUS_FIELD]: yup.string()
                        .required(<FormattedMessage id="form.required" defaultMessage="required field"/>),
                    [STAGE_FIELDS.STAGE_KIND_FIELD]: yup.string()
                        .required(<FormattedMessage id="form.required" defaultMessage="required field"/>),
                })}
            >
                {(props) => {
                    const {
                        isSubmitting,
                        handleSubmit,
                        handleChange,
                    } = props;
                    return (
                        <form onSubmit={handleSubmit}>
                            <DialogContent dividers>
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <Field
                                            name={STAGE_FIELDS.STAGE_NAME_FIELD}
                                            type="text"
                                            helperText=<FormattedMessage id="manager.stage.form.name.helper" defaultMessage="stage name"/>
                                            inputComponent={TextField}
                                            component={FormikAdapter}
                                            label=<FormattedMessage id="manager.stage.form.name.label" defaultMessage="Name"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Field
                                            name={STAGE_FIELDS.STAGE_KIND_FIELD}
                                            type="select"
                                            helperText=<FormattedMessage id="manager.stage.form.kind.helper" defaultMessage="stage kind"/>
                                            data={stageType}
                                            onChange={handleChange}
                                            inputComponent={DataSelect}
                                            component={FormikSelectAdapter}
                                            label=<FormattedMessage id="manager.stage.form.kind.label" defaultMessage="Kind"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            name={STAGE_FIELDS.STAGE_STATUS_FIELD}
                                            type="select"
                                            helperText=<FormattedMessage id="manager.stage.form.status.helper" defaultMessage="status"/>
                                            data={statuses}
                                            onChange={handleChange}
                                            inputComponent={DataSelect}
                                            component={FormikSelectAdapter}
                                            label=<FormattedMessage id="manager.stage.form.status.label" defaultMessage="Status"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            name={STAGE_FIELDS.STAGE_SORT_FIELD}
                                            type="number"
                                            helperText=<FormattedMessage id="manager.stage.form.sort.helper" defaultMessage="sorting"/>
                                            inputComponent={TextField}
                                            component={FormikAdapter}
                                            label=<FormattedMessage id="manager.stage.form.sort.label" defaultMessage="Sort"/>
                                            margin="normal"
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={isSubmitting}>
                                    <FormattedMessage id="button.save" defaultMessage="Save"/>
                                </Button>
                            </DialogActions>
                        </form>
                    );
                }}
            </Formik>
        </Dialog>
    );
}