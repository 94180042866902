import React, {useEffect, useState} from 'react'
import MaterialTable from 'material-table'
import {CORE, ENDPOINT} from "../constants/Common";
import SportIcon from "./common/SportIcon";
import {FormattedMessage, useIntl} from "react-intl";

export default function Teams(props) {
    const [selectedRow, setValue] = useState(0);
    useEffect(() => {
        props.list('', CORE)
    }, []);

    let data = [];
    props.models.forEach(n => data.push(n));

    function handleShowForm() {
        props.showForm(true, CORE)
    }

    function handleUpdate(id) {
        props.view(id, CORE)
    }

    function handleDelete(id) {
        props.delete(id, CORE)
    }

    const intl = useIntl()

    return (
        <MaterialTable
            title={intl.formatMessage({id: 'team',defaultMessage:"Teams"})}
            columns={[
                {
                    title: 'id',
                    field: 'id',
                },
                {
                    title: <FormattedMessage id="team.logo" defaultMessage="logo"/>,
                    filtering: false,
                    sorting: false,
                    render: rowData => <img src={ENDPOINT + "teams/" + rowData.id + "/logo"}
                                            style={{width: 30, borderRadius: '50%'}} alt="logo"/>
                },
                {
                    field: 'type',
                    title:  <FormattedMessage id="team.sport-kind" defaultMessage="sport kind"/>,
                    cellStyle: {
                        width: 70,
                        padding: 0,
                    },
                    render: rowData => <SportIcon type={rowData.sportType}/>
                },
                {
                    title: <FormattedMessage id="team.abbreviation" defaultMessage="abbreviation"/>,
                    field: 'abbreviation',
                },
                {
                    title: <FormattedMessage id="team.name" defaultMessage="name"/>,
                    field: 'name',
                },
                {
                    title: <FormattedMessage id="team.city" defaultMessage="city"/>,
                    field: 'city.name',
                },
            ]}
            options={{
                rowStyle: rowData => ({
                    fontSize: 12,
                    backgroundColor: (selectedRow && selectedRow.tableData.id === rowData.tableData.id) ? '#EEE' : '#FFF'
                }),
                pageSize:15,
                paging: true,
                header: true,
                sorting: true,
                filtering: true,
                actionsColumnIndex: -1,
                showTitle: true
            }}
            localization={
                {
                    toolbar: {
                        searchPlaceholder: intl.formatMessage({id: 'team-title',defaultMessage:"Teams"}),
                        searchTooltip: <FormattedMessage id="common.find" defaultMessage="find"/>
                    },
                    body: {
                        emptyDataSourceMessage: <FormattedMessage id="common.no_data" defaultMessage="no data"/>
                    }
                }
            }
            actions={[
                {
                    icon: 'add',
                    tooltip: <FormattedMessage id="team.add" defaultMessage="add team"/>,
                    isFreeAction: true,
                    onClick: (event) => handleShowForm()
                },
                {
                    icon: 'search',
                    tooltip: <FormattedMessage id="team.view" defaultMessage="view team"/>,
                    onClick: (event, rowData) => alert("You saved " + rowData.name)
                },
                {
                    icon: 'edit',
                    tooltip: <FormattedMessage id="team.edit" defaultMessage="edit team"/>,
                    onClick: (event, rowData) => handleUpdate(rowData.id)
                },
                {
                    icon: 'delete',
                    tooltip: <FormattedMessage id="team.delete" defaultMessage="delete team"/>,
                    onClick: (event, rowData) => handleDelete(rowData.id)
                }
            ]}
            data={data}
        />
    )
}