import React from 'react';
import clsx from 'clsx';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FaceIcon from '@material-ui/icons/Face';
import PeopleIcon from '@material-ui/icons/SupervisorAccount';
import CityIcon from '@material-ui/icons/LocationCity';
import ManagerIcon from '@material-ui/icons/ViewCompact';
import FlagIcon from '@material-ui/icons/Flag';
import TeamIcon from '@material-ui/icons/Group';
import PublicIcon from '@material-ui/icons/Public';
import StadiumIcon from '@material-ui/icons/LocalConvenienceStore';
import InfoSnackbar from "../components/common/InfoSnackbar";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as InfoActions from "../actions/InfoActions";
import * as UserActions from "../actions/UserActions";
import Logout from "../components/common/Logout";
import WebSocket from "../components/WebSocket";
import {Interests} from "@mui/icons-material";
import {FormattedMessage} from "react-intl";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    title: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
}));

function Navigation(props) {
    const {info, user} = props;
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const {hideSnackbar,showVariantMessage} = props.infoActions;
    const {profile} = props.userActions;

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <div className={classes.root}>
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, open && classes.hide)}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Typography variant="h6" noWrap className={classes.title}>
                        R-Stat (version 2.0)
                    </Typography>
                    <Logout/>
                </Toolbar>
            </AppBar>
            `<Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={open}
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            <div className={classes.drawerHeader}>
                <IconButton onClick={handleDrawerClose}>
                    {theme.direction === 'ltr' ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
                </IconButton>
            </div>
            <Divider/>
            <List component="nav">
                <ListItem button component="a" href="/">
                    <ListItemIcon>
                        <ManagerIcon/>
                    </ListItemIcon>
                    <ListItemText primary=<FormattedMessage id="navigation.manage" defaultMessage="Manage"/>/>
                </ListItem>
                <Divider/>
                <ListItem button component="a" href="/people">
                    <ListItemIcon>
                        <FaceIcon/>
                    </ListItemIcon>
                    <ListItemText primary=<FormattedMessage id="navigation.people" defaultMessage="Peoples"/>/>
                </ListItem>
                <ListItem button component="a" href="/team">
                    <ListItemIcon>
                        <TeamIcon/>
                    </ListItemIcon>
                    <ListItemText primary=<FormattedMessage id="navigation.team" defaultMessage="Teams"/>/>
                </ListItem>
                <Divider/>
                <ListItem button component="a" href="/country">
                    <ListItemIcon>
                        <PublicIcon/>
                    </ListItemIcon>
                    <ListItemText primary=<FormattedMessage id="navigation.country" defaultMessage="Countries"/>/>
                </ListItem>
                <ListItem button component="a" href="/city">
                    <ListItemIcon>
                        <CityIcon/>
                    </ListItemIcon>
                    <ListItemText primary=<FormattedMessage id="navigation.city" defaultMessage="Cities"/>/>
                </ListItem>
                <ListItem button component="a" href="/stadium">
                    <ListItemIcon>
                        <StadiumIcon/>
                    </ListItemIcon>
                    <ListItemText primary=<FormattedMessage id="navigation.stadium" defaultMessage="Stadium"/>/>
                </ListItem>
                <ListItem button component="a" href="/penalty">
                    <ListItemIcon>
                        <FlagIcon/>
                    </ListItemIcon>
                    <ListItemText primary=<FormattedMessage id="navigation.penalty" defaultMessage="Penalties"/>/>
                </ListItem>
                <ListItem button component="a" href="/playSituation">
                    <ListItemIcon>
                        <Interests/>
                    </ListItemIcon>
                    <ListItemText primary=<FormattedMessage id="navigation.play-situation" defaultMessage="Play situation"/>/>
                </ListItem>
                <Divider/>
                <ListItem button component="a" href="/user">
                    <ListItemIcon>
                        <PeopleIcon/>
                    </ListItemIcon>
                    <ListItemText primary=<FormattedMessage id="navigation.user" defaultMessage="Users"/>/>
                </ListItem>
            </List>
        </Drawer>
            <main
                className={clsx(classes.content, {
                    [classes.contentShift]: open,
                })}
            >
            </main>
            <InfoSnackbar
                getProfile={profile}
                hide={hideSnackbar}
                title={info.get('title')}
                message={info.get('message')}
                open={info.get('open')}
                variant={info.get('variant')}
            />
            <WebSocket userID={user.get('userID')} showMessage={showVariantMessage}/>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        info: state.info,
        user: state.user,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        infoActions: bindActionCreators(InfoActions, dispatch),
        userActions: bindActionCreators(UserActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Navigation)